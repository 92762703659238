import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const TopHorizontalScroll = ({ tableRef }: any) => {
  const [scrollWidth, setScrollWidth] = useState(1);

  const onResize = useCallback(() => {
    setScrollWidth(tableRef?.current?.windowRef?.current?.scrollWidth ?? 1);
  }, [setScrollWidth, tableRef]);

  useEffect(() => {
    setTimeout(() => {
      setScrollWidth(tableRef?.current?.windowRef?.current?.scrollWidth ?? 1);
    }, 500);

    window.addEventListener('resize', onResize, false);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, tableRef]);

  const handleScroll = useCallback(
    (e: any) => {
      tableRef.current.scroll({ left: e.target.scrollLeft });
    },
    [tableRef]
  );

  return (
    <Box
      id="topScroll"
      p={0}
      onScroll={handleScroll}
      sx={{
        width: '100%',
        height: 18,
        overflowX: 'scroll',
        overflowY: 'hidden',

        '&::-webkit-scrollbar': {
          width: '10'
        },

        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.4)',
          borderRadius: 8,
          '-webkit-border-radius': 8
        },

        '&::-webkit-scrollbar-thumb': {
          '-webkit-border-radius': 10,
          borderRadius: 10,
          background: 'rgba(100,100,100,0.8)',
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)'
        }
      }}
    >
      <Box p={0} sx={{ width: scrollWidth, visibility: 'hidden' }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu porttitor lectus. Aliquam eu sem vehicula, mollis nunc id,
        pellentesque neque. In eget sapien vel risus efficitur dictum vitae lobortis magna. Vivamus quis convallis mi, sit amet volutpat
        eros. In venenatis tincidunt tellus, fringilla semper sapien mattis non. In a turpis enim. Nulla maximus purus et aliquet vulputate.
        Aenean nibh odio, ornare id molestie eget, convallis aliquet libero. In non ante non tellus placerat sollicitudin. Sed sed molestie
        metus, et interdum nisi. Aliquam sed maximus augue. Vivamus sed sodales mi. Nam congue sapien tortor, a dignissim justo pulvinar
        nec. Fusce vitae odio risus.
      </Box>
    </Box>
  );
};

export default TopHorizontalScroll;
