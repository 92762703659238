import { useMutation, useQuery } from 'react-query';
import useAuth from './useAuth';

function useContactMeRequest(
  page?: number,
  rowsPerPage?: number,
  search?: string,
  includeWaiting?: boolean,
  status?: string,
  isRegistrationMailSend?: boolean | string
) {
  const { userId, request } = useAuth();
  const {
    data: contactMeList,
    isLoading: isLoadingContactMeList,
    refetch: refetchContactMeList
  } = useQuery(
    [`/contactus/contactMeList`, search, includeWaiting, status, isRegistrationMailSend ?? ''],
    () =>
      request.get(`/contactus/contactMeList`, {
        params: {
          search,
          includeWaiting,
          status: status === 'All' ? '' : status === 'To be Scheduled' ? 'Pending' : status,
          isRegistrationMailSend: isRegistrationMailSend === 'All' ? '' : isRegistrationMailSend
        }
      }),
    {
      enabled: !!userId
    }
  );

  const { mutateAsync: updateContactMeCount, isLoading: isUpdatingCount } = useMutation(
    () => request.put(`/contactus/update-count`, { updateCount: true, userId }),
    {
      onSuccess: () => refetchContactMeList()
    }
  );

  const { mutateAsync: deleteFromList, isLoading: isDeletingFormList } = useMutation(({ _id }: any) =>
    request.delete(`/contactus/${_id}/delete`)
  );
  const { mutateAsync: addToWaitingList, isLoading: isUpdatingWaitingList } = useMutation(({ _id }: any) =>
    request.put(`/contactus/${_id}`)
  );

  return {
    contactMeList,
    refetchContactMeList,
    isLoadingContactMeList,
    updateContactMeCount,
    isUpdatingCount,
    deleteFromList,
    isDeletingFormList,
    addToWaitingList,
    isUpdatingWaitingList
  };
}

export default useContactMeRequest;
