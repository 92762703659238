import { CancelPresentation, ExitToApp, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import OT from '@opentok/client';
import { IconCamera, IconCameraOff, IconChevronDown, IconMicrophone, IconMicrophoneOff, IconVolume, IconVolume3 } from '@tabler/icons';
import { IconChevronUp, IconGraph, IconScreenShare, IconScreenShareOff, IconWriting } from '@tabler/icons-react';
// import notCaptioning from 'assets/icons/sessions/caption.svg';
// import captioning from 'assets/icons/sessions/closed-caption.svg';
import modalCloseIcon from 'assets/icons/sessions/modal-close.svg';
import muted from 'assets/icons/sessions/muted.svg';
import quiet from 'assets/icons/sessions/quiet.png';
import speaking from 'assets/icons/sessions/speaking.gif';
import cameraBlocked from 'assets/images/call/camera-blocked.png';
import firefoxCameraBlocked from 'assets/images/call/firefox-camera-blocked.png';
import pinIcon from 'assets/images/call/pin.svg';
import safariCameraPermissions from 'assets/images/call/safari-camera-permissions.png';
import safariPreferences from 'assets/images/call/safari-preferences.png';
import brick1Image from 'assets/images/virt/brick1.jpeg';
import brick2Image from 'assets/images/virt/brick2.jpeg';
import useAuth from 'hooks/useAuth';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useUser from 'hooks/useUser';
import { usePopupState } from 'material-ui-popup-state/hooks';
import moment from 'moment';
import 'moment-timezone';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { browserName } from 'react-device-detect';
import Draggable from 'react-draggable';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParam } from 'react-use';
import useStateRef from 'react-usestateref';
import { SET_MENU } from 'store/actions';
import { User } from 'types/user';
import MainCard from 'views/components/cards/MainCard';
import CenteredLoading from 'views/components/CenteredLoading';
import BasicDialog from 'views/components/modals/BasicDialog';
import PoorConnection from 'views/components/PoorConnection';
import AntSwitch from './AntSwitch';
import BackgroundConfigCard from './BackgroundConfigCard';
import CameraPreview from './CameraPreview';
// import { CaptionBar } from './CaptionBar';
import { SessionContext } from 'contexts/SessionContext';
import { DefaultRootStateProps } from 'types';
import ControlButton from './ControlButton';
import MicrophoneBlockedDialog from './dialogs/MicrophoneBlockedDialog';
import SessionSettingsDialog from './dialogs/SessionSettingsDialog';
import LayoutButton from './LayoutButton';
import ScreenName from './ScreenName';
import SessionNotes from './SessionNotes';
import SessionSideDrawer from './SessionSideDrawer';
import Timer from './Timer';
import useSessionData, { SessionData } from './useSessionData';
import useSessionToken from './useSessionToken';
import useSignals from './useSignals';
import WaitingScreen from './WaitingScreen';
import WhiteBoard from './Whiteboard';

// interface CreateNoteMutationProps {
//   generalNote?: boolean;
//   summary?: string;
// }

const apiKey = process?.env?.REACT_APP_TOKBOX_API_KEY ?? '';
let sessionInitialized = false;

const isMicAvailable = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    console.log('MicAvailable');
    return true;
  } catch (error: any) {
    console.error('Mic Unavailable', error?.name, ':', error?.message);
    return false;
  }
};

export default function Session() {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const challenge = useSearchParam('challenge');
  const { token } = useSessionToken(sessionId, challenge);
  const { sessionData, reportError, sessionError, loadingSessionData } = useSessionData(sessionId, challenge);
  const { userId } = useAuth();
  const { user: loggedUser, isClient, isProvider, isLoading: isUserLoading } = useUser(userId);

  const { showModal } = useModal();

  const [isMicrophoneAvailable, setIsMicrophoneAvailable] = useState<boolean | undefined>(undefined);

  const timezone = useMemo(() => {
    if (!sessionData || !loggedUser) return 'America/Toronto';

    const owner = sessionData?.owner;
    let ownerObj;
    if (loggedUser?.settings?.timezone) return loggedUser?.settings?.timezone;

    if (sessionData?.participants?.length) {
      ownerObj = sessionData?.participants?.find((p) => p?.user?._id === owner);
    }

    return ownerObj?.user?.settings?.timezone ?? 'America/Toronto';
  }, [loggedUser, sessionData]);

  useEffect(() => {
    if (sessionInitialized) {
      window.location.reload();
    }

    (async () => {
      const isMicrophoneAvailable = await isMicAvailable();
      setIsMicrophoneAvailable(isMicrophoneAvailable);

      if (!isMicrophoneAvailable) {
        showModal(MicrophoneBlockedDialog);
      }
    })();
  }, [setIsMicrophoneAvailable, showModal]);

  const [hasSessionPassed, isEarly] = useMemo(() => {
    if (!sessionData) return [false, false];

    const endTime = moment(sessionData.date).add(sessionData.duration, 'minutes');
    const today = moment();

    return [endTime.diff(today, 'minutes') < -120, moment(sessionData.date).diff(today, 'minutes') > 30];
  }, [sessionData]);

  if (sessionError && !sessionData && !loadingSessionData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2 }}>
        <MainCard>
          <Stack sx={{ minWidth: '50vw', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={(theme) => ({ marginBottom: 2, fontSize: 18, fontWeight: '500', color: theme.palette.error.main })}>
              {(sessionError as any)?.response?.data?.message}
            </Typography>
          </Stack>
        </MainCard>
      </Box>
    );
  }
  if (isUserLoading || !sessionId || !token || !sessionData) return <CenteredLoading />;

  if (hasSessionPassed) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2 }}>
        <MainCard>
          <Stack>
            <Typography sx={(theme) => ({ marginBottom: 2, fontSize: 18, fontWeight: '500', color: theme.palette.error.main })}>
              <FormattedMessage
                defaultMessage="This session has expired, has ended or is not
              available at the moment"
              />
            </Typography>

            <Button onClick={() => navigate('/')} variant="contained" sx={{ alignSelf: 'center' }}>
              Continue
            </Button>
          </Stack>
        </MainCard>
      </Box>
    );
  }

  if (isEarly) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2 }}>
        <MainCard>
          <Stack>
            <Typography
              sx={(theme) => ({
                marginBottom: 2,
                fontSize: 18,
                textAlign: 'center',
                fontWeight: '500',
                color: theme.palette.error.main,
                lineHeight: 1.8
              })}
            >
              <FormattedMessage
                defaultMessage="This session will start at {date}."
                values={{
                  date: moment(sessionData?.date).tz(timezone).format('dddd MMMM Do YYYY h:mm A z')
                }}
              />
              <br />
              <FormattedMessage defaultMessage="Your session will be available 30 minutes prior to the scheduled start time." />
            </Typography>

            <Button onClick={() => window.location.reload()} variant="contained" sx={{ alignSelf: 'center' }}>
              Click to Refresh
            </Button>
          </Stack>
        </MainCard>
      </Box>
    );
  }

  if (sessionData?.deleted) {
    const mdate = moment(sessionData.date).tz(timezone);
    const day = mdate.format('dddd MMMM Do YYYY');
    const time = mdate.format('h:mm A z');

    return (
      <Typography variant="h4" sx={{ p: 3, textAlign: 'center' }}>
        <FormattedMessage defaultMessage={'Your session on'} /> {day} at {time}{' '}
        <FormattedMessage defaultMessage={'has been canceled. Please connect with your provider to reschedule.'} />
      </Typography>
    );
  }

  if (!isMicrophoneAvailable) return <Box />;

  return (
    <Box id="sessionContainer" m={0} p={0} sx={{ width: '100%', height: '100%' }}>
      <SessionView
        sessionId={sessionId}
        token={token}
        challenge={challenge}
        loggedUser={loggedUser}
        userId={userId}
        isClient={!loggedUser ? true : isClient}
        isProvider={isProvider}
        sessionData={sessionData}
        reportError={reportError}
      />
    </Box>
  );
}

function SessionView({
  sessionId,
  token,
  challenge,
  loggedUser,
  userId,
  isClient,
  isProvider,
  sessionData,
  reportError
}: {
  sessionId: string;
  token: string;
  challenge?: string | null;
  loggedUser?: User;
  userId?: string;
  isClient: boolean;
  isProvider: boolean;
  sessionData: SessionData;
  reportError: Function;
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { request, guestRequest } = useAuth();
  const handle = useFullScreenHandle();

  const sessionRef = useRef<any>();
  const publisherRef = useRef<any>();
  const screenPublisherRef = useRef<any>();
  const subscribersRef = useRef<any>([]);

  const [initialized, setInitialized] = useState(false);

  const draggableRef = useRef<Draggable | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const pinImageRef = useRef<HTMLImageElement>(null);

  const [sharingScreen, setSharingScreen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [unread, setUnread, unreadRef] = useStateRef({});

  const [screenName, setScreenName, screenNameRef] = useStateRef('');
  const [showPromptSave, setShowPromptSave] = useState(false);

  const { selectedCamera } = useContext(SessionContext);
  const [isEndSessionDialogOpen, setEndSessionDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string | null>(null);
  const [cameraAccessDeniedDialogOpen, setCameraAccessDeniedDialogOpen] = useState(false);

  const [closingAnnotation, setClosingAnnotation] = useState(false);
  const [sessionStartTime, setSessionStartTime] = useState<Date | undefined>();
  const sessionSubscriberId = useRef();
  const subscriberDivId = useRef();
  const publisherUserRef = useRef(null);
  const [publisherUserId, setPublisherUserId] = useState();
  const { user: publisherUserDetails, isLoading } = useUser(publisherUserId);
  const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);

  // const [isCaptioning, setIsCaptioning] = useState(false);
  // const lang = store.getState()?.customization?.locale ?? 'fr';
  // const [currentCaptionsLanguage, setCurrentCaptionsLanguage] = useState(lang === 'fr' ? 'fr-CA' : 'en-US');
  // const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const isConnectionPoor = useNetworkStatus();

  // const onSignalCaptionsStarted = useCallback((_: any) => {
  //   setIsCaptioning(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const onSignalCaptionsStopped = useCallback((_: any) => {
  //   setIsCaptioning(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const toggleIsCaptioning = useCallback(() => {
  //   if (sessionId) {
  //     setIsCaptioning((prev) => !prev);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sessionId, isCaptioning]);

  const dispatch = useDispatch();

  const handleError = useCallback(
    (error) => {
      if (error) {
        console.error('...handleError', error);
        try {
          reportError({ error, errorName: String(error), userId, challenge, date: new Date() });
        } catch (error) {
          console.error('...Error handleError', error);
        }
      }
    },
    [reportError, userId, challenge]
  );

  useEffect(() => {
    // Hide menu
    setTimeout(() => {
      dispatch({ type: SET_MENU, opened: false });
    }, 1000);

    // Prevent login session timeout during session
    const intervalId = setInterval(() => {
      localStorage.setItem('lastActivityTimeStamp', String(moment().unix()));
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const floatingEmail = useMemo(() => {
    setSessionStartTime(sessionData?.startTime);
    if (!challenge) {
      return null;
    }
    const participant = sessionData?.floatingParticipants.find((participant) => participant.challenge === challenge);

    return participant?.email ?? null;
  }, [sessionData, challenge]);

  const attendMutation = useMutation(() => request.post(`/session/${sessionId}/participant/${userId}/attend`));
  const { mutate } = attendMutation;
  useEffect(() => {
    if (!challenge) {
      mutate();
    }
  }, [mutate, challenge]);

  const endMutation = useMutation(
    ({ isEndedByClinician = false, connectionIds = [] }: { isEndedByClinician: boolean; connectionIds?: string[] }) =>
      request.post(`/session/${sessionId}`, { endTime: new Date(), isEndedByClinician, connectionIds })
  );

  const setSessionStartTimeMutation = useMutation((startTime: Date) => request.post(`/session/${sessionId}`, { startTime }));

  const startSessionCountdown = useCallback(() => {
    const startTime = new Date();
    setSessionStartTimeMutation.mutate(startTime);
    setSessionStartTime(startTime);
  }, [setSessionStartTimeMutation, setSessionStartTime]);
  useEffect(() => {
    startSessionCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isOwner = userId === sessionData?.owner;
  const {
    connectedUsers,
    isWaitingScreen = true,
    allMuted,
    setMuteAll = () => {},
    publishVideo = false,
    publishAudio = false,
    setPublishVideo = () => {},
    setPublishAudio = () => {},
    isJoinPrompt,
    setJoinPrompt,
    isParticipantRemoved,
    sessionStarted = false,
    setSessionStarted = () => {},
    arrived,
    emitOpenWhiteboard,
    changeLayout = () => {},
    oneToOneChatEnabled = false,
    setOneToOneChatEnabledAndSignal = () => {},
    enableParticipantsToAnnotate,
    setEnableParticipantsToAnnotateAndSignal,
    cameraGranted,
    setCameraGranted,
    setupSignals,
    handleLayout = () => {},
    activeLayoutRef,
    layoutBeforeRef,
    whiteboardOpen = false,
    setWhiteboardOpen = () => {},
    whiteboardOpenRef,
    annotate,
    annotateRef,
    pinnedStreamIdRef,
    arrivedRef,
    currentLayout
    // captions,
    // onCaptionReceived,
    // transcripts
  } = useSignals({
    sessionRef,
    floatingEmail,
    screenNameRef,
    isOwner,
    publisherRef,
    subscribersRef,
    pinImageRef,
    handleError,
    isClient,
    isProvider
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const handleCaptionLanguage = (language: string) => {
  //   changeCaptionLanguage.mutate(language);
  // };
  // const url = `/session/${sessionId}/captions/start`;
  const getRequest = () => (challenge ? guestRequest : request);

  // const changeCaptionLanguage = useMutation((captionsLanguage: string) =>
  //   getRequest()
  //     .post(url, {
  //       captionsLanguage
  //     })
  //     .then((response) => response?.data)
  //     .then((_) => setCurrentCaptionsLanguage(captionsLanguage))
  // );
  // useEffect(() => {
  //   isOwner && (!captions || !captions.length) && handleCaptionLanguage(lang === 'fr' ? 'fr-CA' : 'en-US');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const createNoteMutation = useMutation(({ summary, generalNote }: CreateNoteMutationProps) =>
  //   request
  //     .post(
  //       `/user/${userId}/notes`,
  //       generalNote
  //         ? {
  //             title: summary,
  //             body: transcripts?.split('~').join('<p>') || '',
  //             sessionId: sessionData.appointmentId,
  //             participants: sessionData.participants.map((participant) => participant.user._id)
  //           }
  //         : {
  //             title: 'Transcriptions',
  //             isProviderNotes: false,
  //             body: transcripts?.split('~').join('<p>') || '',
  //             sessionId: sessionData.appointmentId,
  //             sessionDate: sessionData.date,
  //             participants: sessionData.participants.map((participant) => participant.user._id)
  //           }
  //     )
  //     .then((response) => response.data)
  // );

  // const sendTranscriptions = async () => {
  //   const floatingParticipants = sessionData?.floatingParticipants?.map((floatingParticipant) => floatingParticipant.email);
  //   let registeredParticipants = '';
  //   if (sessionData?.participants && sessionData?.participants?.length > 0) {
  //     const registeredClients = sessionData?.participants?.filter(
  //       (registeredUser) => registeredUser?.user?.isClient || registeredUser?.user?.role === 'CLIENT'
  //     );
  //     // for (let index = 0; index < registeredClients.length; index++) {
  //     //   if (registeredClients[index]?.user?._id) {
  //     //     await createNoteMutation.mutateAsync({ clientUserId: registeredClients[index].user._id, generalNote: false });
  //     //   }
  //     //   if (registeredClients[index]?.user?.firstName && index !== registeredClients?.length - 1) {
  //     //     registeredParticipants += registeredClients[index].user.firstName + ' ' + (registeredClients[index].user?.lastName || '') + ', ';
  //     //   } else if (
  //     //     registeredClients[index]?.user?.firstName &&
  //     //     registeredClients[index]?.user?.lastName &&
  //     //     index === registeredClients?.length - 1
  //     //   ) {
  //     //     registeredParticipants += registeredClients[index].user.firstName + ' ' + (registeredClients[index].user?.lastName || '');
  //     //   }
  //     // }
  //     await createNoteMutation.mutateAsync({ generalNote: false });

  //     for (let index = 0; index < registeredClients.length; index++) {
  //       // if (registeredClients[index]?.user?._id) {
  //       //   await createNoteMutation.mutateAsync({ clientUserId: registeredClients[index].user._id, generalNote: false });
  //       // }
  //       if (registeredClients[index]?.user?.firstName && index !== registeredClients?.length - 1) {
  //         registeredParticipants += registeredClients[index].user.firstName + ' ' + (registeredClients[index].user?.lastName || '') + ', ';
  //       } else if (
  //         registeredClients[index]?.user?.firstName &&
  //         registeredClients[index]?.user?.lastName &&
  //         index === registeredClients?.length - 1
  //       ) {
  //         registeredParticipants += registeredClients[index].user.firstName + ' ' + (registeredClients[index].user?.lastName || '');
  //       }
  //     }
  //   }
  //   const summary =
  //     `Session Summary - Virtual Session With ${floatingParticipants?.join(',')} ${
  //       registeredParticipants ? ',' + registeredParticipants : ''
  //     } on ` +
  //     moment(sessionData.startTime).format(intl.locale === 'fr' ? 'do MMMM ' : 'MMMM do') +
  //     ' ' +
  //     intl.formatMessage({ defaultMessage: 'from' }) +
  //     ' ' +
  //     moment(sessionData.startTime).format(intl.locale === 'fr' ? 'HH:mm' : 'h:mm a') +
  //     ' ' +
  //     intl.formatMessage({ defaultMessage: 'to' }) +
  //     ' ' +
  //     moment(sessionData.startTime)
  //       .add(sessionData?.duration, 'minutes')
  //       .format(intl.locale === 'fr' ? 'do MMMM ' : 'MMMM do') +
  //     ' ' +
  //     moment(sessionData.startTime)
  //       .add(sessionData?.duration, 'minutes')
  //       .format(intl.locale === 'fr' ? 'HH:mm' : 'h:mm a');
  //   sessionData?.floatingParticipants?.length > 0 && (await createNoteMutation.mutateAsync({ summary, generalNote: true }));
  // };

  const turnOffScreenShare = useCallback(() => {
    if (!sessionRef.current) return;

    if (pinnedStreamIdRef) {
      pinnedStreamIdRef.current = null;
    }

    const stream = screenPublisherRef.current;
    if (stream) {
      subscribersRef.current = subscribersRef.current.filter((subscriber) => subscriber.streamId !== stream.streamId);
      screenPublisherRef.current.destroy();
      screenPublisherRef.current = null;
    }

    sessionRef.current.signal({ type: 'stoppedSharing' }, (error) => {
      if (!error) {
        setSharingScreen(false);
      }
    });
    setWhiteboardOpen(false);
    changeLayout(layoutBeforeRef?.current || 'grid');
  }, [sessionRef, changeLayout, setWhiteboardOpen, layoutBeforeRef, pinnedStreamIdRef]);

  const toggleScreenShare = useCallback(() => {
    if (sharingScreen) {
      turnOffScreenShare();
    } else {
      OT.checkScreenSharingCapability(async (r) => {
        if (!r.supported || r.extensionRegistered === false) {
          enqueueSnackbar(
            intl.formatMessage({
              defaultMessage: 'Your browser does not support screen sharing.'
            })
          );
        } else {
          try {
            let media;
            if ((window as any).safari) {
              media = await (navigator.mediaDevices as any).getDisplayMedia({
                video: {
                  cursor: 'always'
                },
                audio: false
              });
              media.getVideoTracks()[0].addEventListener('ended', turnOffScreenShare);
            }

            sessionRef.current.signal({ type: 'startedSharing' }, (error) => {
              if (!error) {
                const publisherDiv = document.getElementById('subscribersGrid')!;

                screenPublisherRef.current = OT.initPublisher(
                  publisherDiv,
                  {
                    insertMode: 'append',
                    width: '100%',
                    height: '100%',
                    publishAudio: true,
                    publishVideo: true,
                    // publishCaptions: true,
                    videoSource: media ? media.getVideoTracks()[0] : 'screen',
                    name: 'screen',
                    fitMode: 'contain',
                    showControls: false
                  },
                  handleError
                );

                screenPublisherRef.current.on({
                  accessAllowed: () => {
                    sessionRef.current?.publish(screenPublisherRef.current, () => {
                      if (error) {
                        console.error('Error in screen share:', error);
                      }
                      subscribersRef.current.push(screenPublisherRef.current);
                      setSharingScreen(true);
                      if (layoutBeforeRef?.current) {
                        layoutBeforeRef.current = activeLayoutRef.current || 'grid';
                      }
                      if (pinnedStreamIdRef) {
                        pinnedStreamIdRef.current = null;
                      }
                      changeLayout('left-sidebar');
                    });
                  },
                  mediaStopped: () => {
                    turnOffScreenShare();
                  },
                  accessDenied: (e) => {
                    console.log('Screen sharing permissions denied:', screenPublisherRef.current);
                    turnOffScreenShare();
                  }
                });
              }
            });
          } catch (error) {
            console.error(error);
            handleError(error);
          }
        }
      });
    }
  }, [
    sharingScreen,
    enqueueSnackbar,
    intl,
    turnOffScreenShare,
    handleError,
    changeLayout,
    activeLayoutRef,
    layoutBeforeRef,
    pinnedStreamIdRef
  ]);

  const hasMediaProcessorSupport = useMemo(() => {
    return (OT as any).hasMediaProcessorSupport();
  }, []);

  useEffect(() => {
    const initializeSession = async () => {
      const publisherDiv = document.getElementById('subscribersGrid')!;
      publisherDiv.innerText = '';

      sessionRef.current = OT.initSession(apiKey, sessionId);
      sessionInitialized = true;
      // sessionRef.current.on({
      //   'signal:captions:started': onSignalCaptionsStarted,
      //   'signal:captions:stopped': onSignalCaptionsStopped
      // });
      sessionRef.current.on('sessionDisconnected', function (event) {
        console.log('The session disconnected. ' + event.reason);
        window.location.replace('/appointments');
      });

      publisherRef.current = OT.initPublisher(
        publisherDiv,
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
          publishVideo: false,
          publishAudio: false,
          // publishCaptions: true,
          showControls: false,
          style: {
            backgroundImageURI: loggedUser?.imageSmall,
            archiveStatusDisplayMode: 'auto',
            nameDisplayMode: 'off',
            audioLevelDisplayMode: 'off'
          }
        },
        handleError
      );
      if (publisherRef?.current) {
        publisherRef?.current.publishCaptions(true);
        publisherRef.current.on('streamCreated', async (event) => {
          // subscribe self for receiving self captions

          // if (sessionRef?.current && publisherRef?.current?.stream) {
          //   const subscriber = sessionRef.current.subscribe(publisherRef.current.stream, null, {
          //     insertMode: 'replace',
          //     width: '0%',
          //     height: '0%',
          //     style: {
          //       buttonDisplayMode: 'off',
          //       nameDisplayMode: 'off'
          //     },
          //     showControls: true,
          //     fitMode: 'contain',
          //     testNetwork: true,
          //     subscribeToAudio: false,
          //     subscribeToVideo: false
          //   });
          //   try {
          //     await subscriber.subscribeToCaptions(true);
          //   } catch (err) {
          //     console.warn(err);
          //   }
          //   const subscriberSelfDiv = document.getElementById(subscriber?.id);
          //   if (subscriberSelfDiv) {
          //     subscriberSelfDiv.style.display = 'none';
          //   }
          //   subscriber.on('captionReceived', (event) => {
          //     onCaptionReceived && onCaptionReceived(event, subscriber);
          //   });
          // }

          // subscribe self for receiving self captions. This doesn't add extra users unlike above method
          // if (sessionRef?.current && publisherRef?.current?.stream) {
          //   const captionOnlySub = sessionRef.current.subscribe(publisherRef.current.stream, document.createElement('div'), {
          //     audioVolume: 0
          //     // testNetwork: true // This option is only needed in OpenTok.js versions below 2.25.1
          //   });

          //   captionOnlySub.on('captionReceived', (event) => {
          //     onCaptionReceived && onCaptionReceived(event, publisherRef.current);
          //   });
          // }

          const currentUserRefId = event?.target?.id;
          const currentUserRefContainer = document.getElementById(currentUserRefId);
          if (currentUserRefContainer && event?.target?.stream) {
            const connectionId = event?.target?.stream?.connection?.id;
            sessionSubscriberId.current = connectionId;
            subscriberDivId.current = currentUserRefId;
          }
        });
        publisherRef.current.on('audioLevelUpdated', (event) => {
          const expectedAudioThreshold = 0.09;
          const currentUserRefId = event?.target?.id;
          const currentUserRefContainer = document.getElementById(currentUserRefId);
          const isSpeakingGifAppended = document.getElementById(`${currentUserRefId}-speakingGif`);
          const isQuietImgAppended = document.getElementById(`${currentUserRefId}-quietImg`);
          const isMutedImgAppended = document.getElementById(`${currentUserRefId}-mutedImg`);
          let speakingGif, quietImg, mutedImg;
          if (currentUserRefContainer) {
            /*
             * here we check if the current user's audio is unmuted
             */
            if (event?.audioLevel > expectedAudioThreshold) {
              // This is used to add border effect to current user
              currentUserRefContainer.setAttribute(
                'style',
                'border: 5px solid #6e9fc3; border-radius: 1rem; overflow: hidden; position: relative;'
              );
              // This is used to add speaking gif to current user
              if (!isSpeakingGifAppended) {
                speakingGif = document.createElement('img');
                speakingGif.id = `${currentUserRefId}-speakingGif`;
                speakingGif.src = speaking;
                speakingGif.position = 'absolute';
                speakingGif.top = 0;
                speakingGif.right = 0;
                speakingGif.height = '30px';
                speakingGif.width = '50px';
                speakingGif.style.zIndex = 50;
                currentUserRefContainer.append(speakingGif);
              } else {
                // This is used to show speaking gif to current user
                isSpeakingGifAppended.setAttribute(
                  'style',
                  'display: block; position: absolute; top: 0; right: 0; height: 50px; width: 50px; z-index: 50;'
                );
              }
              // This is used to remove quiet img from current user
              if (isQuietImgAppended) {
                isQuietImgAppended.setAttribute('style', 'display: none;');
              }
            } else {
              // This is used to add border effect to current user who is muted
              currentUserRefContainer.setAttribute(
                'style',
                'transition: border-color 0.75s ease;border: 5px solid #E3F2FD; border-radius: 1rem; overflow: hidden; position: relative;'
              );
              if (!event?.target?.stream?.hasAudio) {
                // This is used to add muted img to current muted user
                if (!isMutedImgAppended) {
                  mutedImg = document.createElement('img');
                  mutedImg.id = `${currentUserRefId}-mutedImg`;
                  mutedImg.src = muted;
                  mutedImg.position = 'absolute';
                  mutedImg.top = '10px';
                  mutedImg.right = '10px';
                  mutedImg.background = 'white';
                  mutedImg.border = '1px solid white';
                  mutedImg.borderRadius = '50%';
                  mutedImg.padding = '5px';
                  mutedImg.height = '30px';
                  mutedImg.width = '30px';
                  mutedImg.style.zIndex = 50;
                  currentUserRefContainer.append(mutedImg);
                } else {
                  // This is used to show muted img to current user
                  isMutedImgAppended.setAttribute(
                    'style',
                    'display: block; position: absolute; top: 10px; right: 10px; height: 30px; width: 30px; background: white; border: 1px solid white; border-radius: 50%; padding: 5px; z-index: 50;'
                  );
                }
                // This is used to remove quiet img from current user
                if (isQuietImgAppended) {
                  isQuietImgAppended.setAttribute('style', 'display: none;');
                }
              } else {
                // This is used to add border effect to current user who is quiet
                currentUserRefContainer.setAttribute(
                  'style',
                  'transition: border-color 0.75s ease; border: 5px solid #6e9fc3; border-radius: 1rem; overflow: hidden; position: relative;'
                );
                // This is used to add quiet img to current user who is muted
                if (!isQuietImgAppended) {
                  quietImg = document.createElement('img');
                  quietImg.id = `${currentUserRefId}-quietImg`;
                  quietImg.src = quiet;
                  quietImg.position = 'absolute';
                  quietImg.top = 0;
                  quietImg.right = 0;
                  quietImg.height = '50px';
                  quietImg.width = '50px';
                  quietImg.style.zIndex = 50;
                  currentUserRefContainer.append(quietImg);
                } else {
                  // This is used to show quiet img to current user who is muted
                  isQuietImgAppended.setAttribute(
                    'style',
                    'display: block; position: absolute; top: 0; right: 0; height: 50px; width: 50px; z-index: 50;'
                  );
                }
                // This is used to remove muted img from current user
                if (isMutedImgAppended) {
                  isMutedImgAppended.setAttribute('style', 'display: none;');
                }
              }
              // This is used to remove speaking gif from current user
              if (isSpeakingGifAppended) {
                isSpeakingGifAppended.setAttribute('style', 'display: none;');
              }
            }
          }
        });
      }

      // Connect to the session
      sessionRef.current?.connect(token, (error) => {
        if (error) {
          handleError(error);
        } else {
          if (setupSignals) {
            localStorage.setItem('token', token);
            setInitialized(true);
            setupSignals(token);
          }
        }
      });
    };

    initializeSession();

    return () => {
      sessionRef.current?.disconnect();
      sessionRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData, token, sessionId, setupSignals, handleError, hasMediaProcessorSupport, loggedUser]);

  // useCallback(async (subToCaptions, subscriber) => {
  //   if (subscriber) {
  //     try {
  //       await subscriber.subscribeToCaptions(subToCaptions);
  //     } catch (err) {
  //       console.log(`[CaptionsProvider] - subscriber.subscribeToCaptions(${subToCaptions}) err`, err);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (connectedUsers && Object.keys(connectedUsers)?.length) {
      const el = document.querySelector(`#${subscriberDivId.current}`);
      const entries = Object.entries(connectedUsers);
      let publisher;
      entries.forEach((connectedUser) => {
        if (publisherUserRef && publisherUserRef.current !== null) {
          (publisherUserRef.current as any) = connectedUser.find(
            (connectedUserId) => (connectedUserId as any)?.connectionId === sessionSubscriberId.current
          );
        }
        if (!publisher) {
          (publisher as any) = connectedUser.find(
            (connectedUserId) => (connectedUserId as any)?.connectionId === sessionSubscriberId.current
          );
        }
      });

      if (el && publisherUserRef && publisher) {
        !(publisher as any)?.screenName && setPublisherUserId(publisher?.userId);
        const screenNameElementFound = el.querySelector(`#${subscriberDivId.current}-name`);
        if (!screenNameElementFound) {
          const nameTextElement = document.createElement('span');
          nameTextElement.innerHTML =
            (publisher as any)?.screenName || publisherUserDetails?.firstName + ' ' + publisherUserDetails?.lastName;
          nameTextElement.id = `${subscriberDivId.current}-name`;
          nameTextElement.setAttribute(
            'style',
            'display: block; position: absolute; top: 10px; left: 10px; color: white; font-size: 1rem; z-index: 50; font-weight: 500;'
          );
          el.append(nameTextElement);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedUsers]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAnnotationClose = () => {
    setAnnotationAnchorEl(null);
  };

  const isCameraAvailable = useCallback(async () => {
    if (!setCameraGranted) return;

    try {
      await navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedCamera } });
      setCameraGranted(true);
      return true;
    } catch (error: any) {
      setCameraAccessDeniedDialogOpen(true);
      console.error(error?.name, ':', error?.message);
      handleError(error);
      return false;
    }
  }, [setCameraGranted, selectedCamera, handleError]);

  // useEffect(() => {
  //   whiteboardOpen && setIsCaptioning(false);
  // }, [whiteboardOpen]);
  const [annotationAnchorEl, setAnnotationAnchorEl] = useState(null);
  const annotationOpen = Boolean(annotationAnchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnnotationAnchorEl(event.currentTarget);
  };

  const backgroundImageUrls = [
    { name: 'Grey', src: brick1Image },
    { name: 'Brick Wall', src: brick2Image }
  ];
  const { showModal } = useModal();
  const fullScreenRef = useRef<any>(null);
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const popupState = usePopupState({ variant: 'popover', popupId: 'layout-menu' });
  const [type, setType, typeRef] = useStateRef<'none' | 'image' | 'blur'>('none');
  const [activeImage, setActiveImage] = useState(backgroundImageUrls[0]?.src);
  const [closeSubMenu, setCloseSubMenu] = useState(false);
  const [bottomToolbarOpen, setBottomToolbarOpen] = useState(true);
  useEffect(() => {
    if (!publisherRef.current || !publishVideo || !sessionStarted) return;

    const applyFilter = async () => {
      try {
        switch (type) {
          case 'none':
            await publisherRef.current.clearVideoFilter();
            break;
          case 'blur':
            await publisherRef.current.applyVideoFilter({
              type: 'backgroundBlur'
            });
            break;
          case 'image':
            await publisherRef.current.applyVideoFilter({
              type: 'backgroundReplacement',
              backgroundImgUrl: activeImage
            });
            break;
        }
      } catch (error) {
        console.error('Error in applyFilter:', error);
      }
    };

    applyFilter();
  }, [type, activeImage, publisherRef, publishVideo, sessionStarted]);

  return (
    <FullScreen handle={handle} className={classes.root}>
      {sessionData && sessionStartTime && isOwner && connectedUsers && Object.keys(connectedUsers)?.length > 1 ? (
        <Timer startTime={sessionStartTime} duration={sessionData.duration} sessionStarted />
      ) : null}

      {sessionData && notesOpen && <SessionNotes sessionData={sessionData} onClose={() => setNotesOpen(false)} />}

      {setCameraGranted && sessionStarted && (
        <Draggable ref={draggableRef} bounds="#sessionContainer">
          <div
            hidden={sessionStarted && arrived}
            style={{
              zIndex: 1200,
              width: 300,
              height: 200,
              position: 'absolute',
              top: window.innerHeight * 0.1,
              right: 20
            }}
          >
            <CameraPreview
              source="index"
              selectedCamera={selectedCamera}
              setCameraGranted={setCameraGranted}
              defaultImage={loggedUser?.imageSmall}
              publishVideo={publishVideo}
            />
          </div>
        </Draggable>
      )}

      <>
        <BasicDialog
          container={containerRef.current}
          open={!!dialogMessage}
          onClose={() => setDialogMessage(null)}
          maxWidth="sm"
          actions={
            <>
              <Button variant="contained" onClick={() => setDialogMessage(null)}>
                <FormattedMessage defaultMessage="OK" />
              </Button>
            </>
          }
        >
          {dialogMessage}
        </BasicDialog>

        {setJoinPrompt && (
          <BasicDialog
            container={containerRef.current}
            open={isJoinPrompt ?? false}
            onClose={() => setJoinPrompt(false)}
            maxWidth="sm"
            actions={
              <>
                <Button variant="contained" onClick={() => setJoinPrompt(false)}>
                  <FormattedMessage defaultMessage="OK" />
                </Button>
              </>
            }
          >
            <Box sx={{ py: 1 }}>
              {!sessionStarted
                ? intl.locale === 'fr'
                  ? `Votre ${isProvider ? 'patient' : 'Provider'} est arrivé. Vous pouvez démarrer la session.`
                  : `Your ${isProvider ? 'patient' : 'Provider'} has arrived. You can start the session.`
                : intl.formatMessage({ defaultMessage: 'Your camera/microphone is being used for the session' })}
            </Box>
          </BasicDialog>
        )}

        <BasicDialog container={containerRef.current} open={isParticipantRemoved ?? false} maxWidth="sm">
          <Box sx={{ py: 1 }}>
            <FormattedMessage defaultMessage={'You have been removed from session by the Provider'} />
          </Box>
        </BasicDialog>

        <BasicDialog
          container={containerRef.current}
          open={isEndSessionDialogOpen}
          onClose={() => setEndSessionDialogOpen(false)}
          maxWidth="sm"
          actions={
            <>
              <Button
                variant="contained"
                onClick={() => setEndSessionDialogOpen(false)}
                sx={{
                  color: '#727272',
                  background: 'white',
                  '&:hover': {
                    color: '#727272',
                    background: 'white'
                  }
                }}
              >
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: '#D92D20',
                  color: 'white',
                  '&:hover': {
                    color: 'white',
                    background: '#D92D20'
                  }
                }}
                onClick={async () => {
                  if (isOwner) {
                    // await sendTranscriptions();
                    const connectionIds = subscribersRef.current.map((sub) => sub?.stream?.connection?.id);
                    await endMutation.mutateAsync({ isEndedByClinician: true, connectionIds });
                  }
                  sessionRef.current?.disconnect();
                  window.location.replace('/appointments');
                }}
              >
                <FormattedMessage defaultMessage="End Session" />
              </Button>
            </>
          }
        >
          <Stack sx={{ py: 1 }} alignItems="center" justifyContent="center">
            <img src={modalCloseIcon} alt="modal-close" height="50px" width="50px" />{' '}
            <Typography variant="h4">
              <FormattedMessage defaultMessage="Are you sure?" />
            </Typography>
            {isOwner ? (
              <FormattedMessage defaultMessage="You want to end session for everyone" />
            ) : (
              <FormattedMessage defaultMessage="You want to leave the session" />
            )}
          </Stack>
        </BasicDialog>

        {sessionData && setPublishVideo && (
          <ScreenName
            serviceTypeName={sessionData?.serviceType?.name}
            serviceTypeNameFrench={sessionData?.serviceType?.nameFrench}
            durationInMins={sessionData?.serviceType?.durationInMinutes}
            selectedCamera={selectedCamera}
            setCameraGranted={setCameraGranted}
            initialized={initialized}
            containerRef={containerRef}
            publisherRef={publisherRef}
            sessionRef={sessionRef}
            sessionStarted={sessionStarted}
            setSessionStarted={setSessionStarted}
            screenName={screenName}
            setScreenName={setScreenName}
            publishVideo={publishVideo}
            publishAudio={publishAudio}
            setPublishVideo={setPublishVideo}
            setPublishAudio={setPublishAudio}
            floatingEmail={floatingEmail}
            isCameraAvailable={isCameraAvailable}
            arrivedRef={arrivedRef}
          />
        )}

        <BasicDialog
          open={cameraAccessDeniedDialogOpen}
          onClose={() => {
            setCameraAccessDeniedDialogOpen(false);
          }}
          container={containerRef.current}
          maxWidth="xs"
          title={intl.formatMessage({ defaultMessage: 'Camera is blocked' })}
          actions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setCameraAccessDeniedDialogOpen(false);
                }}
              >
                <FormattedMessage defaultMessage="OK" />
              </Button>
            </>
          }
        >
          {browserName === 'Safari' ? (
            <>
              <FormattedMessage defaultMessage={' Please enable access to your Camera.'} />
              <br />
              <FormattedMessage defaultMessage={'Go to'} />{' '}
              <b>
                {' '}
                <FormattedMessage defaultMessage="{Safari}" />
              </b>{' '}
              &gt;{' '}
              <b>
                {' '}
                <FormattedMessage defaultMessage={'Preferences'} />
              </b>{' '}
              <FormattedMessage defaultMessage={'and then allow access to Camera.'} />
              <br />
              <img alt="preferences" src={safariPreferences} style={{ width: 160, marginTop: 20 }} />
              <img alt="cam" src={safariCameraPermissions} style={{ width: 180, marginLeft: 20 }} />
            </>
          ) : (
            <>
              <FormattedMessage defaultMessage={'Please enable access to your Camera. Click the camera blocked icon'} />
              <img
                alt="cam"
                src={browserName === 'Chrome' ? cameraBlocked : firefoxCameraBlocked}
                style={{ width: 25, position: 'relative', left: 4, top: 8, marginRight: 5 }}
              />{' '}
              <FormattedMessage defaultMessage={'in your browser'} />
              &apos; <FormattedMessage defaultMessage={'s address bar.'} />
            </>
          )}
        </BasicDialog>
      </>

      <Box ref={containerRef} style={{ flex: 1, maxWidth: '100%' }} position="relative" height={challenge ? '98vh' : '100%'}>
        {isWaitingScreen && <WaitingScreen isProvider={isProvider} />}

        <Box
          m={0}
          p={0}
          sx={(theme) => ({
            width: '100%',
            // height: isCaptioning ? '78%' : '87%',
            height: '87%',
            backgroundColor: theme.palette.primary.light
          })}
          hidden={isWaitingScreen}
        >
          <div id="subscribersGrid" />

          <div id="leftView">
            <div
              className="leftPanel"
              style={{
                height: challenge ? '90vh' : '78vh'
              }}
            />
            {/* <div className="rightPanel" style={{ height: isCaptioning ? '83%' : '94%' }} /> */}
            <div className="rightPanel" style={{ height: '94%' }} />
          </div>

          <div id="justMe" />
          <div id="pinned" />

          <Draggable ref={draggableRef} bounds="#sessionContainer">
            <div id="pipView" />
          </Draggable>

          <div id="hiddenGrid" hidden />

          {initialized && connectedUsers && Object.keys(connectedUsers)?.length ? (
            <WhiteBoard
              containerRef={containerRef}
              subscribersRef={subscribersRef}
              whiteboardOpen={whiteboardOpen}
              whiteboardOpenRef={whiteboardOpenRef}
              setWhiteBoardOpen={setWhiteboardOpen}
              sessionRef={sessionRef}
              annotate={annotate}
              isProvider={isProvider}
              isOwner={isOwner}
              appointmentId={sessionData.appointmentId}
              setDialogMessage={setDialogMessage}
              showPromptSave={showPromptSave}
              setShowPromptSave={setShowPromptSave}
              closingAnnotation={closingAnnotation}
              setClosingAnnotation={setClosingAnnotation}
              chatOpen={chatOpen}
              changeLayout={changeLayout}
              floatingEmail={floatingEmail}
              sessionData={sessionData}
            />
          ) : null}
          <img
            ref={pinImageRef}
            id="pinIcon"
            className="hide"
            src={pinIcon}
            style={{ zIndex: 3, width: 40, height: 40, backgroundColor: '#337ab7', borderRadius: '10px', padding: '5px' }}
            draggable={false}
            alt="pin"
          />
        </Box>
        {!isWaitingScreen && isConnectionPoor && <PoorConnection />}
        <Stack
          position="absolute"
          zIndex={1101}
          bottom={0}
          sx={{
            maxWidth: '100%',
            overflow: 'hidden'
          }}
        >
          {/* {isCaptioning && (loggedUser?.captionsButtonEnabled || loggedUser?.captionsButtonEnabled === undefined) && !whiteboardOpen ? (
            <Box
              sx={{
                clear: 'both',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                background: 'black',
                color: 'white',
                maxWidth: '90vw',
                mb: 2,
                maxHeight: '4em',
                lineHeight: '2em',
                maxLines: 4,
                overflow: 'hidden',
                alignSelf: 'center',
                alignItems: 'center'
              }}
            >
              <CaptionBar captions={captions} />
            </Box>
          ) : null} */}
          <Stack
            sx={{
              alignItems: 'center',
              height: 'auto',
              transition: 'height 240ms ease-in-out, padding 240ms ease-in-out',
              overflow: bottomToolbarOpen ? 'clip' : 'hidden',
              width: { xs: '100vw', md: leftDrawerOpened ? 'calc(100vw - 338px)' : '100vw' },
              margin: 'auto'
            }}
            ref={fullScreenRef}
          >
            <IconButton
              // onMouseOver={() => {
              //   timerRef.current && clearTimeout(timerRef.current);
              //   setBottomToolbarOpen(true);
              // }},
              onClick={() => setBottomToolbarOpen((prev) => !prev)}
              style={{
                backgroundColor: 'rgba(51,51,51,.7)',
                // borderRadius: 4,
                borderRadius: 0,
                height: 28
              }}
            >
              {bottomToolbarOpen ? <IconChevronDown style={{ color: '#fff' }} /> : <IconChevronUp style={{ color: '#fff' }} />}
            </IconButton>
            <ButtonGroup
              color="primary"
              variant="contained"
              sx={{
                minWidth: 0,
                height: bottomToolbarOpen ? 'auto' : 0
              }}
            >
              {(isOwner && !sessionStartTime) || !isOwner ? (
                <Tooltip
                  title={intl.formatMessage({ defaultMessage: 'End Session' })}
                  PopperProps={{
                    container: containerRef.current
                  }}
                >
                  <ControlButton
                    isEndSession
                    containerRef={containerRef}
                    startIcon={
                      (
                        <CancelPresentation
                          sx={{
                            color: 'white',
                            borderRadius: '50%'
                          }}
                        />
                      ) as any
                    }
                    onClick={() => (!isOwner || !sessionStartTime ? setEndSessionDialogOpen(true) : null)}
                  ></ControlButton>
                </Tooltip>
              ) : null}

              {isOwner && (
                <Tooltip
                  title={intl.formatMessage({ defaultMessage: 'End Session' })}
                  PopperProps={{
                    container: containerRef.current
                  }}
                >
                  <ControlButton
                    isEndSession
                    containerRef={containerRef}
                    startIcon={
                      (
                        <CancelPresentation
                          sx={{
                            color: 'white',
                            borderRadius: '50%'
                          }}
                        />
                      ) as any
                    }
                    onClick={async () => {
                      setEndSessionDialogOpen(true);
                    }}
                  ></ControlButton>
                </Tooltip>
              )}
              {isOwner && (
                <Tooltip
                  title={intl.formatMessage({ defaultMessage: 'Leave Session' })}
                  PopperProps={{
                    container: containerRef.current
                  }}
                >
                  <ControlButton
                    isEndSession
                    containerRef={containerRef}
                    startIcon={
                      (
                        <ExitToApp
                          sx={{
                            color: 'white',
                            borderRadius: '50%'
                          }}
                        />
                      ) as any
                    }
                    onClick={async () => {
                      // await sendTranscriptions();
                      sessionRef.current?.disconnect();
                      window.location.replace('/appointments');
                    }}
                  ></ControlButton>
                </Tooltip>
              )}
              <Tooltip
                title={publishAudio ? intl.formatMessage({ defaultMessage: 'Mute' }) : intl.formatMessage({ defaultMessage: 'Unmute' })}
                PopperProps={{
                  container: containerRef.current
                }}
              >
                <ControlButton
                  sx={{
                    background: publishAudio ? '#1D1D1DCC' : '#D92D20',
                    ':hover': {
                      background: publishAudio ? '#2e6da4' : '#8B0000'
                    }
                  }}
                  containerRef={containerRef}
                  startIcon={publishAudio ? <IconMicrophone size={20} /> : <IconMicrophoneOff size={20} />}
                  onClick={() => setPublishAudio(!publishAudio)}
                ></ControlButton>
              </Tooltip>
              <Tooltip
                title={
                  publishVideo ? intl.formatMessage({ defaultMessage: 'Camera Off' }) : intl.formatMessage({ defaultMessage: 'Camera On' })
                }
                PopperProps={{
                  container: containerRef.current
                }}
              >
                <ControlButton
                  sx={{
                    background: publishVideo ? '#1D1D1DCC' : '#D92D20',
                    ':hover': {
                      background: publishVideo ? '#2e6da4' : '#8B0000'
                    }
                  }}
                  containerRef={containerRef}
                  startIcon={publishVideo ? <IconCamera size={20} /> : <IconCameraOff size={20} />}
                  onClick={async () => {
                    // If camera permissions not granted
                    if (!(await isCameraAvailable())) {
                      setPublishVideo(false);
                      return;
                    }
                    setPublishVideo(!publishVideo);
                  }}
                ></ControlButton>
              </Tooltip>
              {isProvider && (
                <Tooltip
                  title={
                    allMuted ? intl.formatMessage({ defaultMessage: 'Unmute All' }) : intl.formatMessage({ defaultMessage: 'Mute All' })
                  }
                  PopperProps={{
                    container: containerRef.current
                  }}
                >
                  <ControlButton
                    sx={{
                      background: !allMuted ? '#1D1D1DCC' : '#D92D20',
                      ':hover': {
                        background: !allMuted ? '#2e6da4' : '#8B0000'
                      }
                    }}
                    containerRef={containerRef}
                    startIcon={allMuted ? <IconVolume3 size={20} /> : <IconVolume size={20} />}
                    onClick={() => setMuteAll(!allMuted)}
                  ></ControlButton>
                </Tooltip>
              )}
              {!matchDownSM && (
                <>
                  {initialized && hasMediaProcessorSupport && sessionStarted && arrived && (
                    <Tooltip
                      title={intl.formatMessage({ defaultMessage: 'Background' })}
                      PopperProps={{
                        container: containerRef.current
                      }}
                    >
                      <ControlButton
                        startIcon={<IconGraph size={20} />}
                        containerRef={containerRef}
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                          showModal(BackgroundConfigCard, {
                            type,
                            setType,
                            containerRef,
                            publisherRef,
                            publishVideo,
                            sessionStarted,
                            activeImage,
                            setActiveImage,
                            backgroundImageUrls
                          });
                        }}
                      />
                    </Tooltip>
                  )}
                  {sessionStarted && arrived && (
                    <Tooltip
                      title={popupState.isOpen ? '' : intl.formatMessage({ defaultMessage: 'Layout' })}
                      PopperProps={{
                        container: containerRef.current
                      }}
                    >
                      <ControlButton>
                        <LayoutButton
                          popupState={popupState}
                          currentLayout={currentLayout}
                          containerRef={containerRef}
                          onLayoutChange={(layout) => {
                            if (layoutBeforeRef?.current) {
                              layoutBeforeRef.current = layout;
                            }
                            changeLayout(layout);
                          }}
                          draggableRef={draggableRef}
                          whiteboardOpen={whiteboardOpen}
                        />
                      </ControlButton>
                    </Tooltip>
                  )}
                  {sessionStarted && arrived && !sessionData?.disableShareScreen && (
                    <Tooltip
                      title={
                        sharingScreen
                          ? intl.formatMessage({ defaultMessage: 'Stop Sharing' })
                          : intl.formatMessage({ defaultMessage: 'Screen Share' })
                      }
                      PopperProps={{
                        container: containerRef.current
                      }}
                    >
                      <ControlButton
                        startIcon={sharingScreen ? <IconScreenShareOff size={20} /> : <IconScreenShare size={20} />}
                        onClick={() => {
                          toggleScreenShare();
                        }}
                      ></ControlButton>
                    </Tooltip>
                  )}
                  {sessionStarted &&
                    arrived &&
                    connectedUsers &&
                    !sessionData?.disableAnnotation &&
                    !!Object.keys(connectedUsers)?.length && (
                      <Tooltip
                        title={
                          !closeSubMenu
                            ? !whiteboardOpen
                              ? intl.formatMessage({ defaultMessage: 'Annotate' })
                              : intl.formatMessage({ defaultMessage: 'Close Annotation' })
                            : ''
                        }
                        PopperProps={{
                          container: containerRef.current
                        }}
                      >
                        <ControlButton
                          containerRef={containerRef}
                          closeSubMenu={closeSubMenu}
                          setCloseSubMenu={setCloseSubMenu}
                          disabled={!isProvider && !enableParticipantsToAnnotate && !whiteboardOpen}
                          startIcon={<IconWriting size={20} color="white" />}
                          onClick={() => {
                            if (!whiteboardOpenRef.current) {
                              if (isOwner) {
                                setDialogMessage('Annotation will be auto saved every 30 seconds in Client Records');
                              }
                              emitOpenWhiteboard(sharingScreen);
                              setClosingAnnotation(false);
                            }

                            let changeLayoutToBefore = false;
                            if (whiteboardOpenRef.current) {
                              changeLayoutToBefore = true;
                            }
                            if (whiteboardOpenRef.current && isOwner) {
                              setClosingAnnotation(true);
                            } else {
                              setWhiteboardOpen((pre) => !pre);

                              if (changeLayoutToBefore) {
                                changeLayout(layoutBeforeRef.current);
                              }
                            }
                          }}
                          subMenu={
                            isProvider ? (
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCloseSubMenu(true);
                                }}
                              >
                                <FormControlLabel
                                  sx={{
                                    color: '#fff',
                                    fontSize: 13,
                                    padding: '3px 10px 3px 2px',
                                    marginLeft: 0,
                                    borderRadius: (theme) => theme.shape.borderRadius
                                  }}
                                  control={
                                    <AntSwitch
                                      checked={enableParticipantsToAnnotate}
                                      size="small"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setEnableParticipantsToAnnotateAndSignal(!enableParticipantsToAnnotate);
                                      }}
                                    />
                                  }
                                  label={intl.formatMessage({ defaultMessage: 'Enable participants to Annotate' })}
                                  labelPlacement="start"
                                />
                              </MenuItem>
                            ) : null
                          }
                        ></ControlButton>
                      </Tooltip>
                    )}
                </>
              )}

              {/*{(loggedUser?.captionsButtonEnabled || loggedUser?.captionsButtonEnabled === undefined) && (
                <Tooltip title={intl.formatMessage({ defaultMessage: 'Captions' })}>
                  <ControlButton
                    containerRef={containerRef}
                    startIcon={
                      isCaptioning ? (
                        <img src={captioning} style={{ height: '30px', width: '30px' }} alt="captioning" />
                      ) : (
                        <img src={notCaptioning} alt="not-captioning" style={{ height: '30px', width: '30px' }} />
                      )
                    }
                    subMenu={
                      isOwner && (
                        <Stack>
                          <MenuItem
                            disabled={changeCaptionLanguage.isLoading}
                            onClick={() => handleCaptionLanguage('en-US')}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                background: '#337AB7'
                              },
                              '&.Mui-selected': {
                                color: '#337AB7',
                                background: 'white',
                                '&:hover': {
                                  background: '#337AB7',
                                  color: 'white'
                                }
                              }
                            }}
                            selected={currentCaptionsLanguage === 'en-US'}
                          >
                            {intl.formatMessage({ defaultMessage: 'English - US' })}
                          </MenuItem>
                          <MenuItem
                            selected={currentCaptionsLanguage === 'fr-CA'}
                            disabled={changeCaptionLanguage.isLoading}
                            onClick={() => handleCaptionLanguage('fr-CA')}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                background: '#337AB7'
                              },
                              '&.Mui-selected': {
                                color: '#337AB7',
                                background: 'white',
                                '&:hover': {
                                  background: '#337AB7',
                                  color: 'white'
                                }
                              }
                            }}
                          >
                            {intl.formatMessage({ defaultMessage: 'Francais - CA' })}
                          </MenuItem>
                          <MenuItem
                            selected={currentCaptionsLanguage === 'es-US'}
                            disabled={changeCaptionLanguage.isLoading}
                            onClick={() => handleCaptionLanguage('es-US')}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                background: '#337AB7'
                              },
                              '&.Mui-selected': {
                                color: '#337AB7',
                                background: 'white',
                                '&:hover': {
                                  background: '#337AB7',
                                  color: 'white'
                                }
                              }
                            }}
                          >
                            {intl.formatMessage({ defaultMessage: 'Español - US' })}
                          </MenuItem>
                          <MenuItem
                            selected={currentCaptionsLanguage === 'fr-FR'}
                            disabled={changeCaptionLanguage.isLoading}
                            onClick={() => handleCaptionLanguage('fr-FR')}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                background: '#337AB7'
                              },
                              '&.Mui-selected': {
                                color: '#337AB7',
                                background: 'white',
                                '&:hover': {
                                  background: '#337AB7',
                                  color: 'white'
                                }
                              }
                            }}
                          >
                            {intl.formatMessage({ defaultMessage: 'Francais - FR' })}
                          </MenuItem>
                          <MenuItem
                            selected={currentCaptionsLanguage === 'hi-IN'}
                            disabled={changeCaptionLanguage.isLoading}
                            onClick={() => handleCaptionLanguage('hi-IN')}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                background: '#337AB7'
                              },
                              '&.Mui-selected': {
                                color: '#337AB7',
                                background: 'white',
                                '&:hover': {
                                  background: '#337AB7',
                                  color: 'white'
                                }
                              }
                            }}
                          >
                            {intl.formatMessage({ defaultMessage: 'Hindi - IN' })}
                          </MenuItem>
                          <MenuItem
                            selected={currentCaptionsLanguage === 'zh-CN'}
                            disabled={changeCaptionLanguage.isLoading}
                            onClick={() => handleCaptionLanguage('zh-CN')}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                background: '#337AB7'
                              },
                              '&.Mui-selected': {
                                color: '#337AB7',
                                background: 'white',
                                '&:hover': {
                                  background: '#337AB7',
                                  color: 'white'
                                }
                              }
                            }}
                          >
                            {intl.formatMessage({ defaultMessage: 'Chinese - CN' })}
                          </MenuItem>
                        </Stack>
                      )
                    }
                    onClick={toggleIsCaptioning}
                    disabled={
                      !(connectedUsers && Object.keys(connectedUsers)?.length > 1) || changeCaptionLanguage.isLoading || whiteboardOpen
                    }
                  ></ControlButton>
                </Tooltip>
                  )}*/}

              <Tooltip
                title={intl.formatMessage({ defaultMessage: 'More Options' })}
                PopperProps={{
                  container: containerRef.current
                }}
              >
                <Button
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    setAnchorEl(event.currentTarget);
                  }}
                  sx={{ background: '#1D1D1DCC', border: 'none !important' }}
                >
                  <MoreVert />
                </Button>
              </Tooltip>
              <Menu
                container={fullScreenRef.current}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <MenuItem
                  onClick={() => {
                    if (handle.active) {
                      handle.exit();
                    } else {
                      handle.enter();
                    }
                    handleClose();
                  }}
                >
                  <FormattedMessage defaultMessage="Full Screen" />
                </MenuItem>
                {matchDownSM && (
                  <>
                    {initialized && hasMediaProcessorSupport && sessionStarted && arrived && (
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                          showModal(BackgroundConfigCard, {
                            containerRef,
                            publisherRef,
                            publishVideo,
                            sessionStarted,
                            type,
                            setType,
                            activeImage,
                            setActiveImage,
                            backgroundImageUrls
                          });
                        }}
                      >
                        <FormattedMessage defaultMessage="Background" />
                      </MenuItem>
                    )}
                    {sessionStarted && arrived && (
                      <MenuItem>
                        <LayoutButton
                          popupState={popupState}
                          currentLayout={currentLayout}
                          containerRef={containerRef}
                          onLayoutChange={(layout) => {
                            if (layoutBeforeRef?.current) {
                              layoutBeforeRef.current = layout;
                            }
                            changeLayout(layout);
                          }}
                          draggableRef={draggableRef}
                          whiteboardOpen={whiteboardOpen}
                        />
                      </MenuItem>
                    )}
                    {sessionStarted && arrived && !sessionData?.disableShareScreen && (
                      <MenuItem
                        onClick={() => {
                          toggleScreenShare();
                          handleClose();
                        }}
                      >
                        {sharingScreen ? (
                          <FormattedMessage defaultMessage="Stop Sharing" />
                        ) : (
                          <FormattedMessage defaultMessage="Screen Share" />
                        )}
                      </MenuItem>
                    )}
                    {sessionStarted &&
                      arrived &&
                      connectedUsers &&
                      !sessionData?.disableAnnotation &&
                      !!Object.keys(connectedUsers)?.length && (
                        <MenuItem disabled={!isProvider && !enableParticipantsToAnnotate && !whiteboardOpen}>
                          <Box
                            onClick={() => {
                              if (!whiteboardOpenRef.current) {
                                if (isOwner) {
                                  setDialogMessage('Annotation will be auto saved every 30 seconds in Client Records');
                                }
                                emitOpenWhiteboard(sharingScreen);
                                setClosingAnnotation(false);
                              }

                              let changeLayoutToBefore = false;
                              if (whiteboardOpenRef.current) {
                                changeLayoutToBefore = true;
                              }
                              if (whiteboardOpenRef.current && isOwner) {
                                setClosingAnnotation(true);
                              } else {
                                setWhiteboardOpen((pre) => !pre);

                                if (changeLayoutToBefore) {
                                  changeLayout(layoutBeforeRef.current);
                                }
                              }
                            }}
                          >
                            {!whiteboardOpen ? (
                              <FormattedMessage defaultMessage="Annotate" />
                            ) : (
                              <FormattedMessage defaultMessage="Close Annotation" />
                            )}
                          </Box>
                          {isProvider && (
                            <>
                              <Box onClick={handleClick} style={{ position: 'absolute', top: 4, right: 20, cursor: 'pointer' }}>
                                <IconChevronUp size={20} />
                              </Box>

                              <Menu
                                open={annotationOpen}
                                anchorEl={annotationAnchorEl}
                                onClose={handleAnnotationClose}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left'
                                }}
                                transformOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center'
                                }}
                              >
                                <MenuItem onClick={(e) => e.stopPropagation()}>
                                  <Typography>{intl.formatMessage({ defaultMessage: 'Enable participants to Annotate' })}</Typography>
                                  <FormControlLabel
                                    sx={{
                                      color: '#fff',
                                      fontSize: 13,
                                      padding: '3px 2px 3px 2px',
                                      marginLeft: 0,
                                      borderRadius: (theme) => theme.shape.borderRadius
                                    }}
                                    control={
                                      <AntSwitch
                                        checked={enableParticipantsToAnnotate}
                                        size="small"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setEnableParticipantsToAnnotateAndSignal(!enableParticipantsToAnnotate);
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </MenuItem>
                      )}
                  </>
                )}
                {!challenge && !sessionData?.disableNotes && (
                  <MenuItem
                    onClick={() => {
                      setNotesOpen(!notesOpen);
                      handleClose();
                    }}
                  >
                    {!notesOpen ? <FormattedMessage defaultMessage="Open Notes" /> : <FormattedMessage defaultMessage="Close Notes" />}
                  </MenuItem>
                )}
                <MenuItem
                  selected={chatOpen}
                  onClick={() => {
                    setChatOpen((pre) => !pre);
                    handleClose();
                  }}
                >
                  <FormattedMessage defaultMessage="Participants" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    showModal(SessionSettingsDialog, {
                      containerRef: fullScreenRef
                    });
                  }}
                >
                  {<FormattedMessage defaultMessage="Settings" />}
                </MenuItem>
              </Menu>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Box>

      {initialized && (
        <SessionSideDrawer
          containerRef={containerRef}
          open={chatOpen}
          onClose={() => setChatOpen(false)}
          floatingEmail={floatingEmail}
          appointmentId={sessionData?.appointmentId}
          sessionId={sessionId}
          unread={unread}
          unreadRef={unreadRef}
          setUnread={setUnread}
          sessionRef={sessionRef}
          sessionData={sessionData}
          connectedUsers={connectedUsers}
          isWaitingScreen={isWaitingScreen}
          role={loggedUser?.role ?? 'CLIENT'}
          screenName={screenName}
          arrived={arrived}
          oneToOneChatEnabled={oneToOneChatEnabled}
          setOneToOneChatEnabled={setOneToOneChatEnabledAndSignal}
          isOwner={isOwner}
          isProvider={isProvider}
          ownerId={sessionData.owner}
        />
      )}
    </FullScreen>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    '& .OT_root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& .OT_video-poster': {
      backgroundPosition: 'center',
      transform: 'translate(0, 0) !important'
    },

    '& #subscribersGrid': {
      padding: 5,
      width: '100%',
      height: '100%',
      display: 'grid',
      gap: 2
    },

    '& #leftView': {
      display: 'none',
      height: '100%',
      justifyContent: 'space-between',
      gridTemplateColumns: '1fr 3fr',

      '& .leftPanel': {
        margin: 3,
        overflowY: 'scroll',
        overflowX: 'hidden',
        '& > div': {
          height: '320px !important',
          marginBottom: 2
        },
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      },

      '& .rightPanel': {
        margin: 3,
        display: 'grid',
        columnGap: 2,
        rowGap: 2
      }
    },

    '& #justMe': { display: 'none', width: '100%', height: '100%' },
    '& #pinned': { display: 'none', width: '100%', height: '100%' },

    '& #pipView': {
      zIndex: 1300,
      width: 300,
      height: 200,
      display: 'none',
      position: 'absolute',
      top: window.innerHeight * 0.1,
      right: 10,
      '@media (max-width:600px)': {
        width: 150,
        height: 150
      }
    },

    '& #pinIcon': {
      cursor: 'pointer',

      '&.show': {
        display: 'block'
      },
      '&.hide': {
        display: 'none'
      }
    }
  }
});
