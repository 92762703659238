import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useQuery } from 'react-query';
import { Appointment, CalendarAppointment } from 'types/appointment';

interface Props {
  from: Date;
  to: Date;
  id?: string;
  enabled?: boolean;
  limit?: boolean;
}

export default function useUpcomingSession({ from, to, id: _id, enabled = true, limit = false }: Props) {
  const { request, userId } = useAuth();
  const { user } = useUser(userId);

  const id = _id || userId;

  const {
    data: appointments,
    refetch: refetchAppointments,
    isLoading: isLoadingAppointments
  } = useQuery(
    [`/user/${id}/upcoming`, { from, to, limit }],
    () =>
      request
        .get(`/user/${id}/upcoming`, { params: { from, to } })
        .then((response) => response.data.appointmentData as (Appointment | CalendarAppointment)[]),
    { enabled: !!user && enabled }
  );

  return { appointments, refetchAppointments, isLoadingAppointments };
}
