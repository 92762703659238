import ArticleIcon from '@mui/icons-material/Article';
import {
  Box,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { IconLink, IconPaperclip, IconWriting } from '@tabler/icons';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useArticles from 'views/pages/resources/useArticles';
import useResource from 'views/pages/resources/useResources';
import DialogClose from '../buttons/DialogClose';
import MainCard from '../cards/MainCard';
import ListSkeleton from '../skeleton/ListSkeleton';

type Props = {
  imageEnabled?: boolean;
  insertSignature?: Function | null;
  attachment?: boolean;
  handleImageSelect?: ChangeEventHandler<HTMLInputElement>;
  userAnnouncementId?: string;
  announcements?: any;
  onClose: () => void;
  getHyperLinkData?: any;
  enableLink?: boolean;
};

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
  </svg>
);
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
  </svg>
);

const PaperProps = (left: number) => ({
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [left, -40]
      }
    }
  ]
});

const QuillToolbar = ({
  imageEnabled,
  insertSignature,
  handleImageSelect,
  onClose,
  attachment = false,
  getHyperLinkData,
  enableLink
}: Props) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { resources } = useResource();
  const [categoryId, setCategoryId] = useState(null);
  const { articles, isLoading } = useArticles({ categoryId });
  const [resourceTitle, setResourceTitle] = useState('');
  const [resourceURL, setResourceURL] = useState('');

  const handleTitleClick = (announcement) => {
    setResourceTitle(announcement.title);
    setResourceURL(announcement.url);
    setCategoryId(announcement._id);
  };

  const handleArticleClick = (article: any) => {
    const link = `/resources/${resourceURL}/${article.url}`;
    getHyperLinkData({ resourceTitle, articleTitle: article.title, link });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (resources?.length > 0) {
      setResourceTitle(resources[0].title);
      setResourceURL(resources[0].url);
      setCategoryId(resources[0]._id);
    }
  }, [resources]);

  return (
    <>
      <div id="toolbar">
        <Tooltip title={<FormattedMessage defaultMessage="Bold" />}>
          <button type="button" className="ql-bold">
            <FormattedMessage defaultMessage={'Bold'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Italic" />}>
          <button type="button" className="ql-italic">
            <FormattedMessage defaultMessage={'Italic'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Underline" />}>
          <button type="button" className="ql-underline">
            <FormattedMessage defaultMessage={'Underline'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Strike" />}>
          <button type="button" className="ql-strike">
            <FormattedMessage defaultMessage={'Strike'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Emoji" />}>
          <button type="button" className="ql-emoji">
            <FormattedMessage defaultMessage={'Emoji'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Blockquote" />}>
          <button type="button" className="ql-blockquote">
            <FormattedMessage defaultMessage={'Blockquote'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Font" />}>
          <select className="ql-font">
            <option value="arial">
              <FormattedMessage defaultMessage={'Arial'} />
            </option>
            <option value="mirza">
              <FormattedMessage defaultMessage={'Mirza'} />
            </option>
            <option value="roboto">
              <FormattedMessage defaultMessage={'Roboto'} />
            </option>
            <option value="tahoma">
              <FormattedMessage defaultMessage={'Tahoma'} />
            </option>
            <option value="times">
              <FormattedMessage defaultMessage={'Times New Roman'} />
            </option>
            <option value="verdana">
              <FormattedMessage defaultMessage={'Verdana'} />
            </option>
          </select>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Size" />}>
          <select className="ql-size">
            <FormattedMessage defaultMessage={'Font'} />
          </select>
        </Tooltip>

        <span className="ql-formats">
          <Tooltip title={<FormattedMessage defaultMessage="Color" />} PopperProps={PaperProps(-120)}>
            <span>
              <select className="ql-color">
                <FormattedMessage defaultMessage={'Color'} />
              </select>
            </span>
          </Tooltip>
          <Tooltip title={<FormattedMessage defaultMessage="Background" />} PopperProps={PaperProps(-90)}>
            <span>
              <select className="ql-background">
                <FormattedMessage defaultMessage={'Background'} />
              </select>
            </span>
          </Tooltip>
          <Tooltip title={<FormattedMessage defaultMessage="Align" />} PopperProps={PaperProps(-70)}>
            <span>
              <select className="ql-align">
                <FormattedMessage defaultMessage={'Align'} />
              </select>
            </span>
          </Tooltip>

          <Tooltip title={<FormattedMessage defaultMessage="Undo" />} PopperProps={PaperProps(-40)}>
            <span>
              <button className="ql-undo" type="button">
                <CustomUndo />
              </button>
            </span>
          </Tooltip>
          <Tooltip title={<FormattedMessage defaultMessage="Redo" />} PopperProps={PaperProps(-15)}>
            <span>
              <button className="ql-redo" type="button">
                <CustomRedo />
              </button>
            </span>
          </Tooltip>

          {enableLink && (
            <Tooltip title={<FormattedMessage defaultMessage="Add Hyperlink to Resource" />} PopperProps={PaperProps(-15)}>
              <span>
                <IconButton onClick={handleClick}>
                  <IconLink />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </span>

        <Tooltip title={<FormattedMessage defaultMessage="Numbering" />}>
          <button type="button" className="ql-list" value="ordered">
            <FormattedMessage defaultMessage={'List'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Bullets" />}>
          <button type="button" className="ql-list" value="bullet">
            <FormattedMessage defaultMessage={'Bullet'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Decrease Indent" />}>
          <button type="button" className="ql-indent" value="-1">
            <FormattedMessage defaultMessage={'Decrease Indent'} />
          </button>
        </Tooltip>

        <Tooltip title={<FormattedMessage defaultMessage="Increase Indent" />}>
          <button type="button" className="ql-indent" value="+1">
            <FormattedMessage defaultMessage={'Increase Indent'} />
          </button>
        </Tooltip>

        {imageEnabled && (
          <Tooltip title={<FormattedMessage defaultMessage="Image" />}>
            <button type="button" className="ql-image">
              <FormattedMessage defaultMessage={'Image'} />
            </button>
          </Tooltip>
        )}

        <Tooltip title={<FormattedMessage defaultMessage="Clear Formatting" />}>
          <button type="button" className="ql-clean">
            <FormattedMessage defaultMessage={'Clean'} />
          </button>
        </Tooltip>

        {attachment && handleImageSelect && (
          <Tooltip title={<FormattedMessage defaultMessage="Attachments" />}>
            <IconButton component="label" sx={{ padding: 0 }}>
              <IconPaperclip style={{ width: 19, height: 19, margin: 0 }} />
              <input type="file" hidden onChange={handleImageSelect} />
            </IconButton>
          </Tooltip>
        )}

        {!!insertSignature && (
          <span className="ql-formats">
            <Tooltip title={<FormattedMessage defaultMessage="Insert Signature" />}>
              <IconButton
                sx={{ color: 'black' }}
                onClick={() => {
                  if (insertSignature) insertSignature();
                }}
              >
                <IconWriting stroke={2} />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </div>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={open}
      >
        <Box sx={{ display: 'flex', padding: '20px', minWidth: '700px', maxWidth: '700px' }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Stack direction="row" alignItems="center">
                <DialogTitle>
                  <FormattedMessage defaultMessage="Add Hyperlink to Resource" />
                  <DialogClose onClose={() => setAnchorEl(null)} />
                </DialogTitle>
              </Stack>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Stack spacing={1} direction={'row'}>
                {resources?.length > 0 ? (
                  <List sx={{ height: 400, overflowY: 'scroll' }}>
                    {resources?.map((item: any) => (
                      <ListItem disablePadding key={item._id}>
                        <ListItemButton
                          onClick={() => handleTitleClick(item)}
                          sx={{
                            textAlign: 'left',
                            color: resourceTitle === item.title ? '#4b7bb7' : '#858585',
                            fontWeight: resourceTitle === item.title ? 'bold' : '',
                            backgroundColor: resourceTitle === item.title ? '#e2f2fd' : '',
                            borderRadius: 3,
                            cursor: 'pointer',
                            fontSize: 17,
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                            width: 200,
                            '&:hover': {
                              color: '#4b7bb7',
                              backgroundColor: '#e2f2fd'
                            }
                          }}
                        >
                          <ListItemIcon>
                            <IconLink fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box sx={{ height: 400, width: 680, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                      <FormattedMessage defaultMessage={'No resources to show'} />
                    </Typography>
                  </Box>
                )}

                {resources?.map((item) => (
                  <React.Fragment key={item._id}>
                    {resourceTitle === item?.title && (
                      <MainCard sx={{ mt: 10, width: 440 }}>
                        {isLoading ? (
                          <ListSkeleton />
                        ) : (
                          <>
                            {articles?.length > 0 ? (
                              <List sx={{ width: 400, height: 350, overflowY: 'scroll' }}>
                                {articles?.map((article) => (
                                  <React.Fragment key={article._id}>
                                    <ListItem disablePadding key={article._id}>
                                      <ListItemButton onClick={() => handleArticleClick(article)}>
                                        <ListItemIcon>
                                          <ArticleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={article.title} />
                                      </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                  </React.Fragment>
                                ))}
                              </List>
                            ) : (
                              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 20 }}>
                                <FormattedMessage defaultMessage={'No articles to show'} />
                              </Typography>
                            )}
                          </>
                        )}
                      </MainCard>
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default QuillToolbar;
