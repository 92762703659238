/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Slide,
  SlideProps,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/system/Stack';
import useAdminDashboardProviderList from 'hooks/useAdminDashboardProviderList';
import useAdminDashboardStatus from 'hooks/useAdminDashboardStatus';
import useAuth from 'hooks/useAuth';
import useTherapists from 'hooks/useTherapists';
import useUser from 'hooks/useUser';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import { User } from 'types/user';
import useLabels from 'utils/apiTranslations/useLabels';
import { isProviderAdminD } from 'utils/roleMatches';
import AnimateButton from 'views/components/extended/AnimateButton';
import Autocomplete from 'views/components/inputs/Autocomplete';

export const CATEGORIES = ['This Month', 'Last 30 Days', 'Last Month', 'This Year', 'Custom Date'];

const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);
interface FilterAddProps {
  open: boolean;
  isLoading: boolean;
  handleCloseDialog: () => void;
  onSuccess: (selectedProviders: any) => void;
  selectTherapist?: any;
  range: any;
  selectedDate: any;
  handleOpenClearFilter: any;
  clearFilters: any;
  startDate?: any;
  endDate?: any;
}

export default function FilterDashboard({
  open,
  handleCloseDialog,
  onSuccess,
  selectedDate,
  handleOpenClearFilter,
  clearFilters,
  startDate,
  endDate
}: FilterAddProps) {
  const { providerList } = useAdminDashboardProviderList();
  const { userId } = useAuth();
  const { user } = useUser(userId);

  const [tabValue, setTabValue] = useState(0);
  const [selectedProviders, setSelectedProviders] = useState<any[]>([]);
  const [selectedSessionTypes, setSelectedSessionTypes] = useState<any[]>([]);

  const [oldState, setOldSet] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProviders, setFilteredProviders] = useState(providerList);

  const intl = useIntl();

  const { refetchStatus } = useAdminDashboardStatus({ selectedProviders, selectedSessionTypes, selectedDate }, startDate, endDate);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleProviderChange = (event, provider) => {
    if (event.target.checked) {
      setSelectedProviders([...selectedProviders, provider?.id]);
    } else {
      setSelectedProviders(selectedProviders?.filter((id) => id !== provider?.id));
    }
  };

  const handleCloseDialogFilter = () => {
    handleCancel();
    handleCloseDialog();
  };

  const handleSessionTypeChange = (event, sessionType) => {
    if (event.target.checked) {
      setSelectedSessionTypes([...selectedSessionTypes, sessionType.name]);
    } else {
      setSelectedSessionTypes(selectedSessionTypes?.filter((name) => name !== sessionType.name));
    }
  };

  const handleApply = () => {
    onSuccess({ selectedProviders, selectedSessionTypes, selectedDate });
    setOldSet({ selectedProviders, selectedSessionTypes });
    handleOpenClearFilter();
    refetchStatus();
    handleCloseDialog();
  };

  const handleCancel = () => {
    setSelectedProviders(oldState.selectedProviders ? oldState.selectedProviders : []);
    setSelectedSessionTypes(oldState.selectedSessionTypes ? oldState.selectedSessionTypes : []);
    handleCloseDialog();
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredProviders(providerList);
    } else {
      setFilteredProviders(
        providerList.filter((provider) => `${provider.firstName} ${provider.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
  }, [searchQuery, providerList]);

  useEffect(() => {
    if (!clearFilters) {
      setSelectedProviders([]);
      setSelectedSessionTypes([]);
      setOldSet({});
    }
  }, [clearFilters]);

  const sessionTypes = [
    { id: 1, name: intl.formatMessage({ defaultMessage: 'Virtual' }) },
    { id: 2, name: intl.formatMessage({ defaultMessage: 'Text' }) },
    { id: 3, name: intl.formatMessage({ defaultMessage: 'Phone' }) },
    { id: 4, name: intl.formatMessage({ defaultMessage: 'In Person' }) }
  ];

  const ProvidersTabContent = () => (
    <>
      <TextField
        fullWidth
        placeholder={intl.formatMessage({ defaultMessage: 'Search' })}
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <Box mt={'10px'}>
        <Grid container spacing={0}>
          {filteredProviders?.map((provider) => (
            <Grid item xs={12} key={provider.id}>
              <Grid container alignItems="center">
                <Grid item>
                  <Checkbox
                    onChange={(event) => handleProviderChange(event, provider)}
                    checked={selectedProviders.some((p) => p === provider.id)}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">{provider.firstName + ' ' + provider.lastName}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );

  const SessionTypeTabContent = () => (
    <Box mt={'10px'}>
      <Grid container spacing={0}>
        {sessionTypes.map((sessionType) => (
          <Grid item xs={12} key={sessionType.id}>
            <Grid container alignItems="center">
              <Grid item>
                <Checkbox
                  onChange={(event) => handleSessionTypeChange(event, sessionType)}
                  checked={selectedSessionTypes?.some((st) => st === sessionType.name)}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">{sessionType.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderTabContent = (index: number) => {
    if (isProviderAdminD(user as User)) {
      switch (index) {
        case 0:
          return <ProvidersTabContent />;
        case 1:
          return <SessionTypeTabContent />;
        default:
          return null;
      }
    } else return <SessionTypeTabContent />;
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      sx={{
        '&>div:nth-of-type(3)': {
          justifyContent: 'flex-end',
          '&>div': {
            m: 0,
            borderRadius: '0px',
            width: '450px',
            maxHeight: '100%',
            height: '100%'
          }
        }
      }}
    >
      <Paper elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', paddingX: '15px !important' }}>
          <Typography variant="h3">{intl.formatMessage({ defaultMessage: 'Filters' })}</Typography>
          <IconButton onClick={handleCloseDialogFilter}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <Box sx={{ flex: '1', paddingRight: 2, margin: '10px' }}>
            <Tabs value={tabValue} onChange={handleChange} textColor="primary" indicatorColor="primary" orientation="vertical">
              {isProviderAdminD(user as User) && <Tab label={intl.formatMessage({ defaultMessage: 'Providers' })} />}
              <Tab label={intl.formatMessage({ defaultMessage: 'Session Type' })} />
            </Tabs>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: '2', overflowY: 'auto', padding: 2 }}>{renderTabContent(tabValue)}</Box>
        </Box>
        <Divider />

        <DialogActions sx={{ justifyContent: 'space-between', p: 2, position: 'sticky', bottom: '-15px', backgroundColor: 'white' }}>
          <Button
            variant="outlined"
            onClick={() => {
              handleCancel();
            }}
            sx={{ flex: '0.5' }}
          >
            {intl.formatMessage({ defaultMessage: 'Cancel' })}
          </Button>
          <Box sx={{ width: '16px' }} />
          <Button variant="contained" color="primary" onClick={handleApply} sx={{ flex: '0.5' }}>
            {intl.formatMessage({ defaultMessage: 'Apply' })}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}

export function FilterDataShow({
  onClose,
  onSuccess,
  isFilteringData,
  range,
  setRange
}: {
  onClose: () => void;
  onSuccess: (data: any) => void;
  isFilteringData: boolean;
  range: any;
  setRange: (range: any) => void;
}) {
  const { locationTypeLabels } = useLabels();
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const { watch } = useFormContext();

  const locationType = watch('locationType');

  const { setValue } = useFormContext();

  const isOfficeVisible = locationType === 'Office';

  const intl = useIntl();

  const { therapists } = useTherapists();
  const therapistId = watch('therapistId');

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const clearFilter = useCallback(() => {
    onSuccess(null);
    if (onClose) {
      onClose();
    }

    setValue('therapistId', []);
    setValue('serviceType', '');
    setValue('locationType', '');
    setValue('officeType', '');
    setValue('date_filter', '');
    setValue('year_start', '');
    setValue('year_end', '');
    setValue('month_start', '');
    setValue('month_end', '');
    setRange([null, null]);
  }, []);

  return (
    <>
      <DialogTitle display="flex" justifyContent="space-between">
        <FormattedMessage defaultMessage="New Filter Data" />
        <Button color="primary" variant="contained" onClick={() => clearFilter()}>
          <FormattedMessage defaultMessage="Clear Filter" />
        </Button>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
          {(user?.role === 'NOUS_SUPPORT' ||
            user?.role === 'PROVIDER_ADMINISTRATOR' ||
            user?.role === 'PROVIDER_ADMINISTRATOR (S)' ||
            user?.role === 'PROVIDER_ADMINISTRATOR (D)' ||
            user?.role === 'ADMINISTRATOR') &&
            therapists && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={therapists || []}
                  filterSelectedOptions
                  getOptionValue={(option: any) => option?._id}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
                  name="therapistId"
                  label={intl.formatMessage({ defaultMessage: 'Therapist' })}
                  disabled={therapists === undefined}
                />
              </Grid>
            )}
          {(user?.role === 'NOUS_SUPPORT' ||
            user?.role === 'PROVIDER_ADMINISTRATOR' ||
            user?.role === 'PROVIDER_ADMINISTRATOR (S)' ||
            user?.role === 'PROVIDER_ADMINISTRATOR (D)' ||
            user?.role === 'ADMINISTRATOR') &&
            therapistId?.length > 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  required
                  options={therapists?.find((therapist) => therapist._id === therapistId[0])?.provider?.services || []}
                  getOptionValue={(option: any) => option?._id}
                  getOptionLabel={(option: any) => option?.name ?? ''}
                  isOptionEqualToValue={(option, value) => option._id === value?._id}
                  label={intl.formatMessage({ defaultMessage: 'Service' })}
                  name="serviceType"
                />
              </Grid>
            )}
          {user?.role !== 'NOUS_SUPPORT' && (
            <Grid item xs={12}>
              <Autocomplete
                required
                options={user?.provider.typeOfAppointments ?? []}
                getOptionLabel={(option: any) => {
                  return locationTypeLabels[option] ?? option;
                }}
                isOptionEqualToValue={(option, value) => !!value && option === value}
                label={intl.formatMessage({ defaultMessage: 'Session Type' })}
                name="locationType"
              />
            </Grid>
          )}
          {isOfficeVisible && (
            <Grid item xs={12}>
              <Autocomplete
                required
                options={user?.provider.offices ?? []}
                getOptionLabel={(option: any) => option?.officeName}
                getOptionValue={(option: any) => option?._id}
                label={intl.formatMessage({ defaultMessage: 'Location' })}
                name="officeType"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <AnimateButton>
            <Button type="submit" variant="contained" color="secondary" disabled={isFilteringData}>
              {intl.formatMessage({ defaultMessage: 'Search Details' })}
            </Button>
          </AnimateButton>
          <Button variant="text" color="error" onClick={handleCancel}>
            {intl.formatMessage({ defaultMessage: 'Close' })}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}
