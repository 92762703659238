import { InitialLoginContextProps } from 'types';
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from '../actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

export interface AccountReducerActionProps {
  type: string;
  payload?: InitialLoginContextProps;
}

const accountReducer = (state: InitialLoginContextProps, action: AccountReducerActionProps) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, userId, token } = action.payload!;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        userId,
        token
      };
    }
    case LOGIN: {
      const { userId, token } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        userId,
        token
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        userId: undefined,
        token: undefined
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
