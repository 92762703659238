import { Box, Dialog, Tab, Tabs } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useTherapists from 'hooks/useTherapists';
import useUser from 'hooks/useUser';
import useUserCalendars from 'hooks/useUserCalendars';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Appointment } from 'types/appointment';
import Loader from 'views/components/Loader';
import AppointmentDetail from './AppointmentDetail';
import BlockOffTimeSlot from './BlockOffTimeSlot';
import CreateAppointment from './CreateAppointment';
import CreateGroupAppointment from './CreateGroupAppointment';
import usePatients from './usePatients';

type Props = {
  appointments: Appointment[];
  onSuccess: () => void;
  createInitialDate: Date;
  initializeTime?: boolean;
  baseUrl: string;
  disableBlockOffTab?: boolean;
};

interface TabPanelProps {
  children?: React.ReactNode;
  value: Number;
  index: Number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box hidden={String(value) !== String(index)} {...other}>
      <Box>{children}</Box>
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const AppointmentDialogs: React.FC<Props> = ({
  appointments,
  onSuccess,
  createInitialDate,
  initializeTime,
  baseUrl,
  disableBlockOffTab,
  ...props
}) => {
  const intl = useIntl();
  const { options } = useOptions();
  const { userId: id } = useAuth();
  const { user } = useUser(id);

  const queryParams = new URLSearchParams(useLocation().search);
  const { appointmentId, userId } = useParams();
  const therapistId = userId ?? queryParams.get('therapist') ?? id;
  const blockOff = queryParams.get('block') ?? false;
  const location: any = useLocation();
  const { calendars } = useUserCalendars(therapistId);
  const { patientsLoading } = usePatients(therapistId);
  const { therapistsLoading } = useTherapists();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(blockOff ? 1 : 0);

  const isCreateGroupOpen = appointmentId === 'create-group';
  const isCreateOpen = appointmentId === 'create';
  const selectedAppointment = useMemo(
    () => appointments?.find((appointment) => !!appointment.event_id && appointment.event_id === appointmentId),
    [appointmentId, appointments]
  );

  if (patientsLoading || therapistsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Dialog maxWidth="sm" fullWidth onClose={() => navigate(baseUrl)} open={isCreateOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {isCreateOpen && (
          <>
            {disableBlockOffTab || !user?.userOptions?.enableBlockOffCalendar || !!calendars?.length ? (
              <CreateAppointment
                initialDate={createInitialDate}
                initializeTime={initializeTime}
                onCreateSuccess={onSuccess}
                onClose={() => navigate(baseUrl)}
                disableBlockOffTab
              />
            ) : (
              <>
                <Tabs
                  value={activeTab}
                  onChange={(e, index) => {
                    setActiveTab(index);
                  }}
                  variant="fullWidth"
                  aria-label="Create Your Appointment"
                >
                  <Tab
                    label={intl.formatMessage({ defaultMessage: 'Create Your Appointment' })}
                    sx={{ textTransform: 'none', fontSize: 17 }}
                    {...a11yProps(0)}
                  />
                  {!location?.state?.row && (
                    <Tab
                      label={intl.formatMessage({ defaultMessage: 'Block Off Time Slot' })}
                      sx={{ textTransform: 'none', fontSize: 17 }}
                      {...a11yProps(1)}
                    />
                  )}
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                  <CreateAppointment
                    initialDate={createInitialDate}
                    initializeTime={initializeTime}
                    onCreateSuccess={onSuccess}
                    onClose={() => navigate(baseUrl)}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <BlockOffTimeSlot
                    initialDate={createInitialDate}
                    initializeTime={initializeTime}
                    onCreateSuccess={onSuccess}
                    onClose={() => navigate(baseUrl)}
                  />
                </TabPanel>
              </>
            )}
          </>
        )}
      </Dialog>

      <Dialog maxWidth="sm" fullWidth onClose={() => navigate(baseUrl)} open={isCreateGroupOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {isCreateGroupOpen && (
          <CreateGroupAppointment initialDate={new Date()} onCreateSuccess={onSuccess} onClose={() => navigate(baseUrl)} />
        )}
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => navigate(baseUrl)}
        open={!!selectedAppointment}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        {selectedAppointment && (
          <AppointmentDetail
            onClose={() => navigate(baseUrl)}
            baseUrl={baseUrl}
            appointment={selectedAppointment}
            onCancelSuccess={onSuccess}
            onUpdateSuccess={onSuccess}
          />
        )}
      </Dialog>
    </>
  );
};

export default AppointmentDialogs;
