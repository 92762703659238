import { Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material';
import { Box, Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TestAgain from 'assets/icons/sessions/test-again.svg';
import testingGif from 'assets/icons/sessions/testing.gif';
import useAuth from 'hooks/useAuth';
import NetworkTest from 'opentok-network-test-js';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import VolumeMeter from './VolumeMeter';

declare const OT: any;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    gap: 20,
    fontWeight: 500,
    '& .iconsBox': {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 10,
      gap: 10,
      '& .passed': {
        color: 'green',
        fontSize: 35
      },
      '& .failed': {
        color: 'red',
        fontSize: 35
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  }
}));

const ConnectionTest = () => {
  const classes = useStyles();
  const intl = useIntl();

  const { guestRequest } = useAuth();
  const { data: session } = useQuery(`/connection-test/`, () => guestRequest.post(`/connection-test/`).then((response) => response.data));

  const [connectivityPassed, setConnectivityPassed] = useState<any>(false);
  const [results, setResults] = useState<any>();
  const [volumeAverage, setVolumeAverage] = useState(0);

  const startPreviewSession = (session) => {
    setConnectivityPassed(false);
    setResults(undefined);
    const apiKey = process.env.REACT_APP_TOKBOX_API_KEY;
    if (!apiKey) {
      return;
    }

    const otNetworkTest = new NetworkTest(OT, {
      apiKey,
      sessionId: session.sessionId,
      token: session.token
    });
    otNetworkTest
      .testConnectivity()
      .then((results) => {
        setConnectivityPassed(results.success);
      })
      .then((stats) => {
        otNetworkTest.testQuality().then((results) => setResults({ audio: results?.audio?.supported, video: results?.video?.supported }));
      });
  };
  const getAudioLevels = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      microphone.connect(analyser);
      analyser.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);
      javascriptNode.onaudioprocess = () => {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;

        const length = array.length;
        for (let i = 0; i < length; i++) {
          values += array[i];
        }

        const average = (values / length) * 0.6;
        setVolumeAverage(average);
      };
    });
  };

  useEffect(() => {
    if (session) {
      startPreviewSession(session);
      getAudioLevels();
    }
  }, [session]);

  return (
    <Box>
      <Box className={classes.root}>
        {!!results && (
          <>
            <Box gap="5px">
              <Box>
                {' '}
                <FormattedMessage defaultMessage={'Status:'} /> {connectivityPassed ? 'Passed' : 'Failed'}
              </Box>
              <Box className="iconsBox" gap="5px">
                {results?.video ? (
                  <Box>
                    <Videocam className="passed" />
                    <FormattedMessage defaultMessage={'Passed'} />{' '}
                  </Box>
                ) : (
                  <Box>
                    <VideocamOff className="failed" />
                    <FormattedMessage defaultMessage={'Failed'} />
                  </Box>
                )}
                {results?.audio ? (
                  <Box>
                    <Mic className="passed" />
                    <FormattedMessage defaultMessage={'Passed'} />
                  </Box>
                ) : (
                  <Box>
                    <MicOff className="failed" />
                    <FormattedMessage defaultMessage={'Failed'} />
                  </Box>
                )}
              </Box>
            </Box>
            <Box gap={1}>
              <VolumeMeter average={volumeAverage} />
              <Box gap={1} display="flex" alignItems="center" onClick={() => startPreviewSession(session)} sx={{ cursor: 'pointer' }}>
                <img src={TestAgain} alt="test-again" />
                <Button type="button">
                  <FormattedMessage defaultMessage={' Test Again'} />
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {!results && (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row">
          <img src={testingGif} alt="testing" height="24px" width="24px" />
          <Typography sx={{ fontSize: '14px', color: '#565656', mt: 1 }}>{intl.formatMessage({ defaultMessage: 'Testing...' })}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ConnectionTest;
