import { Box, Button, DialogActions, DialogContent, Divider, Stack } from '@mui/material';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { range } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useLocation, useParams } from 'react-router';
import { GET_DAYS_OF_WEEK, GET_REPEAT_FREQUENCY_OPTIONS } from 'utils/constants';

import { getMonthDayNumber, getNumberWithOrdinal } from 'utils/dates';
import Autocomplete from 'views/components/inputs/Autocomplete';
import BubbleColorPicker from 'views/components/inputs/BubbleColorPicker';
import Checkbox from 'views/components/inputs/Checkbox';
import DatePicker from 'views/components/inputs/DatePicker';
import FormContainer from 'views/components/inputs/FormContainer';
import MultiDatePicker from 'views/components/inputs/MultiDatePicker';
import Select from 'views/components/inputs/Select';
import TextField from 'views/components/inputs/TextField';
import TimePicker from 'views/components/inputs/TimePicker';
import * as Yup from 'yup';
import RadioButtonGroup from '../../components/inputs/RadioButtonGroup';
import Loader from '../../components/Loader';

type FormValues = any;
interface Props {
  initialDate: Date;
  initializeTime?: boolean;
  onCreateSuccess: () => void;
  onClose: () => void;
}

type REPEAT_OPTIONS = 'singleEvent' | 'recurringEvent' | 'multipleDates';

export default function BlockOffTimeSlot({ initialDate, initializeTime, onCreateSuccess, onClose }: Props) {
  const intl = useIntl();
  const { request, userId: id } = useAuth();

  const { userId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const queryParams = new URLSearchParams(useLocation().search);

  const therapistId = userId ?? queryParams.get('therapist') ?? id;

  const blockOffTimeSlotMutation = useMutation((values) => request.post(`/user/${therapistId}/availability`, values));

  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Date is not valid' })),
    startTime: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Start time is not valid' })),
    endTime: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'End time is not valid' }))
      .min(Yup.ref('startTime'), "End Time can't be before Start Time"),
    description: Yup.string(),
    repeatFrequency: Yup.string(),
    repeatNumberOfTimes: Yup.number(),
    isRecurring: Yup.bool()
  });

  const initialValues = {
    bubbleColor: '#f44336',
    date: queryParams.get('date') ? new Date(queryParams.get('date') ?? '') : initialDate,
    startTime: queryParams.get('startTime') ? moment(queryParams.get('startTime'), 'hh:mm A').toDate() : null,
    endTime: queryParams.get('endTime') ? moment(queryParams.get('endTime'), 'hh:mm A').toDate() : null,
    description: '',
    fullDay: false,

    repeatFrequency: GET_REPEAT_FREQUENCY_OPTIONS()[0].value,
    repeatNumberOfTimes: 0,
    repeatOptions: 'singleEvent'
  };

  const onFormSubmit = useCallback(
    async ({ repeatOptions, date, multipleDates, startTime, endTime, ...values }: FormValues) => {
      const isRecurring = (repeatOptions as REPEAT_OPTIONS) === 'recurringEvent';
      const isMultipleDates = (repeatOptions as REPEAT_OPTIONS) === 'multipleDates';

      try {
        setIsSubmitting(true);

        const startTimeStr = moment(startTime).format('HH:mm');
        const endTimeStr = moment(endTime).format('HH:mm');

        const data = {
          ...values,
          repeatOptions,
          repeatFrequency: isRecurring ? values.repeatFrequency : 'None',
          repeatDays: values.repeatDays?.filter((obj) => {
            if (typeof obj === 'number') return true;
            return false;
          }),
          repeatNumberOfTimes: isRecurring ? values.repeatNumberOfTimes || 0 : 0,
          dates: isMultipleDates
            ? multipleDates?.map((d) => ({
                start: d.unix
                  ? moment(`${moment.unix(d.unix).format('YYYY-MM-DD')} ${startTimeStr}`).toISOString()
                  : moment(`${moment(d).format('YYYY-MM-DD')} ${startTimeStr}`).toISOString(),
                end: d.unix
                  ? moment(`${moment.unix(d.unix).format('YYYY-MM-DD')} ${endTimeStr}`).toISOString()
                  : moment(`${moment(d).format('YYYY-MM-DD')} ${endTimeStr}`).toISOString()
              })) ?? []
            : [
                {
                  start: moment(`${moment(date).format('YYYY-MM-DD')} ${moment(startTime).format('HH:mm')}`).toISOString(),
                  end: moment(`${moment(date).format('YYYY-MM-DD')} ${moment(endTime).format('HH:mm')}`).toISOString()
                }
              ]
        };

        await blockOffTimeSlotMutation.mutateAsync(data);
        onCreateSuccess();
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Blocked Time' }), { variant: 'success' });
      } catch (error) {
        console.error(error);
        setIsSubmitting(false);
      }
    },
    [blockOffTimeSlotMutation, onCreateSuccess, enqueueSnackbar, intl]
  );

  return (
    <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onFormSubmit}>
      <Divider />
      <CreateAppointmentForm onClose={onClose} isSubmitting={isSubmitting} />
    </FormContainer>
  );
}

function CreateAppointmentForm({ onClose, isSubmitting }: { onClose: Function; isSubmitting: boolean }) {
  const { reset } = useFormContext();

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }

    reset();
  }, [reset, onClose]);

  return (
    <>
      {isSubmitting && <Loader />}
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3} mb={3}>
          <AppointmentInfoFields />
        </Stack>

        <Divider />
        <DialogActions sx={{ py: 2, px: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button type="button" variant="outlined" onClick={handleCancel}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              <FormattedMessage defaultMessage="Block Off Time Slot" />
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </>
  );
}

export function AppointmentInfoFields() {
  const intl = useIntl();
  const { watch, setValue } = useFormContext();
  const fullDay = watch('fullDay');
  const repeatOptions = watch('repeatOptions');

  useEffect(() => {
    if (fullDay) {
      setValue('startTime', moment().startOf('day').toDate());
      setValue('endTime', moment().endOf('day').toDate());
    }
  }, [fullDay, setValue]);

  return (
    <>
      <Box sx={(theme) => ({ p: 1, mb: 1, backgroundColor: '#e5e5e5' })}>
        <RadioButtonGroup
          name="repeatOptions"
          label={intl.formatMessage({ defaultMessage: 'Repeat Options' })}
          options={[
            { label: 'Single Event', id: 'singleEvent' },
            { label: 'Recurring Event', id: 'recurringEvent' },
            { label: 'Multiple Dates', id: 'multipleDates' }
          ]}
          row
        />
      </Box>

      <Stack direction="row" spacing={2} alignItems="center">
        {repeatOptions === 'multipleDates' ? (
          <MultiDatePicker name="multipleDates" label={intl.formatMessage({ defaultMessage: 'Date' })} required />
        ) : (
          <DatePicker name="date" label={intl.formatMessage({ defaultMessage: 'Date' })} required />
        )}

        <Box minWidth={100}>
          <Checkbox name="fullDay" color="primary" label={intl.formatMessage({ defaultMessage: 'Full Day' })} />
        </Box>
      </Stack>

      {!fullDay && (
        <Stack direction="row" spacing={2} alignItems="center">
          <>
            <TimePicker name="startTime" label={intl.formatMessage({ defaultMessage: 'Start Time' })} sx={{ flex: 1 }} required />
            <TimePicker name="endTime" label={intl.formatMessage({ defaultMessage: 'End Time' })} sx={{ flex: 1 }} required />
          </>
        </Stack>
      )}

      <RecurringFormFields />

      <TextField name="description" label={intl.formatMessage({ defaultMessage: 'Description, if any' })} fullWidth multiline rows={5} />

      <Stack direction="row" spacing={2} alignItems="center">
        <BubbleColorPicker name="bubbleColor" label={<FormattedMessage defaultMessage="Bubble Color" />} />

        <Select
          name="label"
          label={intl.formatMessage({ defaultMessage: 'Label' })}
          options={['Personal', 'Break', 'Lunch', 'Breakfast', 'Dinner', 'Other']}
        />
      </Stack>
    </>
  );
}

const repeatForOptions = range(1, 15).map((i) => ({
  id: i,
  label: `${i}`
}));

export function RecurringFormFields() {
  const intl = useIntl();
  const { control, setValue } = useFormContext();

  const repeatFrequency = useWatch({
    control,
    name: 'repeatFrequency',
    defaultValue: GET_REPEAT_FREQUENCY_OPTIONS()[0].value
  });

  const repeatOptions: REPEAT_OPTIONS = useWatch({
    control,
    name: 'repeatOptions',
    defaultValue: false
  });

  const isRecurring = repeatOptions === 'recurringEvent';

  const selectedDate = moment(
    useWatch({
      control,
      name: 'date'
    })
  ).toDate();

  useEffect(() => {
    setValue('repeatNumberOfTimes', repeatForOptions[0].id);
  }, [setValue]);

  useEffect(() => {
    if (repeatFrequency === 'Weeks') {
      setValue('repeatDays', [GET_DAYS_OF_WEEK()[selectedDate.getDay()].id]);
    } else {
      setValue('repeatDays', []);
    }
  }, [repeatFrequency, selectedDate, setValue]);

  if (!isRecurring) return null;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          required
          style={{ flex: 1 }}
          name="repeatNumberOfTimes"
          label={intl.formatMessage({ defaultMessage: 'Repeat for' })}
          options={repeatForOptions}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          defaultValue={repeatForOptions[0]}
          filterOptions={(x) => x}
        />

        <Autocomplete
          required
          style={{ flex: 1 }}
          name="repeatFrequency"
          options={GET_REPEAT_FREQUENCY_OPTIONS()}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Stack>

      {repeatFrequency === 'Weeks' && (
        <Autocomplete
          required
          multiple
          name="repeatDays"
          options={GET_DAYS_OF_WEEK()}
          label={intl.formatMessage({ defaultMessage: 'Repeat on' })}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      )}

      {repeatFrequency === 'Months' && selectedDate && (
        <Autocomplete
          required
          name="repeatMonthDays"
          options={[
            {
              name: `${getMonthDayNumber(selectedDate)} ${format(selectedDate, 'EEEE')} of every month`,
              value: 'SameWeekDay'
            },
            { name: `${getNumberWithOrdinal(selectedDate.getDate())} of every month`, value: 'SameDate' }
          ]}
          label={intl.formatMessage({ defaultMessage: 'Repeat on' })}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      )}
    </>
  );
}
