import { FormattedMessage, useIntl } from 'react-intl';
import BasicDialog from 'views/components/modals/BasicDialog';

import { Button, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actionTypes from 'store/actions';

interface Props {
  event: React.MouseEvent<HTMLLIElement, MouseEvent>;
  handleClose: Function;
  selectedLanguage: string;
  setSelectedLanguage: Function;
  lng: string;
}
function LanguageChangeDialog({ event, handleClose, lng, selectedLanguage, setSelectedLanguage }: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>,
    lng: string
  ) => {
    if ((lng === 'en-CA' || lng === 'en') && selectedLanguage !== 'fr') {
      setSelectedLanguage(lng);
      localStorage.setItem('language', 'en');
      handleClose();
      return;
    }
    setSelectedLanguage(lng);
    localStorage.setItem('language', lng);
    dispatch({ type: actionTypes.THEME_LOCALE, locale: lng });
    handleClose();
  };

  return (
    <BasicDialog
      open={open}
      maxWidth="xs"
      actions={
        <>
          <Button
            variant="contained"
            onClick={() => {
              handleListItemClick(event, lng);
              setOpen(false);
            }}
          >
            <FormattedMessage defaultMessage="OK" />
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
        </>
      }
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{intl.formatMessage({ defaultMessage: 'Session Language Change' })}</Typography>
          <Button onClick={() => setOpen(false)}>
            <IconX />
          </Button>
        </Stack>
      </Stack>
      <Typography component="span">
        {intl.formatMessage({
          defaultMessage: 'Are you sure you want to change language? Doing so will disconnect you from the session.'
        })}
      </Typography>
    </BasicDialog>
  );
}

export default LanguageChangeDialog;
