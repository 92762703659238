import useAuth from 'hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface MessageType {
  clientId?: string;
  page?: number;
  limit?: number;
  sent?: boolean;
  orderBy?: string;
  order?: string;
  composeMessage?: any;
  isInternalMessage?: boolean;
}

export default function useMessages({ clientId, page, limit, sent, orderBy, order, composeMessage, isInternalMessage }: MessageType) {
  const { request, userId } = useAuth();
  const queryClient = useQueryClient();

  const fetchMessagesKey = `/messages?page=${page}&page&limit=${limit}&sent=${sent}&orderBy=${orderBy}&order=${order}&clientId=${clientId}&isInternalMessage=${isInternalMessage}`;

  const {
    data: messages,
    refetch,
    isLoading: listLoading,
    isFetching,
    isRefetching
  } = useQuery(
    fetchMessagesKey,
    () =>
      request
        .get(`/messages`, {
          params: {
            isInternalMessage,
            sent,
            page,
            limit,
            orderBy,
            order,
            clientId
          }
        })
        .then((response) => response.data as any),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        queryClient.setQueryData('/messages/count', (messageNotifications: any) => {
          if (!messageNotifications || sent) {
            return messageNotifications;
          }
          const tempObj = { ...messageNotifications };
          tempObj.total = data?.unread ?? 0;
          return tempObj;
        });
      }
    }
  );

  const { mutateAsync: sendMessage, isLoading: createLoading } = useMutation(
    `messages`,
    (payload: any) => request.post(`/messages`, payload).then(({ data }) => data as any),
    { onSuccess: () => refetch() }
  );

  const { mutateAsync: replyToMessage, isLoading: replyLoading } = useMutation(
    `messages/reply`,
    (payload: any) => request.post(`/messages/${payload._id}/reply`, payload),
    { onSuccess: () => refetch() }
  );

  const { mutateAsync: markAsRead } = useMutation(
    `messages/mark-as-read`,
    (payload: any) => request.put(`/messages/${payload._id}/mark-as-read`, { read: payload.read }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(fetchMessagesKey, (messages: any) => {
          const tempMessages = [...messages.messages];
          tempMessages.forEach((obj) => {
            if (obj._id === data.data._id) {
              obj.unread = data.data.unread;
            }
          });
          return {
            ...messages,
            messages: tempMessages
          };
        });
      }
    }
  );

  return {
    messages: messages?.messages || [],
    total: messages?.total || 0,
    sendMessage,
    markAsRead,
    replyToMessage,
    isLoading: !isRefetching && (isFetching || listLoading || createLoading || replyLoading),
    refetchMessages: refetch
  };
}
