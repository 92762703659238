import { Box, Button, LinearProgress, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import draftToHtml from 'draftjs-to-html';
import useAuth from 'hooks/useAuth';
import { useModal } from 'mui-modal-provider';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import ImageResize from 'quill-image-resize-module-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { markdownToHTML } from 'utils/notes';
import DocumentCover from '../announcement/DocumentCover';
import DeleteConfirmationDialog from '../modals/DeleteConfirmationDialog';
import './quill.scss';
import QuillToolbar from './QuillToolbar';

const Font = Quill.import('formats/font');
Font.whitelist = ['mirza', 'roboto', 'arial', 'verdana', 'times', 'tahoma'];
Quill.register(Font, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register('modules/emoji', Emoji);

const isHTML = RegExp.prototype.test.bind(/^(<([^>]+)>)$/i);

interface Props {
  value: string;
  name: string;
  focus?: boolean;
  disabled?: boolean;
  hasHtml?: boolean;
  onChange?: any;
  imageEnabled?: boolean;
  setModified?: () => void;
  binarySignature?: File;
  ref?: any;
  handleAttachmentUpload?: (string) => void;
  handleAttachmentRemove?: (string) => void;
  attachments?: any[];
  editorRef?: any;
  enableLink?: boolean;
}

const QuillEditor = ({
  enableLink = false,
  name,
  editorRef,
  focus,
  disabled = false,
  hasHtml,
  value,
  onChange,
  setModified,
  imageEnabled = true,
  binarySignature,
  ref,
  handleAttachmentUpload,
  handleAttachmentRemove,
  attachments
}: Props) => {
  const intl = useIntl();
  const { showModal } = useModal();

  const formContext = useFormContext();
  const { setValue } = formContext || {};
  const quillRef = useRef<any>();
  const strData: any = useRef();
  const { userId, request } = useAuth();
  const theme = useTheme();
  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [data, setData] = useState('');
  const [hyperLinkData, getHyperLinkData] = useState<any>(null);
  const [viewAllAttachments, setViewAllAttachments] = useState(false);

  useEffect(() => {
    if (editorRef) {
      editorRef.current = quillRef.current;
    }
  }, [editorRef]);

  useEffect(() => {
    document.querySelector('.ql-toolbar')?.addEventListener('click', (e) => {
      if (setModified) {
        setModified();
      }
    });

    const isDraftJsFormat = (str) => {
      try {
        const parsed = JSON.parse(str);
        if (parsed.blocks || parsed.entityMap) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    };

    if (value !== null && value !== strData.current) {
      try {
        // If draftjs format, convert to html
        if (!isHTML(value) && isDraftJsFormat(value)) {
          setData(draftToHtml(JSON.parse(value)));
        } else {
          setData(markdownToHTML(value));
        }
      } catch (e) {
        setData(value);
        console.log('Error in QuillEditor', e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = useCallback(
    (html: string) => {
      strData.current = html;
      setData(html);
      if (setValue) {
        setValue(name, html);
      }
      if (onChange) {
        onChange(html);
      }
    },
    [name, setValue, onChange]
  );

  const detectChanges = () => {
    if (setModified) {
      setModified();
    }
  };

  const onUploadProgress = (e) => {
    setUploadProgressPercent(Math.round((e.loaded * 100) / e.total));
  };

  const uploadMutation = useMutation(
    (formData: any) => {
      setIsUploading(true);
      setUploadProgressPercent(0);

      return request.post(`/user/${userId}/files`, formData, { onUploadProgress });
    },
    {
      onSuccess: () => {
        setIsUploading(false);
      }
    }
  );

  const uploadFile = useCallback((file, insertToEnd = false) => {
    const editor = quillRef?.current?.getEditor();
    if (!editor) return;
    let cursorPosition;

    if (insertToEnd) {
      cursorPosition = editor.getLength() ?? 0;
    } else {
      cursorPosition = quillRef?.current?.getEditorSelection()?.index ?? 0;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', 'NOTES_DOCUMENT');
    uploadMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        editor.insertEmbed(cursorPosition, 'image', data.url);
        editor.setSelection(cursorPosition + 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Image upload handler
  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (!input.files?.length) return;
      const file = input.files[0];
      uploadFile(file);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropImageHandler = useCallback((imageDataUrl, type, imageData) => {
    const file = imageData.toFile();

    uploadFile(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insertSignature = () => {
    if (!binarySignature) return;

    uploadFile(binarySignature, true);
  };

  const undoChange = useCallback(() => {
    const quill = quillRef.current.getEditor();
    quill.history.undo();
  }, []);

  const redoChange = useCallback(() => {
    const quill = quillRef.current.getEditor();
    quill.history.redo();
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: '#toolbar',
        handlers: {
          image: imageHandler,
          undo: undoChange,
          redo: redoChange
        }
      },
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      },
      // imageDropAndPaste: {
      //   handler: dropImageHandler
      // },
      clipboard: { matchVisual: true },
      'emoji-toolbar': true,
      'emoji-shortname': true
    }),
    [imageHandler, redoChange, undoChange]
  );

  const handleImageSelect = (e) => {
    const files: any = Array.from(e.target.files);

    if (!files?.length || !handleAttachmentUpload) return;

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('fileType', 'NOTES_DOCUMENT');
    uploadMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        handleAttachmentUpload(data.url);
      }
    });
  };

  const confirmDelete = (url: any) => {
    if (!handleAttachmentRemove) return;

    const modal = showModal(DeleteConfirmationDialog, {
      title: intl.formatMessage({ defaultMessage: 'Delete Attachment' }),
      description: intl.formatMessage({ defaultMessage: 'Are you sure you want to delete this attachment?' }),
      onConfirm: async () => {
        await handleAttachmentRemove(url);
        modal.hide();
      },
      onCancel: () => modal.hide()
    });
  };

  useEffect(() => {
    const quill = quillRef.current.getEditor();

    if (hyperLinkData && quill) {
      const { link: hyperLink, articleTitle, resourceTitle } = hyperLinkData;

      const length = quill?.getLength();

      let cursorPosition = 0;

      if (length > 1) {
        cursorPosition = quill.getSelection()?.index ?? 0;
      }

      const ops =
        cursorPosition === 0
          ? [{ insert: `Resources > ${resourceTitle} > ${articleTitle}`, attributes: { link: hyperLink } }]
          : [{ retain: cursorPosition }, { insert: `Resources > ${resourceTitle} > ${articleTitle}`, attributes: { link: hyperLink } }];

      const delta = {
        ops
      };

      quill.updateContents(delta);
    }
  }, [quillRef, hyperLinkData]);

  return (
    <>
      <Box p={0} m={0} sx={{ '& img': { minWidth: '50px !important' } }} className={`main-editor-custom${disabled ? ' hide-toolbar' : ''}`}>
        <QuillToolbar
          enableLink={enableLink}
          getHyperLinkData={getHyperLinkData}
          onClose={() => {}}
          imageEnabled={imageEnabled}
          insertSignature={binarySignature ? insertSignature : null}
          handleImageSelect={handleImageSelect}
          attachment={!!handleAttachmentUpload}
        />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={data || ''}
          onChange={(html) => handleChange(html)}
          onKeyDown={detectChanges}
          modules={modules}
          readOnly={disabled}
        />

        {isUploading && <LinearProgress variant="determinate" value={uploadProgressPercent} />}
      </Box>
      <Stack direction={'row'} spacing={1} rowGap={1} flexWrap="wrap" mt={2}>
        {(viewAllAttachments ? attachments : attachments?.slice(0, 2))?.map((attachment, index) => (
          <DocumentCover
            key={attachment.name + index}
            _id={attachment.url}
            name={attachment.name}
            link={attachment.url}
            onDelete={!disabled ? confirmDelete : undefined}
          />
        ))}

        {Number(attachments?.length) > 2 && (
          <Button size="small" onClick={() => setViewAllAttachments((pre) => !pre)}>
            {!viewAllAttachments ? (
              <FormattedMessage defaultMessage="View all {num} attachments" values={{ num: attachments?.length }} />
            ) : (
              <FormattedMessage defaultMessage="View less" />
            )}
          </Button>
        )}
      </Stack>
    </>
  );
};
export default QuillEditor;
