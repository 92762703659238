import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export default function useSessionToken(sessionId: string | undefined, challenge: string | null) {
  const { request, guestRequest } = useAuth();

  const url = `/session/${sessionId}/token`;

  const getRequest = () => (challenge ? guestRequest : request);

  const { data: token } = useQuery(
    url,
    () =>
      getRequest()
        .get(url, challenge ? { params: { challenge } } : {})
        .then((r) => r.data.token as string),
    { enabled: !!sessionId }
  );

  return { token };
}
