import { PaletteMode } from '@mui/material';

const config: {
  basename: string;
  defaultPath: string;
  fontFamily: string;
  borderRadius: number;
  outlinedFilled: boolean;
  theme: PaletteMode;
  presetColor: string;
  i18n: string;
  rtlLayout: boolean;
} = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/appointments',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: 'en',
  rtlLayout: false
};

export default config;
