import { Alert, AlertTitle, Button, Grid, Typography, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { gridSpacing } from 'store/constant';

const ResendVerification = () => {
  const { request, userId } = useAuth();
  const { user, isClient } = useUser(userId);
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const resendRegistrationEmail = useMutation(() => request.post(`/user/${userId}/verification/resend`), {
    onSuccess: () => {
      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Successfully resent registration email' }), { variant: 'success' });
    }
  });

  const handleResendRegistrationEmail = useCallback(() => {
    resendRegistrationEmail.mutate();
  }, [resendRegistrationEmail]);

  if (!user?.emailVerified && isClient) {
    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ mb: 4 }}>
          <Alert
            severity="warning"
            variant="filled"
            sx={{
              bgcolor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              border: '4px solid',
              borderColor: theme.palette.primary.dark
            }}
          >
            <Grid sx={{ mb: 1 }}>
              <AlertTitle>
                <Typography variant="h3" sx={{ color: theme.palette.primary.dark }}>
                  <FormattedMessage defaultMessage={'Thank you for registering'} />
                </Typography>
              </AlertTitle>
              <br />
              <Typography>
                <FormattedMessage defaultMessage="For your security and in order to attend appointments with your provider we require you to verify your email address." />
              </Typography>
              <br />
              <Typography>
                <FormattedMessage defaultMessage="We have sent you a verification link. Please check your Inbox or Spam/Junk folders for the verification email. This link is valid for 24 hours only." />{' '}
              </Typography>
              <br />
              <Typography>
                <FormattedMessage defaultMessage="If you do not see the email link please use the button below to request a new verification link.  " />
              </Typography>
              <br />
            </Grid>
            <Button color="primary" variant="contained" size="small" onClick={handleResendRegistrationEmail}>
              <FormattedMessage defaultMessage="Resend Verification Email" />
            </Button>
          </Alert>
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default ResendVerification;
