import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useContactMeRequest from 'hooks/useContactMeRequest';
import useTimezone from 'hooks/useTimezone';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import NoResults from 'views/components/common/NoResults';

interface Column {
  id: 'clientName' | 'requestedDate' | 'sessionMode' | 'providerName' | 'Status';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

function createData(clientName: string, requestedDate: string, sessionMode: string, providerName: string, Status: string) {
  return { clientName, requestedDate, sessionMode, providerName, Status };
}

function ComplimentaryTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { timezone } = useTimezone();
  const navigate = useNavigate();
  const [isTabletScreen, setIsTabletScreen] = React.useState(false);

  const intl = useIntl();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const { contactMeList } = useContactMeRequest();

  const rows = contactMeList?.data?.clients?.map((contact: any) => {
    const formattedDate = format(
      utcToZonedTime(contact.date, timezone),
      intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a',
      {
        locale: intl.locale === 'fr' ? frLocale : enLocale
      }
    );

    return createData(
      `${contact.firstName} ${contact.lastName}`,
      formattedDate,
      contact.locationType === 'Online'
        ? intl.formatMessage({ defaultMessage: 'Virtual' })
        : intl.formatMessage({ defaultMessage: 'Text' }),
      contact.therapist || '-',
      contact.status
    );
  });

  const columns: Column[] = [
    { id: 'clientName', label: intl.formatMessage({ defaultMessage: 'Clients' }) },
    {
      id: 'requestedDate',
      label: intl.formatMessage({ defaultMessage: 'Requested Date' }),
      minWidth: 100
    },
    { id: 'sessionMode', label: intl.formatMessage({ defaultMessage: 'Session Mode' }) },
    {
      id: 'providerName',
      label: intl.formatMessage({ defaultMessage: 'Provider Name' }),
      minWidth: 100
    },
    {
      id: 'Status',
      label: intl.formatMessage({ defaultMessage: 'Status' }),
      minWidth: 100
    }
  ];
  const statusColors = {
    Pending: '#FFFAEB',
    'To be Scheduled': '#FFFAEB',
    'To be Registered': '#FFFAEB',
    Completed: '#ECFDF3',
    Scheduled: '#ECFDF3',
    ACCEPTED: '#E3F6FD',
    'Invitation Sent': '#E3F6FD',
    ATTENDED: '#ECFDF3',
    CANCELED: '#FEF3F2',
    'LATE-CANCELED': '#FFEBEE',
    'NO-SHOW': '#FFEBEE'
  };

  const statusTextColors = {
    Pending: '#DC6803',
    'To be Scheduled': '#DC6803',
    'To be Registered': '#DC6803',
    Completed: '#039855',
    Scheduled: '#039855',
    'Invitation Sent': '#6495ED',
    ATTENDED: '#039855',
    CANCELED: '#ff0000',
    'LATE-CANCELED': '#ff0000',
    'NO-SHOW': '#ff0000'
  };

  const handleNavigate = () => {
    navigate('/complimentarysession');
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const statusTranslations = {
    Requested: intl.formatMessage({ defaultMessage: 'Requested' }),
    Accepted: intl.formatMessage({ defaultMessage: 'Accepted' }),
    'To be Scheduled': intl.formatMessage({ defaultMessage: 'To be Scheduled' }),
    Scheduled: intl.formatMessage({ defaultMessage: 'Scheduled' }),
    Completed: intl.formatMessage({ defaultMessage: 'Completed' }),
    Canceled: intl.formatMessage({ defaultMessage: 'Cancelled' }),
    'Late-Canceled': intl.formatMessage({ defaultMessage: 'Late-Cancelled' }),
    'No Show': intl.formatMessage({ defaultMessage: 'No Show' })
  };
  React.useEffect(() => {
    const handleResize = (event) => {
      if (event.target.innerWidth < 1466) {
        setIsTabletScreen(true);
      } else {
        setIsTabletScreen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  return (
    <Paper
      sx={{
        width: isTabletScreen ? '100%' : '117%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        overflow: 'hidden'
      }}
    >
      <TableContainer
        sx={{ maxHeight: '100%', flexGrow: 1, borderRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={{ backgroundColor: '#F8F8F8' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                    {intl.formatMessage({ defaultMessage: 'Complimentary Sessions' })}
                  </Typography>{' '}
                  <Button onClick={handleNavigate} endIcon={<ArrowOutwardIcon />}>
                    {intl.formatMessage({ defaultMessage: 'More' })}
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <NoResults>{intl.formatMessage({ defaultMessage: 'No Complimentary Sessions Found' })}</NoResults>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          {rows?.length !== 0 && (
            <TableBody>
              {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ height: 70 }}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'Status') {
                        const Status = statusTranslations[value.replace(/^./, (str) => str.toUpperCase())];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ paddingLeft: 0.3 }}>
                            <Chip
                              label={
                                row?.Status && row.Status?.length > 0
                                  ? row.Status === 'Pending' || row.Status === 'null' || row.Status === ''
                                    ? intl.formatMessage({ defaultMessage: 'To be Scheduled' })
                                    : row.Status === 'ACCEPTED'
                                    ? intl.formatMessage({ defaultMessage: 'Scheduled' })
                                    : row.Status === 'ATTENDED'
                                    ? intl.formatMessage({ defaultMessage: 'Completed' })
                                    : row.Status === 'CANCELED'
                                    ? intl.formatMessage({ defaultMessage: 'Cancelled' })
                                    : row.Status === 'LATE-CANCELED'
                                    ? intl.formatMessage({ defaultMessage: 'Late Cancelled' })
                                    : row.Status === 'NO-SHOW'
                                    ? intl.formatMessage({ defaultMessage: 'No Show' })
                                    : row.Status === 'LATE-SHOW'
                                    ? intl.formatMessage({ defaultMessage: 'Late Show' })
                                    : row.Status
                                  : intl.formatMessage({ defaultMessage: 'To be Scheduled' })
                              }
                              size="small"
                              sx={{
                                backgroundColor:
                                  statusColors[
                                    row?.Status === 'Pending' || row?.Status === '' || row?.Status === 'null' || row?.Status === null
                                      ? 'To be Scheduled'
                                      : row?.Status
                                  ],
                                color:
                                  statusTextColors[
                                    row?.Status === 'Pending' || row?.Status === '' || row?.Status === 'null' || row?.Status === null
                                      ? 'To be Scheduled'
                                      : row?.Status
                                  ]
                              }}
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          SelectProps={{
            sx: { display: 'none' }
          }}
        />
      </Box>
    </Paper>
  );
}
export { ComplimentaryTable };
