import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { Calendar } from 'types/calendar';

export default function useUserCalendars(id?: string) {
  const { request } = useAuth();

  const { data, refetch, isLoading, isRefetching } = useQuery(
    `/user/${id}/calendar`,
    () => request.get(`/user/${id}/calendar`).then((response) => response.data.calendars as Calendar[]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: typeof id === 'string' && id !== '0'
    }
  );

  const calendars = data?.filter((d) => !d.calendar_deleted);

  return { calendars, refetchCalendars: refetch, isLoading, isRefetching };
}
