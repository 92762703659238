/* eslint-disable prettier/prettier */
import { Box, Button, FormControl, Grid, InputLabel, Select, TextField, Tooltip, Typography } from '@mui/material';
// eslint-disable-next-line prettier/prettier
import { MenuItem } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { IconFilterCancel } from '@tabler/icons-react';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import useAuth from 'hooks/useAuth';
import useDashboard from 'hooks/useDashboard';
import usePermissions from 'hooks/usePermissions';
import useProfileOptions from 'hooks/useProfileOptions';
import useUser from 'hooks/useUser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { store } from 'store';
import { gridSpacing } from 'store/constant';
import { ComplimentaryTable } from '../ComplimentryTable';
import ReusableDashboardCard from '../DashboardCards';
import { NewClient } from '../NewClient';
import NotesTable from '../NotesTable';
import UpcomingSessions from '../UpcomingSessions';
import Updates from '../Updates';

const ProviderDashboard = () => {
  const intl = useIntl();

  const [filter, setFilter] = React.useState<String | null>('This Month');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [clearFilter, setClearFilter] = React.useState<boolean>(false);

  const lang = store.getState()?.customization?.locale ?? 'fr';

  const navigate = useNavigate();

  const [permissionsChecked, setPermissionsChecked] = React.useState(false);
  const [selected, setSelected] = useState('This Month');
  const { permissions } = usePermissions();
  const { profileOptionsMap } = useProfileOptions();

  const { dashboardData, refetchTotalEarning } = useDashboard({
    filter,
    startDate,
    endDate
  });

  const { userId } = useAuth();
  const { user } = useUser(userId);

  const { perDifference } = dashboardData?.data || {};

  useEffect(() => {
    const permission = permissions?.some(
      (permissionsUser) => user?._id === permissionsUser?._id && permissionsUser?.accessSettings?.DASHBOARD
    );
    if (permission === false || profileOptionsMap?.providerPractice?.keys?.DASHBOARD?.value === false) {
      navigate('/appointments');
    } else {
      refetchTotalEarning();
    }
    setPermissionsChecked(true);
  }, [filter, startDate, endDate, user, permissions, navigate, refetchTotalEarning, profileOptionsMap]);


  const handleChange = (event) => {
    setSelected(event.target.value);
    if (event.target.value !== 'This Month') setClearFilter(true);
    else setClearFilter(false);
    if (event.target.value !== 'Custom Date') {
      setFilter(event.target.value);
    }
  };
  const [range, setRange] = useState<[string | Date | null, string | Date | null]>([null, null]);

  const handlerClearFilter = () => {
    setStartDate('');
    setEndDate('');
    setFilter('This Month');
    setSelected('This Month');
    setRange([null, null]);
    setClearFilter(false);
  };

  const isValid = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const handleRangeChange = (value) => {
    setRange(value);
    if (value[0] !== null && value[1] !== null) {
      const isValidStart = isValid(value[0].$d);
      const isValidEnd = isValid(value[1].$d);

      if (isValidStart && isValidEnd) {
        setStartDate(moment(value[0].$d.toISOString()).format('YYYY/MM/DD'));
        setEndDate(moment(value[1].$d.toISOString()).format('YYYY/MM/DD'));
        setFilter(selected);
      }
    }
    return value;
  };

  const Loader = () => (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', marginTop: 1.5, marginBottom: 1.5 }}>
      <LinearProgress sx={{ width: '140px' }} />
    </Box>
  );

  if (!permissionsChecked) {
    return null;
  } else {
    return (
      <Box sx={{ display: 'flex', rowGap: 2, flexDirection: 'column' }}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{intl.formatMessage({ defaultMessage: 'Provider Dashboard' })}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display="flex" sx={{ alignSelf: 'flex-end' }} gap="15px">
            <FormControl sx={{ width: '8rem', borderRadius: '12px', height: '2.5rem', marginRight: '20px' }}>
              <InputLabel id="demo-simple-select-label">{intl.formatMessage({ defaultMessage: 'Range' })}</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selected} label="Range" onChange={handleChange}>
                <MenuItem value="This Month">{intl.formatMessage({ defaultMessage: 'This Month' })}</MenuItem>
                <MenuItem value="Last 30 Days">{intl.formatMessage({ defaultMessage: 'Last 30 Days' })}</MenuItem>
                <MenuItem value="Last Month">{intl.formatMessage({ defaultMessage: 'Last Month' })}</MenuItem>
                <MenuItem value="This Year">{intl.formatMessage({ defaultMessage: 'This Year' })}</MenuItem>
                <MenuItem value="Custom Date">{intl.formatMessage({ defaultMessage: 'Custom Date' })}</MenuItem>
              </Select>
            </FormControl>

            {selected === 'Custom Date' && (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang === 'fr' ? 'fr' : 'en'}>
                <DateRangePicker
                  calendars={1}
                  onChange={handleRangeChange}
                  value={range}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        autoComplete="off"
                        name="custom_start"
                        size="medium"
                        {...startProps}
                        label={intl.formatMessage({ defaultMessage: 'Start' })}
                      />
                      <Box sx={{ mx: 1 }}>-</Box>
                      <TextField
                        autoComplete="off"
                        name="custom_end"
                        size="medium"
                        {...endProps}
                        label={intl.formatMessage({ defaultMessage: 'End' })}
                      />
                    </>
                  )}
                />
              </LocalizationProvider>
            )}

            {clearFilter && (
              <Tooltip title={<FormattedMessage defaultMessage="Cancel Filters" />} placement="top">
                <Button sx={{ p: 1.3, mr: '20px', borderColor: '#C0C0C0' }} variant="outlined" onClick={handlerClearFilter}>
                  <IconFilterCancel />
                </Button>
              </Tooltip>
            )}
          </Box>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={12} sm={6} md={3} lg={lang !== 'fr' ? 3 : 2.8} xl={3}>
              <ReusableDashboardCard
                title={intl.formatMessage({ defaultMessage: 'Total Sessions' })}
                percentageChangeFromLastMonth={perDifference?.totalAppointments}
                value={dashboardData?.data ? dashboardData?.data.totalAppointments : <Loader />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={lang !== 'fr' ? 3 : 2.8} xl={3}>
              <ReusableDashboardCard
                title={intl.formatMessage({ defaultMessage: 'Attended' })}
                percentageChangeFromLastMonth={perDifference?.totalAttended}
                value={dashboardData?.data ? dashboardData?.data.totalAttended : <Loader />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={lang !== 'fr' ? 3 : 2.8} xl={3}>
              <ReusableDashboardCard
                title={intl.formatMessage({ defaultMessage: 'Cancelled' })}
                percentageChangeFromLastMonth={perDifference?.totalCanceled}
                value={dashboardData?.data ? dashboardData?.data.totalCanceled : <Loader />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={lang !== 'fr' ? 3 : 3.6} xl={3}>
              <ReusableDashboardCard
                title={intl.formatMessage({ defaultMessage: 'No-Show' })}
                percentageChangeFromLastMonth={perDifference?.totalNoShow}
                value={dashboardData?.data ? dashboardData?.data.totalNoShow : <Loader />}
              />
            </Grid>
          </Grid>
          {user?.userOptions?.accessComplimentarySession === true ? (
            <>
              <Grid container spacing={gridSpacing} sx={{ gap: '8%' }}>
                <Grid item xs={10.3} sm={5.1}>
                  <ComplimentaryTable />
                </Grid>
                <Grid item xs={10.3} sm={5.1}>
                  <Updates />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <UpcomingSessions />
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ gap: '8%' }}>
                <Grid item xs={10.3} sm={5.1}>
                  <NewClient />
                </Grid>
                <Grid item xs={10.3} sm={5.1}>
                  <NotesTable style={{ width: '117%' }} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <UpcomingSessions />
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ gap: '8%' }}>
                <Grid item xs={10.3} sm={5.1}>
                  <NewClient />
                </Grid>
                <Grid item xs={10.3} sm={5.1}>
                  <Updates />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <NotesTable style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    );
  }
};

export default ProviderDashboard;
