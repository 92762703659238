import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
type Props = {
  categoryId?: string | null;
  articleId?: string | null;
};

export default function useArticles({ categoryId }: Props) {
  const { request } = useAuth();

  const {
    data: articles,
    refetch: refetchArticles,
    isLoading
  } = useQuery(
    `/resources/${categoryId}/articles`,
    () => request.get(`/resources/${categoryId}/articles`).then((response) => response.data.articles),

    {
      enabled: !!categoryId
    }
  );

  const { mutateAsync: addArticle } = useMutation((payload: any) => request.post(`/resources/${categoryId}/articles`, payload), {
    onSuccess: () => refetchArticles()
  });

  const { mutateAsync: reorderArticles } = useMutation(
    (payload: any) => request.post(`resources/${categoryId}/articles/reorder`, payload),
    {
      onSuccess: () => refetchArticles()
    }
  );

  return { isLoading, articles, refetchArticles, addArticle, reorderArticles };
}
