import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type TextFieldProps = Omit<MUITextFieldProps, 'name'> & {
  name: string;
  optional?: boolean;
  parseError?: (error: FieldError) => string;
};

const TextField = React.memo(({ parseError, type, required, name, label, optional, ...rest }: TextFieldProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => (
        <MUITextField
          {...rest}
          inputProps={{ ...rest.inputProps }}
          name={name}
          label={optional ? (intl.locale === 'fr' ? `${label} (facultatif)` : `${label} (optional)`) : label}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          type={type}
          error={invalid}
          sx={{ width: '100%' }}
          // eslint-disable-next-line no-nested-ternary
          helperText={error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : rest.helperText}
        />
      )}
    />
  );
});

export default TextField;
