import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useTimezone from 'hooks/useTimezone';
import useUserAnnouncements from 'hooks/useUserAnnouncements';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { getTypeCell } from 'utils/announcementTypes';
import ViewAnnouncementModal from 'views/components/announcement/modals/ViewAnnouncementModal';
import NoResults from 'views/components/common/NoResults';

interface Announcement {
  _id: string;
  title: string;
  type: string;
  updatedAt: string;
}

interface Column {
  id: 'title' | 'type' | 'updatedAt';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

const Updates = () => {
  const intl = useIntl();
  const { timezone } = useTimezone();
  const { userAnnouncements, refetchUserAnnouncements } = useUserAnnouncements();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<string | null>(null);
  const [rows, setRows] = useState<Announcement[] | null>([]);
  const navigate = useNavigate();

  useEffect(() => {
    refetchUserAnnouncements();
    const transformedData = userAnnouncements?.announcements?.map((userAnnouncement) => {
      const formatDate = userAnnouncement?.announcement?.updatedAt
        ? format(
            utcToZonedTime(userAnnouncement?.announcement?.updatedAt, timezone),
            intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a',
            {
              locale: intl.locale === 'fr' ? frLocale : enLocale
            }
          )
        : '-';

      return {
        updatedAt: formatDate,
        _id: userAnnouncement._id,
        title: userAnnouncement?.announcement?.title,
        type: getTypeCell(userAnnouncement?.announcement?.type, intl)
      };
    });

    setRows(transformedData || null);
  }, [intl.locale, refetchUserAnnouncements, userAnnouncements, timezone, intl]);

  const columns: Column[] = [
    { id: 'title', label: intl.formatMessage({ defaultMessage: 'Title' }) },
    {
      id: 'type',
      label: intl.formatMessage({ defaultMessage: 'Type' }),
      minWidth: 100
    },
    { id: 'updatedAt', label: intl.formatMessage({ defaultMessage: 'Date' }) }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleNavigate = () => {
    navigate('/notifications');
  };

  return (
    <>
      {!!selectedAnnouncementId && (
        <ViewAnnouncementModal
          userAnnouncementId={selectedAnnouncementId}
          onClose={() => {
            setSelectedAnnouncementId(null);
            refetchUserAnnouncements();
          }}
        />
      )}
      <Paper sx={{ width: '117%', height: '640px', display: 'flex', flexDirection: 'column', borderRadius: '16px', overflow: 'hidden' }}>
        <TableContainer
          sx={{ maxHeight: '100%', flexGrow: 1, borderRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ backgroundColor: '#F8F8F8' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                      {intl.formatMessage({ defaultMessage: 'Updates' })}
                    </Typography>
                    <Button onClick={handleNavigate} endIcon={<ArrowOutwardIcon />}>
                      {intl.formatMessage({ defaultMessage: 'More' })}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <NoResults>{intl.formatMessage({ defaultMessage: 'No Updates Found' })}</NoResults>
                  </TableCell>
                </TableRow>
              ) : (
                rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => setSelectedAnnouncementId(row._id)}
                      sx={{ height: 75, cursor: 'pointer' }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <TablePagination
            // rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            SelectProps={{
              sx: { display: 'none' }
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Updates;
