/* eslint-disable */
import { Divider, List, Theme, Typography } from '@mui/material';
// material-ui
import { makeStyles } from '@mui/styles';
import useMessageNotifications from 'hooks/useMessageNotifications';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps, GenericCardProps } from 'types';
import NavCollapse from '../NavCollapse';
// project imports
import NavItem from '../NavItem';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption
  },
  menuDivider: {
    marginTop: '2px',
    marginBottom: '10px',
    borderColor: '#d6eded'
  },
  notificationChip: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.main,
    marginLeft: 3
  }
}));

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface NavGroupProps {
  item: {
    id?: string;
    type?: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: NavGroupProps['item'][];
    title?: React.ReactNode | string;
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    divider?: boolean;
  };
}

const NavGroup = ({ item }: NavGroupProps) => {
  const socket = useSelector((state: DefaultRootStateProps) => state.chat)?.socket;
  const classes = useStyles();

  const { refetchMessageNotifications } = useMessageNotifications();

  useEffect(() => {
    socket?.on('receive-notification', () => {
      refetchMessageNotifications();
    });
    socket?.on('notification-updated', () => {
      refetchMessageNotifications();
    });
  }, []);

  // menu list collapse & items
  const items = item?.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return (
          <Fragment key={menu.id}>
            {menu.divider && <Divider className={classes.menuDivider} />}
            <NavItem item={menu} level={1} />
          </Fragment>
        );

      default:
        return (
          <Typography key={menu.id} variant="h6" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
    </>
  );
};

export default NavGroup;
