import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

export default function usePermissions(id?: string) {
  const intl = useIntl();
  const { request } = useAuth();

  const {
    data: permissionsData,
    refetch: refetchPermissions,
    isLoading,
    isRefetching
  } = useQuery(`/permissions`, () => request.get(`/permissions`).then((response) => response.data as Permissions), {
    refetchOnMount: true
  });

  const { mutateAsync: updatePermissions, isLoading: isPermissionsUpdating } = useMutation(
    `/permissions`,
    (payload: any) => request.put(`/permissions`, payload).then((response) => response.data),
    {
      onSuccess: () => refetchPermissions()
    }
  );

  useEffect(() => {
    refetchPermissions();
  }, [intl.locale, refetchPermissions]);

  return {
    updatePermissions,
    permissions: permissionsData?.users,
    permissionsMap: permissionsData?.permissionsMap,
    refetchPermissions,
    isLoadingPermissions: isLoading || isRefetching,
    isPermissionsUpdating,
    isRefetching
  };
}

interface Permissions {
  permissionsMap: any;
  users: {
    _id: string;
    fullName: string;
    accessSettings: any;
    disabledPermissions: string[];
  }[];
}
