import HomeIcon from '@mui/icons-material/Home';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PrintIcon from '@mui/icons-material/Print';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { IconPencil, IconTrash } from '@tabler/icons';
import draftToHtml from 'draftjs-to-html';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useUser from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { Fragment, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { convertImageBlobToImageFile } from 'utils/convertImageBlobToImageFile';
import { markdownToHTML } from 'utils/notes';
import DialogClose from 'views/components/buttons/DialogClose';
import MainCard from 'views/components/cards/MainCard';
import CenteredLoading from 'views/components/CenteredLoading';
import QuillEditorController from 'views/components/common/QuillEditorController';
import CheckboxGroup from 'views/components/inputs/CheckboxGroup';
import FormContainer from 'views/components/inputs/FormContainer';
import SwitchField from 'views/components/inputs/Switch';
import TextField from 'views/components/inputs/TextField';
import ArticlesList from './ArticlesList';
import CategoriesList from './CategoriesList';
import { highlight } from './Searchcategorylist';
import useArticle from './useArticle';
import useArticles from './useArticles';
import useResource from './useResources';

function ArticlesDescription() {
  const theme = useTheme();
  const { resources, isLoading } = useResource();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(!matchDownSM);
  const { category, article } = useParams();
  const navigate = useNavigate();

  const [notFound, setNotFound] = useState(false);

  const selectedCategory = useMemo(() => {
    return resources?.find((item) => item.url === category);
  }, [resources, category]);

  const { articles, refetchArticles, isLoading: isLoadingArticles } = useArticles({ categoryId: selectedCategory?._id });
  const selectedArticle = useMemo(() => {
    const t = articles?.find((item) => item.url === article);

    if (articles && !t) {
      setNotFound(true);
    }

    return t;
  }, [articles, article]);

  const toggleSidebar = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <IconButton onClick={toggleSidebar} size="large" aria-label="chat menu collapse" sx={{}}>
        <MenuRoundedIcon />
      </IconButton>

      <MainCard>
        <Breadcrumbs
          sx={{
            mb: 3,
            '& a:hover': {
              textDecorationLine: 'underline'
            }
          }}
        >
          <Link style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }} to="/resources">
            <HomeIcon sx={{ mr: 0.5, fontSize: 18 }} />
            <FormattedMessage defaultMessage={'Resources'} />
          </Link>
          {selectedCategory?.url && (
            <Link
              style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }}
              to={`/resources/${selectedCategory?.url}`}
            >
              <Typography sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{selectedCategory?.title}</Typography>
            </Link>
          )}
          {selectedArticle?.title && (
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              {selectedArticle?.title}
            </Typography>
          )}
        </Breadcrumbs>
        {isLoading || isLoadingArticles ? (
          <CenteredLoading />
        ) : (
          <>
            <Stack direction={'row'}>
              {openDrawer && (
                <Grid item sx={{ width: 300, minHeight: '100%' }}>
                  <ArticlesList articles={articles} isLoadingArticles={isLoadingArticles} />
                  <CategoriesList />
                </Grid>
              )}
              <ArticleItem
                selectedCategory={selectedCategory}
                selectedArticle={selectedArticle}
                notFound={notFound}
                refetchArticles={refetchArticles}
              />
              <Divider sx={{ mt: 3 }} />
            </Stack>
            <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate(-1)}>
              <FormattedMessage defaultMessage={'Back'} />
            </Button>
          </>
        )}
      </MainCard>
    </>
  );
}

const ArticleItem = ({ selectedCategory, selectedArticle, notFound, refetchArticles }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isButtonHidden, setButtonHidden] = useState(false);
  const intl = useIntl();
  const { options, roles } = useOptions();
  const searchString = useSearchParam('q');
  const { userId, request } = useAuth();
  const { isNousSupport, user } = useUser(userId);
  const [deleteid, setDeleteId] = useState(null); // store the index of the item to be deleted
  const preferredLanguage = user?.preferredLanguage ?? 'english';
  const hasEditAccess = useMemo(() => {
    if (isNousSupport && options?.MODIFY_RESOURCES?.value) {
      return true;
    }

    return false;
  }, [isNousSupport, options]);

  const { article, isLoading, updateArticle, deleteArticle, refetchArticle } = useArticle({
    categoryId: selectedCategory?._id,
    articleId: selectedArticle?._id
  });
  const [value, setValue] = useState<any>('1');
  const [showTextField, setShowTextField] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const disableeditButton = () => {
    setIsButtonDisabled(true);
  };
  const handleCloseButtonClick = () => {
    setShowTextField(false);
    setIsButtonDisabled(false);
    setButtonHidden(true);
  };
  const uploadMutation = useMutation((formData: any) => request.post(`/user/${userId}/files`, formData));
  const blobUrlMap = new Map();
  const onFormSubmit = async ({ title, titleFrench, description, descriptionFrench, isDraft, targetAudience }) => {
    let updatedDescription = description;
    if (updatedDescription) {
      updatedDescription = await convertImageBlobToImageFile(description, uploadMutation, blobUrlMap);
    }
    let updatedDescriptionFrench = descriptionFrench;
    if (descriptionFrench) {
      updatedDescriptionFrench = await convertImageBlobToImageFile(descriptionFrench, uploadMutation, blobUrlMap);
    }
    const data = {
      title,
      titleFrench,
      description: updatedDescription,
      descriptionFrench: updatedDescriptionFrench,
      isDraft,
      targetAudience
    };
    await updateArticle(data);
    refetchArticle();
    refetchArticles();
    formContext.reset();
    handleCloseButtonClick();
  };
  const initialValues = {
    title: '',
    titleFrench: '',
    description: '',
    descriptionFrench: '',
    isDraft: false,
    targetAudience: []
  };
  const formContext = useForm({ defaultValues: initialValues });

  const confirmDelete = async () => {
    await deleteArticle();
    disableeditButton();
    enqueueSnackbar(intl.formatMessage({ defaultMessage: `The article has been successfully deleted` }), { variant: 'success' });
    navigate(`/resources/${selectedCategory.url}`);
    setDeleteId(null);
  };

  const cancelDelete = () => {
    setDeleteId(null);
  };

  const handleEdit = () => {
    setShowTextField(true);
    setIsButtonDisabled(true);
    setIsEditing(true);
    setButtonHidden(false);
  };

  const handlePrintClick = () => {
    let body;
    try {
      body = draftToHtml(JSON.parse(article?.body ?? {}));
    } catch (e) {
      try {
        body = markdownToHTML(article?.body ?? '');
      } catch (e) {
        body = article?.body ?? '';
      }
    }

    const imageElement = article?.image ? `<img src="${article.image}" alt="Article Image" />` : '';
    const printContents = `
      <h1 align="center">${article?.title}</h1>
      <p>${article?.description}</p>
      ${imageElement}
      <br/>
      <hr/> 
      ${body}
    `;

    const pop = window.open();
    if (pop) {
      pop.document.body.innerHTML = printContents;
      const imgs = Array.from(pop.document.images);
      const len = imgs.length;
      let counter = 0;

      const incrementCounter = () => {
        counter++;
        if (counter === len) {
          pop.print();
          setTimeout(() => {
            pop.close();
          }, 700);
        }
      };

      if (len === 0) {
        incrementCounter();
      } else {
        imgs.forEach((img) => {
          if (img.complete) {
            incrementCounter();
          } else {
            img.addEventListener('load', incrementCounter, false);
          }
        });
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid item sx={{ flex: 1, minHeight: '100%', ml: 2 }} width={800}>
        <MainCard>
          {isLoading ? (
            <div style={{ width: '100%' }}>
              <CenteredLoading />
            </div>
          ) : (
            <>
              {article ? (
                <Fragment>
                  <>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                      {selectedArticle?.isDraft && (
                        <Typography
                          sx={{
                            color: '#f00',
                            fontWeight: '500',
                            borderWidth: 1.5,
                            borderColor: '#f00',
                            borderStyle: 'solid',
                            borderRadius: 4,
                            mt: 0.4,
                            mx: 0.5,
                            py: 0.4,
                            px: 2
                          }}
                        >
                          <FormattedMessage defaultMessage="Draft" />
                        </Typography>
                      )}
                      <Tooltip title={intl.formatMessage({ defaultMessage: 'Print ' })}>
                        <IconButton color="primary" onClick={handlePrintClick}>
                          <PrintIcon
                            sx={(theme) => ({
                              fontSize: '1.3rem'
                            })}
                          />
                        </IconButton>
                      </Tooltip>
                      {hasEditAccess && (
                        <>
                          <Tooltip title={intl.formatMessage({ defaultMessage: 'Edit' })}>
                            <IconButton sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleEdit} disabled={isButtonDisabled}>
                              <IconPencil />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={intl.formatMessage({ defaultMessage: 'Delete' })}>
                            <IconButton
                              sx={{ '&:hover': { cursor: 'pointer' } }}
                              onClick={() => setDeleteId(selectedArticle._id)}
                              disabled={isButtonDisabled}
                            >
                              <IconTrash />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Stack>
                  </>
                  <FormContainer
                    defaultValues={{
                      title: article?.title ?? '',
                      titleFrench: article?.titleFrench ?? '',
                      description: highlight(article?.description, searchString) ?? '',
                      descriptionFrench: highlight(article?.descriptionFrench, searchString) ?? '',
                      isDraft: article?.isDraft ?? false,
                      targetAudience: article?.targetAudience ?? []
                    }}
                    onSuccess={onFormSubmit}
                  >
                    {isNousSupport ? (
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={handleChange}>
                            <Tab label="EN" value="1" />
                            <Tab label="FR" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ paddingTop: 0 }}>
                          {!showTextField ? (
                            <>
                              <Typography sx={{ fontSize: 30, fontWeight: 'bold', color: 'rgb(30, 32, 40)', textAlign: 'left', mt: 4 }}>
                                <span dangerouslySetInnerHTML={{ __html: highlight(article?.title, searchString) ?? '' }} />
                              </Typography>
                              <Typography sx={{ mt: 2, md: 4, fontSize: 12, color: '#333', fontWeight: '500' }}>
                                {article?.targetAudience.join(',  ') ?? ''}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Box sx={{ mb: 2, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <SwitchField name="isDraft" label={intl.formatMessage({ defaultMessage: 'Draft Mode' })} />
                              </Box>
                              <DialogContent>
                                <CheckboxGroup
                                  name="targetAudience"
                                  label={intl.formatMessage({ defaultMessage: 'Target Audience' })}
                                  options={
                                    roles?.map((role: any) => ({
                                      label:
                                        intl.locale === 'fr'
                                          ? role?.roleNameFrench || role.roleName
                                          : role.roleName || role?.roleNameFrench,
                                      id: role.roleName
                                    })) ?? []
                                  }
                                  row
                                />
                              </DialogContent>
                              <TextField name="title" label={intl.formatMessage({ defaultMessage: 'Title' })} />
                            </>
                          )}
                          <Grid item display="flex" flexDirection="column" mt={4}>
                            <Box
                              sx={{
                                '& .ql-editor': { minHeight: 500, maxHeight: 400 },
                                '& .main-editor-custom': {
                                  borderWidth: !showTextField ? 0 : '1px'
                                }
                              }}
                            >
                              <QuillEditorController name="description" disabled={!showTextField} />
                            </Box>
                          </Grid>
                        </TabPanel>
                        <TabPanel value="2" sx={{ paddingTop: 0 }}>
                          {!showTextField ? (
                            <>
                              <Typography sx={{ fontSize: 30, fontWeight: 'bold', color: 'rgb(30, 32, 40)', textAlign: 'left', mt: 4 }}>
                                <span dangerouslySetInnerHTML={{ __html: highlight(article?.titleFrench, searchString) ?? '' }} />
                              </Typography>
                              <Typography sx={{ mt: 2, md: 4, fontSize: 12, color: '#333', fontWeight: '500' }}>
                                {article.targetAudience
                                  ? article.targetAudience
                                      .map((item: any) => {
                                        if (roles) {
                                          const role: any = roles.find((e: any) => e.roleName === item);
                                          return role?.roleNameFrench ?? '';
                                        }
                                        return '';
                                      })
                                      .filter((item) => item.length > 0)
                                      .join(', ')
                                  : ''}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Box sx={{ mb: 2, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <SwitchField name="isDraft" label={intl.formatMessage({ defaultMessage: 'Draft Mode' })} />
                              </Box>
                              <DialogContent>
                                <CheckboxGroup
                                  name="targetAudience"
                                  label={intl.formatMessage({ defaultMessage: 'Target Audience' })}
                                  options={
                                    roles?.map((role: any) => ({
                                      label:
                                        intl.locale === 'fr'
                                          ? role?.roleNameFrench || role.roleName
                                          : role.roleName || role?.roleNameFrench,
                                      id: role.roleName
                                    })) ?? []
                                  }
                                  row
                                />
                              </DialogContent>
                              <TextField name="titleFrench" label={intl.formatMessage({ defaultMessage: 'Title' })} />
                            </>
                          )}
                          <Grid item display="flex" flexDirection="column" mt={4}>
                            <Box
                              sx={{
                                '& .ql-editor': { minHeight: 500, maxHeight: 400 },
                                '& .main-editor-custom': {
                                  borderWidth: !showTextField ? 0 : '1px'
                                }
                              }}
                            >
                              <QuillEditorController name="descriptionFrench" disabled={!showTextField} />
                            </Box>
                          </Grid>
                        </TabPanel>
                      </TabContext>
                    ) : !showTextField ? (
                      <>
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', color: 'rgb(30, 32, 40)', textAlign: 'left', mt: 4 }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                preferredLanguage === 'french'
                                  ? highlight(article?.titleFrench, searchString) ?? ''
                                  : highlight(article?.title, searchString) ?? ''
                            }}
                          />
                        </Typography>
                        {isNousSupport && (
                          <Typography sx={{ mt: 2, md: 4, fontSize: 12, color: '#333', fontWeight: '500' }}>
                            {article?.targetAudience.join(',  ') ?? ''}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <Box sx={{ mb: 2, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                          <SwitchField name="isDraft" label={intl.formatMessage({ defaultMessage: 'Draft Mode' })} />
                        </Box>
                        <DialogContent>
                          <CheckboxGroup
                            name="targetAudience"
                            label={intl.formatMessage({ defaultMessage: 'Target Audience' })}
                            options={
                              roles?.map((role: any) => ({
                                label:
                                  intl.locale === 'fr' ? role?.roleNameFrench || role.roleName : role?.roleName || role?.roleNameFrench,
                                id: role.roleName
                              })) ?? []
                            }
                            row
                          />
                        </DialogContent>
                        <TextField name="title" label={intl.formatMessage({ defaultMessage: 'Title' })} />
                      </>
                    )}
                    {!isNousSupport && (
                      <Grid item display="flex" flexDirection="column" mt={4}>
                        <Box
                          sx={{
                            '& .ql-editor': { minHeight: 500, maxHeight: 400 },
                            '& .main-editor-custom': {
                              borderWidth: !showTextField ? 0 : '1px'
                            }
                          }}
                        >
                          <QuillEditorController name="description" disabled={!showTextField} />
                        </Box>
                      </Grid>
                    )}
                    {hasEditAccess && (
                      <Typography sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        {isEditing ? (
                          <>
                            {!isButtonHidden && (
                              <>
                                <Button onClick={handleCloseButtonClick} variant="outlined">
                                  <FormattedMessage defaultMessage={'cancel'} />
                                </Button>

                                <Button
                                  type="submit"
                                  sx={{
                                    fontSize: 17,
                                    cursor: 'pointer',
                                    ml: 1
                                  }}
                                  variant="contained"
                                  disabled={uploadMutation.isLoading}
                                >
                                  <FormattedMessage defaultMessage={'save'} />
                                </Button>
                              </>
                            )}
                          </>
                        ) : null}
                      </Typography>
                    )}
                  </FormContainer>

                  <Dialog open={deleteid ? true : false} onClose={cancelDelete} fullWidth>
                    <DialogTitle>
                      <FormattedMessage defaultMessage="Delete item" />
                    </DialogTitle>
                    <Divider />
                    <DialogClose onClose={cancelDelete} />
                    <DialogContent>
                      <Typography>
                        <FormattedMessage defaultMessage="Are you sure you want to delete this item??" />
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={cancelDelete} variant="outlined">
                        <FormattedMessage defaultMessage="Cancel" />
                      </Button>
                      <Button onClick={confirmDelete} variant="contained">
                        <FormattedMessage defaultMessage=" Confirm Delete" />
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Fragment>
              ) : (
                <Box height={600} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {notFound && (
                    <Typography>
                      <FormattedMessage defaultMessage="Article not found" />
                    </Typography>
                  )}
                </Box>
              )}
            </>
          )}
        </MainCard>
      </Grid>
    </>
  );
};

export default ArticlesDescription;
