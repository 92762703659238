// material-ui
import { ButtonBase } from '@mui/material';
// project imports
import config from 'config';
import { Link } from 'react-router-dom';
import Logo from 'views/components/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <Logo size="md" maxWidth={190} />
  </ButtonBase>
);

export default LogoSection;
