import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loadable from 'views/components/Loadable';
import MinimalLayout from 'views/layout/MinimalLayout';

const InformedConsent = Loadable(lazy(() => import('views/pages/informed-consent')));

const ICRoute = {
  path: '/',
  element: (
    <AuthGuard>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/informed-consent',
      element: <InformedConsent />
    }
  ]
};

export default ICRoute;
