import { lazy } from 'react';
// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'views/components/Loadable';
import MinimalLayout from 'views/layout/MinimalLayout';
import NavMotion from 'views/layout/NavMotion';
import Session from 'views/pages/session';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/login')));
// register routing
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/register')));
//
const AuthcomplimentarySession = Loadable(lazy(() => import('views/pages/authentication/complimentary-session')));
// forgot-password routing
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/forgot-password')));
// reset-password routing
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/reset-password')));
// check-email routing
const CheckEmail = Loadable(lazy(() => import('views/pages/authentication/check-mail')));
// verify-email routing
const VerifyEmail = Loadable(lazy(() => import('views/pages/authentication/verify-mail')));
// create password
const CreatePassword = Loadable(lazy(() => import('views/pages/authentication/create-password')));
// Registercontactus
const Registercontactus = Loadable(lazy(() => import('views/pages/register-contactus')));
const ThankYouPage = Loadable(lazy(() => import('views/pages/thankyou')));
const ConnectionTest = Loadable(lazy(() => import('views/pages/appointments/ConnectionTestDialog')));
// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
  path: '/',
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: '/login',
      element: (
        <NavMotion>
          <AuthLogin />
        </NavMotion>
      )
    },
    {
      path: '/register',
      element: (
        <NavMotion>
          <AuthRegister />
        </NavMotion>
      )
    },
    {
      path: '/contactme',
      element: (
        <NavMotion>
          <AuthcomplimentarySession />
        </NavMotion>
      )
    },
    {
      path: '/thankyou',
      element: <ThankYouPage />
    },
    {
      path: '/register/client',
      element: (
        <NavMotion>
          <AuthRegister />
        </NavMotion>
      )
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/create-password',
      element: <CreatePassword />
    },
    {
      path: '/password/reset',
      element: <ResetPassword />
    },
    {
      path: '/check-email',
      element: <CheckEmail />
    },
    {
      path: '/floatingsession/:sessionId',
      element: <Session />
    },
    {
      path: '/verify-email',
      element: <VerifyEmail />
    },
    {
      path: `/register-contactus`,
      element: <Registercontactus />
    },
    {
      path: '/floating-connection-test',
      element: <ConnectionTest />
    }
  ]
};

export default AuthRoutes;
