import config from 'config';
// project imports
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, userId } = useAuth();
  const { user, isPermissionsManager, isNousSupport } = useUser(userId);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && isLoggedIn) {
      if (isPermissionsManager) {
        console.log('redirect.');
        navigate('/administration', { replace: true });
      } else {
        console.log('redirect.');
        navigate(config.defaultPath, { replace: true });
      }
    }
  }, [isLoggedIn, navigate, isPermissionsManager, user]);

  return children;
};

export default GuestGuard;
