import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import waitingRoomImg from 'assets/images/call/waiting-room-leaf.png';
import { FormattedMessage } from 'react-intl';

interface Props {
  isProvider?: boolean;
}

const WaitingScreen = ({ isProvider }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {!isProvider && (
        <Typography className="waitingMsg">
          <FormattedMessage defaultMessage="Your Provider will join the session shortly." />
        </Typography>
      )}

      {isProvider && (
        <Typography className="waitingMsg">
          <FormattedMessage defaultMessage="Your Client will join the session shortly." />
        </Typography>
      )}
    </Box>
  );
};

export default WaitingScreen;

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${waitingRoomImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& .waitingMsg': {
      color: '#000',
      marginTop: '30vh',
      fontSize: 23,
      fontWeight: '500'
    }
  }
});
