import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
import { NoteType, NoteUpdateType } from 'types/note';

export default function useNotes() {
  const { request, userId } = useAuth();

  const {
    data: notes,
    refetch,
    isLoading: listLoading,
    isFetching
  } = useQuery(`/user/${userId}/notes`, () => request.get(`/user/${userId}/notes`).then((response) => response.data as any), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!userId
  });

  const { mutateAsync: createNote, isLoading: createLoading } = useMutation(
    `user/${userId}/notes/create`,
    (payload: NoteType) => request.post(`/user/${userId}/notes/`, payload).then((response) => response?.data?.note as NoteType),
    { onSuccess: () => refetch() }
  );
  const { mutateAsync: updateNote } = useMutation(
    `user/${userId}/notes/update`,
    (data: NoteUpdateType) => request.put(`/user/${userId}/notes/${data.id}`, data.payload),
    { onSuccess: () => refetch() }
  );
  const { mutateAsync: deleteNote, isLoading: deleteLoading } = useMutation(
    (noteIds: string[]) => Promise.all(noteIds.map((noteId) => request.delete(`user/${userId}/notes/${noteId}`))),
    { onSuccess: () => refetch() }
  );

  return {
    notes,
    createNote,
    updateNote,
    deleteNote,
    isLoading: isFetching || listLoading || createLoading || deleteLoading
  };
}
