import { Badge, Button, Stack } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useBlobView from 'hooks/useBlobView';
import useInvoices from 'hooks/useInvoices';
import { Session } from 'hooks/useSessions';
import { uniqueId } from 'lodash';
import { usePopupState } from 'material-ui-popup-state/hooks';

import { FormattedMessage } from 'react-intl';

interface Props {
  session: Session;
  refetchSessions: () => void;
}

export default function RecordActions({ session, refetchSessions }: Props) {
  const { userId } = useAuth();

  const popupState = usePopupState({ variant: 'popover', popupId: uniqueId('record-actions-') });
  const { updateInvoice } = useInvoices({ userId });

  const { handleClick: handleViewInvoiceClick } = useBlobView({
    getLinkUrl: `invoices/${session.invoiceId}/pdf`,
    name: 'Invoice',
    mimeType: 'application/pdf'
  });
  const { handleClick: handleViewReceiptClick } = useBlobView({
    getLinkUrl: `payments/${session.payment?.id}/receipt`,
    name: 'Receipt',
    mimeType: 'application/pdf'
  });

  return (
    <Stack spacing={2} direction="row">
      {session.paymentStatus === 'Invoiced' && session.invoiceId && (
        <Badge
          color="primary"
          variant="dot"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          invisible={session.isInvoiceViewed}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              popupState.close();
              handleViewInvoiceClick();
              updateInvoice({ invoiceId: session.invoiceId, isViewed: true });
              refetchSessions();
            }}
          >
            <FormattedMessage defaultMessage="View Invoice" />
          </Button>
        </Badge>
      )}

      {session.paymentStatus === 'Paid' && (
        <Button
          variant="contained"
          onClick={() => {
            popupState.close();
            handleViewReceiptClick();
          }}
        >
          <FormattedMessage defaultMessage="View Receipt" />
        </Button>
      )}
    </Stack>
  );
}
