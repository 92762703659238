import { Button, Typography } from '@mui/material';
import { RefObject } from 'react';
import { browserName } from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import BasicDialog from 'views/components/modals/BasicDialog';

import cameraBlocked from 'assets/images/call/camera-blocked.png';
import firefoxMicBlocked from 'assets/images/call/firefox-mic-blocked.png';
import safariMicPermissions from 'assets/images/call/safari-mic-permissions.png';
import safariPreferences from 'assets/images/call/safari-preferences.png';

type Props = {
  containerRef?: RefObject<HTMLDivElement> | null;
};

function MicrophoneBlockedDialog({ containerRef }: Props) {
  const intl = useIntl();

  return (
    <BasicDialog
      open
      onClose={() => {
        window.location.reload();
      }}
      container={containerRef?.current}
      maxWidth="xs"
      title={intl.formatMessage({ defaultMessage: 'Microphone is unavailable' })}
      actions={
        <>
          <Button
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
          >
            <FormattedMessage defaultMessage="OK" />
          </Button>
        </>
      }
    >
      {browserName === 'Safari' ? (
        <>
          <Typography component="span">Please connect your microphone.</Typography>
          <br />
          <Typography component="span">
            If you have already connected a microphone, go to <b>Safari</b> &gt; <b>Preferences</b> and then allow access to Microphone,
            then click OK button.
          </Typography>
          <img alt="preferences" src={safariPreferences} style={{ width: 160, marginTop: 20 }} />
          <img alt="cam" src={safariMicPermissions} style={{ width: 180, marginLeft: 20 }} />
        </>
      ) : (
        <>
          <Typography component="span">Please connect your microphone.</Typography>
          <br />
          <Typography component="span">
            If you have already connected a microphone, click the {browserName === 'Chrome' ? 'camera' : 'mic'} blocked icon
          </Typography>
          <img
            alt="cam"
            src={browserName === 'Chrome' ? cameraBlocked : firefoxMicBlocked}
            style={{ width: 25, position: 'relative', left: 4, top: 8, marginRight: 5 }}
          />{' '}
          <Typography component="span">
            in your browser&apos;s address bar, allow access to Camera & Microphone, then click OK button.
          </Typography>
        </>
      )}
    </BasicDialog>
  );
}

export default MicrophoneBlockedDialog;
