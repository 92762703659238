import config from 'config';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import AuthenticationRoutes from './AuthenticationRoutes';
import GlobalRoutes from './GlobalRoutes';
import ICRoute from './ICRoute';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { request, userId } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const cronofy = queryParams.get('cronofy');
  const code = queryParams.get('code');

  const authorizeCalendarMuatation = useMutation(
    ({ id, code }: { id: string; code: string }) => request.put(`/user/${id}/calendar/`, { code }),
    {
      onSuccess() {
        console.log('cronofy sync success');
        navigate('/account?tab=calendar');
      },
      onError(e: any) {
        console.log('cronofy sync failed');
        // enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      },
      onSettled() {
        console.log('cronofy sync settled');
        navigate('/account?tab=calendar');
      }
    }
  );

  useEffect(() => {
    if (cronofy && code && userId && !authorizeCalendarMuatation.isLoading) {
      authorizeCalendarMuatation.mutate({ code, id: userId });
    }
  }, [cronofy, code, userId, authorizeCalendarMuatation]);

  useEffect(() => {
    if (!cronofy && location.pathname === '/') {
      navigate(config.defaultPath);
    }
  }, [navigate, location, cronofy]);

  return useRoutes([AuthenticationRoutes, LoginRoutes, MainRoutes, ICRoute, GlobalRoutes]);
}
