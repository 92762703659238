/**
 * Opentok signals
 */
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import muted from 'assets/icons/sessions/muted.svg';
import quiet from 'assets/icons/sessions/quiet.png';
import speaking from 'assets/icons/sessions/speaking.gif';
import pinIcon from 'assets/images/call/pin.svg';
import pinnedOffIcon from 'assets/images/call/pinned-off.svg';
import beepAudio from 'assets/sounds/beep.mp3';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import useStateRef from 'react-usestateref';
import { Layout } from './LayoutButton';

let joinPromptShown = false;
let lastPinMoveDate = new Date();

type Props = {
  sessionRef?: any | null;
  floatingEmail?: string | null;
  screenNameRef?: RefObject<String | null>;
  isOwner?: boolean;
  publisherRef: any;
  subscribersRef: any;
  pinImageRef: RefObject<HTMLImageElement | null>;
  handleError: Function;
  isClient: boolean;
  isProvider: boolean;
};

export default function useSignals({
  sessionRef,
  floatingEmail,
  screenNameRef,
  isOwner,
  publisherRef,
  subscribersRef,
  pinImageRef,
  handleError,
  isClient,
  isProvider
}: Props) {
  const intl = useIntl();
  const [connectedUsers, setConnectedUsers, connectedUsersRef] = useStateRef({});
  const [isWaitingScreen, setWaitingScreen] = useState(true);
  const [publishVideo, setPublishVideo, publishVideoRef] = useStateRef(true);
  const [publishAudio, setPublishAudio, publishAudioRef] = useStateRef(false);
  const [allMuted, setAllMuted] = useState(false);
  const [isJoinPrompt, setJoinPrompt] = useState(false);
  const [isParticipantRemoved, setParticipantRemoved] = useState(false);
  const [sessionStarted, setSessionStarted, sessionStartedRef] = useStateRef(false);
  const [arrived, setArrived, arrivedRef] = useStateRef(false);
  const activeLayoutRef = useRef<Layout>('grid');
  const [currentLayout, setCurrentLayout] = useState<Layout>(activeLayoutRef.current || 'grid');
  const layoutBeforeRef = useRef<Layout>('grid');
  const pinnedStreamIdRef = useRef<String | null>(null);
  // const [captions, setCaptions] = useState<any>([]);
  // const [transcripts, setTranscripts] = useState('');
  const [whiteboardOpen, setWhiteboardOpen, whiteboardOpenRef] = useStateRef(false);
  const [annotate, setAnnotate, annotateRef] = useStateRef(false);
  const [oneToOneChatEnabled, setOneToOneChatEnabled, oneToOneChatEnabledRef] = useStateRef(false);
  const [enableParticipantsToAnnotate, setEnableParticipantsToAnnotate, enableParticipantsToAnnotateRef] = useStateRef(false);
  const timeoutRef = useRef<any>(null);
  // Camera/Microphone Permissions
  const [cameraGranted, setCameraGranted] = useState(true);
  useEffect(() => {
    const providerConnected = Object.entries(connectedUsersRef?.current)?.some(
      (connectedUser) =>
        (typeof connectedUser === 'object' && (connectedUser[1] as unknown as any)?.isProvider) ||
        (connectedUser[1] as unknown as any)?.isOwner
    );
    !providerConnected && setMuteAll(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedUsers]);

  const navigate = useNavigate();

  const { userId } = useAuth();
  const { user: loggedUser } = useUser(userId);
  const { enqueueSnackbar } = useSnackbar();

  // const onCaptionReceived = useCallback(
  //   (event, subscriber) => {
  //     const subscriberStreamId = subscriber?.stream?.connection?.id;
  //     let speaker;
  //     if (connectedUsersRef && Object.entries(connectedUsersRef.current)?.length) {
  //       const entries = Object.entries(connectedUsersRef.current);
  //       const entry = entries.find((entry) => (entry as any)[1].connectionId === subscriberStreamId);
  //       entry && (speaker = (entry as any)[1].screenName);
  //     }
  //     !speaker && (speaker = subscriber.streamName || event.streamId);
  //     setCaptions((prev) => [
  //       ...prev,
  //       {
  //         timestamp: `${new Date().toTimeString().split(' ')[0]}`,
  //         speaker: speaker,
  //         text: event.caption,
  //         isFinal: event.isFinal
  //       }
  //     ]);
  //     // eslint-disable-next-line no-useless-concat
  //     event?.isFinal && setTranscripts((prev) => prev + `${speaker}: ${event.caption}` + '~');
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [captions, connectedUsersRef.current]
  // );
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const handleLayout = useCallback(() => {
    const subscribersGrid = document.getElementById('subscribersGrid')!;

    if (activeLayoutRef.current === 'grid' || activeLayoutRef.current === 'just-them' || activeLayoutRef.current === 'picture-in-picture') {
      const [subscribersGridCols, subscribersGridRows] = getColRowCount(subscribersGrid, matchDownMd);

      if (subscribersGridCols || subscribersGridRows) {
        subscribersGrid.style.gridTemplateColumns = subscribersGridCols
          ? Array(subscribersGridCols)
              .fill(1)
              .map((c) => '1fr')
              .join(' ')
          : 'none';
        subscribersGrid.style.gridTemplateRows = subscribersGridRows
          ? Array(subscribersGridRows)
              .fill(1)
              .map((c) => '1fr')
              .join(' ')
          : 'none';
      }
    }
  }, [matchDownMd]);

  const changeLayout = useCallback(
    (layout: Layout) => {
      const subscribersGrid = document.getElementById('subscribersGrid')!;
      const hiddenGrid = document.getElementById('hiddenGrid')!;

      const pipView = document.getElementById('pipView')!;

      const leftView = document.getElementById('leftView')!;
      const leftViewLeftPanel = leftView.querySelector('.leftPanel')!;
      const leftViewRightPanel = leftView.querySelector('.rightPanel')!;

      const justMe = document.getElementById('justMe')!;
      const pinned = document.getElementById('pinned')!;

      const applyPinIcon = (parent) => {
        const pinImage = pinImageRef.current!;

        const onPinClick = (streamId) => {
          if (!pinnedStreamIdRef.current) {
            pinnedStreamIdRef.current = streamId;
            layoutBeforeRef.current = activeLayoutRef.current;

            changeLayout('pinned');
          } else {
            pinnedStreamIdRef.current = null;

            changeLayout(layoutBeforeRef.current);
          }
        };

        parent.element.onmousemove = (e) => {
          const currentTime = new Date();
          if (currentTime.getTime() - lastPinMoveDate.getTime() < 100) return;

          parent.element.appendChild(pinImage);
          const centerX = parent.element.clientWidth / 2;
          const centerY = parent.element.clientHeight / 2;
          if ((e.offsetX < 50 && e.offsetY < 50) || (Math.abs(centerX - e.offsetX) < 100 && Math.abs(centerY - e.offsetY) < 80)) {
            pinImage.className = 'show';
            pinImage.style.display = whiteboardOpenRef?.current ? 'none' : 'block';
            // setTimeout(() => {
            //   pinImage.className = 'hide';
            //   pinImage.style.display = 'none';
            // }, 15000);
          } else {
            pinImage.className = 'hide';
            pinImage.style.display = 'none';
          }
          if (!pinnedStreamIdRef.current) {
            pinImage.title = intl.formatMessage({ defaultMessage: 'Focus this participant' });
            pinImage.src = pinIcon;
          } else {
            pinImage.title = intl.formatMessage({ defaultMessage: 'Unfocus this participant' });
            pinImage.src = pinnedOffIcon;
          }

          pinImage.onclick = () => {
            setTimeout(() => {
              onPinClick(parent?.stream?.streamId);
            }, 500);
          };

          lastPinMoveDate = currentTime;
        };
      };

      activeLayoutRef.current = layout;
      setCurrentLayout(layout);
      if (layout === 'pinned' && pinnedStreamIdRef.current === null) {
        layoutBeforeRef.current = 'grid';
        activeLayoutRef.current = 'grid';
      }

      const screenElement = subscribersRef.current.findLast(
        (subscriber) => subscriber?.stream?.videoType === 'screen' || subscriber?.stream?.name === 'screen'
      );
      if (whiteboardOpenRef.current && screenElement) {
        setAnnotate(true);
      } else {
        setAnnotate(false);
      }

      switch (activeLayoutRef.current) {
        case 'pinned':
          pipView.style.display = 'none';
          subscribersGrid.style.display = 'none';
          leftView.style.display = 'none';
          justMe.style.display = 'none';
          pinned.style.display = 'grid';

          const allChildren = [publisherRef.current];
          subscribersRef.current.forEach((subscriber) => {
            allChildren.push(subscriber);
          });

          const toBePinnedElement = allChildren.find((child) => child?.stream?.streamId === pinnedStreamIdRef.current);
          if (toBePinnedElement) {
            allChildren.forEach((child) => {
              if (child?.stream?.streamId !== pinnedStreamIdRef.current) {
                hiddenGrid.appendChild(child.element);
              } else {
                pinned.appendChild(child.element);
                child.element.style.height = '100%';
              }
            });
          }

          break;
        case 'grid':
          pipView.style.display = 'none';
          subscribersGrid.style.display = 'grid';
          subscribersGrid.style.overflowX = 'scroll';
          leftView.style.display = 'none';
          justMe.style.display = 'none';
          pinned.style.display = 'none';

          subscribersGrid.appendChild(publisherRef.current.element);
          applyPinIcon(publisherRef.current);
          subscribersRef.current.forEach((subscriber) => {
            subscribersGrid.appendChild(subscriber.element);

            applyPinIcon(subscriber);
          });

          handleLayout();
          break;
        case 'just-them':
          pipView.style.display = 'none';
          subscribersGrid.style.display = 'grid';
          leftView.style.display = 'none';
          justMe.style.display = 'none';
          pinned.style.display = 'none';

          hiddenGrid.appendChild(publisherRef.current.element);
          subscribersRef.current.forEach((subscriber) => {
            subscribersGrid.appendChild(subscriber.element);
            applyPinIcon(subscriber);
          });
          handleLayout();
          break;
        case 'picture-in-picture':
          pipView.style.display = 'grid';
          subscribersGrid.style.display = 'grid';
          publisherRef.current.element.style.width = '100%';
          leftView.style.display = 'none';
          justMe.style.display = 'none';
          pinned.style.display = 'none';

          pipView.appendChild(publisherRef.current.element);
          subscribersRef.current.forEach((subscriber) => {
            subscribersGrid.appendChild(subscriber.element);
          });

          handleLayout();
          break;
        case 'just-me':
          pipView.style.display = 'none';
          subscribersGrid.style.display = 'none';
          leftView.style.display = 'none';
          publisherRef.current.element.style.width = '100%';
          justMe.style.display = 'grid';
          pinned.style.display = 'none';

          justMe.appendChild(publisherRef.current.element);
          handleLayout();
          break;
        case 'left-sidebar':
          pipView.style.display = 'none';
          subscribersGrid.style.display = 'none';
          leftView.style.display = 'grid';
          justMe.style.display = 'none';
          pinned.style.display = 'none';

          let rightViewElement;

          if (whiteboardOpenRef.current && !screenElement) {
            rightViewElement = null;
            if (pinImageRef?.current) {
              pinImageRef.current.style.display = 'none';
            }
          } else {
            rightViewElement = screenElement;
            if (!rightViewElement && subscribersRef.current.length) {
              rightViewElement = subscribersRef.current[subscribersRef.current.length - 1];
            }
          }

          for (let i = subscribersRef.current.length - 1; i >= 0; i--) {
            leftViewLeftPanel.appendChild(subscribersRef.current[i].element);
          }
          leftViewLeftPanel.appendChild(publisherRef.current.element);
          if (rightViewElement) {
            leftViewRightPanel.appendChild(rightViewElement.element);
          }

          if (!whiteboardOpenRef.current) {
            applyPinIcon(publisherRef.current);
            subscribersRef.current.forEach((subscriber) => {
              applyPinIcon(subscriber);
            });
          }

          handleLayout();
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleLayout, publisherRef, subscribersRef, whiteboardOpenRef, intl, pinImageRef, setAnnotate, matchDownMd]
  );
  useEffect(() => {
    handleLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd, handleLayout]);

  /**
   * Send signal to all with user settings
   */
  const emitPresenceToAll = useCallback(() => {
    if (!sessionRef.current?.connection || !sessionStartedRef.current || !publisherRef.current) {
      return;
    }

    if (sessionStartedRef.current && arrivedRef.current) {
      publisherRef.current.publishVideo(publishVideoRef.current);
      publisherRef.current.publishAudio(
        Object.entries(connectedUsersRef?.current)?.some(
          (connectedUser) =>
            (typeof connectedUser === 'object' && (connectedUser[1] as unknown as any)?.isProvider) ||
            (connectedUser[1] as unknown as any)?.isOwner
        ) && publishAudioRef.current
      );
    }

    sessionRef.current?.signal({
      type: 'userConnected',
      data: JSON.stringify({
        connectionId: sessionRef.current?.connection?.connectionId,
        userId,
        floatingEmail,
        publishVideo: publishVideoRef.current,
        publishAudio:
          Object.entries(connectedUsersRef?.current)?.some(
            (connectedUser) =>
              (typeof connectedUser === 'object' && (connectedUser[1] as unknown as any)?.isProvider) ||
              (connectedUser[1] as unknown as any)?.isOwner
          ) && publishAudioRef.current,
        role: loggedUser?.role ?? 'CLIENT',
        screenName: screenNameRef?.current ?? '',
        image: loggedUser?.imageSmall,
        oneToOneChatEnabled: oneToOneChatEnabledRef.current,
        enableParticipantsToAnnotate: enableParticipantsToAnnotateRef.current,
        isOwner,
        isClient,
        isProvider
      })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sessionRef,
    sessionStartedRef,
    publisherRef,
    arrivedRef,
    userId,
    floatingEmail,
    publishVideoRef,
    publishAudioRef,
    loggedUser?.role,
    loggedUser?.imageSmall,
    screenNameRef,
    oneToOneChatEnabledRef,
    enableParticipantsToAnnotateRef,
    isOwner,
    isClient,
    isProvider
  ]);

  const emitOpenWhiteboard = useCallback(
    (annotate = false) => {
      sessionRef.current?.signal({
        type: !annotate ? 'openWhiteboard' : 'annotate',
        data: JSON.stringify({
          connectionId: sessionRef.current?.connection?.connectionId
        })
      });
    },
    [sessionRef]
  );

  useEffect(() => {
    const session = sessionRef.current;

    return () => {
      console.log('>>>Destroying');
      session?.off(
        'streamCreated streamDestroyed connectionDestroyed signal:userConnected signal:muteCam signal:mute signal:muteAll signal:unmuteAll signal:removeParticipant signal:annotate'
      );
    };
  }, [sessionRef]);

  useEffect(() => {
    if (screenNameRef?.current) {
      emitPresenceToAll();
    }
  }, [sessionStarted, arrived, emitPresenceToAll, screenNameRef, publishVideo, publishAudio]);

  useEffect(() => {
    if (!cameraGranted) {
      setTimeout(() => {
        setPublishVideo(false);
        emitPresenceToAll();
      }, 800);
    }
  }, [cameraGranted, setPublishVideo, emitPresenceToAll]);

  /**
   * Mute/unmute all
   */
  const setMuteAll = useCallback(
    (value) => {
      setAllMuted(value);

      sessionRef.current?.signal({
        type: value ? 'muteAll' : 'unmuteAll'
      });
    },
    [sessionRef]
  );

  /**
   * Change oneToOneChatEnabled and send signal to all
   */
  const setOneToOneChatEnabledAndSignal = useCallback(
    (value) => {
      setOneToOneChatEnabled(value);
      emitPresenceToAll();
    },
    [setOneToOneChatEnabled, emitPresenceToAll]
  );
  /**
   * Change enableParticipantsToAnnotateVar and send signal to all
   */
  const setEnableParticipantsToAnnotateAndSignal = useCallback(
    (value) => {
      setEnableParticipantsToAnnotate(value);
      emitPresenceToAll();
    },
    [setEnableParticipantsToAnnotate, emitPresenceToAll]
  );

  /**
   * Add user to connectedUsers
   */
  const addConnectedUser = useCallback(
    (user) => {
      const userExisted = connectedUsersRef.current[user?.userId ?? user?.floatingEmail];
      if (!userExisted && user.isProvider) {
        sessionRef.current?.signal({
          type: 'unmuteAll'
        });
      }
      if (user.image) {
        const subscriber = subscribersRef.current?.find((sub) => sub?.stream?.connection?.id === user?.connectionId);
        subscriber?.setStyle('backgroundImageURI', user.image);
      }

      connectedUsersRef.current[user?.userId ?? user?.floatingEmail] = user;
      setConnectedUsers({ ...connectedUsersRef.current });

      // Remove waiting screen, pause waiting music, show alert of mic and camera being used

      const audio = new Audio(beepAudio);
      if (user?.isProvider && (loggedUser?.isClient || !loggedUser?.role)) {
        setArrived(true);

        if (sessionStartedRef.current) {
          publisherRef.current.publishVideo(publishVideoRef.current);
          publisherRef.current.publishAudio(
            Object.entries(connectedUsersRef?.current)?.some(
              (connectedUser) =>
                (typeof connectedUser === 'object' && (connectedUser[1] as unknown as any)?.isProvider) ||
                (connectedUser[1] as unknown as any)?.isOwner
            ) && publishAudioRef.current
          );
        }

        if (!joinPromptShown && isWaitingScreen) {
          setJoinPrompt(true);
          joinPromptShown = true;
        }
        setWaitingScreen(false);

        if (!userExisted) {
          setTimeout(() => {
            audio.play();
            enqueueSnackbar(
              intl.locale === 'fr' ? `${user.screenName} (Fournisseur) est arrivé` : `${user.screenName} (Provider) has arrived`,
              { variant: 'success' }
            );
          }, 1500);
        }
      } else if ((user?.isClient || user?.isProvider || !user?.role) && loggedUser?.isProvider && user.userId !== loggedUser.id) {
        setArrived(true);

        if (sessionStartedRef.current) {
          publisherRef.current.publishVideo(publishVideoRef.current);
          publisherRef.current.publishAudio(
            Object.entries(connectedUsersRef?.current)?.some(
              (connectedUser) =>
                (typeof connectedUser === 'object' && (connectedUser[1] as unknown as any)?.isProvider) ||
                (connectedUser[1] as unknown as any)?.isOwner
            ) && publishAudioRef.current
          );
        }

        if (!joinPromptShown && isWaitingScreen) {
          setJoinPrompt(true);
          joinPromptShown = true;
        }
        setWaitingScreen(false);

        if (!userExisted) {
          setTimeout(() => {
            audio.play();
            if (user?.isProvider) {
              enqueueSnackbar(
                intl.locale === 'fr' ? `${user.screenName} (Fournisseur) est arrivé` : `${user.screenName} (Provider) has arrived`,
                { variant: 'success' }
              );
            } else {
              enqueueSnackbar(intl.locale === 'fr' ? `${user.screenName} (Client) est arrivé` : `${user.screenName} (Client) has arrived`, {
                variant: 'success'
              });
            }
          }, 1500);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      connectedUsersRef,
      setConnectedUsers,
      loggedUser,
      subscribersRef,
      setArrived,
      sessionStartedRef,
      isWaitingScreen,
      publisherRef,
      publishVideoRef,
      publishAudioRef,
      enqueueSnackbar,
      intl
    ]
  );

  /**
   * Remove user to connectedUsers
   */
  const removeConnectedUser = useCallback(
    (connectionId) => {
      const users = Object.values(connectedUsersRef.current);
      const foundUser: any = users.find((obj: any) => obj?.connectionId === connectionId)!;

      if (!foundUser) {
        return;
      }

      const audio = new Audio(beepAudio);
      audio.play();

      if (arrivedRef?.current) {
        if (foundUser?.isProvider) {
          enqueueSnackbar(
            intl.locale === 'fr' ? `${foundUser.screenName} (Fournisseur) a quitté` : `${foundUser.screenName} (Provider) has left`
          );
        } else {
          enqueueSnackbar(
            intl.locale === 'fr' ? `${foundUser.screenName} (Client) est parti` : `${foundUser.screenName} (Client) has left`
          );
        }
      }
      delete connectedUsersRef.current[foundUser?.userId ?? foundUser?.floatingEmail];

      setConnectedUsers({ ...connectedUsersRef.current });
    },
    [connectedUsersRef, arrivedRef, enqueueSnackbar, setConnectedUsers, intl]
  );

  const isCameraAvailable = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      return true;
    } catch (error: any) {
      return false;
    }
  };

  const setupSignals = useCallback(
    (token: string) => {
      const session = sessionRef.current!;

      session.on('streamCreated', async (event: any) => {
        const storedToken = localStorage.getItem('token');
        if (storedToken !== token) {
          console.log('Token mismatch');
          window.location.replace('/');
        }

        const subscribersDiv = document.getElementById('subscribersGrid')!;

        const subscriberExists = subscribersRef.current.find((subscriber) => subscriber?.streamId === event?.stream?.streamId);
        if (subscriberExists) {
          return;
        }

        const subscriber = session.subscribe(
          event.stream,
          subscribersDiv,
          {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            showControls: true,
            style: { buttonDisplayMode: 'off', nameDisplayMode: 'off', audioLevelDisplayMode: 'off' }
          },
          handleError
        );
        // try {
        //   await subscriber.subscribeToCaptions(true);
        // } catch (err) {
        //   console.warn(err);
        // }
        // subscriber.on('captionReceived', (event) => {
        //   onCaptionReceived(event, subscriber);
        // });
        subscribersRef.current.push(subscriber);
        const entries = Object.entries(connectedUsersRef.current);
        const getCurrentConnectedSubscriber = entries.find(
          (user) => user?.length > 0 && (user[1] as any)?.connectionId === subscriber?.stream?.connection?.id
        );
        if (
          getCurrentConnectedSubscriber?.length &&
          (getCurrentConnectedSubscriber[1] as any)?.screenName &&
          !subscriber.element.querySelector(`#${subscriber?.id}-name`)
        ) {
          const nameTextElement = document.createElement('span');
          nameTextElement.innerHTML = (getCurrentConnectedSubscriber[1] as any)?.screenName;
          nameTextElement.id = `${subscriber?.id}-name`;
          nameTextElement.setAttribute(
            'style',
            'display: block; position: absolute; top: 10px; left: 10px; color: white; font-size: 1rem; z-index: 50;'
          );
          subscriber.element.append(nameTextElement);
        }
        subscriber?.on('audioLevelUpdated', (event) => {
          const expectedAudioThreshold = 0.09;
          const participantRefId = event?.target?.id;
          const participantRefContainer = document.getElementById(participantRefId);
          const isSpeakingGifAppended = document.getElementById(`${participantRefId}-speakingGif`);
          const isQuietImgAppended = document.getElementById(`${participantRefId}-quietImg`);
          const isMutedImgAppended = document.getElementById(`${participantRefId}-mutedImg`);
          let speakingGif, quietImg, mutedImg;
          if (participantRefContainer) {
            /*
             * here we check if the participant audio is unmuted
             */
            if (event?.audioLevel > expectedAudioThreshold) {
              // This is used to add border effect to speaking participant
              participantRefContainer.setAttribute(
                'style',
                'border: 5px solid #6e9fc3; border-radius: 1rem; overflow: hidden; position: relative;'
              );
              // This is used to add speaking gif to speaking participant
              if (!isSpeakingGifAppended) {
                speakingGif = document.createElement('img');
                speakingGif.id = `${participantRefId}-speakingGif`;
                speakingGif.src = speaking;
                speakingGif.position = 'absolute';
                speakingGif.top = 0;
                speakingGif.right = 0;
                speakingGif.height = '30px';
                speakingGif.width = '50px';
                speakingGif.style.zIndex = 50;
                participantRefContainer.append(speakingGif);
              } else {
                // This is used to show speaking gif to speaking participant
                isSpeakingGifAppended.setAttribute(
                  'style',
                  'display: block; position: absolute; top: 0; right: 0; height: 50px; width: 50px; z-index: 50;'
                );
              }
              // This is used to remove quiet img from speaking participant
              if (isQuietImgAppended) {
                isQuietImgAppended.setAttribute('style', 'display: none;');
              }
            } else {
              // This is used to add border effect to muted participant
              participantRefContainer.setAttribute(
                'style',
                'transition: border-color 0.75s ease;border: 5px solid #E3F2FD; border-radius: 1rem; overflow: hidden; position: relative;'
              );

              if (!event?.target?.stream?.hasAudio) {
                // This is used to add muted img to muted participant
                if (!isMutedImgAppended) {
                  mutedImg = document.createElement('img');
                  mutedImg.id = `${participantRefId}-mutedImg`;
                  mutedImg.src = muted;
                  mutedImg.position = 'absolute';
                  mutedImg.top = '10px';
                  mutedImg.right = '10px';
                  mutedImg.background = 'white';
                  mutedImg.border = '1px solid white';
                  mutedImg.borderRadius = '50%';
                  mutedImg.padding = '5px';
                  mutedImg.height = '30px';
                  mutedImg.width = '30px';
                  mutedImg.style.zIndex = 50;
                  participantRefContainer.append(mutedImg);
                } else {
                  // This is used to show muted img to muted participant
                  isMutedImgAppended.setAttribute(
                    'style',
                    'display: block; position: absolute; top: 10px; right: 10px; height: 30px; width: 30px; background: white; transition: border-color 0.75s ease; border: 1px solid white; border-radius: 50%; padding: 5px; z-index: 50;'
                  );
                }
                // This is used to remove quiet img from speaking participant
                if (isQuietImgAppended) {
                  isQuietImgAppended.setAttribute('style', 'display: none;');
                }
              } else {
                // This is used to add border effect to quiet participant
                participantRefContainer.setAttribute(
                  'style',
                  'transition: border-color 0.75s ease; border: 5px solid #6e9fc3;; border-radius: 1rem; overflow: hidden; position: relative;'
                );
                // This is used to add quiet img to muted participant
                if (!isQuietImgAppended) {
                  quietImg = document.createElement('img');
                  quietImg.id = `${participantRefId}-quietImg`;
                  quietImg.src = quiet;
                  quietImg.position = 'absolute';
                  quietImg.top = 0;
                  quietImg.right = 0;
                  quietImg.height = '50px';
                  quietImg.width = '50px';
                  quietImg.style.zIndex = 50;
                  participantRefContainer.append(quietImg);
                } else {
                  // This is used to show quiet img to muted participant
                  isQuietImgAppended.setAttribute(
                    'style',
                    'display: block; position: absolute; top: 0; right: 0; height: 50px; width: 50px; z-index: 50;'
                  );
                }
                // This is used to remove muted img from speaking participant
                if (isMutedImgAppended) {
                  isMutedImgAppended.setAttribute('style', 'display: none;');
                }
              }
              // This is used to remove speaking gif from muted participant
              if (isSpeakingGifAppended) {
                isSpeakingGifAppended.setAttribute('style', 'display: none;');
              }
            }
          }
        });

        if (event?.stream?.videoType === 'screen' || event?.stream?.name === 'screen') {
          pinnedStreamIdRef.current = null;
          layoutBeforeRef.current = activeLayoutRef.current;
          changeLayout('left-sidebar');
        } else {
          changeLayout(activeLayoutRef.current);
        }

        emitPresenceToAll();
      });

      session.on('streamDestroyed', (event: any) => {
        if (pinnedStreamIdRef.current === event.stream.streamId) {
          pinnedStreamIdRef.current = null;
        }

        subscribersRef.current = subscribersRef.current.filter((subscriber) => subscriber?.streamId !== event?.stream?.streamId);

        setTimeout(() => {
          changeLayout(
            event?.stream?.videoType === 'screen' || event?.stream?.name === 'screen' ? layoutBeforeRef.current : activeLayoutRef.current
          );
        }, 500);
      });

      session.on('connectionDestroyed', (event: any) => {
        removeConnectedUser(event.connection.id);
      });

      // User presence signal
      session.on('signal:userConnected', (event: any) => {
        const user = JSON.parse(event?.data);
        addConnectedUser(user);
        const getSubscriber = subscribersRef?.current?.find((sub) => sub?.stream?.connection?.id === user?.connectionId);
        const subscribersDiv = document.getElementById('subscribersGrid')!;
        const participantRefContainer = getSubscriber?.id && subscribersDiv.querySelector(`#${getSubscriber?.id}`);
        if (participantRefContainer && user?.screenName) {
          const screenNameElementFound = participantRefContainer.querySelector(`#${getSubscriber?.id}-name`);
          if (!screenNameElementFound) {
            const nameTextElement = document.createElement('span');
            nameTextElement.innerHTML = user?.screenName;
            nameTextElement.id = `${getSubscriber?.id}-name`;
            nameTextElement.setAttribute(
              'style',
              'display: block; position: absolute; top: 10px; left: 10px; color: white; font-size: 1rem; z-index: 50; font-weight: 500;'
            );
            participantRefContainer.append(nameTextElement);
          }
        }
        if (user.isProvider && 'oneToOneChatEnabled' in user) {
          setOneToOneChatEnabled(user.oneToOneChatEnabled);
          setEnableParticipantsToAnnotate(user.enableParticipantsToAnnotate);
        }
      });

      // Mute camera
      session.on('signal:muteCam', async (event: any) => {
        const data = JSON.parse(event?.data);

        if ((data.id && data.id === userId) || data.email === floatingEmail) {
          if (!(await isCameraAvailable())) {
            setPublishVideo(false);
            return;
          }
          setPublishVideo(data.value);
        }
      });

      // Mute audio
      session.on('signal:mute', (event: any) => {
        const data = JSON.parse(event?.data);

        if ((data.id && data.id === userId) || data.email === floatingEmail) {
          setPublishAudio(data.value);
        }
      });

      // Mute everyone's audio
      session.on('signal:muteAll', (event: any) => {
        if (!isOwner) {
          setPublishAudio(false);
        }
      });

      // Unmute everyone's audio
      session.on('signal:unmuteAll', (event: any) => {
        if (!isOwner) {
          setPublishAudio(true);
        }
      });

      // Participant Removed
      session.on('signal:removeParticipant', (event: any) => {
        const { id, email } = JSON.parse(event.data);
        if ((id && id === userId) || email === floatingEmail) {
          setParticipantRemoved(true);
          setTimeout(() => {
            session.disconnect();
            navigate('/appointments');
          }, 2000);
        }
      });

      session.on('signal:annotate', (event: any) => {
        activeLayoutRef.current = 'left-sidebar';
        pinnedStreamIdRef.current = null;
        setAnnotate(true);

        layoutBeforeRef.current = activeLayoutRef.current;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [floatingEmail, userId]
  );

  if (!sessionRef.current || !(floatingEmail || loggedUser)) {
    return {};
  }

  return {
    changeLayout,
    connectedUsers,
    isWaitingScreen,
    allMuted,
    setMuteAll,
    publishVideo,
    publishAudio,
    setPublishAudio,
    setPublishVideo,
    isJoinPrompt,
    setJoinPrompt,
    isParticipantRemoved,
    sessionStarted,
    setSessionStarted,
    arrived,
    emitOpenWhiteboard,
    activeLayoutRef,
    currentLayout,
    layoutBeforeRef,
    oneToOneChatEnabled,
    setOneToOneChatEnabledAndSignal,
    enableParticipantsToAnnotate,
    setEnableParticipantsToAnnotateAndSignal,
    cameraGranted,
    setCameraGranted,
    setupSignals,
    handleLayout,
    whiteboardOpen,
    setWhiteboardOpen,
    whiteboardOpenRef,
    annotate,
    setAnnotate,
    annotateRef,
    pinnedStreamIdRef,
    arrivedRef
    // captions,
    // onCaptionReceived,
    // transcripts
  };
}

function getColRowCount(element, matchDownMd) {
  const count = element.childElementCount;
  if (!count) return [0, 0];

  let colNum = 1;
  let rowNum = 1;

  if (count <= 1) {
    colNum = 1;
    rowNum = 1;
  } else if (count <= 2) {
    colNum = matchDownMd ? 1 : 2;
    rowNum = matchDownMd ? 2 : 1;
  } else if (count <= 4) {
    colNum = matchDownMd ? 1 : 2;

    rowNum = 2;
  } else if (count <= 5) {
    colNum = matchDownMd ? 1 : 2;

    rowNum = 3;
  } else {
    colNum = Math.ceil(Math.sqrt(count));
    rowNum = Math.ceil(Math.sqrt(count));
  }
  return [colNum, rowNum];
}
