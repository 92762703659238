import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export default function useAdminDashboardProviderList() {
  const { request } = useAuth();

  const {
    data: { providerList } = {},
    refetch,
    isLoading,
    isFetching,
    isRefetching
  } = useQuery('/dashboard-admin/providerList', () => request.get('/dashboard-admin/providerList').then((response) => response.data), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (data) => ({
      providerList: data.providerList
    })
  });

  return {
    providerList,
    refetchProviders: refetch,
    isLoading: isLoading || isFetching || isRefetching
  };
}
