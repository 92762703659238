import { useLocation, useParams } from 'react-router-dom';
import useOptions from './useOptions';
import useUser from './useUser';

export default function useProvider(id?: string, providerId?: string) {
  const { user, isProvider, isProviderAdmin } = useUser(id);
  const { isDefaultProvider, defaultProvider } = useOptions();
  const queryParams = new URLSearchParams(useLocation().search);
  const { userId } = useParams();
  const therapistId = userId ?? queryParams.get('therapist');

  let selectedProviderId = isProvider || isProviderAdmin ? user?.id : user?.therapist;

  if (userId) {
    selectedProviderId = userId;
  }

  if (therapistId) {
    selectedProviderId = therapistId;
  }

  if (providerId) {
    selectedProviderId = providerId;
  }

  if (!selectedProviderId && isDefaultProvider) {
    selectedProviderId = defaultProvider;
  }

  if (!selectedProviderId) {
    selectedProviderId = id;
  }

  const { user: provider, isLoading } = useUser(selectedProviderId);

  return { provider, isLoading };
}
