import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, InputAdornment, LinearProgress, Skeleton, Stack, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowData, GridSortItem } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps, useGridApiRef } from '@mui/x-data-grid-pro';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro';
import { debounce, escapeRegExp, uniqBy } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';
import NoResults from './common/NoResults';
import TopHorizontalScroll from './scrolls/TopHorizontalScroll';

LicenseInfo.setLicenseKey('6858f8b162dc6cce95dd2513a024c2efT1JERVI6NDIyOTcsRVhQSVJZPTE2ODIxOTc4NDcwMDAsS0VZVkVSU0lPTj0x');

interface Props extends Omit<DataGridProProps, 'rows'> {
  emptyMessage: ReactNode;
  rows: GridRowData[] | null | undefined;
  defaultSortModel?: GridSortItem[];
  uniqueBy?: string;
  hideSearch?: boolean;
  enableDoubleScroll?: boolean;
  alignSearch?: string;
  setSite?: any;
  showSitesDropdown?: boolean;
  sites?: any[];
}

const useStyles = makeStyles((theme: Theme) => ({
  hideSeparator: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    }
  },
  root: {
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main
    }
  }
}));

export default function BasicTable({
  setSite,
  emptyMessage,
  rows,
  columns,
  pageSize = 8,
  uniqueBy = 'id',
  defaultSortModel,
  hideSearch = false,
  enableDoubleScroll = false,
  alignSearch = 'flex-end',
  showSitesDropdown,
  sites,
  ...props
}: Props) {
  const intl = useIntl();
  const classes = useStyles();

  const tableRef = useGridApiRef();

  const [sortModel, setSortModel] = useState<GridSortItem[]>(
    defaultSortModel ?? [
      {
        field: columns?.[0]?.field[1],
        sort: 'asc'
      }
    ]
  );
  const [filteredRows, setFilteredRows] = useState<GridRowData[] | undefined>(undefined);

  useEffect(() => {
    if (rows) {
      setFilteredRows(uniqBy(rows, uniqueBy));
    }
  }, [rows, uniqueBy]);

  const decoratedColumns = useMemo(
    () =>
      columns
        .map((column) => (column.field ? column : { ...column, field: 'actions', headerName: ' ' }))
        .map((column) => {
          if (column.width || column.flex) {
            return column;
          }

          return { ...column, flex: 1 };
        })
        .map((column) => ({ ...column, headerClassName: classes.hideSeparator })),
    [columns, classes]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeFilter = useCallback(
    debounce((filter) => {
      const filterRegex = new RegExp(escapeRegExp(filter), 'i');
      setFilteredRows(
        uniqBy(
          rows?.filter((row) =>
            columns.some((column) => {
              if (filterRegex.test(row[column.field] ?? '')) {
                return true;
              }

              if (filterRegex.test(column.valueGetter?.({ value: row[column.field], row } as any)?.toString() ?? '')) {
                return true;
              }

              try {
                if (filterRegex.test(renderToString(<>{column.renderCell?.({ row } as any)}</>))) {
                  return true;
                }
                // eslint-disable-next-line no-empty
              } catch {}

              return false;
            })
          ) ?? [],
          uniqueBy
        )
      );
    }, 200),
    [columns, rows]
  );

  const handleScroll = (e: any) => {
    if (!enableDoubleScroll) return;
    const topScroll = document.getElementById('topScroll')!;

    if (
      Math.abs(topScroll.scrollLeft - e.target.scrollLeft) < 50 ||
      (Math.abs(topScroll.scrollLeft - e.target.scrollLeft) > 100 && e.target.scrollLeft < 100)
    )
      return;

    topScroll.scrollLeft = e.target.scrollLeft;
  };

  const { rowsPerPageOptions, ...rest } = props;

  return (
    <Stack spacing={2}>
      {!hideSearch && (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={(e: any) => handleChangeFilter(e.target.value)}
          placeholder={intl.formatMessage({ defaultMessage: 'Search...' })}
          size="small"
          sx={{ alignSelf: alignSearch, width: 280, margin: 2 }}
        />
      )}
      {showSitesDropdown && (
        <Autocomplete
          sx={{ width: 280 }}
          options={sites || []}
          isOptionEqualToValue={(option, value) => option === value}
          freeSolo={false}
          renderInput={(params) => <TextField {...params} label="Sites" />}
          onChange={(e, value) => {
            setSite(value);
          }}
          id="siteName"
        />
      )}
      {!filteredRows && (
        <Stack spacing={1}>
          <LinearProgress />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
          <Skeleton variant="rectangular" height={30} />
        </Stack>
      )}

      {filteredRows && filteredRows.length === 0 && <NoResults>{emptyMessage}</NoResults>}

      {filteredRows && filteredRows.length > 0 && (
        <Box onScrollCapture={handleScroll}>
          {enableDoubleScroll && <TopHorizontalScroll tableRef={tableRef} />}
          <DataGridPro
            apiRef={tableRef}
            className={classes.root}
            autoHeight
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            isRowSelectable={() => false}
            columns={decoratedColumns}
            rows={filteredRows}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions || [pageSize]}
            pagination
            paginationMode="server"
            disableVirtualization
            disableColumnMenu
            componentsProps={{
              pagination: {
                labelRowsPerPage: intl.formatMessage({ defaultMessage: 'Rows per page' })
              }
            }}
            {...rest}
          />
        </Box>
      )}
    </Stack>
  );
}
