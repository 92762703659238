import useAuth from 'hooks/useAuth';
import useUsers from 'hooks/useUsers';
import { useQuery } from 'react-query';
import { User } from 'types/user';

export interface TherapistPatient extends Omit<User, 'therapist'> {
  _id?: string;
  therapist: User | null;
  therapistId: string;
  imageUrl: string;
  client: any;
}

export default function useDashboardTherapistPatients(therapistId: string | null | undefined) {
  const { request } = useAuth();
  const { users } = useUsers({});

  const url = `/dashboard/${therapistId}/providerClients`;

  const {
    data: patients,
    refetch: refetchPatients,
    isLoading
  } = useQuery(
    url,
    () =>
      request.get(url).then((response) => {
        return response.data.patients;
      }),
    {
      enabled: !!therapistId && !!users && !url.includes('undefined')
    }
  );

  return { patients, refetchPatients, isLoading };
}
