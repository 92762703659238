import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { UserAnnouncementsResponse } from './../types/announcement';
import useAuth from './useAuth';

export default function useUserAnnouncements() {
  const { request } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: userAnnouncements,
    refetch: refetchUserAnnouncements,
    isLoading
  } = useQuery('/user-announcements', () => request.get('/user-announcements').then(({ data }) => data as UserAnnouncementsResponse));

  const { mutateAsync: updateUserAnnouncement } = useMutation(
    '/user-announcements/POST',
    (payload: any) => request.put('/user-announcements', payload),
    {
      onError: (error: string) => {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  );

  return { userAnnouncements, updateUserAnnouncement, refetchUserAnnouncements, isLoading };
}
