import { EventInput } from '@fullcalendar/core';
import useAuth from 'hooks/useAuth';
import { useQueriesTyped } from 'hooks/useQueriesTyped';
import useUsers from 'hooks/useUsers';
import { useCallback, useMemo } from 'react';
import { Appointment } from 'types/appointment';
import { populate } from 'utils/arrayUtils';
import { appointmentToEvent, ntCalendarToEvent } from './useAppointments';

interface Props {
  from: Date;
  to: Date;
  enabled?: boolean;
}

export default function useAllAppointments({ from, to, enabled = true }: Props) {
  const { request, userId } = useAuth();

  const { therapists, users } = useUsers({});

  const activeTherapists = therapists.filter((t) => !(t.inactive || t.disabled));

  const queries = useQueriesTyped(
    activeTherapists
      ? activeTherapists.map((user) => ({
          queryKey: ['user', user.id],
          queryFn: () =>
            request.get(`/user/${user.id}/appointments`, { params: { from, to } }).then(
              (response) =>
                response.data.appointmentData.map((appointment: { members: any[]; start: string }) => ({
                  ...appointment,
                  members: populate(appointment.members, users),
                  resourceId: user.id
                })) as Appointment[]
            )
        }))
      : []
  );

  const events = useMemo(() => {
    if (queries) {
      return queries.reduce(
        (prev, query) => [
          ...prev,
          ...(query.data
            ? query.data?.map((d: any) => {
                if (d?.ntCalendarEvent) {
                  return ntCalendarToEvent(d, d.resourceId);
                }

                return appointmentToEvent(d, userId || '', d.resourceId);
              })
            : [])
        ],
        [] as EventInput[]
      );
    }
    return [];
  }, [queries, userId]);

  const allFinished = queries.filter((q) => q.isLoading).length === 0;

  const refetchQueries = useCallback(() => {
    queries.forEach((query) => {
      query.refetch();
    });
  }, [queries]);

  return { queries, refetchQueries, events, allFinished };
}
