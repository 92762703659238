import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { format, parse } from 'date-fns';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useProvider from 'hooks/useProvider';
import useTherapists from 'hooks/useTherapists';
import useUser from 'hooks/useUser';
import { range, round } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import { CreateAppointment as CreateAppointmentModel } from 'types/appointment';
import { User } from 'types/user';
import useLabels from 'utils/apiTranslations/useLabels';
import { DURATION_OPTIONS, GET_DAYS_OF_WEEK, GET_REPEAT_FREQUENCY_OPTIONS, REMINDER_OPTIONS } from 'utils/constants';
import { getMonthDayNumber, getNumberWithOrdinal } from 'utils/dates';
import { getErrorMessage } from 'utils/stringUtils';
import DialogClose from 'views/components/buttons/DialogClose';
import Autocomplete from 'views/components/inputs/Autocomplete';
import DatePicker from 'views/components/inputs/DatePicker';
import FormContainer from 'views/components/inputs/FormContainer';
import SwitchField from 'views/components/inputs/Switch';
import TextField from 'views/components/inputs/TextField';
import TimePicker from 'views/components/inputs/TimePicker';
import Loader from 'views/components/Loader';
import * as Yup from 'yup';
import useGroups from './useGroups';

interface Props {
  initialDate: Date;
  onCreateSuccess: () => void;
  onClose: () => void;
}

type FormValues = any;

export default function CreateGroupAppointment({ initialDate, onCreateSuccess, onClose }: Props) {
  const intl = useIntl();
  const { request, userId: id } = useAuth();
  const { userId } = useParams();
  const { provider } = useProvider(id);
  const therapistId = userId ?? id;
  const { user } = useUser(therapistId);

  const createAppointmentMutation = useMutation((values: CreateAppointmentModel) =>
    request.post(`/therapists/${therapistId}/appointments`, values)
  );
  const { enqueueSnackbar } = useSnackbar();
  const { groups, groupsLoading } = useGroups();
  const { therapists, therapistsLoading } = useTherapists();

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Date is not valid' })),
    time: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Time is not valid' })),
    sessionType: Yup.string()
      .nullable()
      .required(intl.formatMessage({ defaultMessage: 'Required' })),
    serviceTypeId: Yup.string()
      .nullable()
      .required(intl.formatMessage({ defaultMessage: 'Required' })),
    duration: Yup.number()
      .required('Required')
      .typeError(intl.formatMessage({ defaultMessage: 'Required' })),
    group: Yup.string()
      .nullable()
      .required(intl.formatMessage({ defaultMessage: 'Required' })),
    reminders: Yup.array().of(Yup.number().defined()),
    message: Yup.string(),
    repeatFrequency: Yup.string(),
    repeatNumberOfTimes: Yup.number(),
    enableArchiving: Yup.bool(),
    isRecurring: Yup.bool(),
    officeId: Yup.string().when('sessionType', {
      is: 'Office',
      then: Yup.string()
        .nullable()
        .required(intl.formatMessage({ defaultMessage: 'Required' })),
      otherwise: Yup.string().nullable()
    })
  });

  const initialValues = {
    date: initialDate,
    time: null,
    sessionType: null,
    serviceTypeId: null,
    duration: null,
    group: null,
    message: '',
    members: [] as any[],
    reminders: provider?.provider?.remindersAnticipationTime?.filter((time) =>
      REMINDER_OPTIONS.find((option) => Number(option.value) === Number(time))
    ) ?? [REMINDER_OPTIONS[2].value],
    repeatFrequency: GET_REPEAT_FREQUENCY_OPTIONS()[0].value,
    repeatNumberOfTimes: 0,
    enableArchiving: false,
    isRecurring: false,
    officeId: null,
    otherTherapists: []
  };

  const onFormSubmit = useCallback(
    async ({ isRecurring, officeId, group: groupId, date, time, reminders, ...values }: FormValues) => {
      try {
        const group = groups?.find((g) => g.id === groupId);
        if (!group) {
          return;
        }

        const members = [...group.members, ...values.otherTherapists];

        const data = {
          ...values,
          repeatFrequency: isRecurring ? values.repeatFrequency : 'None',
          repeatDays: values.repeatDays?.filter((obj) => {
            if (typeof obj === 'number') return true;
            return false;
          }),
          date: parse(format(time, 'p'), 'p', date),
          members,
          emails: group.emails,
          officeId: officeId ?? '',
          repeatNumberOfTimes: isRecurring ? values.repeatNumberOfTimes || 0 : 0,
          reminders: !user?.userOptions?.enableAppointmentReminders
            ? []
            : reminders.map((reminder) => ({
                hours: reminder,
                participants: members,
                floatingParticipants: group.emails,
                sent: false,
                sentEmail: null,
                sentSMS: null
              }))
        };
        delete data.otherTherapists;

        if (!data.members.length && !data.emails?.length) {
          enqueueSnackbar(intl.formatMessage({ defaultMessage: 'An appointment must have at least one participant' }), {
            variant: 'error'
          });
          return;
        }

        if (moment(data.date).isBefore()) {
          enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment date must be later than today' }), {
            variant: 'error'
          });
          return;
        }

        await createAppointmentMutation.mutateAsync(data);
        onCreateSuccess();
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment Created!' }), { variant: 'success' });
      } catch (e: any) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }
    },
    [groups, user?.userOptions?.enableAppointmentReminders, createAppointmentMutation, onCreateSuccess, enqueueSnackbar, intl]
  );

  if (therapistsLoading || groupsLoading) {
    return <Loader />;
  }

  return (
    <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onFormSubmit}>
      <DialogTitle>
        <FormattedMessage defaultMessage="Create Your Appointment" />
        <DialogClose onClose={onClose} />
      </DialogTitle>
      <Divider />
      <CreateAppointmentForm
        onClose={onClose}
        isSubmitting={createAppointmentMutation.isLoading || !groups}
        therapists={therapists?.filter((t) => t._id !== therapistId) ?? []}
        user={user}
      />
    </FormContainer>
  );
}

function CreateAppointmentForm({
  onClose,
  isSubmitting,
  therapists,
  user
}: {
  onClose: Function;
  isSubmitting: boolean;
  therapists: User[];
  user?: User;
}) {
  const { options } = useOptions();
  const { reset } = useFormContext();
  const { groups } = useGroups();
  const intl = useIntl();

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }

    reset();
  }, [reset, onClose]);

  return (
    <>
      {isSubmitting && <Loader />}
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3}>
          <AppointmentInfoFields />

          <Autocomplete
            required
            options={groups ?? []}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            label={intl.formatMessage({ defaultMessage: 'Group' })}
            name="group"
          />

          {therapists && (
            <Autocomplete
              multiple
              options={therapists || []}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              name="otherTherapists"
              label={intl.formatMessage({ defaultMessage: 'Other Providers' })}
              disabled={therapists === undefined}
              hidden={!options?.MULTIPLE_CLINICIANS_ENCOUNTERS?.value}
            />
          )}

          <Autocomplete
            multiple
            options={REMINDER_OPTIONS}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            label={intl.formatMessage({ defaultMessage: 'Reminders' })}
            name="reminders"
            hidden={!user?.userOptions?.enableAppointmentReminders}
          />

          <SwitchField name="isRecurring" label={intl.formatMessage({ defaultMessage: 'This is a recurring appointment' })} />
          <RecurringFormFields />

          <OtherFields />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button type="button" variant="outlined" onClick={handleCancel}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            <FormattedMessage defaultMessage="Create Appointment" />
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

export function AppointmentInfoFields() {
  const { locationTypeLabels } = useLabels();
  const { userId: id } = useAuth();
  const { userId } = useParams();
  const { user } = useUser(userId ?? id);
  const { watch, setValue } = useFormContext();
  const sessionType = watch('sessionType');
  const isOfficeVisible = sessionType === 'Office';
  const intl = useIntl();

  const serviceTypeId = watch('serviceTypeId');
  const [disabled, setDisabled] = useState(false);
  const [helperText, setHelperText] = useState('');
  const clinicalHours = user?.provider?.allowInBetweenAppointmentsTime;
  const services = useMemo(() => user?.provider.services ?? [], [user]);
  const serviceType = useMemo(() => services.find((service) => service._id === serviceTypeId) ?? null, [serviceTypeId, services]);
  const timeBetween = user?.provider?.inBetweenAppointmentsTime || 0;

  useEffect(() => {
    if (!serviceType) {
      return;
    }
    if (serviceType?.durationInMinutes === 50 && clinicalHours) {
      setHelperText('This session is a standard clinical hour - (50 mins session + 10 mins for note taking related to client care)');
      setValue('duration', serviceType.durationInMinutes + 10);
      setDisabled(true);
    } else {
      setValue('duration', serviceType.durationInMinutes);
      setDisabled(false);
    }
    setValue('duration', serviceType.durationInMinutes);
  }, [serviceType, setValue, clinicalHours, timeBetween]);

  const durations = useMemo(() => {
    const preDuration = serviceType?.durationInMinutes;
    if (preDuration && !DURATION_OPTIONS.map((it) => it.value).includes(preDuration)) {
      return [...DURATION_OPTIONS, { value: preDuration, label: `${preDuration} minutes` }].sort((a, b) => a.value - b.value);
    }
    return DURATION_OPTIONS;
  }, [serviceType]);

  return (
    <>
      <DatePicker name="date" label={intl.formatMessage({ defaultMessage: 'Date' })} required />

      <TimePicker name="time" label={intl.formatMessage({ defaultMessage: 'Time' })} required />

      <Autocomplete
        required
        options={user?.provider.typeOfAppointments ?? []}
        getOptionLabel={(option) => {
          return locationTypeLabels[option] ?? option;
        }}
        isOptionEqualToValue={(option, value) => !!value && option === value}
        label={intl.formatMessage({ defaultMessage: 'Session Type' })}
        name="sessionType"
      />

      {isOfficeVisible && (
        <Autocomplete
          required
          options={user?.provider.offices ?? []}
          getOptionLabel={(option) => option.officeName}
          getOptionValue={(option) => option._id}
          label={intl.formatMessage({ defaultMessage: 'Location' })}
          name="officeId"
        />
      )}

      <Autocomplete
        required
        options={user?.provider.services ?? []}
        getOptionLabel={(option) =>
          `${intl.locale === 'fr' ? option.nameFrench || option.name : option.name || option.nameFrench} [${option.durationInMinutes} [${
            option.durationInMinutes
          } min] ($${round(Number(option.rate), 3)})`
        }
        getOptionValue={(option) => option._id}
        label={intl.formatMessage({ defaultMessage: 'Service Type' })}
        name="serviceTypeId"
      />

      <Autocomplete
        required
        options={durations}
        disabled={disabled}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        hasCreateOption
        getCreateOptionFromInput={(input: string) => {
          const num = Number(input);
          if (isNaN(num)) {
            return null;
          }
          return { value: num, label: `${num} minutes` };
        }}
        label={intl.formatMessage({ defaultMessage: 'Duration' })}
        name="duration"
      />
    </>
  );
}

const repeatForOptions = range(1, 15).map((i) => ({
  id: i,
  label: `${i}`
}));

export function RecurringFormFields() {
  const intl = useIntl();
  const { control, setValue } = useFormContext();
  const repeatFrequency = useWatch({
    control,
    name: 'repeatFrequency',
    defaultValue: GET_REPEAT_FREQUENCY_OPTIONS()[0].value
  });
  const isRecurring = useWatch({
    control,
    name: 'isRecurring',
    defaultValue: false
  });
  const selectedDate = moment(
    useWatch({
      control,
      name: 'date'
    })
  ).toDate();

  useEffect(() => {
    setValue('repeatNumberOfTimes', repeatForOptions[0].id);
  }, [setValue]);

  useEffect(() => {
    if (repeatFrequency === 'Weeks') {
      setValue('repeatDays', [GET_DAYS_OF_WEEK()[selectedDate.getDay()].id]);
    } else {
      setValue('repeatDays', []);
    }
  }, [repeatFrequency, selectedDate, setValue]);

  if (!isRecurring) return null;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          required
          style={{ flex: 1 }}
          name="repeatNumberOfTimes"
          label={intl.formatMessage({ defaultMessage: 'Repeat for' })}
          options={repeatForOptions}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          defaultValue={repeatForOptions[0]}
          filterOptions={(x) => x}
        />

        <Autocomplete
          required
          style={{ flex: 1 }}
          name="repeatFrequency"
          options={GET_REPEAT_FREQUENCY_OPTIONS()}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Stack>

      {repeatFrequency === 'Weeks' && (
        <Autocomplete
          required
          multiple
          name="repeatDays"
          options={GET_DAYS_OF_WEEK()}
          label={intl.formatMessage({ defaultMessage: 'Repeat on' })}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      )}

      {repeatFrequency === 'Months' && selectedDate && (
        <Autocomplete
          required
          name="repeatMonthDays"
          options={[
            {
              name: `${getMonthDayNumber(selectedDate)} ${format(selectedDate, 'EEEE')} of every month`,
              value: 'SameWeekDay'
            },
            { name: `${getNumberWithOrdinal(selectedDate.getDate())} of every month`, value: 'SameDate' }
          ]}
          label={intl.formatMessage({ defaultMessage: 'Repeat on' })}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      )}
    </>
  );
}

export function OtherFields() {
  const intl = useIntl();
  const { enableArchiving } = useOptions();
  const { watch } = useFormContext();
  const isOnline = watch('sessionType') === 'Online';

  return (
    <>
      {enableArchiving && isOnline && (
        <SwitchField
          name="enableArchiving"
          label={intl.formatMessage({ defaultMessage: 'Record the session for later auditing or review' })}
        />
      )}
      <TextField
        name="message"
        label={intl.formatMessage({ defaultMessage: 'Include a message for your session participant(s)' })}
        fullWidth
        multiline
        rows={5}
      />
    </>
  );
}
