import { Button, Card, Paper, Popper, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DateRange } from '@mui/x-date-pickers-pro';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import { Dayjs } from 'dayjs';
import useAuth from 'hooks/useAuth';
import useSessions, { Session } from 'hooks/useSessions';
import useTimezone from 'hooks/useTimezone';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useLabels from 'utils/apiTranslations/useLabels';
import { formatCurrency } from 'utils/stringUtils';
import BasicTable from 'views/components/BasicTable';
import MainCard from 'views/components/cards/MainCard';
import DateRangePicker from 'views/components/date-picker/DateRangePicker';
import filterSessionCanceledBy from '../session/filterSessionCanceledBy';
import RecordActions from './RecordActions';

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const GridCellExpand = memo((props: GridCellExpandProps) => {
  const { width, value } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex'
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0
        }}
      />
      <Box ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

function renderCellExpand(params: GridRenderCellParams<string>) {
  return <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />;
}

interface GridCellExpandProps {
  value: string;
  width: number;
}

const Records = () => {
  const intl = useIntl();
  const { timezone } = useTimezone();
  const { userId } = useAuth();
  const [DateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([null, null]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { sessions, refetchSessions, totalRows, balanceOwing, totalPaid, currency } = useSessions(
    page + 1,
    rowsPerPage,
    undefined,
    userId,
    undefined,
    DateRangeValue
  );

  const { paymentStatusLabels, statusLabels } = useLabels();

  const rowsPerPageOptions = [10, 25, 50];

  const handleChangeRowsPerPage = useCallback((size: number) => {
    setRowsPerPage(+size);
    setPage(0);
  }, []);

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: intl.formatMessage({ defaultMessage: 'Session Date' }),
        minWidth: 250,
        flex: 1,
        valueGetter: ({ row }) => {
          return format(utcToZonedTime(row.date, timezone), intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a', {
            locale: intl.locale === 'fr' ? frLocale : enLocale
          });
        }
      },
      {
        field: 'status',
        headerName: intl.formatMessage({ defaultMessage: 'Status' }),
        minWidth: 150,
        flex: 1,
        renderCell: renderCellExpand,
        valueGetter: (params) => {
          const row = params.row as Session;
          return (
            <Typography color={row?.isComplimentary === true ? 'red' : ''}>
              {statusLabels[filterSessionCanceledBy(row.status)] ?? filterSessionCanceledBy(row.status)}
            </Typography>
          );
        }
      },
      {
        field: 'paymentDate',
        headerName: intl.formatMessage({ defaultMessage: 'Payment Date' }),
        minWidth: 210,
        flex: 1,
        valueGetter: ({ row }) => {
          return row?.payment
            ? format(utcToZonedTime(row?.payment?.date, timezone), intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a', {
                locale: intl.locale === 'fr' ? frLocale : enLocale
              })
            : '-';
        }
      },
      {
        field: 'refNumber',
        headerName: intl.formatMessage({ defaultMessage: 'Ref Number' }),
        minWidth: 210,
        flex: 1,

        valueGetter: ({ row }) => {
          return row?.payment ? String(row?.payment?.refNumber).padStart(5, '0') : '-';
        }
      },
      {
        field: 'paymentStatus',
        headerName: intl.formatMessage({ defaultMessage: 'Payment Status' }),
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          const row = params.row as Session;
          if (row.paymentStatus === 'Charge Failed' && row.paymentError)
            return (
              <Tooltip title={row.paymentError}>
                <Typography color="error">
                  <FormattedMessage defaultMessage="Failed" />
                </Typography>
              </Tooltip>
            );
          return (
            <Typography color={row?.isComplimentary === true ? 'red' : ''}>
              {row?.isComplimentary === true
                ? 'NA'
                : row.paymentStatus === null
                ? intl.formatMessage({ defaultMessage: 'Charge Failed' })
                : paymentStatusLabels[row.paymentStatus] ?? row.paymentStatus}
            </Typography>
          );
        },
        cellClassName: (params) => {
          const row = params.row as Session;
          return `${
            row.paymentStatus === null || row.paymentStatus === 'Charge Failed'
              ? intl.formatMessage({ defaultMessage: 'Charge Failed' })
              : row.paymentStatus
          }`;
        }
      },
      {
        field: 'total',
        headerName: intl.formatMessage({ defaultMessage: 'Total' }),
        minWidth: 100,
        flex: 1,
        renderCell: renderCellExpand,
        valueGetter: (params) => {
          const row = params.row as Session;
          return (
            <Typography color={row?.isComplimentary === true ? 'red' : ''}>
              {row?.isComplimentary === true
                ? intl.formatMessage({ defaultMessage: 'No Charge' })
                : formatCurrency(row.total, row.currency || currency)}
            </Typography>
          );
        }
      },
      {
        field: 'isPaid',
        headerName: intl.formatMessage({ defaultMessage: 'Charged' }),
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => {
          const row = params.row as Session;
          return row?.isPaid && !!row?.payment ? formatCurrency(row.payment.chargedAmount, row.payment.currency) : '-';
        }
      },

      {
        field: 'actions',
        headerName: intl.formatMessage({ defaultMessage: 'Actions' }),
        flex: 1,
        minWidth: 400,
        sortable: false,
        renderCell: (params) => {
          const row = params.row as Session;
          return (
            <Box sx={{ minHeight: 52, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
              <RecordActions session={row} refetchSessions={refetchSessions} />
            </Box>
          );
        }
      }
    ],
    [currency, intl, refetchSessions, timezone, paymentStatusLabels, statusLabels]
  );
  return (
    <MainCard sx={{ height: '100%' }} title={intl.formatMessage({ defaultMessage: 'Records' })}>
      <Stack direction={'row'} m={2} justifyContent={'space-between'}>
        <Card variant="outlined" sx={{ width: 'fit-content', py: 1, px: 2, alignSelf: 'flex-end' }}>
          {Number.isFinite(totalPaid) && (
            <Stack direction="row" spacing="4px">
              <Typography variant="h5">
                <FormattedMessage defaultMessage="Total Paid:" />
              </Typography>

              <Typography variant="h5" color={'primary'}>
                {formatCurrency(totalPaid, currency)}
              </Typography>
            </Stack>
          )}

          {Number.isFinite(balanceOwing) && (
            <Stack direction="row" spacing="4px">
              <Typography variant="h5">
                <FormattedMessage defaultMessage="Outstanding Balance:" />
              </Typography>

              <Typography variant="h5" color={balanceOwing > 0 ? 'error' : 'success'}>
                {formatCurrency(balanceOwing, currency)}
              </Typography>
            </Stack>
          )}
        </Card>
        <Stack direction="row" spacing={2} alignItems="center">
          <DateRangePicker value={DateRangeValue} setValue={setDateRangeValue} />
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              setDateRangeValue([null, null]);
            }}
          >
            <FormattedMessage defaultMessage={'Clear Filter'} />
          </Button>
        </Stack>
      </Stack>

      <BasicTable
        hideSearch={true}
        emptyMessage={<FormattedMessage defaultMessage="No records found." />}
        columns={columns}
        page={page}
        pageSize={rowsPerPage}
        rows={sessions?.filter((session) => session?.client?.id === userId)}
        rowCount={totalRows || 0}
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={rowsPerPageOptions}
        paginationMode={sessions?.length > 10 ? 'client' : 'server'}
        onPageChange={handleChangePage}
        onPageSizeChange={(size) => handleChangeRowsPerPage(size)}
        sortingMode="server"
      />
    </MainCard>
  );
};

export default Records;
