import { getWeekOfMonth, getWeeksInMonth } from 'date-fns';
import moment, { Moment } from 'moment-timezone';

export function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function getWeekNums(momentObj = moment()) {
  const clonedMoment = moment(momentObj);

  // get week number for first day of month
  const first = clonedMoment.startOf('month').week();
  // get week number for last day of month
  let last = clonedMoment.endOf('month').week();

  // In case last week is in next year
  if (first > last) {
    last = first + last;
  }
  return last - first + 1;
}

export function getDaysInAMonth(start, day) {
  const days: Moment[] = [];
  const theDay = moment(start).clone().startOf('month').day(day);
  if (theDay.date() > 7) {
    theDay.add(7, 'd');
  }
  const month = theDay.month();
  while (month === theDay.month()) {
    days.push(theDay.clone());
    theDay.add(7, 'd');
  }
  return days;
}

export function dateFormatter(timezone, date) {
  return moment(date).tz(timezone).format('dddd, MMMM Do YYYY, h:mm a');
}

export function TimeFormat24hours(time) {
  const timeArr = time.split(' ');
  const hourArr = timeArr[0].split(':');
  if (timeArr[1] === 'PM') return `${Number(hourArr[0]) + 12}:${hourArr[1]}`;
  return `${hourArr[0]}:${hourArr[1]}`;
}

// First, Last or same week
export function getMonthDayNumber(selectedDate: Date) {
  const startDateDay = moment(selectedDate).startOf('month').weekday();
  const lastDateDay = moment(selectedDate).endOf('month').weekday();
  const currentDateDay = moment(selectedDate).weekday();
  let dayNumber = getWeekOfMonth(selectedDate);
  if (startDateDay > currentDateDay) {
    dayNumber -= 1;
  }
  const weeksInMonth = getWeeksInMonth(selectedDate);
  const currentWeek = getWeekOfMonth(selectedDate);
  if (currentWeek === 1 || (currentWeek === 2 && startDateDay > currentDateDay)) {
    return 'First';
  }
  if (currentWeek === weeksInMonth || (currentWeek === weeksInMonth - 1 && lastDateDay < currentDateDay)) {
    return 'Last';
  }
  return getNumberWithOrdinal(dayNumber);
}
