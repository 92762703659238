/**
 *
 * Participant's Screen Name
 */
import { CameraAlt, ErrorOutline, HelpOutline, MicNone, Wifi } from '@mui/icons-material';
import { Box, Button, FormLabel, Grid, MenuItem, Modal, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CameraOffIcon from 'assets/icons/sessions/camera-off.svg';
import CameraIcon from 'assets/icons/sessions/camera.svg';
import MicOffIcon from 'assets/icons/sessions/mic-off.svg';
import MicIcon from 'assets/icons/sessions/mic.svg';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import NetworkTest from 'opentok-network-test-js';
import { RefObject, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParam } from 'react-use';
import { DefaultRootStateProps } from 'types';
import CameraPreview from './CameraPreview';
import ConnectionTest from './ConnectionTest';

declare const OT: any;

interface Props {
  initialized: boolean;
  containerRef: RefObject<HTMLDivElement> | null;
  publisherRef: any;
  sessionRef: any;
  sessionStarted: boolean;
  setSessionStarted: Function;
  screenName: string;
  setScreenName: Function;
  publishVideo: boolean;
  publishAudio: boolean;
  setPublishVideo: Function;
  setPublishAudio: Function;
  floatingEmail: string | null | undefined;
  isCameraAvailable: Function;
  arrivedRef: any;
  selectedCamera: any;
  setCameraGranted: any;
  serviceTypeName?: string;
  serviceTypeNameFrench?: string;
  durationInMins?: number;
}

const ScreenName = ({
  initialized,
  containerRef,
  publisherRef,
  sessionRef,
  sessionStarted,
  setSessionStarted,
  screenName,
  setScreenName,
  publishVideo,
  publishAudio,
  setPublishVideo,
  setPublishAudio,
  floatingEmail,
  selectedCamera,
  isCameraAvailable,
  setCameraGranted,
  arrivedRef,
  serviceTypeName,
  serviceTypeNameFrench,
  durationInMins
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [showConnectionTest, setShowConnectionTest] = useState(false);
  const [isMicAvailable, setIsMicAvailable] = useState(false);
  const [volumeAverage, setVolumeAverage] = useState(0);

  const { userId, request, guestRequest } = useAuth();
  const { user: loggedUser } = useUser(userId);
  const { sessionId } = useParams();
  const challenge = useSearchParam('challenge');
  const [connectivityPassed, setConnectivityPassed] = useState<any>(false);
  const [results, setResults] = useState<any>();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(localStorage.getItem('language') || customization.locale);

  const { data: session } = useQuery(`/connection-test/`, () => guestRequest.post(`/connection-test/`).then((response) => response.data));

  const updateLoggedUserScreenName = useMutation((screenName: string) =>
    request.put(`/session/${sessionId}/participant/${userId}/`, { screenName })
  );

  const updateFloatingUserScreenName = useMutation((screenName: string) =>
    request.put(`/session/${sessionId}/floating/${challenge}/`, { screenName })
  );

  const startPreviewSession = (session) => {
    setConnectivityPassed(false);
    setResults(undefined);
    const apiKey = process.env.REACT_APP_TOKBOX_API_KEY;
    if (!apiKey) {
      return;
    }

    const otNetworkTest = new NetworkTest(OT, {
      apiKey,
      sessionId: session.sessionId,
      token: session.token
    });
    otNetworkTest
      .testConnectivity()
      .then((results) => {
        setConnectivityPassed(results.success);
      })
      .then((stats) => {
        otNetworkTest.testQuality().then((results) => setResults({ audio: results?.audio?.supported, video: results?.video?.supported }));
      });
  };

  useEffect(() => {
    if (session) {
      startPreviewSession(session);
    }
  }, [session]);

  useEffect(() => {
    // Get previous settings from localStorage
    setScreenName(sessionStorage.getItem('screenName') ?? '');
    setPublishVideo(localStorage.getItem('publishVideo') === 'true' || !localStorage.getItem('publishVideo'));
    setPublishAudio(localStorage.getItem('publishAudio') === 'true' || !localStorage.getItem('publishAudio'));

    (async () => {
      // Measuring audio volume
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      const audioContext = new AudioContext();
      const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
      const analyserNode = audioContext.createAnalyser();
      mediaStreamAudioSourceNode.connect(analyserNode);

      const pcmData = new Float32Array(analyserNode.fftSize);
      const onFrame = () => {
        analyserNode.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        for (const amplitude of pcmData as any) {
          sumSquares += amplitude * amplitude;
        }

        setVolumeAverage(sumSquares * 10);
        if (sumSquares > 0.3) {
          setIsMicAvailable(true);
        }
        window.requestAnimationFrame(onFrame);
      };
      window.requestAnimationFrame(onFrame);
    })();
  }, [sessionId, setPublishAudio, setPublishVideo, setScreenName, setSessionStarted]);

  // useEffect(() => {
  //   // Mute audio before publishing
  //   const mutedStream = new OT.Stream(sessionRef.current, '', { audioFallbackEnabled: false, video: false, audio: false });
  //   sessionRef.current.publish(mutedStream);
  // }, [sessionRef]);

  const handleError = (error) => {
    if (error) {
      console.error('...handleError', error);
    }
  };

  const joinSession = () => {
    if (!sessionRef.current) return;

    let finalScreenName = screenName;
    if (!finalScreenName) {
      if (loggedUser?.firstName) {
        finalScreenName = `${loggedUser?.firstName} ${loggedUser?.lastName}`;
      }
      if (floatingEmail) {
        finalScreenName = floatingEmail?.match(/^([^@]*)@/)![1] ?? '';
      }
      setScreenName(finalScreenName);
    }

    if (floatingEmail) {
      // Floating session
      updateFloatingUserScreenName.mutate(finalScreenName);
    } else {
      // Normal session
      updateLoggedUserScreenName.mutate(finalScreenName);
    }

    localStorage.setItem('sessionId', sessionId ?? '');
    sessionStorage.setItem('screenName', finalScreenName);

    // Publish
    sessionRef.current.publish(publisherRef.current, handleError);

    if (arrivedRef.current) {
      publisherRef.current.publishVideo(publishVideo);
      publisherRef.current.publishAudio(publishAudio);
    }

    setSessionStarted(true);
  };
  const [systemMicrophoneList, setSystemMicrophoneList] = useState<any>([]);
  const [systemVideoDevicesList, setSystemVideoDevicesList] = useState<any>([]);
  const [selectedMicrophoneId, setSelectedMicrophoneId] = useState<any>('');
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<any>('');

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            switch (device.kind) {
              case 'audioinput': {
                if (!systemMicrophoneList.length) {
                  setSelectedMicrophoneId(device.deviceId);
                } else {
                  setSelectedMicrophoneId('');
                }
                setSystemMicrophoneList((prev) => [...prev, device]);
                break;
              }

              case 'videoinput': {
                if (!systemVideoDevicesList.length) {
                  setSelectedVideoDeviceId(device.deviceId);
                } else {
                  setSelectedVideoDeviceId('');
                }
                setSystemVideoDevicesList((prev) => [...prev, device]);
                break;
              }
            }
          });
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      container={containerRef?.current}
      open={!sessionStarted}
      onClose={() => {
        setSessionStarted(true);
      }}
      sx={{
        overflowY: 'auto'
      }}
    >
      <Box
        className={classes.root}
        sx={{
          alignItems: {
            xs: 'flex-start',
            md: 'center'
          }
        }}
      >
        <div className="container">
          <Box display="flex" alignItems="center" gap={1} mb={1}>
            <Typography variant="h3">{intl.formatMessage({ defaultMessage: 'Join Session' })}</Typography>
            {(serviceTypeName || serviceTypeNameFrench) && durationInMins && (
              <Typography sx={{ color: '#8F8F8F', fontSize: '18px' }}>
                ({selectedLanguage === 'fr' ? serviceTypeNameFrench || serviceTypeName : serviceTypeName}
                {', '}
                {durationInMins} {intl.formatMessage({ defaultMessage: 'minutes' })})
              </Typography>
            )}
          </Box>

          <div className="box-border" style={{ gap: 1 }}>
            <Stack gap={1}>
              <Box
                sx={{
                  height: {
                    xs: 200,
                    sm: 230,
                    md: 250,
                    lg: '35vh'
                  },
                  width: {
                    xs: '80vw',
                    sm: 500,
                    md: 600,
                    lg: '35vw'
                  }
                }}
              >
                <CameraPreview
                  source="screenName"
                  selectedCamera={selectedCamera}
                  setCameraGranted={setCameraGranted}
                  defaultImage={loggedUser?.imageSmall}
                  publishVideo={publishVideo}
                />
              </Box>

              <Box display="flex" alignItems="center" justifyContent="space-between" my={1}>
                <Box display="flex" gap={1} alignItems="center">
                  {publishAudio ? (
                    <img
                      src={MicIcon}
                      alt="mic-on"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPublishAudio(false);
                        localStorage.setItem('publishAudio', 'false');
                      }}
                    />
                  ) : (
                    <img
                      src={MicOffIcon}
                      alt="mic-off"
                      className={classes.muted}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPublishAudio(true);
                        localStorage.setItem('publishAudio', 'true');
                      }}
                    />
                  )}
                  {publishVideo ? (
                    <img
                      src={CameraIcon}
                      alt="camera-on"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPublishVideo(false);
                        localStorage.setItem('publishVideo', 'false');
                      }}
                    />
                  ) : (
                    <img
                      src={CameraOffIcon}
                      alt="camera-off"
                      className={classes.muted}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPublishVideo(true);
                        localStorage.setItem('publishVideo', 'true');
                      }}
                    />
                  )}
                </Box>
                <Stack>
                  <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                    {!showConnectionTest ? (
                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
                        <Wifi sx={{ color: '#0076A1' }} />

                        <Button
                          type="button"
                          onClick={() => {
                            setShowConnectionTest(true);
                          }}
                          disabled={!initialized || !results}
                        >
                          <FormattedMessage defaultMessage={'Test Connection'} />
                        </Button>
                        <Tooltip
                          title={
                            <Stack alignItems="flex-start" justifyContent="flex-start">
                              <Typography>{intl.formatMessage({ defaultMessage: 'For Enhanced Stability' })}</Typography>
                              <ul style={{ gap: 1 }}>
                                <li>{intl.formatMessage({ defaultMessage: 'Check your internet connection.' })}</li>
                                <li>{intl.formatMessage({ defaultMessage: 'Close ununsed apps and programs.' })}</li>
                                <li>{intl.formatMessage({ defaultMessage: 'Disconnect inactive devies from Wi-Fi.' })}</li>
                                <li>{intl.formatMessage({ defaultMessage: "Clear your browser's temporary files (called cache)." })}</li>
                                <li>{intl.formatMessage({ defaultMessage: 'If nothing improves, restart your computer.' })}</li>
                              </ul>
                            </Stack>
                          }
                        >
                          <HelpOutline />
                        </Tooltip>
                      </div>
                    ) : (
                      <ConnectionTest />
                    )}
                  </Box>
                  {results && !isMicAvailable && (
                    <Stack spacing={1} direction={'row'} alignItems={'end'}>
                      <Typography sx={{ inlineSize: '35rem', color: 'red', width: 'fit-content' }}>
                        <FormattedMessage defaultMessage={'Your microphone is not connected.'} />
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Stack>
            <div className="Input" style={{ marginBottom: '10px', marginTop: '10px' }}>
              <FormLabel sx={{ fontSize: 16, color: '#565656' }}>{intl.formatMessage({ defaultMessage: 'Your name' })}</FormLabel>
              <TextField
                InputLabelProps={{ shrink: false }}
                placeholder={intl.formatMessage({ defaultMessage: 'Enter your name' })}
                onChange={(e) => setScreenName(e.target.value)}
                value={screenName}
                fullWidth
              />
            </div>

            <Grid container gap={1} my="20px">
              <Grid item xs={5.9} display="flex" flexDirection="column">
                <Grid item xs={12}>
                  <FormLabel>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CameraAlt />
                      <Typography>{intl.formatMessage({ defaultMessage: 'Camera' })}</Typography>
                    </Box>
                  </FormLabel>

                  <Select
                    fullWidth
                    MenuProps={{ className: classes.menu }}
                    classes={{ select: classes.selectedItem }}
                    id="demo-simple-select"
                    value={selectedVideoDeviceId}
                    onChange={(e) => {
                      setSelectedVideoDeviceId(e.target.value);
                      navigator.mediaDevices
                        .getUserMedia({ video: { deviceId: e?.target?.value }, audio: selectedMicrophoneId })
                        .then((stream) => {
                          console.log('Input devices changed successfully:', stream);
                        })
                        .catch((err) => console.error('Error applying device:', err));
                    }}
                    displayEmpty
                    placeholder={intl.formatMessage({ defaultMessage: 'Select Camera' })}
                  >
                    <MenuItem value="" key="-1">
                      {intl.formatMessage({ defaultMessage: 'Select Camera' })}
                    </MenuItem>
                    {systemVideoDevicesList?.map((videoDevice) => {
                      return (
                        <MenuItem value={videoDevice.deviceId} key={videoDevice.deviceId} className={classes.listItem}>
                          {videoDevice.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={5.9} display="flex" flexDirection="column">
                <Grid item xs={12}>
                  <FormLabel>
                    {' '}
                    <Box display="flex" alignItems="center" gap={1}>
                      <MicNone />
                      <Typography>{intl.formatMessage({ defaultMessage: 'Microphone' })}</Typography>
                    </Box>
                  </FormLabel>
                  <Select
                    MenuProps={{ className: classes.menu }}
                    classes={{ select: classes.selectedItem }}
                    fullWidth
                    id="demo-simple-select"
                    value={selectedMicrophoneId}
                    onChange={(e) => {
                      setSelectedMicrophoneId(e.target.value);
                      navigator.mediaDevices
                        .getUserMedia({ audio: { deviceId: e?.target?.value }, video: selectedVideoDeviceId })
                        .then((stream) => {
                          console.log('Input devices changed successfully:', stream);
                        })
                        .catch((err) => console.error('Error applying device:', err));
                    }}
                    displayEmpty
                    placeholder={intl.formatMessage({ defaultMessage: 'Select Microphone' })}
                  >
                    <MenuItem value="" key="-1">
                      {intl.formatMessage({ defaultMessage: 'Select Microphone' })}
                    </MenuItem>
                    {systemMicrophoneList?.map((microphone) => {
                      return (
                        <MenuItem value={microphone.deviceId} key={microphone.deviceId} className={classes.listItem}>
                          {microphone.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid container gap={1} alignItems="center" justifyContent="space-between" width={'100%'} direction="row" my="10px">
              <Grid
                item
                xs={7.5}
                sx={{ background: '#FEF0C7', color: '#B54708' }}
                display="flex"
                alignItems="center"
                border="1px solid"
                borderRadius="8px"
                px="2px"
                py="10px"
                gap={1}
                className={classes.warningContainer}
              >
                <ErrorOutline sx={{ ml: 1 }} />
                <Typography>
                  {intl.formatMessage({
                    defaultMessage: 'To confirm that your microphone is correctly enabled, please speak or create a sound'
                  })}
                </Typography>
              </Grid>
              <Button variant="contained" type="submit" onClick={joinSession} disabled={!initialized || !isMicAvailable}>
                <FormattedMessage defaultMessage={' Join Session'} />
              </Button>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ScreenName;

const pulse = { '@keyframes pulse': { to: { boxShadow: '0 0 0 20px rgba(90, 0, 141, 0)' } } };

const flicker = {
  '@keyframes flicker': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 1
    }
  }
};

const useStyles = makeStyles({
  ...pulse,
  ...flicker,
  menu: {
    maxWidth: 200,
    textOverflow: 'ellipsis'
  },
  listItem: {
    overflowX: 'auto',
    textOverflow: 'ellipsis'
  },
  selectedItem: {
    maxWidth: 350,
    textOverflow: 'ellipsis'
  },
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    '& .container': {
      border: '1px solid #ddd',
      backgroundColor: '#fff',
      padding: '24px 30px 30px 30px',
      minWidth: '35%',
      borderRadius: 4
    }
  },
  muted: {
    animation: '$pulse 2s infinite cubic-bezier(0.66, 0, 0, 1)',
    ':hover': {
      animation: 'none'
    }
  },
  warningContainer: {
    animation: '$flicker 1s infinite',
    ':hover': {
      animation: 'none'
    }
  }
});
