import { useEffect, useState } from 'react';
import ZendeskService from './ZendeskService';

const ZendeskSupportButton = () => {
  const [zendeskService, setZendeskService] = useState<ZendeskService>();

  useEffect(() => {
    setZendeskService(new ZendeskService());
  }, []);

  const buttonStyle = {
    position: 'fixed' as 'fixed',
    bottom: '10px',
    right: '10px',
    borderRadius: '999em 0 0 999em',
    fontWeight: 'bold' as 'bold',
    display: 'inline-block',
    padding: '.5em 1em',
    fontSize: 'medium',
    letterSpacing: '0.04em',
    outline: 'none'
  };

  const iconSpanStyle = {
    verticalAlign: 'middle',
    display: 'inline-block',
    paddingRight: '.5em',
    font: 'icon'
  };

  if ((window as any).global?.isInSession || !zendeskService) {
    return null;
  }

  return (
    <button
      id="zendesk-support-button"
      className="btn btn-primary"
      type="button"
      style={buttonStyle}
      onClick={() => {
        zendeskService.activate({}).then((_) => {});
      }}
    >
      <span style={iconSpanStyle}>
        <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" style={{ verticalAlign: 'middle' }}>
          <g id="Layer_4">
            <path
              fill="white"
              d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"
            />
            <circle fill="white" cx="10" cy="15" r="1" />
            <path
              fill="white"
              d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"
            />
          </g>
        </svg>
      </span>
      <span style={{ verticalAlign: 'middle' }}>Support</span>
    </button>
  );
};

export default ZendeskSupportButton;
