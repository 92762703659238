/* eslint-disable react-hooks/exhaustive-deps */
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { IconFilterCancel } from '@tabler/icons-react';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import useAdminDashboardStatus from 'hooks/useAdminDashboardStatus';
import useAuth from 'hooks/useAuth';
import usePermissions from 'hooks/usePermissions';
import useProfileOptions from 'hooks/useProfileOptions';
import useUser from 'hooks/useUser';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { store } from 'store';
import { gridSpacing } from 'store/constant';
import { User } from 'types/user';
import { isProviderAdminD } from 'utils/roleMatches';
import ReusableDashboardCard from 'views/dashboard/DashboardCards';
import ChartCard from './ChartCard';
import { ComplimentaryTable } from './ComplimentryTable';
import FilterDashboard from './FilterDashboard';
import { default as DashboardNotesTable, default as NotesTable } from './NotesTable';
import { ProviderListTable } from './ProviderListTable';
import { RecentClient } from './RecentClient';

const useStyles = makeStyles((theme) => ({
  blackText: {
    color: 'black'
  },
  card: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #D0D5DD'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  icon: {
    fontSize: 16,
    marginRight: '1'
  },
  positive: {
    color: '#039855'
  },
  negative: {
    color: '#D92D20'
  }
}));

const AdminDashboard = () => {
  const classes = useStyles();

  const [filter, setFilter] = React.useState<any>({ date: 'This Month' });
  const [isTabletScreen, setIsTabletScreen] = useState(false);
  const [sessionStatusFilter, setSessionStatusFilter] = React.useState<any>(null);

  const intl = useIntl();
  const [range, setRange] = React.useState<[string | Date | null, string | Date | null]>([null, null]);

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const navigate = useNavigate();
  const [permissionsChecked, setPermissionsChecked] = React.useState(false);
  const spacingMD = matchDownMD ? 1 : 1.5;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({ date: 'This Month' });
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [clearFilter, setClearFilter] = React.useState<boolean>(false);
  const lang = store.getState()?.customization?.locale ?? 'fr';
  const [selectedProviders, setSelectedProviders] = React.useState<any[]>([]);
  const { profileOptionsMap } = useProfileOptions();
  const { permissions } = usePermissions();
  const [selectedSessionTypes, setSelectedSessionTypes] = React.useState<any[]>([]);

  const { sessionStatus, percentageDifference, refetchStatus, isLoadingStatus } = useAdminDashboardStatus(
    { selectedDate: filter, selectedSessionTypes, selectedProviders },
    startDate,
    endDate
  );
  const { userId } = useAuth();
  const { user } = useUser(userId);

  const currentUser = permissions && permissions.find((user) => user?._id === userId)?.accessSettings;

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    const permission = permissions?.some(
      (permissionsUser) => user?._id === permissionsUser?._id && permissionsUser?.accessSettings?.DASHBOARD
    );

    if (permission === false || profileOptionsMap?.providerPractice?.keys?.DASHBOARD?.value === false) {
      navigate('/appointments');
    } else {
      refetchStatus();
    }

    setPermissionsChecked(true);
  }, [filter, startDate, endDate, refetchStatus, permissions, profileOptionsMap]);

  useEffect(() => {
    const handleResize = (event) => {
      if (event.target.innerWidth < 1466) {
        setIsTabletScreen(true);
      } else {
        setIsTabletScreen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  useEffect(() => {
    if (selectedProviders?.length > 0 || selectedSessionTypes?.length > 0 || filter.date !== 'This Month') setClearFilter(true);
    else setClearFilter(false);
  }, [selectedProviders, selectedSessionTypes, filter]);

  const handleChange = (event) => {
    setSelected({ date: event.target.value });
    if (event.target.value !== 'Custom Date') {
      setFilter({ date: event.target.value });
    }
    setClearFilter(true);
  };

  const handleOpenClearFilter = () => {
    setClearFilter(true);
  };

  const getFilterChanges = ({ selectedProviders, selectedSessionTypes, selectedDate }) => {
    setSelectedSessionTypes(selectedSessionTypes);
    setSelectedProviders(selectedProviders);
    setFilter(selectedDate);
  };

  const handlerClearFilter = () => {
    setSelected({ date: 'This Month' });
    setFilter({ date: 'This Month' });
    setRange([null, null]);
    setSelectedProviders([]);
    setSelectedSessionTypes([]);
    setClearFilter(false);
  };

  const isValid = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const handleRangeChange = (value) => {
    setRange(value);
    if (value[0] !== null && value[1] !== null) {
      const isValidStart = isValid(value[0].$d);
      const isValidEnd = isValid(value[1].$d);

      if (isValidStart && isValidEnd) {
        setStartDate(moment(value[0].$d.toISOString()).format('YYYY/MM/DD'));
        setEndDate(moment(value[1].$d.toISOString()).format('YYYY/MM/DD'));
        setFilter(selected);
      }
    }
    return value;
  };

  const Loader = () => (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', marginTop: 1.5, marginBottom: 1.5 }}>
      <LinearProgress sx={{ width: '140px' }} />
    </Box>
  );

  if (!permissionsChecked) {
    return null;
  } else {
    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid item>
            <Stack
              paddingLeft={1.5}
              paddingRight={3.2}
              marginBottom={3}
              direction="row"
              justifyContent="space-between"
              alignItems={'center'}
              spacing={matchDownSM ? 0.5 : spacingMD}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
                {intl.formatMessage({ defaultMessage: 'Admin Dashboard' })}
              </Typography>

              <Box display="flex" sx={{ alignSelf: 'flex-end' }}>
                <FormControl sx={{ width: '8rem', borderRadius: '12px', height: '2.5rem', marginRight: '20px' }}>
                  <InputLabel id="demo-simple-select-label">{intl.formatMessage({ defaultMessage: 'Month' })}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected?.date}
                    label="Date"
                    onChange={handleChange}
                  >
                    <MenuItem value="This Month">{intl.formatMessage({ defaultMessage: 'This Month' })}</MenuItem>
                    <MenuItem value="Last 30 Days">{intl.formatMessage({ defaultMessage: 'Last 30 Days' })}</MenuItem>
                    <MenuItem value="Last Month">{intl.formatMessage({ defaultMessage: 'Last Month' })}</MenuItem>
                    <MenuItem value="This Year">{intl.formatMessage({ defaultMessage: 'This Year' })}</MenuItem>
                    <MenuItem value="Custom Date">{intl.formatMessage({ defaultMessage: 'Custom Date' })}</MenuItem>
                  </Select>
                </FormControl>

                {selected?.date === 'Custom Date' && (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang === 'fr' ? 'fr' : 'en'}>
                    <DateRangePicker
                      calendars={1}
                      onChange={handleRangeChange}
                      value={range}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField
                            autoComplete="off"
                            name="custom_start"
                            size="medium"
                            {...startProps}
                            label={intl.formatMessage({ defaultMessage: 'Start' })}
                          />
                          <Box sx={{ mx: 1 }}>-</Box>
                          <TextField
                            sx={{ marginRight: '20px' }}
                            autoComplete="off"
                            name="custom_end"
                            size="medium"
                            {...endProps}
                            label={intl.formatMessage({ defaultMessage: 'Start' })}
                          />
                        </>
                      )}
                    />
                  </LocalizationProvider>
                )}
                <Badge
                  badgeContent={selectedProviders?.length + selectedSessionTypes?.length}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <Button
                    disableRipple
                    variant="outlined"
                    onClick={handleClickOpenDialog}
                    color="secondary"
                    className={classes.blackText}
                    sx={{ p: 1.3, borderColor: '#D0D5DD', color: 'black' }}
                    startIcon={<FilterAltIcon sx={{ fontWeight: 500, color: 'black' }} />}
                  >
                    {intl.formatMessage({ defaultMessage: 'Filter' })}
                  </Button>
                </Badge>
                {clearFilter && (
                  <Tooltip title={intl.formatMessage({ defaultMessage: 'Cancel Filters' })} placement="top">
                    <Button sx={{ p: 1.3, ml: '20px', borderColor: '#C0C0C0' }} variant="outlined" onClick={handlerClearFilter}>
                      <IconFilterCancel />
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Stack>
          </Grid>
          <FilterDashboard
            open={open}
            handleCloseDialog={handleCloseDialog}
            isLoading={isLoadingStatus}
            range={range}
            onSuccess={getFilterChanges}
            selectedDate={selected}
            startDate={startDate}
            endDate={endDate}
            handleOpenClearFilter={handleOpenClearFilter}
            clearFilters={clearFilter}
          />
        </Grid>
        <Grid container spacing={1} justifyContent="space-around" sx={{ marginLeft: '20px' }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ReusableDashboardCard
              title={intl.formatMessage({ defaultMessage: 'Sessions Completed' })}
              percentageChangeFromLastMonth={percentageDifference?.attended}
              value={sessionStatus ? sessionStatus?.attended : <Loader />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ReusableDashboardCard
              title={intl.formatMessage({ defaultMessage: 'Cancelled' })}
              percentageChangeFromLastMonth={percentageDifference?.cancelled}
              value={sessionStatus ? sessionStatus?.cancelled : <Loader />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ReusableDashboardCard
              title={intl.formatMessage({ defaultMessage: 'No-Show' })}
              percentageChangeFromLastMonth={percentageDifference?.noshow}
              value={sessionStatus ? sessionStatus?.noshow : <Loader />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ReusableDashboardCard
              title={intl.formatMessage({ defaultMessage: 'Late-Cancelled' })}
              percentageChangeFromLastMonth={percentageDifference?.lateCancelled}
              value={sessionStatus ? sessionStatus?.lateCancelled : <Loader />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing} direction={isTabletScreen ? 'column' : 'row'}>
            <Grid item xs={5} md={5}>
              <ChartCard
                isSessionStatus={true}
                labels={
                  [
                    intl.formatMessage({ defaultMessage: 'Accepted' }),
                    intl.formatMessage({ defaultMessage: 'Attended' }),
                    intl.formatMessage({ defaultMessage: 'Cancelled' }),
                    intl.formatMessage({ defaultMessage: 'No-show' }),
                    intl.formatMessage({ defaultMessage: 'Pending' })
                  ] || []
                }
                data={[
                  sessionStatus?.accepted,
                  sessionStatus?.attended,
                  sessionStatus?.cancelled,
                  sessionStatus?.noshow,
                  sessionStatus?.pending
                ]}
                setFilter={setSessionStatusFilter}
                isLoading={isLoadingStatus}
                title={intl.formatMessage({ defaultMessage: 'Session Status' })}
                textCenter={intl.formatMessage({ defaultMessage: 'Total Session' })}
                type={'Session'}
                url="/clinic-appointments"
              />
            </Grid>
            {currentUser?.COMPLIMENTARY_SESSION ? (
              <Grid item xs={6} md={6}>
                <ComplimentaryTable />
              </Grid>
            ) : (
              <Grid item xs={6} md={6}>
                <RecentClient />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!currentUser?.COMPLIMENTARY_SESSION ? (
          <Grid item xs={12}>
            <Grid item xs={10.25} md={10.25}>
              <DashboardNotesTable />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing} sx={{ gap: '8%' }}>
              <Grid item xs={10.3} md={5.1}>
                <RecentClient />
              </Grid>
              <Grid item xs={10.3} md={5.1}>
                <NotesTable />
              </Grid>
            </Grid>
          </Grid>
        )}
        {isProviderAdminD(user as User) && (
          <Grid item xs={12}>
            <Grid item xs={10.25} md={10.25}>
              <ProviderListTable />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
};

export default AdminDashboard;
