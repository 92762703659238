import ms from 'ms';
import { store } from 'store';
const protectedTemplateString = (f) => (s) => {
  if (s === undefined) {
    throw new Error("Undefined string used in template. You don't want this.");
  }
  return f(s);
};

export const begin = protectedTemplateString((s) => `${s}_BEGIN`);
export const error = protectedTemplateString((s) => `${s}_ERROR`);
export const succeed = protectedTemplateString((s) => `${s}_SUCCEED`);
export const trigger = protectedTemplateString((s) => `${s}_TRIGGER`);

export const RELATIONSHIP_OPTIONS = ['Relative', 'Father', 'Mother', 'Son', 'Daughter', 'Partner', 'Friend', 'Authorized Person'];

export const RELATIONSHIP_OPTIONS_FR = ['Parent', 'Père', 'Mère', 'Fils', 'Fille', 'Partenaire', 'Ami', 'Personne Autorisée'];

export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Electronic Transfer', value: 'Electronic Transfer' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'ACH', value: 'ACH' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Third Party Insurance Company', value: 'Third Party Insurance Company' },
  { label: 'Another credit card', value: 'Another credit card' },
  { label: 'Paypal', value: 'Paypal' },
  { label: 'Other credit card via Square', value: 'Other credit card via Square' }
];

export const PAYMENT_METHOD_OPTIONS_FR = [
  { label: 'Virement électronique', value: 'Electronic Transfer' },
  { label: 'Chèque', value: 'Cheque' },
  { label: 'ACH', value: 'ACH' },
  { label: 'Espèces', value: 'Cash' },
  { label: 'Compagnie d assurance tierce', value: 'Third Party Insurance Company' },
  { label: 'Autre carte de crédit', value: 'Another credit card' },
  { label: 'PayPal', value: 'Paypal' },
  { label: 'Autre carte de crédit via Square', value: 'Other credit card via Square' }
];

export const REMINDER_OPTIONS = [
  {
    label: '30 min',
    value: ms('30 m')
  },
  {
    label: '1 hrs',
    value: ms('1 hrs')
  },
  {
    label: '2 hrs',
    value: ms('2 hrs')
  },
  {
    label: '24 hrs',
    value: ms('24 hrs')
  },
  {
    label: '36 hrs',
    value: ms('36 hrs')
  },
  {
    label: '48 hrs',
    value: ms('48 hrs')
  },
  {
    label: '72 hrs',
    value: ms('72 hrs')
  }
];

export const DURATION_OPTIONS = [
  10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 150, 180, 240
].map((duration) => ({
  label: `${duration} minutes`,
  value: duration
}));

// export const REPEAT_FREQUENCY_OPTIONS = ['Weekly', 'Bi-weekly', 'Once a month'];
export const REPEAT_FREQUENCY_OPTIONS_EN = [
  { label: 'Days', value: 'Days' },
  { label: 'Weeks', value: 'Weeks' },
  { label: 'Bi-weekly', value: 'Bi-weekly' },
  { label: 'Months', value: 'Months' }
];
export const REPEAT_FREQUENCY_OPTIONS_FR = [
  { label: 'Jours', value: 'Days' },
  { label: 'Semaines', value: 'Weeks' },
  { label: 'Deux fois par semaine', value: 'Bi-weekly' },
  { label: 'Mois', value: 'Months' }
];

export const PREFFERED_LANGUAGES = [
  { label: 'English - US', value: 'english-US' },
  { label: 'English - CA', value: 'english-CA' },
  { label: 'Français (French)', value: 'french' }
];

export const MARITALSTATUSES = [
  { label: 'Unmarried', value: 'unmarried' },
  { label: 'Married', value: 'married' },
  { label: 'Other', value: 'other' }
];

// Multi select options for selecting days
export const DAYS_OF_WEEK = [
  { name: 'Sunday', id: 0 },
  { name: 'Monday', id: 1 },
  { name: 'Tuesday', id: 2 },
  { name: 'Wednesday', id: 3 },
  { name: 'Thursday', id: 4 },
  { name: 'Friday', id: 5 },
  { name: 'Saturday', id: 6 }
];
export const DAYS_OF_WEEK_FR = [
  { name: 'Dimanche', id: 0 },
  { name: 'Lundi', id: 1 },
  { name: 'Mardi', id: 2 },
  { name: 'Mercredi', id: 3 },
  { name: 'Jeudi', id: 4 },
  { name: 'Vendredi', id: 5 },
  { name: 'Samedi', id: 6 }
];

export const SENTIMENTAL_STATUS_OPTIONS = ['Married', 'Divorced', 'Single', 'Separated', 'Partnered'];

export const SENTIMENTAL_STATUS_OPTIONS_FR = ['Marié(e)', 'Divorcé(e)', 'Célibataire', 'Séparé(e)', 'En couple'];

export const GENDERORIENTED_OPTIONS = ['Heterosexual', 'Lesbian', 'Gay', 'Bisexual', 'Other', 'Do not want to disclose'];

export const GENDERORIENTED_OPTIONS_FR = ['Hétérosexuel(le)', 'Lesbienne', 'Gay', 'Bisexuel(le)', 'Autre', 'Ne souhaite pas divulguer'];

export const GENDER_OPTIONS = ['Male', 'Female', 'Genderfluid', 'Agender', 'Non-binary', 'Other'];

export const GENDER_OPTIONS_FR = ['Homme', 'Femme', 'Genre fluide', 'Agender', 'Non-binaire', 'Autre'];

export const SEX_OPTIONS = ['Male', 'Female', 'Intersex', 'Do not want to disclose', 'Other'];

export const SEX_OPTIONS_FR = ['Homme', 'Femme', 'Intersexe', 'Ne souhaite pas divulguer', 'Autre'];

export const FEE_TYPE_OPTIONS_EN = [
  { title: 'Flat Rate', value: 'flat-rate' },
  { title: 'Percentage', value: 'percentage' }
];

export const FEE_TYPE_OPTIONS_FR = [
  { title: 'Forfait', value: 'flat-rate' },
  { title: 'Pourcentage', value: 'percentage' }
];

export const CURRENCY_TYPES = [
  { title: 'CAD $', value: 'CAD' },
  { title: 'USD $', value: 'USD' }
  // { title: 'EUR €', value: 'EUR' },
  // { title: 'GBP £', value: 'GBP' }
];

export function GET_DAYS_OF_WEEK() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? DAYS_OF_WEEK : DAYS_OF_WEEK_FR;
}

export function GET_SENTIMENTAL_STATUS_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? SENTIMENTAL_STATUS_OPTIONS : SENTIMENTAL_STATUS_OPTIONS_FR;
}

export function GET_GENDERORIENTED_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? GENDERORIENTED_OPTIONS : GENDERORIENTED_OPTIONS_FR;
}

export function GET_GENDER_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? GENDER_OPTIONS : GENDER_OPTIONS_FR;
}

export function GET_SEX_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? GENDER_OPTIONS : GENDER_OPTIONS_FR;
}

export function GET_RELATIONSHIP_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? RELATIONSHIP_OPTIONS : RELATIONSHIP_OPTIONS_FR;
}

export function GET_PAYMENT_METHOD_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? PAYMENT_METHOD_OPTIONS : PAYMENT_METHOD_OPTIONS_FR;
}

export function GET_FEE_TYPE_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';
  // Return the appropriate array based on the language
  return lang.includes('en') ? FEE_TYPE_OPTIONS_EN : FEE_TYPE_OPTIONS_FR;
}

export function GET_REPEAT_FREQUENCY_OPTIONS() {
  const lang = store.getState()?.customization?.locale ?? 'fr';

  // Return the appropriate array based on the language
  return lang.includes('en') ? REPEAT_FREQUENCY_OPTIONS_EN : REPEAT_FREQUENCY_OPTIONS_FR;
}
