import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';

type Props = {
  noteId: string | null;
};

export default function useNote({ noteId }: Props) {
  const { request, userId } = useAuth();

  const {
    data: note,
    refetch,
    isLoading,
    isFetching,
    isRefetching
  } = useQuery(
    `/user/${userId}/notes/${noteId}`,
    () => request.get(`/user/${userId}/notes/${noteId}`).then((response) => response.data.note as any),
    {
      enabled: !!noteId && noteId !== 'null' && !!userId,
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const { mutateAsync: uploadAttachment } = useMutation(`user/${userId}/notes/${noteId}/attachment`, (payload: any) =>
    request.post(`/user/${userId}/notes/${noteId}/attachment`, { url: payload.url }).then((response) => response?.data)
  );

  const { mutateAsync: removeAttachment } = useMutation(`user/${userId}/notes/${noteId}/attachment`, (payload: any) =>
    request.delete(`/user/${userId}/notes/${noteId}/attachment`, { data: { url: payload.url } }).then((response) => response?.data)
  );

  return {
    note,
    refetchNote: refetch,
    uploadAttachment,
    removeAttachment,
    isLoading: isLoading || isFetching || isRefetching
  };
}
