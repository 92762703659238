import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Tab } from '@mui/material';
import useOptions from 'hooks/useOptions';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessage } from 'utils/stringUtils';
import DialogClose from 'views/components/buttons/DialogClose';
import CheckboxGroup from 'views/components/inputs/CheckboxGroup';
import FormContainer from 'views/components/inputs/FormContainer';
import SwitchField from 'views/components/inputs/Switch';
import TextField from 'views/components/inputs/TextField';
import * as Yup from 'yup';
import useResources from './useResources';

type Props = {
  onClose: () => void;
  editingCategory: any;
};

function AddCategoryDialog({ onClose, editingCategory }: Props) {
  const {
    index,
    title = '',
    titleFrench = '',
    description = '',
    descriptionFrench = '',
    targetAudience = [],
    isDraft = false
  } = editingCategory || {};
  const { addCategory, editCategory } = useResources();
  const intl = useIntl();
  const { roles } = useOptions();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<any>('1');

  const initialValues = {
    title,
    titleFrench,
    description: description,
    descriptionFrench: descriptionFrench,
    targetAudience,
    isDraft
  };

  const onFormSubmit = async (data: any) => {
    try {
      const payload = {
        title: data.title,
        titleFrench: data.titleFrench,
        description: data.description,
        descriptionFrench: data.descriptionFrench,
        targetAudience: data.targetAudience,
        isDraft: data.isDraft
      };

      if (editingCategory) {
        const data = { ...payload, _id: editingCategory._id };
        await editCategory(data);
        enqueueSnackbar(intl.formatMessage({ defaultMessage: `Resources category updated` }), { variant: 'success' });
        onClose();
        return;
      }

      await addCategory(payload);
      enqueueSnackbar(intl.formatMessage({ defaultMessage: `Resources category added` }), { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({ defaultMessage: 'Title is required' })),
    titleFrench: Yup.string(),
    description: Yup.string().required(intl.formatMessage({ defaultMessage: 'Description is required' })),
    descriptionFrench: Yup.string()
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onFormSubmit}>
        <DialogTitle>
          {editingCategory ? <FormattedMessage defaultMessage="Edit category" /> : <FormattedMessage defaultMessage="Add category" />}
          <DialogClose onClose={onClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid sx={{ mb: 2 }} item xs={12} alignItems="flex-end">
            <SwitchField name="isDraft" label={intl.formatMessage({ defaultMessage: 'Draft Mode' })} />
          </Grid>

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab label="EN" value="1" />
                <Tab label="FR" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid sx={{ mt: 1 }} item xs={12}>
                <TextField fullWidth name="title" label={intl.formatMessage({ defaultMessage: 'Title' })} />
              </Grid>
              <Grid sx={{ mt: 3 }} item xs={12}>
                <TextField fullWidth name="description" label={intl.formatMessage({ defaultMessage: 'Description' })} multiline rows={5} />
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid sx={{ mt: 1 }} item xs={12}>
                <TextField fullWidth name="titleFrench" label={intl.formatMessage({ defaultMessage: 'Title' })} />
              </Grid>
              <Grid sx={{ mt: 3 }} item xs={12}>
                <TextField
                  fullWidth
                  name="descriptionFrench"
                  label={intl.formatMessage({ defaultMessage: 'Description' })}
                  multiline
                  rows={5}
                />
              </Grid>
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogContent>
          <CheckboxGroup
            name="targetAudience"
            label={intl.formatMessage({ defaultMessage: 'Target Audience' })}
            options={
              roles?.map((role: any) => ({
                label: intl.locale === 'fr' ? role?.roleNameFrench : role.roleName,
                id: role.roleName
              })) ?? []
            }
            row
          />
        </DialogContent>
        <DialogActions>
          {editingCategory ? (
            <Button type="submit" variant="contained">
              <FormattedMessage defaultMessage="Update" />
            </Button>
          ) : (
            <Button type="submit" variant="contained" sx={{ m: 0 }}>
              <FormattedMessage defaultMessage="Create" />
            </Button>
          )}
          <Button variant="outlined" onClick={onClose} sx={{ m: 0 }}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

export default AddCategoryDialog;
