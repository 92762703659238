/* eslint-disable react/destructuring-assignment */
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useTimezone from 'hooks/useTimezone';
import moment from 'moment-timezone';
import React, { CSSProperties, FormHTMLAttributes } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';

export type FormContainerProps<T extends ObjectShape = {}> = {
  validation?: ObjectSchema<T>;
  defaultValues?: Partial<T>;
  onSuccess?: (values: any) => void;
  handleSubmit?: (values: any) => void;
  formContext?: UseFormReturn<any>;
  FormProps?: FormHTMLAttributes<HTMLFormElement>;
  children?: React.ReactNode;
};

const formStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
};

const FormContainerCore: React.FC<FormContainerProps> = ({ defaultValues = {}, onSuccess = () => {}, validation, FormProps, children }) => {
  const intl = useIntl();
  const methods = useForm<typeof defaultValues>({
    defaultValues,
    ...(validation ? { resolver: yupResolver(validation) } : {})
  });
  const { handleSubmit } = methods;
  const { timezone } = useTimezone();
  moment.tz.setDefault(timezone);

  return (
    <FormProvider {...methods}>
      <LocalizationProvider
        adapterLocale={intl.locale === 'fr' ? frLocale : enLocale}
        dateAdapter={AdapterDateFns}
        // dateLibInstance={moment}
      >
        <form onSubmit={handleSubmit(onSuccess)} noValidate style={formStyle} {...FormProps}>
          {children}
        </form>
      </LocalizationProvider>
    </FormProvider>
  );
};

const FormContainer: React.FC<FormContainerProps> = React.memo((props) => {
  const intl = useIntl();
  const { timezone } = useTimezone();
  moment.tz.setDefault(timezone);

  if (!props.formContext && !props.handleSubmit) {
    return <FormContainerCore {...props} />;
  }
  if (props.handleSubmit && props.formContext) {
    return (
      <FormProvider {...props.formContext}>
        <form noValidate {...props.FormProps} style={formStyle} onSubmit={props.handleSubmit}>
          {props.children}
        </form>
      </FormProvider>
    );
  }
  if (props.formContext && props.onSuccess) {
    return (
      <FormProvider {...props.formContext}>
        <LocalizationProvider
          // adapterLocale={intl.locale === 'fr' ? frLocale : enLocale}
          dateAdapter={AdapterDateFns}
          // dateLibInstance={moment}
        >
          <form onSubmit={props.formContext.handleSubmit(props.onSuccess)} style={formStyle} noValidate {...props.FormProps}>
            {props.children}
          </form>
        </LocalizationProvider>
      </FormProvider>
    );
  }

  return <div>Incomplete setup of FormContainer..</div>;
});

export default FormContainer;
