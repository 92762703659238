import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
import { User } from 'types/user';

export default function useSessionData(sessionId: string | undefined, challenge: string | null) {
  const { request, guestRequest } = useAuth();

  const getRequest = () => (challenge ? guestRequest : request);

  const url = `/session/${sessionId}${challenge ? '/unauthorized' : ''}`;

  const {
    data: sessionData,
    error: sessionError,
    isLoading: loadingSessionData
  } = useQuery(
    url,
    () =>
      getRequest()
        .get(url)
        .then((r) => (challenge ? r.data : r.data.session) as SessionData),
    { enabled: !!sessionId }
  );

  const reportErrorUrl = `/session/${sessionId}/report-error`;

  const { mutateAsync: reportError } = useMutation(reportErrorUrl, (payload: any) => request.post(reportErrorUrl, payload));

  return { sessionData, reportError, sessionError, loadingSessionData };
}

export interface Participant {
  isPaid: boolean;
  chargeAmount: number;
  connectionId: string;
  _id: string;
  user: Omit<User, 'id'> & { _id: string };
  status: string;
  isPending: boolean;
}

export interface FloatingParticipant {
  challenge: string;
  connectionId: string;
  email: string;
  _id: string;
}

export interface ServiceType {
  name?: string;
  nameFrench?: string;
  isTaxByProvince?: boolean;
  rate?: number;
  durationInMinutes?: number;
  taxType?: string;
  taxPercentage?: number;
  tps?: number;
  tvq?: number;
  allowInAppointments?: boolean;
  hourlyRate?: number;
  isComplimentary?: boolean;
  sessionType?: string[];
  groupType?: string;
  _id: string;
}

export interface SessionData {
  appointmentId: string;
  duration: number;
  date: Date;
  participants: Participant[];
  floatingParticipants: FloatingParticipant[];
  deleted: boolean;
  startTime: Date;
  endTime: Date;
  owner: string;
  disableNotes?: boolean;
  disableShareScreen: boolean;
  disableAnnotation: boolean;
  serviceType?: ServiceType;
}
