import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';

export type Props = Omit<AutocompleteProps<{}, true, false, false>, 'renderInput'> & {
  name: string;
  placeholder?: string;
  label?: string;
  optionLabelKey?: string;
  optionValueKey?: string;
  parseError?: (error: FieldError) => string;
  helperText?: string;
  isMulti?: boolean;
  emailData?: any;
  limit?: any;
};

const TagsInput: React.FC<Props> = ({
  name,
  placeholder,
  label,
  optionLabelKey = 'label',
  optionValueKey = 'value',
  parseError,
  helperText,
  options,
  defaultValue,
  isMulti,
  emailData,
  ...rest
}) => {
  const [input, setInput] = useState('');
  const handleInputChange = (event: any, value: string) => {
    setInput(value);
  };

  return (
    <Controller
      render={({ field: { onChange, onBlur, value }, fieldState: { invalid, error }, ...props }) =>
        isMulti === false ? (
          <Autocomplete
            filterSelectedOptions
            multiple
            options={input ? [...(options || []), input] : options || []}
            onInputChange={handleInputChange}
            getOptionLabel={(option: any) => option[optionLabelKey] ?? option.value ?? option}
            onBlur={onBlur}
            onChange={(e, data) => {
              const output = data?.map((it) => it[optionValueKey] ?? it);
              onChange(output);
            }}
            onKeyDown={(e) => {
              if (['Enter', 'Tab'].includes(e.key)) {
                if (input) {
                  e.preventDefault();
                  const output = [...value, input];
                  onChange(output);
                }
              }
            }}
            value={value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                error={invalid}
                onKeyDown={(e) => emailData > 0 && e.preventDefault()}
                // eslint-disable-next-line no-nested-ternary
                helperText={
                  error && error[0]?.message
                    ? typeof parseError === 'function'
                      ? parseError(error[0])
                      : error[0].message
                    : error?.message
                    ? error.message
                    : helperText
                }
              />
            )}
            {...rest}
          />
        ) : (
          <Autocomplete
            filterSelectedOptions
            multiple
            options={input ? [...(options || []), input] : options || []}
            onInputChange={handleInputChange}
            getOptionLabel={(option: any) => option[optionLabelKey] ?? option.value ?? option}
            onBlur={onBlur}
            onChange={(e, data) => {
              const output = data?.map((it) => it[optionValueKey] ?? it);
              onChange(output);
            }}
            onKeyDown={(e) => {
              if (['Enter', 'Tab'].includes(e.key)) {
                if (input) {
                  e.preventDefault();
                  const output = [...value, input];
                  onChange(output);
                }
              }
            }}
            value={value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                error={invalid}
                // eslint-disable-next-line no-nested-ternary
                helperText={
                  error && error[0]?.message
                    ? typeof parseError === 'function'
                      ? parseError(error[0])
                      : error[0].message
                    : error?.message
                    ? error.message
                    : helperText
                }
              />
            )}
            {...rest}
          />
        )
      }
      defaultValue={options ? options.filter((it) => defaultValue?.some((item) => item === it[optionValueKey] ?? it)) : []}
      name={name}
    />
  );
};

export default TagsInput;
