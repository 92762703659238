import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { Box, Card, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface ReusableDashboardCardProps {
  title: string;
  icon?: React.ReactElement;
  value?: string | number | Element | any;
  percentageChangeFromLastMonth: number;
  valueType?: string;
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    letterSpacing: '0.0025em'
  },
  value: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '28.8px'
  },
  upDownFromLastMonth: {
    fontColor: '#727272',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0.0025em'
  },
  iconContainer: {
    marginLeft: 'auto',
    fontSize: '32px'
  }
}));

const ReusableDashboardCard = ({ title = '', value, percentageChangeFromLastMonth }: ReusableDashboardCardProps) => {
  const classes = useStyles();
  return (
    <Card sx={{ background: 'white', borderRadius: 3, border: '1px solid #D0D5DD' }}>
      <Box sx={{ p: 2 }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="column" rowGap={1}>
              <Grid item>
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.value}>{value}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {percentageChangeFromLastMonth !== undefined && (
            <Grid item>
              <Grid container direction="row" alignItems="center" className={classes.iconContainer}>
                {percentageChangeFromLastMonth > 0 ? <TrendingUp color="success" /> : <TrendingDown color="error" width="20" />}
                <Typography color={percentageChangeFromLastMonth > 0 ? '#039855' : 'error'} fontWeight={350} fontSize="1rem">
                  {Math.abs(percentageChangeFromLastMonth)}%
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
};

export default ReusableDashboardCard;
