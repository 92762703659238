import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { User } from 'types/user';

export default function useUser(id?: string) {
  const { request } = useAuth();

  const {
    data: user,
    refetch,
    isLoading,
    isRefetching
  } = useQuery(`/user/${id}`, () => request.get(`/user/${id}`).then((response) => response.data as User), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: typeof id === 'string' && id !== '0' && id !== '1' && id !== '2',
    cacheTime: 0
  });

  const isProvider = user?.isProvider || false;
  const isClient = user?.isClient || false;
  const isNousSupport = user?.isNousSupport || false;
  const isPermissionsManager = user?.isPermissionsManager || false;
  const isAdministrator = user?.isAdministrator || false;
  const isProviderAdmin = user?.isProvider && user?.isAdministrator;
  const isSuperAdmin = user?.isSuperAdmin || false;

  const isProviderOnly = user?.isProvider && !user?.isAdministrator;
  const isProviderAdminS = user?.role === 'PROVIDER_ADMINISTRATOR (S)';
  const isProviderAdminD = user?.role === 'PROVIDER_ADMINISTRATOR (D)';

  return {
    isProviderAdminS,
    isProviderAdminD,
    user,
    refetchUser: refetch,
    isLoading,
    isRefetching,
    isProvider,
    isClient,
    isNousSupport,
    isPermissionsManager,
    isAdministrator,
    isProviderOnly,
    isProviderAdmin,
    isSuperAdmin
  };
}
