import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { differenceBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';

export type AutocompleteFieldProps<T extends {}, Multiple extends boolean> = Omit<
  AutocompleteProps<T, Multiple, false, false>,
  'renderInput'
> & {
  name: string;
  label?: string;
  parseError?: (error: FieldError) => string;
  getOptionValue?: (option: T) => string | number | null | undefined;
  options: T[];
  multiple?: Multiple;
  helperText?: string;
  required?: boolean;
  hasCreateOption?: boolean;
  getCreateOptionFromInput?: (input: string) => T | null;
  hideCloseIcon?: boolean;
  limit?: number;
  placeholder?: string;
};

const AutocompleteField = <T extends {}, Multiple extends boolean>({
  parseError,
  name,
  label,
  options,
  defaultValue,
  isOptionEqualToValue,
  getOptionLabel,
  renderOption,
  onChange: ignored,
  required,
  getOptionValue,
  hasCreateOption,
  getCreateOptionFromInput,
  hideCloseIcon,
  limit = 0,
  ...rest
}: AutocompleteFieldProps<T, Multiple>): JSX.Element => {
  const [optionsWithCreatOption, setOptionsWithCreateOption] = useState(options);

  useEffect(() => {
    setOptionsWithCreateOption(options);
  }, [options]);

  const handleInputChange = (inputValue: string, value: any) => {
    if (!hasCreateOption || !getOptionValue) {
      return;
    }

    const createdOption = differenceBy(optionsWithCreatOption, options, getOptionValue)?.[0] ?? null;

    if (!inputValue && createdOption && value && getOptionValue(createdOption) !== value) {
      setOptionsWithCreateOption(options);
    }

    const createOption = inputValue ? getCreateOptionFromInput?.(inputValue) : null;

    if (createOption && options.every((option) => getOptionValue?.(option) !== getOptionValue?.(createOption))) {
      setOptionsWithCreateOption([createOption, ...options]);
    }
  };

  const isOptionEqualToValueWrapper = useCallback(
    (option, value) => {
      if (value == null || value === '') return false;

      if (typeof getOptionValue === 'function') {
        return getOptionValue(option) === value;
      }

      return isOptionEqualToValue
        ? isOptionEqualToValue(option, value)
        : (value.id && option.id === value.id) || (value._id && option._id === value._id);
    },
    [isOptionEqualToValue, getOptionValue]
  );

  const getOptionLabelWrapper = useCallback(
    (option) => {
      const orgOption =
        typeof option !== 'object' && getOptionValue ? optionsWithCreatOption.find((op) => getOptionValue(op) === option) : option;

      if (orgOption == null || orgOption === '') return '';

      return getOptionLabel ? getOptionLabel(orgOption) ?? '' : orgOption.label ?? '';
    },
    [getOptionLabel, getOptionValue, optionsWithCreatOption]
  );

  const getValues = (data) => {
    if (typeof getOptionValue !== 'function') {
      return data;
    }

    if (Array.isArray(data)) {
      return data.map((item) => (typeof item !== 'object' ? item : getOptionValue(item)));
    }

    return getOptionValue(data);
  };

  return (
    <Controller
      render={({ field: { onChange, onBlur, value }, fieldState: { invalid, error }, ...props }) => (
        <Autocomplete
          options={optionsWithCreatOption}
          onInputChange={(_event, inputValue) => handleInputChange(inputValue, value)}
          getOptionLabel={getOptionLabelWrapper}
          renderOption={renderOption}
          isOptionEqualToValue={isOptionEqualToValueWrapper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label ? `${label} ${required ? ' *' : ''}` : ''}
              placeholder={rest?.placeholder}
              name={name}
              error={invalid}
              // eslint-disable-next-line no-nested-ternary
              helperText={error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : rest.helperText}
              InputProps={hideCloseIcon ? { ...params.InputProps, endAdornment: null } : { ...params.InputProps }}
            />
          )}
          onChange={(e, data) => {
            if (limit > 0 && data.length > limit) {
              return;
            }
            onChange(getValues(data));
          }}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
      defaultValue={defaultValue}
      name={name}
    />
  );
};

export default AutocompleteField;
