import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, Box, Button, Stack, TextField as MuiTextField, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useNote from 'hooks/useNote';
import useUser from 'hooks/useUser';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { convertImageBlobToImageFile } from 'utils/convertImageBlobToImageFile';
import { getErrorMessage } from 'utils/stringUtils';
import QuillEditorController from 'views/components/common/QuillEditorController';
import FormContainer from 'views/components/inputs/FormContainer';
import TextField from 'views/components/inputs/TextField';
import PopularCard from 'views/components/skeleton/PopularCard';
import * as Yup from 'yup';
import useNoteTemplates, { Template } from '../client-record/useNoteTemplates';
import { SessionData } from './useSessionData';
import { Note } from './useSessionNotes';

interface Props {
  sessionData: SessionData;
  noteId: string | null;
  onSubmitSuccess: () => void;
  onClose: () => void;
  notesHeight: number;
  binarySignature?: File;
}

export default function SessionNoteForm({ sessionData, noteId, onSubmitSuccess, onClose, notesHeight, binarySignature }: Props) {
  const initialNoteIdRef = useRef(noteId);

  const creatingRef = useRef(false);

  const intl = useIntl();
  const { request, userId } = useAuth();

  const { note, isLoading: isNoteLoading, uploadAttachment, removeAttachment } = useNote({ noteId });
  const blobUrlMap = new Map();
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({ defaultMessage: 'Required' })),
    body: Yup.string()
  });

  const createNoteMutation = useMutation(
    (variables: any) => request.post(`/user/${userId}/notes`, variables).then((response) => response.data),
    {
      onMutate: () => {
        creatingRef.current = true;
      },
      onSettled: () => {
        creatingRef.current = false;
      },
      onSuccess: onSubmitSuccess
    }
  );

  const updateNoteMutation = useMutation(
    (variables: any) => request.put(`/user/${userId}/notes/${note?._id || initialNoteIdRef.current}`, variables),
    {
      onSuccess: onSubmitSuccess
    }
  );

  const handleSubmit = useCallback(
    async (newValues: any) => {
      if (note || initialNoteIdRef.current) {
        await updateNoteMutation.mutateAsync({ ...(note || {}), ...newValues });
      } else if (!creatingRef.current) {
        const newNote = await createNoteMutation.mutateAsync({
          ...newValues,
          sessionId: sessionData.appointmentId,
          participants: sessionData.participants.map((participant) => participant.user._id)
        });

        const newNoteId = newNote?._id || newNote?.id;

        if (newNoteId) {
          initialNoteIdRef.current = newNoteId;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateNoteMutation, createNoteMutation, note]
  );
  const uploadMutation = useMutation((formData: any) => request.post(`/user/${userId}/files`, formData));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBodyUpdate = useCallback(
    debounce(async ({ title, body }: any) => {
      if (note) {
        let updatedBody = body;
        if (body) {
          updatedBody = await convertImageBlobToImageFile(body, uploadMutation, blobUrlMap);
        }
        updateNoteMutation.mutateAsync({ ...note, body: updatedBody });
      } else {
        let updatedBody = body;

        if (!createNoteMutation.isLoading && !isNoteLoading) {
          updatedBody = await convertImageBlobToImageFile(body, uploadMutation, blobUrlMap);
        }
        await createNoteMutation.mutateAsync({
          title,
          body: updatedBody,
          sessionId: sessionData.appointmentId,
          participants: sessionData.participants.map((participant) => participant.user._id)
        });
      }
    }, 2000),
    [updateNoteMutation, note]
  );

  return (
    <FormContainer
      validation={validationSchema}
      defaultValues={{
        title: note?.title ?? '',
        body: note?.body ?? ''
      }}
      onSuccess={handleSubmit}
    >
      {!note && isNoteLoading && initialNoteIdRef.current ? (
        <PopularCard />
      ) : (
        <FormFields
          uploadMutation={uploadMutation}
          initialNoteId={initialNoteIdRef.current}
          note={note}
          onBodyUpdate={handleBodyUpdate}
          isSubmitting={createNoteMutation.isLoading || updateNoteMutation.isLoading}
          onClose={onClose}
          notesHeight={notesHeight}
          binarySignature={binarySignature}
          uploadAttachment={uploadAttachment}
          removeAttachment={removeAttachment}
        />
      )}
    </FormContainer>
  );
}

interface FormFieldsProps {
  isSubmitting: boolean;
  initialNoteId: string | null;
  onBodyUpdate: ({ title, body }: any) => void;
  note?: Note | null;
  onClose: () => void;
  notesHeight: number;
  binarySignature?: File;
  uploadAttachment: Function;
  removeAttachment: Function;
  uploadMutation: any;
}

function FormFields({
  isSubmitting,
  initialNoteId,
  note,
  onBodyUpdate,
  onClose,
  notesHeight,
  binarySignature,
  uploadAttachment,
  removeAttachment,
  uploadMutation
}: FormFieldsProps) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { userId, request } = useAuth();
  const { user, isClient } = useUser(userId);
  const { templates } = useNoteTemplates(userId);
  const { reset, control, setValue } = useFormContext();
  const [template, setTemplate] = useState<Template | null>(null);
  const title = useWatch({ control, name: 'title' });
  const body = useWatch({ control, name: 'body' });
  const [created, setCreated] = useState(false);

  const [attachments, setAttachments] = useState(note?.attachments ?? []);

  const handleAttachmentUpload = useCallback(
    async (url) => {
      if (!note?._id) return;

      try {
        const data = await uploadAttachment({ id: note?._id, url });
        setAttachments(data.attachments);
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      }
    },
    [note?._id, uploadAttachment, enqueueSnackbar]
  );

  const handleAttachmentRemove = useCallback(
    async (url) => {
      if (!note?._id) return;

      try {
        const data = await removeAttachment({ id: note?._id, url });
        setAttachments(data.attachments);
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      }
    },
    [note?._id, removeAttachment, enqueueSnackbar]
  );

  useEffect(() => {
    if (initialNoteId && note) {
      setValue('title', note.title ?? '');
      setValue('body', note.body ?? '');
      setAttachments(note.attachments ?? []);
    }
  }, [note, initialNoteId, setValue]);

  const handleChange = useCallback(
    async () => {
      if (!body || !title) return;
      setCreated(true);
      onBodyUpdate({ title, body });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title, body, created]
  );

  useEffect(() => {
    if (!template) {
      return;
    }

    setValue('body', template.content);
  }, [template, setValue]);

  useEffect(() => {
    if (body?.replace(/&amp;/g, '&') === note?.body) return;
    handleChange();
  }, [body, note?.body, title, handleChange]);

  useEffect(() => {
    if (note?.title && title === note.title) return;
    handleChange();
  }, [title, note?.title, handleChange]);

  return (
    <Stack spacing={3}>
      {user?.userOptions?.canViewEditTemplates && (
        <Autocomplete
          options={templates ?? []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option) => option.name === template?.name}
          renderInput={(params) => <MuiTextField {...params} label={intl.formatMessage({ defaultMessage: 'Choose a note template' })} />}
          onChange={(_, option) => setTemplate(option)}
        />
      )}
      <TextField name="title" label={intl.formatMessage({ defaultMessage: 'Title' })} required />
      <Box>
        <Box
          style={{ maxHeight: notesHeight - 520, minHeight: notesHeight - 370 }}
          sx={{ '& .ql-container': { height: notesHeight - 470 } }}
        >
          <QuillEditorController
            name="body"
            disabled={!title}
            binarySignature={binarySignature}
            handleAttachmentUpload={handleAttachmentUpload}
            handleAttachmentRemove={handleAttachmentRemove}
            attachments={attachments}
          />
        </Box>
        <Box display="flex" px={2} mt={1} justifyContent="flex-end">
          {isSubmitting ? (
            <Typography variant="caption">
              <FormattedMessage defaultMessage="Auto saving..." />
            </Typography>
          ) : (
            <CheckIcon color="success" fontSize="small" />
          )}
        </Box>
      </Box>

      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
        <Button
          type="button"
          variant="outlined"
          onClick={() => {
            reset({ title: '', body: '' });
            setValue('body', '');
            setTemplate(null);
          }}
        >
          <FormattedMessage defaultMessage="Clear" />
        </Button>
        <Button variant="contained" disabled={isSubmitting || !title || uploadMutation?.isLoading} onClick={handleChange}>
          <FormattedMessage defaultMessage="Save" />
        </Button>
        <Button variant="contained" onClick={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </Stack>
    </Stack>
  );
}
