import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import { FormattedMessage, useIntl } from 'react-intl';

const DateRangePicker = ({ value, setValue }) => {
  const intl = useIntl();

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={intl.locale === 'fr' ? { start: 'Date de début', end: 'Date de fin' } : { start: 'Start-date', end: 'End-date' }}
        adapterLocale={intl.locale === 'fr' ? frLocale : enLocale}
      >
        <MuiDateRangePicker
          inputFormat={intl.locale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} autoComplete="off" size="small" />
              <Box sx={{ mx: 2 }}>
                {' '}
                <FormattedMessage defaultMessage={'to'} />{' '}
              </Box>
              <TextField {...endProps} autoComplete="off" size="small" />
            </>
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateRangePicker;
