// action - state management
import { DefaultRootStateProps } from 'types';
import * as actionTypes from '../actions';

export const initialState: DefaultRootStateProps['chat'] = {
  socket: null
};

const socketReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SOCKET:
      return {
        ...state,
        socket: action.payload
      };
    default:
      return state;
  }
};

export default socketReducer;
