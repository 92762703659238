import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = DialogProps & {
  title: string;
  description?: string;
  confirmButtonText?: string;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
};

const DeleteConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  confirmButtonText = <FormattedMessage defaultMessage={'Delete'} />,
  onCancel,
  onConfirm,
  ...props
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { p: 0 } }} {...props} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <Divider />
      <DialogActions>
        <Button onClick={onCancel} variant="contained" color="secondary">
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={async (e) => {
            setIsDeleting(true);
            await onConfirm(e);
          }}
          variant="outlined"
          disabled={isDeleting}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
