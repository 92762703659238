import { Box, Theme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { IconArrowUpRight } from '@tabler/icons';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#0076A1',
    display: 'flex'
  },
  icon: {
    width: '18px',
    height: '18px'
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    alignItems: 'left'
  },
  arrow: {
    alignItems: 'right'
  }
}));

interface CardSecondaryActionProps {
  title?: string;
  link?: string;
  icon?: ReactNode | string;
  to?: string;
}

const TableMoreAction = ({ title, to = '' }: CardSecondaryActionProps) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Tooltip title={title || 'Reference'} placement="left">
      <ButtonBase disableRipple component={Link} to={to} style={{ textDecoration: 'none' }}>
        <Box className={classes.root}>
          <Box className={classes.text}>{intl.formatMessage({ defaultMessage: 'More' })}</Box>
          <Box className={classes.arrow}>
            <IconArrowUpRight className={classes.icon} />
          </Box>
        </Box>
      </ButtonBase>
    </Tooltip>
  );
};

export default TableMoreAction;
