import { Box, Button, Dialog, Typography } from '@mui/material';

import { IconSpeakerphone } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import DialogClose from '../buttons/DialogClose';

function SoloModePrompt({ open, setOpen }: any) {
  const handleClose = () => {
    localStorage.setItem('soloModePrompt', 'false');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
          textAlign: 'center',
          alignItems: 'center',
          minWidth: 300,
          p: 0
        }
      }}
    >
      <DialogClose onClose={handleClose} />

      <Box sx={{ pb: 3, p: 3 }}>
        <IconSpeakerphone size={0} color="#444" strokeWidth={1.8} />
        <Typography
          variant="h3"
          sx={{
            fontSize: 16,
            fontWeight: '500',
            lineHeight: '1.334em',
            fontFamily: 'Roboto,sans-serif',
            color: '#616161',
            py: 1
          }}
        >
          <FormattedMessage defaultMessage="There is only one provider in the clinic. Please set clinic to solo mode or add another provider." />
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" style={{ width: '150px' }} onClick={handleClose}>
            <FormattedMessage defaultMessage="Not Now" />
          </Button>
          <Link to="/administration?tab=clinic-users">
            <Button variant="contained" style={{ marginLeft: '20px', width: '180px' }} onClick={handleClose}>
              <FormattedMessage defaultMessage="Add New Provider" />
            </Button>
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
}

export default SoloModePrompt;
