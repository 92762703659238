import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, DialogActions, DialogContent, Divider, FormHelperText, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zonedTimeToUtc } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useAuth from 'hooks/useAuth';
import useProvider from 'hooks/useProvider';
import { padStart } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Appointment } from 'types/appointment';
import { getErrorMessage } from 'utils/stringUtils';
import SubCard from 'views/components/cards/SubCard';
import FormContainer from 'views/components/inputs/FormContainer';
import TextField from 'views/components/inputs/TextField';
import * as Yup from 'yup';
import useAvailabilities, { Availability } from '../availability/useAvailabilities';

interface Props {
  appointment: Appointment;
  onUpdateSuccess: () => void;
  renderCancel: () => ReactNode;
}

const validationSchema = Yup.object({
  message: Yup.string()
});

type FormValues = Yup.InferType<typeof validationSchema>;

export default function RescheduleAppointment({ appointment, onUpdateSuccess, renderCancel }: Props) {
  const { request, userId } = useAuth();
  const { provider } = useProvider(userId);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [date, setDate] = useState(moment().toDate());
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<string>('');
  const [serviceId, setServiceId] = useState<string>('');

  const [responseError, setResponseError] = useState<string | undefined>(undefined);

  const tz = provider?.settings.timezone || 'America/Vancouver';

  const {
    availabilities,
    isLoading: isAvailabilitiesLoading,
    error: availabilityError
  } = useAvailabilities({
    from: moment(date).subtract(1, 'month').toDate(),
    to: moment(date).add(1, 'month').toDate(),
    provider: provider?.id,
    locationType: appointment.locationType
  });

  useEffect(() => {
    const responseError: any = availabilityError;

    if (responseError?.response?.data?.message) {
      setResponseError(responseError?.response?.data?.message);
    } else {
      setResponseError(undefined);
    }
  }, [availabilityError]);

  useEffect(() => {
    if (provider && provider.provider.services && provider.provider.services.length > 0) {
      setServiceId(provider.provider.services[0]._id);
    }
  }, [provider]);

  const service = provider?.provider.services.find((s) => s._id === serviceId);

  const findValidSlots = useCallback(
    (chunk: Availability, serviceDur: number, timeBetween: number, endDayTime: any) => {
      const slots: string[] = [];
      const diffEnd = moment(chunk.end).diff(endDayTime, 'minutes');
      let startChunk = moment(chunk.start);
      // Minutes must be divisible by 5
      if (startChunk.minutes() % 5) {
        startChunk = startChunk.add(5 - (startChunk.minutes() % 5), 'minutes');
      }

      // No buffer for last slot of the day
      let endSlotWithSpace = moment(chunk.end);
      let diff = moment(chunk.end).diff(moment(startChunk), 'minutes');
      if (diffEnd === 0) {
        diff += timeBetween;
        endSlotWithSpace = endSlotWithSpace.add(timeBetween, 'minutes');
      }

      const now = moment.tz(tz);

      for (let startDiff = 0; startDiff <= diff; ) {
        const newMomentDate = moment(startChunk).tz(tz).add(startDiff, 'minutes');
        // const time = convertToQuaterHour(newMomentDate);
        const time = moment(newMomentDate);
        if (time.isSameOrAfter(now) && time.isSameOrBefore(moment(endSlotWithSpace).subtract(serviceDur + timeBetween, 'minutes'))) {
          slots.push(time.format('hh:mm A'));
        }
        startDiff += timeBetween + serviceDur;
      }

      return slots;
    },
    [tz]
  );

  useEffect(() => {
    if (service && provider && availabilities) {
      const clinicalHours = provider?.provider?.allowInBetweenAppointmentsTime;
      let dur = clinicalHours && service.durationInMinutes === 50 ? service.durationInMinutes + 10 || 0 : service.durationInMinutes || 0;
      // Make duration divisible by 5
      dur = Math.ceil(dur / 5) * 5;

      const timeBetween = provider.provider.inBetweenAppointmentsTime || 0;

      const from = moment(moment(date).tz(tz).format('YYYY-MM-DD')).startOf('day');

      const to = moment(from).add(24, 'hours');

      const freeChunks = availabilities.filter((a) => a.freeBusyStatus === 'free');
      const chunksToday = freeChunks.filter((a) => from.isSameOrBefore(a.start) && to.isSameOrAfter(a.end));
      const workWeek = provider?.provider?.workWeek;
      const selectedWorkDay = workWeek[moment(date).tz(tz).day()];
      const timeSlots: string[] = [];

      // if there is an available slot today
      if (chunksToday && chunksToday.length > 0) {
        chunksToday?.forEach((chunk) => {
          const endChunk = moment(chunk.end).set({ hour: selectedWorkDay.endTime.hour });
          const endTime = moment.tz(selectedWorkDay.endTime, tz);
          const lastTime = endChunk.set({ hour: endTime.toDate().getHours(), minute: endTime.toDate().getMinutes() });

          const slots = findValidSlots(chunk, dur, timeBetween, lastTime);
          if (slots.length > 0) {
            timeSlots.push(...slots);
          }
        });
      }

      setTimeSlots(timeSlots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilities, date, provider, service, tz, setTimeSlots, findValidSlots]);

  const rescheduleAppointmentMuatation = useMutation(
    (variables: any) => request.post(`/appointments/${appointment.event_id}/reschedule`, variables),
    {
      onSuccess() {
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment Rescheduled!' }), { variant: 'success' });
        onUpdateSuccess();
      },
      onError(e: any) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }
    }
  );

  const onFormSubmit = useCallback(
    async (values: FormValues) => {
      await rescheduleAppointmentMuatation.mutateAsync(values);
    },
    [rescheduleAppointmentMuatation]
  );

  const initialValues: FormValues = {
    message: ''
  };

  const formContext = useForm({ defaultValues: initialValues, resolver: yupResolver(validationSchema) });

  useEffect(() => {
    setSelectedSlot('');
  }, [timeSlots]);

  const amTimeSlots = useMemo(() => {
    return timeSlots.filter((slot) => slot.includes('AM'));
  }, [timeSlots]);

  const pmTimeSlots = useMemo(() => {
    return timeSlots.filter((slot) => slot.includes('PM'));
  }, [timeSlots]);

  if (!appointment) return null;

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={(values) => {
        if (!selectedSlot) {
          enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Must select a valid time slot' }), { variant: 'error' });
          return;
        }

        onFormSubmit({
          ...values,
          date: zonedTimeToUtc(
            `${moment(date).tz(tz).format('YYYY-MM-DD')} ${padStart(moment(selectedSlot, 'hh:mm A').hours().toString(), 2, '0')}:${padStart(
              moment(selectedSlot, 'hh:mm A').minutes().toString(),
              2,
              '0'
            )}`,
            tz
          ).toISOString()
        });
      }}
    >
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3}>
          <SubCard title={intl.formatMessage({ defaultMessage: 'Select Date & Time' })} sx={{ width: '100%' }}>
            <LocalizationProvider adapterLocale={intl.locale === 'fr' ? frLocale : enLocale} dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={date}
                onChange={(date) => date && setDate(date)}
                disablePast
                showDaysOutsideCurrentMonth
                renderInput={(params) => <TextField name="selectedDate" {...params} />}
              />
            </LocalizationProvider>
          </SubCard>

          <SubCard title={intl.formatMessage({ defaultMessage: 'Time Slots Available' })} sx={{ width: '100%' }}>
            {isAvailabilitiesLoading ? (
              <LinearProgress variant="indeterminate" />
            ) : responseError ? (
              <Box sx={{ px: 2 }}>
                <FormHelperText sx={{ py: 2, fontSize: 17, fontWeight: '500' }} error>
                  {responseError}
                </FormHelperText>
              </Box>
            ) : (
              <>
                {!!amTimeSlots?.length && (
                  <>
                    <Typography fontSize={14} fontWeight={600} sx={{ pb: 2 }}>
                      <FormattedMessage defaultMessage="AM" values={{ tz }} />
                    </Typography>

                    <Grid container spacing={4}>
                      {amTimeSlots.map((slot) => (
                        <Grid item key={slot}>
                          <Button variant={slot === selectedSlot ? 'contained' : 'outlined'} onClick={() => setSelectedSlot(slot)}>
                            {slot}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}

                {!!pmTimeSlots?.length && (
                  <>
                    <Typography fontSize={14} fontWeight={600} sx={{ pt: 3, pb: 2 }}>
                      <FormattedMessage defaultMessage="PM" values={{ tz }} />
                    </Typography>

                    <Grid container spacing={4}>
                      {pmTimeSlots.map((slot) => (
                        <Grid item key={slot}>
                          <Button variant={slot === selectedSlot ? 'contained' : 'outlined'} onClick={() => setSelectedSlot(slot)}>
                            {slot}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}

                {!!timeSlots?.length && (
                  <Typography variant="body2" sx={{ py: 4 }}>
                    <FormattedMessage defaultMessage="* All times shown in {tz} timezone" values={{ tz }} />
                  </Typography>
                )}

                {(!timeSlots || timeSlots.length === 0) && (
                  <Box sx={{ pb: 6, pt: 4, textAlign: 'center' }}>
                    <Typography variant="body2">
                      <FormattedMessage defaultMessage="No time slots available for this day" />
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </SubCard>

          <TextField
            name="message"
            label={intl.formatMessage({ defaultMessage: 'Include a message for your session participant(s) (optional)' })}
            fullWidth
            multiline
            rows={5}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          {renderCancel()}
          <Button type="submit" variant="contained" disabled={rescheduleAppointmentMuatation.isLoading}>
            <FormattedMessage defaultMessage="Reschedule" />
          </Button>
        </Stack>
      </DialogActions>
    </FormContainer>
  );
}
