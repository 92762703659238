import { EventInput } from '@fullcalendar/react';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import useUserCalendars from 'hooks/useUserCalendars';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import tinycolor from 'tinycolor2';
import { Appointment, CalendarAppointment, NTCalendarEvent } from 'types/appointment';

interface Props {
  from: Date;
  to: Date;
  id?: string;
  enabled?: boolean;
}

const appointmentToEvent = (appointment: Appointment, userId: string, resourceId?: string) => {
  const isOwner = appointment.owner === userId;
  const participant = appointment?.members?.find((member) => member.id === userId);
  const isPending = participant?.status === 'PENDING' || participant?.isPending;
  const participantsAccepted = participant?.status?.match(/ATTENDED/i) || (isOwner && (!isPending || participant.status === 'ACCEPTED'));
  const appoinmentPending = appointment.appointmentStatus?.match(/pending/i);
  const responseRequired = appointment.contextStatus?.match(/pending/i) && !participantsAccepted;

  let color: string = '#3279B7';
  let textColor: string = '#ffffff';

  if (appointment.isAssociateTherapist) {
    color = '#673AB7';
    textColor = '#ffffff';

    if (appoinmentPending) {
      if (responseRequired) {
        color = '#edd1ff';
        textColor = '#000000';
      } else {
        color = '#D9ECF7';
        textColor = '#000000';
      }
    }
  } else {
    if (appoinmentPending) {
      if (responseRequired) {
        color = '#ffc107';
      } else {
        color = '#D9ECF7';
        textColor = '#000000';
      }
    }
  }

  return {
    title: appointment.summary,
    start: appointment.start,
    end: appointment.end,
    backgroundColor: color,
    borderColor: color,
    textColor,
    extendedProps: {
      type: 'managed',
      appointment
    },
    resourceId: resourceId || appointment.owner
  } as EventInput;
};

const calendarAppointmentToEvent = (appointment: CalendarAppointment, calendars) => {
  const selectedCalendar = calendars?.find((item) => item.calendar_id === appointment.calendar_id);

  let isDark = true;
  if (selectedCalendar?.color && !tinycolor(selectedCalendar?.color).isDark()) {
    isDark = false;
  }

  return {
    title: appointment.summary,
    start: appointment.start,
    end: appointment.end,
    backgroundColor: selectedCalendar?.color,
    borderColor: selectedCalendar?.color,
    textColor: isDark ? '#fff' : '#000',
    resourceId: appointment.calendar_id,
    extendedProps: {
      type: 'external',
      calendarId: appointment.calendar_id
    }
  };
};

const ntCalendarToEvent = (event: NTCalendarEvent, resourceId?: string) => {
  const color: string = event.bubbleColor ?? '#3279B7';
  let isDark = true;
  if (!tinycolor(color).isDark()) {
    isDark = false;
  }

  return {
    title: event.summary,
    start: event.start,
    end: event.end,
    repeatId: event.repeatId,
    backgroundColor: color,
    borderColor: color,
    textColor: isDark ? '#fff' : '#000',
    extendedProps: {
      type: 'nt-managed',
      event
    },
    resourceId
  } as EventInput;
};

export default function useAppointments({ from, to, id: _id, enabled = true }: Props) {
  const { request, userId } = useAuth();
  const { user } = useUser(userId);
  const { calendars } = useUserCalendars(userId);

  const id = _id || userId;

  const {
    data: appointments,
    refetch: refetchAppointments,
    isLoading: isLoadingAppointments
  } = useQuery(
    [`/user/${id}/appointments`, { from, to }],
    () =>
      request
        .get(`/user/${id}/appointments`, { params: { from, to } })
        .then((response) => response.data.appointmentData as (Appointment | CalendarAppointment)[]),
    { enabled: !!user && enabled }
  );

  const events = useMemo(() => {
    if (appointments) {
      return appointments.map((appointment: any) => {
        if (appointment?.ntCalendarEvent) {
          return ntCalendarToEvent(appointment);
        }
        if (appointment?.calendar_id) {
          return calendarAppointmentToEvent(appointment, calendars);
        }
        return appointmentToEvent(appointment as Appointment, userId || '');
      }) as EventInput[];
    }
    return [];
  }, [appointments, calendars, userId]);

  return { appointments, refetchAppointments, events, isLoadingAppointments };
}

export { appointmentToEvent, ntCalendarToEvent };
