import { FiberManualRecord } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
// material-ui
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { IconEdit, IconPaperclip } from '@tabler/icons';
// third-party
import clsx from 'clsx';
import useAuth from 'hooks/useAuth';
import useMessageNotifications from 'hooks/useMessageNotifications';
import useTimezone from 'hooks/useTimezone';
import useUsers from 'hooks/useUsers';
import moment from 'moment-timezone';
// project imports
import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ArrangementOrder, DefaultRootStateProps, EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import CenteredLoading from 'views/components/CenteredLoading';
import useTherapistPatients from 'views/pages/client-record/useTherapistPatients';
import ComposeMessage from './ComposeMessage';
import useMessages from './useMessages';
import ViewMessage from './ViewMessage';

// style const
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
    '& .tableCellUnread > p': {
      color: theme.palette.grey[900],
      fontWeight: 800
    },
    '& .tableCellRead > p': {
      color: theme.palette.grey[600],
      fontWeight: 400
    },
    '& .checkIcon': {
      width: 15,
      height: 15,
      position: 'relative',
      top: 4,
      marginRight: 7,
      marginBottom: 1
    },

    '& .undelivered': {
      color: '#f00'
    },

    '& .delivered': {
      color: '#00a700'
    }
  },
  sortSpan: { ...visuallyHidden }
}));

const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight: {
    color: theme.palette.secondary.main
  },
  title: {
    flex: '1 1 100%'
  }
}));

// ==============================|| TABLE HEADER ||============================== //

export interface ProReviewEnhancedTableHeadProps extends EnhancedTableHeadProps {
  theme: Theme;
  selected: string[];
  inbox?: boolean;
  sent?: boolean;
}

const EnhancedTableHead: FC<ProReviewEnhancedTableHeadProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  theme,
  selected,
  handleDelete,
  inbox,
  sent
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const createSortHandler = (property: string) => (event: SyntheticEvent<Element, Event>) => {
    onRequestSort(event, property);
  };

  // table header options
  const headCells: HeadCell[] = [
    {
      id: inbox ? 'sender.firstName' : 'receiver.firstName',
      numeric: false,
      label: inbox ? intl.formatMessage({ defaultMessage: 'From' }) : intl.formatMessage({ defaultMessage: 'To' }),
      align: 'left'
    },
    {
      id: 'title',
      numeric: true,
      label: intl.formatMessage({ defaultMessage: 'Message' }),
      align: 'left'
    },
    {
      id: 'updatedAt',
      numeric: false,
      label: intl.formatMessage({ defaultMessage: 'Date' }),
      align: 'left'
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {numSelected > 0 && (
          <TableCell padding="none" colSpan={7}>
            <EnhancedTableToolbar numSelected={selected.length} handleDelete={handleDelete} />
          </TableCell>
        )}

        {numSelected <= 0 &&
          headCells.map((headCell) => (
            <TableCell
              style={{ color: '#000' }}
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                style={{ color: '#000' }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.sortSpan}>
                    {order === 'desc' ? (
                      <FormattedMessage defaultMessage="sorted descending" />
                    ) : (
                      <FormattedMessage defaultMessage="sorted ascending" />
                    )}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = ({ numSelected, handleDelete }) => {
  const classes = useToolbarStyles();
  const intl = useIntl();
  const [deleting, setDeleting] = useState(false);

  const handleClick = async () => {
    setDeleting(true);
    await handleDelete();
    setDeleting(false);
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 && (
          <Typography className={classes.title} color="inherit" variant="h4" component="div">
            {numSelected} <FormattedMessage defaultMessage="Selected" />
          </Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title={intl.formatMessage({ defaultMessage: 'Delete' })}>
            {deleting ? (
              <CircularProgress />
            ) : (
              <IconButton onClick={handleClick}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Tooltip>
        )}
      </Toolbar>
    </>
  );
};

// ==============================|| PRODUCT REVIEW LIST ||============================== //

type MessageTableType = {
  isInternalMessage?: boolean;
  clientId?: string;
  inbox?: boolean;
  sent?: boolean;
};

const MessageTable: React.FC<MessageTableType> = ({ isInternalMessage = false, clientId, inbox, sent }) => {
  const socket = useSelector((state: DefaultRootStateProps) => state.chat)?.socket;

  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [order, setOrder] = useState<ArrangementOrder>('desc');
  const [orderBy, setOrderBy] = useState<string>('updatedAt');
  const { timezone } = useTimezone();
  const [composeMessage, setComposeMessage] = useState(false);
  const [replyingMessage, setReplyingMessage] = useState(null);

  const { messages, total, isLoading, markAsRead, replyToMessage, refetchMessages } = useMessages({
    clientId,
    page,
    limit: rowsPerPage,
    sent,
    orderBy,
    order,
    composeMessage,
    isInternalMessage
  });

  const { refetchMessageNotifications } = useMessageNotifications();

  useEffect(() => {
    socket?.on('receive-message', () => {
      refetchMessages();
      refetchMessageNotifications();
    });
  }, [socket, refetchMessages, refetchMessageNotifications]);

  const [selectedMessage, setSelectedMessage] = useState(null);

  const { userId } = useAuth();
  const { patients, isLoading: isPatientsLoading } = useTherapistPatients(userId);
  const { therapists, isLoading: isProvidersLoading } = useUsers({});

  const handleRequestSort = (event: SyntheticEvent<Element, Event>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messages) : 0;

  if (isLoading) {
    return <CenteredLoading />;
  }

  return (
    <>
      {!!selectedMessage && (
        <ViewMessage
          message={selectedMessage}
          sent={sent ?? false}
          onClose={() => {
            setSelectedMessage(null);
            refetchMessages();
            refetchMessageNotifications();
          }}
          markAsRead={markAsRead}
          setReplyingMessage={setReplyingMessage}
        />
      )}
      {(composeMessage || replyingMessage) && (
        <ComposeMessage
          isInternalMessage={isInternalMessage}
          onClose={() => {
            setComposeMessage(false);
            setReplyingMessage(null);
            setSelectedMessage(null);
            setTimeout(() => {
              refetchMessages();
              refetchMessageNotifications();
            }, 1700);
          }}
          patients={patients ?? []}
          therapists={therapists ?? []}
          isLoading={isPatientsLoading || isProvidersLoading}
          replyingMessage={replyingMessage}
          setReplyingMessage={setReplyingMessage}
          replyToMessage={replyToMessage}
          defaultRecipientIds={clientId ? [clientId] : []}
          clientId={clientId}
        />
      )}

      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => setComposeMessage(true)}
            style={{ position: 'absolute', top: 10, right: 50, display: 'flex' }}
          >
            <h3>
              <FormattedMessage defaultMessage="COMPOSE" />
            </h3>
            <IconEdit style={{ marginTop: '10px', marginLeft: '4px' }} size={24} />
          </Box>
        </Grid>
      </Grid>

      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            classes={classes}
            numSelected={0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={() => {}}
            onRequestSort={handleRequestSort}
            rowCount={messages.length}
            theme={theme}
            selected={[]}
            handleDelete={() => {}}
            inbox={inbox}
            sent={sent}
          />
          <TableBody>
            {messages.map((row, index) => {
              const { unreadBy, title, sender, receiver, createdAt, file, files, replies } = row;
              const unread = unreadBy === userId;
              const labelId = `enhanced-table-checkbox-${index}`;

              let lastMessage = title;
              let lastMessageDate = createdAt;
              if (replies?.length && replies[replies.length - 1]?.title) {
                lastMessage = replies[replies.length - 1]?.title;
                lastMessageDate = replies[replies.length - 1]?.sentOn;
              }

              let numAttachments = 0;
              if (file) {
                numAttachments = 1;
              }
              if (files?.length) {
                numAttachments += files.length;
              }

              replies.forEach((reply) => {
                if (reply.file) {
                  numAttachments += 1;
                }
                if (reply?.files?.length) {
                  numAttachments += reply.files.length;
                }
              });
              if (numAttachments > 6) {
                numAttachments = 6;
              }

              const getDisplayName = () => {
                if (sender) {
                  const senderName = `${sender.firstName} ${sender.lastName}`;
                  if (inbox && sender._id !== userId) {
                    return senderName;
                  }
                }

                if (receiver) {
                  const receiverName = `${receiver.firstName} ${receiver.lastName}`;
                  return receiverName;
                }

                return '';
              };

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={false}
                  tabIndex={-1}
                  key={index}
                  selected={false}
                  onClick={() => setSelectedMessage(row)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" id={labelId} scope="row" className={unread ? 'tableCellUnread' : 'tableCellRead'}>
                    <Typography variant="body2">
                      {sent && !unreadBy && (
                        <Tooltip title={intl.formatMessage({ defaultMessage: 'Read by recipient' })}>
                          <FiberManualRecord className="checkIcon delivered" />
                        </Tooltip>
                      )}
                      {sent && !!unreadBy && (
                        <Tooltip title={intl.formatMessage({ defaultMessage: 'Unread by recipient' })}>
                          <FiberManualRecord className="checkIcon undelivered" />
                        </Tooltip>
                      )}
                      {getDisplayName()}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '65%' }} className={unread ? 'tableCellUnread' : 'tableCellRead'}>
                    <Typography variant="body2" component="span">
                      {lastMessage}
                    </Typography>
                    {numAttachments > 0 && (
                      <Tooltip title={intl.formatMessage({ defaultMessage: 'Attachment' })}>
                        <span style={{ marginLeft: 8, top: 2, position: 'relative' }}>
                          {Array(numAttachments)
                            .fill(1)
                            .map((value, index) => (
                              <IconPaperclip key={index} width={18} height={18} />
                            ))}
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className={unread ? 'tableCellUnread' : 'tableCellRead'}>
                    <Typography variant="body2">{moment(lastMessageDate).tz(timezone).format('DD MMM YYYY, h:mm:ss a')}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* table pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={intl.formatMessage({ defaultMessage: 'Rows per page' })}
      />
    </>
  );
};

export default MessageTable;
