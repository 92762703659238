import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

export default function useUserRoles() {
  const intl = useIntl();
  const { request, userId } = useAuth();

  const {
    data: userRolesData,
    refetch: refetchUserRoles,
    isLoading: isLoadingUserRoles,
    isRefetching
  } = useQuery(`user-roles`, () => request.get(`user-roles`).then((response) => response.data as UserRolesType), {
    enabled: !!userId
  });

  const { mutateAsync: addUserRole, isLoading: isAddingUserRole } = useMutation(
    `user-roles`,
    (payload: any) => request.post(`user-roles`, payload).then((response) => response.data as any),
    {
      onSuccess: () => refetchUserRoles()
    }
  );

  const { mutateAsync: updateUserRoles, isLoading: isUserRolesUpdating } = useMutation(
    `user-roles`,
    (payload: any) => request.put(`user-roles`, payload).then((response) => response.data),
    {
      onSuccess: () => refetchUserRoles()
    }
  );

  const { mutateAsync: deleteUserRole, isLoading: deletingUserRole } = useMutation(
    `user-roles`,
    (payload: any) => request.delete(`user-roles`, { data: payload }).then((response) => response.data),
    {
      onSuccess: () => refetchUserRoles()
    }
  );

  useEffect(() => {
    if (userId) {
      refetchUserRoles();
    }
  }, [intl.locale, refetchUserRoles, userId]);

  return {
    updateUserRoles,
    userRoles: userRolesData?.userRoles,
    permissionsMap: userRolesData?.permissionsMap,
    refetchUserRoles,
    isLoadingUserRoles,
    isUserRolesUpdating,
    isRefetching,
    addUserRole,
    isAddingUserRole,
    deleteUserRole,
    deletingUserRole
  };
}

export interface UserRolesType {
  userRoles: UserRoleType[];
  permissionsMap: any;
}

export interface UserRoleType {
  _id: string;
  roleName: string;
  roleNameFrench: string;
  isCustomRole?: boolean;
  permissions?: any;
  createdAt?: string;
  updatedAt?: string;
}
