import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';
import useUser from './useUser';

export default function useSignature({ userId, enabled = false }) {
  const { request } = useAuth();
  const { isClient } = useUser(userId);

  const { data, refetch, isLoading } = useQuery(
    `/therapists/${userId}/settings/signature`,
    () =>
      request.get(`/therapists/${userId}/settings/signature`, { responseType: 'blob' }).then((response) => {
        const file = new File([response.data], 'signature', {
          type: response.headers['content-type']
        });

        const preview = URL.createObjectURL(file);

        return { preview, file };
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: enabled === true && !isClient
    }
  );

  const {
    data: clientData,
    refetch: refetchClientSignature,
    isLoading: isLoadingClientData
  } = useQuery(
    `/user/${userId}/settings/signature`,
    () =>
      request.get(`/user/${userId}/settings/signature`, { responseType: 'blob' }).then((response) => {
        const file = new File([response.data], 'signature', {
          type: response.headers['content-type']
        });

        const preview = URL.createObjectURL(file);

        return { preview, file };
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: isClient
    }
  );

  return {
    signature: data?.preview,
    binarySignature: data?.file,
    refetchSignature: refetch,
    isLoading,
    clientSignature: clientData?.preview,
    clientBinarySignature: clientData?.file,
    refetchClientSignature,
    isLoadingClientData
  };
}
