import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useConfig from 'hooks/useConfig';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import NoResults from 'views/components/common/NoResults';

ChartJS.register(Tooltip, Legend, ArcElement, ChartDataLabels);

interface TotalRevenuProps {
  isLoading: boolean;
  labels: any[];
  data: any[];
  title: string;
  isSessionStatus?: boolean;
  textCenter: string;
  setFilter: any;
  filterLabel?: string;
  type: any;
  url?: string;
}

const options = {
  responsive: true,
  maintainAspectRatio: true,
  cutout: '60%',
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        generateLabels: (chart) => {
          const data = chart.data;
          return data.labels.map((label, index) => {
            const value = data.datasets[0].data[index];
            return {
              text: `${label}: ${value}`,
              fillStyle: data.datasets[0].backgroundColor[index],
              hidden: chart.getDatasetMeta(0).data[index].hidden,
              index: index,
              pointStyle: 'circle'
            };
          });
        },
        usePointStyle: true,
        padding: 25,
        font: {
          size: 14
        }
      }
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem: any) {
          const label = tooltipItem.label || '';
          const value = tooltipItem.raw || '';
          return `${label}: ${value}`;
        }
      }
    },
    datalabels: {
      color: '#000',
      font: {
        weight: 'bold' as const
      },
      backgroundColor: '#fff',
      borderRadius: 4,
      padding: 6,
      formatter: (value: any, context: any) => {
        return context.chart.data.datasets[0].data[context.dataIndex];
      }
    }
  }
};

const ChartCard = ({
  isLoading,
  title,
  textCenter: text,
  labels,
  data,
  isSessionStatus = false,
  url,
  filterLabel = 'Filter'
}: TotalRevenuProps) => {
  const theme = useTheme();
  const { rtlLayout } = useConfig();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const intl = useIntl();
  const chartContainerStyles: any = {
    height: matchDownXs ? '250px' : '500px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  };

  const handleNavigate = () => {
    if (url) navigate(url);
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.font = ' 20px sans-serif';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, xCoor, yCoor - 15);
      ctx.font = ' 20px sans-serif';
      ctx.fillStyle = 'black';
      let total = data.datasets[0].data.reduce((acc: number, value: number) => acc + (value ?? 0), 0);
      total = total ? total : 0;
      ctx.fillText(total, xCoor, yCoor + 25);
    }
  };

  const responsiveLegendPlugin = {
    id: 'responsiveLegendPlugin',
    resize: (chart: any) => {
      chart.options.plugins.legend.position = 'bottom';
      chart.update();
    }
  };

  return (
    <MainCard
      title={title}
      content={false}
      secondary={
        <Typography sx={{ position: 'absolute' }}>
          <Box sx={{ position: 'absolute', right: 5, top: 0 }}>
            <Button variant="text" onClick={handleNavigate} endIcon={<ArrowOutwardIcon />}>
              {intl.formatMessage({ defaultMessage: 'More' })}
            </Button>
          </Box>
        </Typography>
      }
    >
      {isLoading ? (
        <Box sx={{ height: matchDownXs ? '250px' : '640px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SkeletonEarningCard />
        </Box>
      ) : (
        <Box>
          {data.length ? (
            <Grid container spacing={2} direction={matchDownMd && !matchDownXs ? 'row' : 'column'} sx={{ marginBottom: '20px' }}>
              <Grid
                item
                xs={12}
                sm={7}
                md={12}
                sx={{ marginBottom: '15px', '& .apexcharts-legend-text': { marginLeft: rtlLayout ? '8px' : 'initial' } }}
              >
                <div style={{ ...chartContainerStyles, marginTop: '20px' }}>
                  <Doughnut
                    key={text}
                    options={options}
                    plugins={[textCenter, responsiveLegendPlugin]}
                    data={{
                      labels,
                      datasets: [{ data, backgroundColor: ['#6a45ff', '#FF4560', '#008FFB', '#FEB019', '#d9512c'], hoverOffset: 4 }]
                    }}
                  />
                </div>
              </Grid>
              <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                <Grid item>
                  <Divider />
                </Grid>
              </Box>
            </Grid>
          ) : (
            <NoResults>
              <FormattedMessage defaultMessage="No Data found." />
            </NoResults>
          )}
        </Box>
      )}
    </MainCard>
  );
};

export default ChartCard;
