import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useProfileOptions from 'hooks/useProfileOptions';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import { getErrorMessage } from 'utils/stringUtils';
import SubCard from 'views/components/cards/SubCard';
import AnimateButton from 'views/components/extended/AnimateButton';
import PopularCard from 'views/components/skeleton/PopularCard';
import MainCard from '../cards/MainCard';
import FormContainer from '../inputs/FormContainer';
import Switch from '../inputs/Switch';

const useStyles = makeStyles((theme: Theme) => ({
  optionContainer: {
    minHeight: 'calc(100vh - 250px)'
  },
  accountAvatar: {
    width: '200px',
    height: '200px',
    margin: '0 auto'
  },
  accountContent: {
    textAlign: 'center'
  },
  btnProfile: {
    width: '100%',
    '&:hover ': {
      color: '#fff'
    },
    '&.MuiButton-outlinedPrimary:hover ': {
      background: theme.palette.primary.main
    },
    '&.MuiButton-outlinedSecondary': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    },
    '&.MuiButton-outlinedSecondary:hover ': {
      background: theme.palette.error.main,
      color: '#fff'
    }
  },
  projectTableCard: {
    padding: '0px',
    paddingBottom: '0px !important',
    overflowX: 'auto'
  },
  bookingAppointmentSelect: {
    textTransform: 'capitalize'
  },
  bookingAppointment: {
    '&.MuiPaper-root li': {
      textTransform: 'capitalize'
    }
  }
}));

interface Props {
  showInCard?: boolean;
}

const ProfileOptions = ({ showInCard = true }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { options } = useOptions();

  const isDirectoryMode = useMemo(() => {
    if (options?.DEFAULT_PROVIDER?.value === '0') {
      return true;
    } else {
      return false;
    }
  }, [options]);

  const { enqueueSnackbar } = useSnackbar();

  const { userId } = useAuth();

  const { profileOptionsMap, updateProfileOptions, isLoading, isRefetching, isPermissionsUpdating } = useProfileOptions();

  const defaultValues = useMemo(() => {
    const profileOptions: any = {};
    if (!profileOptionsMap) return profileOptions;

    Object.values(profileOptionsMap).forEach((permissionGroup: any) => {
      Object.entries(permissionGroup.keys).forEach(([key, values]: any) => {
        profileOptions[key] = values.value;
      });
    });

    return profileOptions;
  }, [profileOptionsMap]);

  const noProfileOptions = useMemo(() => {
    if (isLoading || isRefetching || !profileOptionsMap) return false;

    return !Object.keys(profileOptionsMap)?.length;
  }, [profileOptionsMap, isLoading, isRefetching]);

  const onFormSubmit = async (values) => {
    try {
      const res = await updateProfileOptions({ profileOptions: values });
      enqueueSnackbar(res.message, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  if (isLoading || isRefetching) {
    return <PopularCard />;
  }

  const isShowable = (value, isDirectoryMode) => {
    if (!isDirectoryMode) {
      if (value === 'Clinic Team Settings') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const Options = () => (
    <Box className={classes.optionContainer} p={gridSpacing}>
      <Grid container spacing={gridSpacing}>
        <Grid item sm={12}>
          <FormContainer defaultValues={defaultValues} onSuccess={onFormSubmit}>
            <SubCard
              className={classes.projectTableCard}
              title={
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                  <Box>
                    <FormattedMessage defaultMessage="Profile Options" />
                  </Box>

                  {!noProfileOptions && (
                    <AnimateButton>
                      <Button type="submit" variant="contained">
                        <FormattedMessage defaultMessage="Save" />
                      </Button>
                    </AnimateButton>
                  )}
                </Stack>
              }
              content={false}
              sx={{
                '& .MuiPaper-root': {
                  marginTop: '0px !important'
                },
                '& .MuiAccordionSummary-root': {
                  minHeight: '48px !important'
                },
                '& .Mui-expanded': {
                  margin: '0px !important',
                  minHeight: ''
                }
              }}
            >
              {!!profileOptionsMap &&
                Object.entries(profileOptionsMap).map(([groupKey, groupValues]: any) => {
                  return (
                    <>
                      {isShowable(groupValues.name, isDirectoryMode) ? (
                        <Accordion key={groupKey}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: groupValues.color }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{groupValues.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer
                              sx={{ borderWidth: 2, borderColor: groupValues.color, borderStyle: 'solid', borderRadius: 3, my: 3 }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ pl: 3 }}>
                                      <FormattedMessage defaultMessage="Name" />
                                    </TableCell>
                                    <TableCell>
                                      <FormattedMessage defaultMessage="Description" />
                                    </TableCell>
                                    <TableCell sx={{ px: 3 }}>
                                      <FormattedMessage defaultMessage="Actions" />
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.entries(groupValues.keys).map(([key, row]: any) => {
                                    return (
                                      <TableRow hover key={key}>
                                        <TableCell sx={{ py: 1, pl: 3 }}>{row.name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.description}</TableCell>
                                        <TableCell sx={{ py: 1, pr: 3 }}>
                                          <Switch name={key} color="primary" />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}

              {noProfileOptions && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                  <Typography sx={{ fontWeight: '500' }}>
                    <FormattedMessage defaultMessage="No Profile Options Available" />
                  </Typography>
                </Box>
              )}

              {!noProfileOptions && (
                <Box p={gridSpacing}>
                  <Stack direction="row" justifyContent="flex-end">
                    <AnimateButton>
                      <Button type="submit" variant="contained">
                        <FormattedMessage defaultMessage="Save" />
                      </Button>
                    </AnimateButton>
                  </Stack>
                </Box>
              )}
            </SubCard>
          </FormContainer>
        </Grid>
      </Grid>
    </Box>
  );

  if (showInCard) {
    return (
      <MainCard>
        <Options />
      </MainCard>
    );
  }

  return <Options />;
};

export default ProfileOptions;
