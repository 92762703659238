import { ComponentProps, memo } from 'react';
import { Controller } from 'react-hook-form';
import QuillEditor from './QuillEditor';

interface Props extends Partial<ComponentProps<typeof QuillEditor>> {
  name: string;
  editorRef?: any;
}

const QuillEditorController = ({ name, ...props }: Props) => (
  <Controller name={name} render={({ field }) => <QuillEditor {...props} {...field} ref={null} />} />
);

export default memo(QuillEditorController);
