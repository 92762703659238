import { Box, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import CenteredLoading from 'views/components/CenteredLoading';
import useResource from './useResources';

function CategoriesList() {
  const { category } = useParams();
  const { resources, isLoading } = useResource();
  const navigate = useNavigate();

  const selectedCategory = useMemo(() => {
    return resources?.find((item) => item.url === category);
  }, [resources, category]);

  return (
    <>
      {isLoading ? (
        <CenteredLoading />
      ) : (
        <>
          {selectedCategory ? (
            <Box>
              <Grid item sx={{ width: 300, minHeight: '100%' }}>
                <Typography
                  sx={{
                    fontSize: 25,
                    color: '#1e2028',
                    fontWeight: 'bold',
                    lineHeight: '1.2',
                    mt: 2
                  }}
                >
                  <FormattedMessage defaultMessage="Categories" />
                </Typography>

                {resources?.map((item: any) => (
                  <Typography
                    key={item.url}
                    sx={{
                      textAlign: 'left',
                      color: item.url === selectedCategory?.url ? '#4b7bb7' : '#858585',
                      fontWeight: item.url === selectedCategory?.url ? 'bold' : '',
                      backgroundColor: item.url === selectedCategory?.url ? '#e2f2fd' : '',
                      padding: item.url === selectedCategory?.url ? 1 : 1,
                      borderRadius: item.url === selectedCategory?.url ? 3 : 3,
                      width: item.url === selectedCategory?.url ? 280 : 280,
                      cursor: 'pointer',
                      fontSize: 17,
                      display: 'flex',
                      flexWrap: 'wrap',
                      mt: 1,
                      '&:hover': {
                        color: '#4b7bb7',
                        backgroundColor: '#e2f2fd'
                      }
                    }}
                    onClick={() => {
                      navigate(`/resources/${item.url}`);
                    }}
                  >
                    {item.title}
                  </Typography>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box height={600} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>
                <FormattedMessage defaultMessage="Category not found" />
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default CategoriesList;
