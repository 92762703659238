import { Typography } from '@mui/material';

export default function VolumeMeter({ average, width = 200, height = 20 }) {
  const getColor = (index) => `#ff${55 - index * 5}00`;

  if (average > 10) {
    average = 10;
  }
  if (average < 0) {
    average = 0;
  }
  average = Math.floor(average);

  return (
    <div>
      <Typography fontSize={12}>Mic Volume</Typography>
      <div
        style={{
          display: 'grid',
          width,
          gridTemplateColumns: 'auto auto auto auto auto auto auto auto auto auto',
          height,
          border: '1px solid #aaa'
        }}
      >
        {Array(average)
          .fill(1)
          .map((obj, index) => (
            <div
              style={{
                backgroundColor: getColor(index),
                margin: 1
              }}
            />
          ))}
      </div>
    </div>
  );
}
