/* eslint-disable */
import currency from 'currency.js';
import emailValidator from 'email-validator';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { createSelector } from 'reselect';
import { Status } from 'types/appointment';
import { User } from 'types/user';

export function formatAmount(amount) {
  return currency(amount, {
    precision: 2,
    symbol: '$'
  }).format();
}

export const combineSelectors = (obj) => (store) => {
  const res = {};
  for (const name in obj) {
    res[name] = obj[name](store);
  }
  return res;
};

const dataUiSelector = (f) => (dataSel, uiSel) => createSelector(dataSel, uiSel, f);

const uiSelector = (f) => (uiSel) => createSelector(uiSel, f);

const prepareArrayField = ({ value, dirty, focused, ...rest }) => {
  const empty = dirty && (!value || !Array.isArray(value) || value.length === 0) && !focused;
  return {
    value: value || [],
    dirty,
    focused,
    empty,
    ...rest
  };
};

const prepareTextField = ({ value, dirty, focused, ...rest }) => {
  const empty = dirty && value === '' && !focused;
  return {
    value: value ? value.toString() : '',
    dirty,
    focused,
    empty,
    ...rest
  };
};

const prepareEmailField = ({ value, dirty, focused, ...rest }) => {
  const { empty } = prepareTextField({ value, dirty, focused });
  const invalid = dirty && !empty && !focused && !emailValidator.validate(value);
  return {
    value,
    dirty,
    focused,
    empty,
    invalid,
    ...rest
  };
};

const prepareEmailArrayField = ({ value, dirty, focused, ...rest }) => {
  const { empty, value: newValue } = prepareArrayField({
    value,
    dirty,
    focused,
    ...rest
  });
  const invalid = dirty && !empty && !focused && newValue.reduce((v, i) => v || !emailValidator.validate(i), false);
  return {
    ...rest,
    value: newValue,
    empty,
    invalid,
    dirty,
    focused
  };
};

const makeDataUiFieldSelector = (prep) =>
  dataUiSelector((dataValue, { value, dirty, ...rest }) =>
    prep({
      value: dirty ? value : dataValue,
      dirty,
      ...rest
    })
  );

const makePlainUiFieldSelector = (prep) => uiSelector(prep);

export const textFieldSelector = makeDataUiFieldSelector(prepareTextField);
export const plainTextFieldSelector = makePlainUiFieldSelector(prepareTextField);
export const plainEmailFieldSelector = makePlainUiFieldSelector(prepareEmailField);
export const emailFieldSelector = makeDataUiFieldSelector(prepareEmailField);
export const emailArrayFieldSelector = makePlainUiFieldSelector(prepareEmailArrayField);
export const boolFieldSelector = makeDataUiFieldSelector(({ value, ...rest }) => ({
  value: Boolean(value),
  ...rest
}));

export const timeFieldSelector = dataUiSelector(({ hour, minute }, { value, dirty }) => {
  const time = moment().tz('est').hour(hour).minute(minute).startOf('minute');

  return { value: dirty ? value : time };
});

export const autocompleteSelector = dataUiSelector((dataValues, { value, autocompleteResults, dirty }) => ({
  value: dirty ? value : dataValues,
  autocompleteResults
}));

export const plainAutoCompleteSelector = makePlainUiFieldSelector(({ value, autocompleteResults, dirty }) => ({
  value: dirty ? value : '',
  autocompleteResults
}));

export const tagAutoCompleteSelector = dataUiSelector((dataValues, { value, additionalTags, dirty }) => ({
  value: dirty ? value : dataValues,
  additionalTags: dirty ? additionalTags : []
}));

const workDayReducer = (dataSel, uiSel) => (s) => ({
  include: boolFieldSelector(
    (s) => dataSel(s).include,
    (s) => uiSel(s).include
  )(s),
  startTime: timeFieldSelector(
    (s) => dataSel(s).startTime,
    (s) => uiSel(s).startTime
  )(s),
  endTime: timeFieldSelector(
    (s) => dataSel(s).endTime,
    (s) => uiSel(s).endTime
  )(s),
  office: textFieldSelector(
    (s) => dataSel(s).office,
    (s) => uiSel(s).office
  )(s)
});

export const generateProviderServiceTypes = (provider: User, includeRate: boolean, filterForClient = true, intl) => {
  if (!provider) {
    return [];
  }

  const {
    provider: { services = [] }
  } = provider;

  return services
    .filter((s) => !filterForClient || s.allowInAppointments)
    .map((s) => ({
      ...s,
      label: `${intl.locale === 'fr' ? s.nameFrench || s.name : s.name || s.nameFrench} [${s.durationInMinutes} min] ${
        includeRate ? ` (${formatAmount(s.rate)})` : ''
      }`,
      value: JSON.stringify(s._id),
      show: true
    }));
};

export const scheduleSelector = (dataSel, uiSel) => (s) => ({
  0: workDayReducer(
    (s) => dataSel(s)[0],
    (s) => uiSel(s)[0]
  )(s),
  1: workDayReducer(
    (s) => dataSel(s)[1],
    (s) => uiSel(s)[1]
  )(s),
  2: workDayReducer(
    (s) => dataSel(s)[2],
    (s) => uiSel(s)[2]
  )(s),
  3: workDayReducer(
    (s) => dataSel(s)[3],
    (s) => uiSel(s)[3]
  )(s),
  4: workDayReducer(
    (s) => dataSel(s)[4],
    (s) => uiSel(s)[4]
  )(s),
  5: workDayReducer(
    (s) => dataSel(s)[5],
    (s) => uiSel(s)[5]
  )(s),
  6: workDayReducer(
    (s) => dataSel(s)[6],
    (s) => uiSel(s)[6]
  )(s)
});

export const roleNameSelector = (role: string, intl: any) => {
  switch (role) {
    case 'CLIENT':
      return 'Client';
    case 'PROVIDER_ADMINISTRATOR':
      return intl.locale === 'fr' ? 'ADMINISTRATEUR_FOURNISSEUR' : 'PROVIDER_ADMINISTRATOR';
    case 'PROVIDER_ADMINISTRATOR (S)':
      return intl.locale === 'fr' ? 'ADMINISTRATEUR_FOURNISSEUR (S)' : 'PROVIDER_ADMINISTRATOR (S)';
    case 'PROVIDER_ADMINISTRATOR (D)':
      return intl.locale === 'fr' ? 'ADMINISTRATEUR_FOURNISSEUR (D)' : 'PROVIDER_ADMINISTRATOR (D)';
    case 'PROVIDER':
      return intl.locale === 'fr' ? 'Fournisseur' : 'Provider';
    case 'ADMINISTRATOR':
      return intl.locale === 'fr' ? 'Administrateur' : 'Administrator';
    case 'NOUS_SUPPORT':
      return 'Nous Support';
    default:
      return _.startCase(_.toLower(role.replace('_', ' ')));
  }
};

export const memberStatusSelector = (status: Status) => {
  switch (status) {
    case Status.Pending:
      return 'waiting for confirmation.';
    case Status.Accepted:
      return 'confirmed';
    case Status.NoShow:
      return 'no show';
    case Status.Canceled:
      return 'canceled';
    case Status.LateCanceled:
      return 'late canceled';
    default:
      return '';
  }
};
