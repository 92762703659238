// material-ui
import { Button, ButtonGroup, Grid, GridProps, IconButton, Stack, Tooltip, Typography } from '@mui/material';
// assets
import { IconChevronLeft, IconChevronRight, IconLayoutList, IconTemplate } from '@tabler/icons';
// third-party
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import { FormattedMessage, useIntl } from 'react-intl';

// constant
const viewOptions: any[] = [
  {
    label: 'Week',
    value: 'resourceTimelineWeek',
    duration: { days: 1 },
    format: { weekday: 'short', year: undefined, month: '2-digit', day: '2-digit', omitCommas: true },
    icon: IconTemplate
  },
  {
    label: 'Day',
    value: 'resourceTimeline',
    duration: { hours: 1 },
    format: { hour: 'numeric', minute: '2-digit', omitZeroMinute: true, meridiem: 'short' },
    icon: IconLayoutList
  }
];

// ==============================|| CALENDAR TOOLBAR ||============================== //

export interface ToolbarProps {
  date: number | Date;
  view: string;
  onClickNext: () => void;
  onClickPrev: () => void;
  onClickToday: () => void;
  onChangeView: (s: string, d: any) => void;
  setDuration: any;
  setSlotLabelFormat: any;
  sx?: GridProps['sx'];
}

const ClinicToolbar = ({
  date,
  view,
  onClickNext,
  onClickPrev,
  onClickToday,
  onChangeView,
  setDuration,
  setSlotLabelFormat,
  sx,
  ...others
}: ToolbarProps) => {
  const intl = useIntl();
  const locale = intl.locale === 'fr' ? frLocale : enLocale;

  return (
    <Grid alignItems="center" container justifyContent="space-between" spacing={3} {...others} sx={{ pb: 3 }}>
      <Grid item>
        <Button variant="outlined" onClick={onClickToday} sx={{ visibility: view === 'listWeek' ? 'hidden' : 'visible' }}>
          <FormattedMessage defaultMessage="Today" />
        </Button>
      </Grid>
      <Grid item>
        <Stack direction="row" alignItems="center" spacing={3}>
          <IconButton onClick={onClickPrev}>
            <IconChevronLeft />
          </IconButton>
          <Typography variant="h3" color="textPrimary">
            {view === 'resourceTimeline' ? format(date, 'PP', { locale }) : format(date, 'MMMM yyyy', { locale })}
          </Typography>
          <IconButton onClick={onClickNext}>
            <IconChevronRight />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {viewOptions.map((viewOption) => {
            const Icon = viewOption.icon;
            return (
              <Tooltip title={viewOption.label} key={viewOption.value}>
                <Button
                  disableElevation
                  variant={viewOption.value === view ? 'contained' : 'outlined'}
                  onClick={() => {
                    onChangeView(viewOption.value, date);
                    setDuration(viewOption.duration);
                    setSlotLabelFormat(viewOption.format);
                  }}
                >
                  <Icon stroke="2" size="1.3rem" />
                </Button>
              </Tooltip>
            );
          })}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default ClinicToolbar;
