/* eslint-disable no-nested-ternary */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField as MUITextField,
  Typography
} from '@mui/material';
import { addHours, format, isAfter, subHours, subMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isBefore } from 'date-fns/esm';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useAuth from 'hooks/useAuth';
import useProvider from 'hooks/useProvider';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useSearchParam } from 'react-use';
import { Appointment } from 'types/appointment';
import { memberStatusSelector, roleNameSelector } from 'utils/selectors';
import { getErrorMessage, stripeHtml } from 'utils/stringUtils';
import DialogClose from 'views/components/buttons/DialogClose';
import FormContainer from 'views/components/inputs/FormContainer';
import Loader from '../../components/Loader';
import ContactProviderDialog from './ContactProviderDialog';
import EditAppointment from './EditAppointment';
import RescheduleAppointment from './RescheduleAppointment';

interface Props {
  appointment: Appointment;
  baseUrl: string;
  onCancelSuccess: () => void;
  onUpdateSuccess: () => void;
  onClose: () => void;
}

type Mode = 'view' | 'cancel' | 'cannot-attend' | 'edit' | 'reschedule' | 'editFor';

export default function AppointmentDetail({ appointment, baseUrl, onCancelSuccess, onUpdateSuccess, onClose }: Props) {
  const { appointmentId, detailMode } = useParams();
  const navigate = useNavigate();
  const mode = (detailMode ?? 'view') as Mode;
  const [cancelMessage, setCancelMessage] = React.useState('');
  const [isChargeApplied, setIsChargeApplied] = React.useState('yes');
  const [cancelFuture, setCancelFuture] = useState(false);
  const [helperText, setHelperText] = React.useState('Choose wisely');
  const [cancellationCharge, setCancellationCharge] = React.useState('0');
  const [isChargeEdited, setIsChargeEdited] = React.useState(false);
  const { userId: idFromParams } = useParams();
  const therapistId = useSearchParam('therapist');
  const { request, userId: id } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const userId = therapistId ?? idFromParams ?? id;
  const { isProvider, isNousSupport, isClient } = useUser(userId);
  const { user: currentUser } = useUser(id);
  const intl = useIntl();
  const timeZone = currentUser?.settings?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { providerId, date: dateStr } = useParams();
  const { provider } = useProvider(userId, providerId);

  const cancelAppointmentMutation = useMutation(
    ({
      cancelMessage,
      isChargeApplied,
      cancellationCharge,
      cancelFuture = false
    }: {
      cancelMessage: string;
      isChargeApplied: string;
      cancellationCharge: string;
      cancelFuture: boolean;
    }) =>
      request.post(`/appointments/${appointment.event_id}/cancel`, {
        userId,
        cancelMessage,
        isChargeApplied,
        cancellationCharge,
        cancelFuture
      }),
    {
      onSuccess() {
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment canceled!' }), { variant: 'success' });
        onCancelSuccess();
      },
      onError(e: any) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }
    }
  );

  const cannotAttendMutation = useMutation(
    ({ cancelMessage }: { cancelMessage: string }) =>
      request.post(`/appointments/${appointment.event_id}/cannot-attend`, {
        cancelMessage,
        therapistId: userId
      }),
    {
      onSuccess() {
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment rejected!' }), { variant: 'success' });
        onCancelSuccess();
      },
      onError(e: any) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }
    }
  );

  const acceptMutation = useMutation(() => request.post(`/appointments/${appointment.event_id}/accept`, { userId }), {
    onSuccess() {
      enqueueSnackbar('Appointment has been accepted!', { variant: 'success' });
      onUpdateSuccess();
    },
    onError(e: any) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  });

  const resendInvitesMutation = useMutation(() => request.post(`/appointments/${appointment.event_id}/resend-invites`), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment has been accepted!' }), { variant: 'success' });
    },
    onError(e: any) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChargeApplied((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value === 'edited') {
      setIsChargeEdited(true);
    } else {
      setIsChargeEdited(false);
    }
    // setHelperText(`Your Cancelation Charge is: $${currentUser?.provider?.cancellationPolicy?.charge}`);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setCancellationCharge((event.target as HTMLInputElement).value);
  // };
  const isOnlineSession = appointment?.locationType === 'Online';
  const isOwner = appointment.owner === userId;
  const participant = appointment?.members?.find((member) => member.id === userId);
  const isPending = participant?.status === 'PENDING' || participant?.isPending;
  const participantsAccepted =
    participant?.status?.match(/ATTENDED/i) ||
    participant?.status?.match(/accepted/i) ||
    (isOwner && (!isPending || participant.status === 'ACCEPTED'));
  const isRegisteredSession = appointment.members?.some((member) => member.role === 'CLIENT');

  const responseRequired = appointment.contextStatus?.match(/pending/i) && !participantsAccepted;

  // If at least one client has confirmed
  const isEnableBegin = isRegisteredSession
    ? appointment?.members?.some(
        (member) => member.role === 'CLIENT' && (member?.status?.match(/ATTENDED/i) || member?.status?.match(/accepted/i))
      )
    : appointment?.members?.every((member) => member?.status?.match(/ATTENDED/i) || member?.status?.match(/accepted/i));

  const currentUserCancellationPolicy = Number(
    provider?.provider?.cancellationPolicy?.time || currentUser?.provider?.cancellationPolicy?.time || 0
  );
  // CancellationPolicy feeType can be "percentage" or "flat-rate"
  const currentUserCancellationCharge =
    provider?.provider?.cancellationPolicy?.feeType === 'percentage'
      ? (Number(appointment?.serviceType?.rate || 0) * Number(provider?.provider?.cancellationPolicy?.charge || 0)) / 100
      : Number(provider?.provider?.cancellationPolicy?.charge || 0);
  const now = new Date();
  const cancellationPolicyTime = isAfter(now, subHours(new Date(appointment.start), currentUserCancellationPolicy));

  const isNow = isAfter(now, subMinutes(new Date(appointment.start), 30)) && isBefore(now, new Date(appointment.end));
  const appointmentDuration = moment(appointment.end).diff(moment(appointment.start), 'minutes');
  const [showPopup, setShowPopup] = useState(false);
  const clinicalHours = provider?.provider.allowInBetweenAppointmentsTime;
  const originalDuration = appointment.serviceType?.durationInMinutes;
  const showBegin = !isNousSupport && isNow && isOnlineSession && (isEnableBegin || isProvider);
  const showRejoin =
    participant?.status.match(/ATTENDED/i) &&
    (isEnableBegin || isProvider) &&
    isOnlineSession &&
    isAfter(now, subMinutes(new Date(appointment.start), 30)) &&
    isBefore(now, addHours(new Date(appointment.end), 2));
  const showCancelEdit = isBefore(now, addHours(new Date(appointment.end), 2));
  const participants = useMemo(
    () => appointment.members?.filter((member) => !member.isProviderOfRecord || !member.isAssociateTherapist),
    [appointment.members]
  );

  const inactiveParticipant = participants?.find((member) => member.inactive === true);

  const associateTherapists = appointment.members?.filter((member) => member.isAssociateTherapist && member.isProviderOfRecord);

  const closePopup = () => {
    setShowPopup(false);
  };

  const RecurringInfo = ({ repeatInfo = '' }) => {
    return (
      <Typography
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
          p: 0.6,
          ml: 1,
          borderRadius: 5,
          fontSize: 12,
          bottom: 3,
          position: 'relative'
        })}
        component="span"
      >
        Recurring {repeatInfo}
      </Typography>
    );
  };
  const { showModal } = useModal();

  return (
    <>
      {showPopup && isProvider && (
        <Dialog open>
          <FormContainer>
            <DialogTitle sx={{ pt: 0, width: '95%' }}>
              <FormattedMessage defaultMessage="Reminder" />
              <DialogClose onClose={closePopup} />
            </DialogTitle>

            <DialogContent dividers>
              <FormattedMessage defaultMessage="Your client is  currently inactive. Please reactivate before confirming the session. " />
            </DialogContent>

            <DialogActions sx={{ pt: 2, px: 2 }}></DialogActions>
          </FormContainer>
        </Dialog>
      )}

      {cancelAppointmentMutation.isLoading && <Loader />}
      {isOwner && (
        <DialogTitle sx={{ width: '95%' }}>
          {appointment.summary} <FormattedMessage defaultMessage="on" />{' '}
          {format(new Date(appointment.start), intl.locale === 'fr' ? 'do MMMM ' : 'MMMM do', {
            locale: intl.locale === 'fr' ? frLocale : enLocale
          })}{' '}
          <FormattedMessage defaultMessage={'from'} />{' '}
          {format(utcToZonedTime(appointment.start, timeZone), intl.locale === 'fr' ? 'HH:mm' : 'h:mm a', {
            locale: intl.locale === 'fr' ? frLocale : enLocale
          })}{' '}
          <FormattedMessage defaultMessage={'to'} />{' '}
          {format(utcToZonedTime(appointment.end, timeZone), intl.locale === 'fr' ? 'HH:mm' : 'h:mm a', {
            locale: intl.locale === 'fr' ? frLocale : enLocale
          })}
          {!!appointment.repeatInfo && <RecurringInfo repeatInfo={appointment.repeatInfo} />}
          <Typography sx={{ fontSize: 12 }}>{appointment.serviceType?.name || ''}</Typography>
          <DialogClose onClose={onClose} />
        </DialogTitle>
      )}
      {(!isOwner && clinicalHours && appointmentDuration === 60 && originalDuration === 50 && (
        <DialogTitle sx={{ width: '95%' }}>
          {appointment.summary} on {format(new Date(appointment.start), 'MMMM do')} <FormattedMessage defaultMessage={'from'} />{' '}
          {format(utcToZonedTime(appointment.start, timeZone), intl.locale === 'fr' ? 'HH:mm' : 'h:mm a', {
            locale: intl.locale === 'fr' ? frLocale : enLocale
          })}{' '}
          <FormattedMessage defaultMessage={'to'} />{' '}
          {format(utcToZonedTime(appointment.end, timeZone), intl.locale === 'fr' ? 'HH:mm' : 'h:mm a', {
            locale: intl.locale === 'fr' ? frLocale : enLocale
          })}
          {!!appointment.repeatInfo && <RecurringInfo repeatInfo={appointment.repeatInfo} />}
          <DialogClose onClose={onClose} />
        </DialogTitle>
      )) ||
        (!isOwner && (
          <DialogTitle sx={{ width: '95%' }}>
            {appointment.summary} on {format(new Date(appointment.start), 'MMMM do')} <FormattedMessage defaultMessage={'from'} />{' '}
            {format(utcToZonedTime(appointment.start, timeZone), intl.locale === 'fr' ? 'HH:mm' : 'h:mm a', {
              locale: intl.locale === 'fr' ? frLocale : enLocale
            })}{' '}
            <FormattedMessage defaultMessage={'to'} />{' '}
            {format(utcToZonedTime(appointment.end, timeZone), intl.locale === 'fr' ? 'HH:mm' : 'h:mm a', {
              locale: intl.locale === 'fr' ? frLocale : enLocale
            })}
            {!!appointment.repeatInfo && <RecurringInfo repeatInfo={appointment.repeatInfo} />}
            <DialogClose onClose={onClose} />
          </DialogTitle>
        ))}

      <Divider />

      {mode === 'view' && (
        <>
          <DialogContent sx={{ p: 3 }}>
            <Typography>{stripeHtml(appointment.description ?? '')}</Typography>
            {isProvider && (
              <>
                <Stack spacing={1} sx={{ mt: 3 }}>
                  <Typography variant="h5">
                    <FormattedMessage defaultMessage="Participants" />:
                  </Typography>
                  {participants?.map((member) => (
                    <Stack direction="row" spacing={1} key={member.id}>
                      <Typography>-</Typography>
                      <Typography sx={{ fontWeight: 'bold' }}>{member.fullName}</Typography>
                      <Typography sx={{ textTransform: 'lowercase' }}>{memberStatusSelector(member.status)}</Typography>
                      {member.role === 'CLIENT' && (
                        <Typography sx={{ textTransform: 'lowercase', color: 'red' }}>{member.inactive ? '[Inactive]' : ''}</Typography>
                      )}

                      {member.role !== 'CLIENT' && <Typography>({roleNameSelector(member.role, intl)})</Typography>}
                    </Stack>
                  ))}
                </Stack>

                {!!associateTherapists?.length && (
                  <Stack spacing={1} sx={{ mt: 2 }}>
                    <Typography variant="h5">
                      <FormattedMessage defaultMessage="Associate Providers" />:
                    </Typography>
                    {associateTherapists?.map((member) => (
                      <Stack direction="row" spacing={1} key={member.id}>
                        <Typography>-</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{member.fullName}</Typography>
                        <Typography sx={{ textTransform: 'lowercase' }}>{memberStatusSelector(member.status)}</Typography>
                        {member.role !== 'CLIENT' && <Typography>({roleNameSelector(member.role, intl)})</Typography>}
                      </Stack>
                    ))}
                  </Stack>
                )}

                <Stack spacing={1} sx={{ mt: 2 }}>
                  <Typography variant="h5">
                    <FormattedMessage defaultMessage="Non-registered Participants:" />
                  </Typography>
                  {!appointment?.emailsNewShow?.length && (
                    <Typography>
                      <FormattedMessage defaultMessage="No non-registered participants" />
                    </Typography>
                  )}
                  {appointment?.emailsNewShow?.map?.((email, key) =>
                    email?.isContactMe === 'false' ? (
                      <Stack direction="row" spacing={1} key={key}>
                        <Typography>-</Typography>
                        <Typography>{email?.email}</Typography>
                      </Stack>
                    ) : (
                      ''
                    )
                  )}
                </Stack>
              </>
            )}
          </DialogContent>
          <Divider />
          <DialogActions sx={{ py: 2, px: 3 }}>
            {acceptMutation.isLoading && <Loader />}
            <Stack direction="column" spacing={2} alignItems="flex-end">
              <Stack direction="row" spacing={2} alignItems="center">
                {showCancelEdit && (
                  <>
                    {isOwner && (
                      <Button
                        type="button"
                        variant="contained"
                        disabled={resendInvitesMutation.isLoading}
                        onClick={() => resendInvitesMutation.mutate()}
                      >
                        <FormattedMessage defaultMessage="Resend" />
                      </Button>
                    )}

                    {isClient || isOwner || (!isOwner && !participant) ? (
                      <Button
                        type="button"
                        disabled={!isProvider && !currentUser?.clientOptions?.canClientRequestAppointment}
                        color="error"
                        variant="outlined"
                        onClick={() => {
                          if (!isProvider && cancellationPolicyTime) {
                            showModal(ContactProviderDialog);
                          } else {
                            navigate(`${baseUrl}/${appointmentId}/cancel`);
                          }
                        }}
                      >
                        {responseRequired ? (
                          <FormattedMessage defaultMessage="Reject Session" />
                        ) : (
                          <FormattedMessage defaultMessage="Cancel Session" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        disabled={!isProvider && !currentUser?.clientOptions?.canClientRequestAppointment}
                        color="error"
                        variant="outlined"
                        onClick={() => navigate(`${baseUrl}/${appointmentId}/cannot-attend`)}
                      >
                        <FormattedMessage defaultMessage="Cannot Attend" />
                      </Button>
                    )}
                  </>
                )}
                {showCancelEdit && (
                  <>
                    {isProvider ? (
                      <>
                        {(isOwner || !participant) && (
                          <Button type="button" variant="outlined" onClick={() => navigate(`${baseUrl}/${appointmentId}/edit`)}>
                            <FormattedMessage defaultMessage="Edit Session" />
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Button
                          type="button"
                          disabled={
                            !currentUser?.clientOptions?.canClientRequestAppointment ||
                            (isClient && cancellationPolicyTime) ||
                            appointment.multipleClinicians
                          }
                          variant="outlined"
                          onClick={() => navigate(`${baseUrl}/${appointmentId}/reschedule`)}
                        >
                          <FormattedMessage defaultMessage="Reschedule" />
                        </Button>
                      </>
                    )}
                  </>
                )}

                {responseRequired && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={acceptMutation.isLoading}
                    onClick={() => {
                      if (inactiveParticipant) {
                        setShowPopup(true);
                      } else {
                        acceptMutation.mutate();
                      }
                    }}
                  >
                    <FormattedMessage defaultMessage="Confirm Session" />
                  </Button>
                )}

                {(showBegin || showRejoin) && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.replace(`/session/${appointment.sessionId}`);
                      // navigate(`/session/${appointment.sessionId}`);
                    }}
                  >
                    {showRejoin ? (
                      <FormattedMessage defaultMessage="Rejoin Session" />
                    ) : (
                      <FormattedMessage defaultMessage="Begin Session" />
                    )}
                  </Button>
                )}
              </Stack>
              <Stack direction="row" spacing={2} alignItems="flex-start">
                {!isProvider && !currentUser?.clientOptions?.canClientRequestAppointment && (
                  <FormattedMessage defaultMessage="Please contact your Provider directly to cancel or reschedule your appointment." />
                )}
              </Stack>
            </Stack>
          </DialogActions>
        </>
      )}

      {mode === 'cancel' && (
        <>
          <DialogContent sx={{ p: 3 }}>
            {isClient && cancellationPolicyTime ? (
              <Typography sx={{ pb: 0.5 }}>
                <FormattedMessage
                  values={{ cancellationPolicyHours: provider?.provider?.cancellationPolicy?.time }}
                  defaultMessage={`Your Provider has a {cancellationPolicyHours} hour cancellation policy. A late-cancellation fee may be charged.`}
                />
              </Typography>
            ) : (
              <Typography>
                {responseRequired ? (
                  <FormattedMessage defaultMessage="Are you sure you want to reject this appointment?" />
                ) : (
                  <FormattedMessage defaultMessage="Are you sure you want to cancel this appointment?" />
                )}
              </Typography>
            )}

            {isProvider && (
              <Stack direction="row" sx={{ mx: 3, mt: 2 }} spacing={2} justifyContent="flex-start">
                <RadioGroup defaultValue={intl.formatMessage({ defaultMessage: 'This session only' })} value={cancelFuture}>
                  <FormControlLabel
                    value="This session only"
                    checked={!cancelFuture}
                    control={<Radio onClick={() => setCancelFuture(false)} />}
                    label={intl.formatMessage({ defaultMessage: 'This session only' })}
                  />
                  <FormControlLabel
                    value="All future sessions"
                    checked={cancelFuture}
                    control={<Radio onClick={() => setCancelFuture(true)} />}
                    label={intl.formatMessage({ defaultMessage: 'All future sessions (if any)' })}
                  />
                </RadioGroup>
              </Stack>
            )}

            <MUITextField
              label={intl.formatMessage({ defaultMessage: 'Include a message for your session participant(s)' })}
              fullWidth
              multiline
              rows={5}
              value={cancelMessage}
              onChange={(e) => setCancelMessage(e.target.value)}
              sx={{ mt: 2 }}
            />
            {isOwner && cancellationPolicyTime && isRegisteredSession && (
              <div>
                <FormControl sx={{ mt: 2 }} variant="standard">
                  <FormLabel id="check-cancellation-charge-radios">
                    <FormattedMessage defaultMessage="Do you wish to apply a cancellation charge?" />
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="check-cancellation-charge-radios"
                    name="isCancellationChargeAppplied"
                    value={isChargeApplied}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label={intl.formatMessage({ defaultMessage: `Yes` })} />
                    {/*
                    
                    /--------------------------------------------------------------------------------------------------------------/
                    Please Do not remove this code; I have noticed an issue with this workflow that was working as expected before. 
                    /--------------------------------------------------------------------------------------------------------------/

                    <FormControlLabel value="edited" control={<Radio />} label="Yes - Edit Cancellation Charge" />
                    {isChargeEdited && (
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          value={cancellationCharge}
                          onChange={handleChange}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Amount"
                        />
                      </FormControl>
                    )} */}
                    <FormControlLabel value="no" control={<Radio />} label={intl.formatMessage({ defaultMessage: 'No' })} />
                  </RadioGroup>
                  {/* <FormHelperText>{helperText}</FormHelperText> */}
                </FormControl>
              </div>
            )}
          </DialogContent>
          <Divider />
          <DialogActions sx={{ py: 2, px: 3 }}>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Button
                type="button"
                variant="outlined"
                onClick={() => navigate(`${baseUrl}/${appointmentId}`)}
                disabled={cancelAppointmentMutation.isLoading}
              >
                <FormattedMessage defaultMessage="No, Go back" />
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={() =>
                  cancelAppointmentMutation.mutate({
                    cancelMessage,
                    isChargeApplied: cancellationPolicyTime && isRegisteredSession ? isChargeApplied : 'no',
                    cancellationCharge: String(currentUserCancellationCharge),
                    cancelFuture
                  })
                }
                disabled={cancelAppointmentMutation.isLoading}
              >
                {responseRequired ? (
                  <FormattedMessage defaultMessage="Yes, Reject Session" />
                ) : (
                  <FormattedMessage defaultMessage="Yes, Cancel Session" />
                )}
              </Button>
            </Stack>
          </DialogActions>
        </>
      )}
      {mode === 'cannot-attend' && (
        <>
          <DialogContent sx={{ p: 3 }}>
            <Typography>
              <FormattedMessage defaultMessage="Are you sure you cannot attend this appointment?" />
            </Typography>

            <MUITextField
              label={intl.formatMessage({ defaultMessage: 'Include a message for the Provider' })}
              fullWidth
              multiline
              rows={5}
              value={cancelMessage}
              onChange={(e) => setCancelMessage(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <Divider />
          <DialogActions sx={{ py: 2, px: 3 }}>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Button
                type="button"
                variant="outlined"
                onClick={() => navigate(`${baseUrl}/${appointmentId}`)}
                disabled={cannotAttendMutation.isLoading}
              >
                <FormattedMessage defaultMessage="No, Go back" />
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={() =>
                  cannotAttendMutation.mutate({
                    cancelMessage
                  })
                }
                disabled={cannotAttendMutation.isLoading}
              >
                <FormattedMessage defaultMessage="Cannot Attend" />
              </Button>
            </Stack>
          </DialogActions>
        </>
      )}
      {['edit', 'editFor'].includes(mode) && (
        <EditAppointment
          appointment={appointment}
          baseUrl={baseUrl}
          appointmentId={appointmentId}
          mode={mode}
          onUpdateSuccess={onUpdateSuccess}
          renderCancel={() => (
            <Button
              type="button"
              variant="outlined"
              onClick={() => navigate(`${baseUrl}/${appointmentId}`)}
              disabled={cancelAppointmentMutation.isLoading}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          )}
        />
      )}
      {mode === 'reschedule' && (
        <RescheduleAppointment
          appointment={appointment}
          onUpdateSuccess={onUpdateSuccess}
          renderCancel={() => (
            <Button
              type="button"
              variant="outlined"
              onClick={() => navigate(`${baseUrl}/${appointmentId}`)}
              disabled={cancelAppointmentMutation.isLoading}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          )}
        />
      )}
    </>
  );
}
