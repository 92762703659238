import CircleIcon from '@mui/icons-material/Circle';
import { Box, IconButton, Popover } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { CirclePicker } from 'react-color';
import { Controller } from 'react-hook-form';

export type BubbleColorPickerProps = {
  name: string;
  label?: string | ReactNode;
};

const BubbleColorPicker = React.memo(({ name, label, ...rest }: BubbleColorPickerProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'bubble-color-picker' : undefined;

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <>
          <Box aria-describedby={id} {...rest}>
            {label}
            <IconButton aria-describedby={id} onClick={handleClick}>
              <CircleIcon sx={{ color: value || '#ff0000', fontSize: 28 }} />
            </IconButton>
          </Box>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Box p={1}>
              <CirclePicker
                color={value || ''}
                onChange={(color) => {
                  onChange(color.hex);
                  handleClose();
                }}
              />
            </Box>
          </Popover>
        </>
      )}
    />
  );
});

export default BubbleColorPicker;
