import { Box, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useIntl } from 'react-intl';

interface Props {
  startTime: Date;
  duration: number;
  sessionStarted: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: 35,
    left: 50,
    zIndex: 1200,
    width: '125px',

    '& .label': {
      backgroundColor: 'rgba(51,51,51,.7)',
      color: '#fff',
      fontSize: 25,
      fontWeight: 'bold',
      padding: '3px 8px',
      borderRadius: theme.shape.borderRadius
    }
  }
}));

export default function Timer({ startTime, duration, sessionStarted }: Props) {
  const classes = useStyles();
  const intl = useIntl();

  const [timeLeft, setTimeLeft] = useState('0:00:00');
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [isDragging, setIsDragging] = useState(false);

  const startTimer = useCallback(() => {
    const endTime = moment(startTime).add(duration, 'minutes');

    intervalRef.current = setInterval(() => {
      let diffTo = moment(startTime);

      if (sessionStarted) {
        diffTo = moment();
      }

      let timeLeftTemp = '0:00:00';

      if (!(moment().diff(endTime) >= 0)) {
        timeLeftTemp = moment(endTime.valueOf() - diffTo.valueOf())
          .tz('GMT')
          .format('H:mm:ss');
      } else if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      setTimeLeft(timeLeftTemp);
    }, 1000);
  }, [startTime, duration, sessionStarted]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    startTimer();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [startTimer]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  return (
    <Draggable onStart={handleDragStart} onStop={handleDragStop}>
      <Tooltip title={intl.formatMessage({ defaultMessage: 'Drag to move Timer' })} sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
        <Box className={classes.root}>
          <span className="label">{timeLeft}</span>
        </Box>
      </Tooltip>
    </Draggable>
  );
}
