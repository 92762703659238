import { Box, Button, Modal, Paper, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import brick1Image from 'assets/images/virt/brick1.jpeg';
import brick2Image from 'assets/images/virt/brick2.jpeg';
import clsx from 'clsx';
import { RefObject, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import blurImage from 'assets/images/virt/blur.jpeg';

interface BackgroundImage {
  src: string;
  name: string;
}

type Props = {
  containerRef: RefObject<HTMLDivElement> | null;
  publisherRef: any;
  publishVideo: boolean;
  sessionStarted: boolean;
  type: string;
  setType: Function;
  backgroundImageUrls: Array<BackgroundImage>;
  activeImage: string;
  setActiveImage: Function;
};

function BackgroundConfigCard({ containerRef, type, setType, activeImage, setActiveImage, backgroundImageUrls }: Props) {
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = useState(true);
  const imageDescription = new Map([
    [brick1Image, 'Grey'],
    [brick2Image, 'Brick Wall']
  ]);

  return (
    <>
      <Modal
        container={containerRef?.current}
        title={intl.formatMessage({ defaultMessage: 'Background' })}
        open={open}
        onClose={() => {
          setTimeout(() => {
            setOpen(false);
          }, 0);
        }}
        sx={{ zIndex: 1350 }}
      >
        <Paper className={classes.root}>
          <Stack>
            <Stack direction="row">
              <Box className={clsx('btn', type === 'none' ? 'activeBtn' : 'inactiveBtn')}>
                <Button
                  variant="text"
                  style={{ width: '100%', height: '100%' }}
                  onClick={() => {
                    setType('none');
                    setTimeout(() => {
                      setOpen(false);
                    }, 0);
                  }}
                >
                  <Typography>
                    <FormattedMessage defaultMessage="None" />
                  </Typography>
                </Button>
              </Box>

              <Box
                className={clsx('btn', type === 'blur' ? 'activeBtn' : 'inactiveBtn')}
                onClick={() => {
                  setType('blur');
                  setTimeout(() => {
                    setOpen(false);
                  }, 0);
                }}
              >
                <img src={blurImage} alt="virtual" />
                <Typography sx={(theme) => ({ position: 'absolute', color: theme.palette.primary.main })}>
                  <FormattedMessage defaultMessage="Blur" />
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row">
              {backgroundImageUrls.map((image) => (
                <Box
                  key={image?.src}
                  className={clsx('btn', type === 'image' && activeImage === image?.src ? 'activeBtn' : 'inactiveBtn')}
                  onClick={() => {
                    setType('image');
                    setActiveImage(image?.src);
                    setTimeout(() => {
                      setOpen(false);
                    }, 0);
                  }}
                >
                  <img src={image?.src} alt="virtual" />
                  <Typography
                    sx={(theme) => ({ position: 'absolute', color: image?.name !== 'Grey' ? 'white' : theme.palette.primary.main })}
                  >
                    {image?.name === 'Grey' ? (
                      <FormattedMessage defaultMessage={'Grey'} />
                    ) : (
                      <FormattedMessage defaultMessage={'Brick Wall'} />
                    )}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    top: '35vh',
    margin: '0 auto',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    border: '4px solid #666',

    '& .btn': {
      borderRadius: 8,
      overFlow: 'hidden',
      backgroundColor: '#eee',
      width: 120,
      height: 120,
      margin: 10,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',

      '& img': { width: '95%', height: '95%', objectFit: 'cover', borderRadius: 8 }
    },

    '& .activeBtn': {
      border: `3px solid ${theme.palette.primary.main}`
    },
    '& .inactiveBtn': {
      border: 0
    }
  }
}));

export default BackgroundConfigCard;
