import { Box, Dialog, DialogContent, Divider, Stack, Typography, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { format, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useUserAnnouncement from 'hooks/useUserAnnouncement';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTypeCell } from 'utils/announcementTypes';
import DialogClose from 'views/components/buttons/DialogClose';
import QuillEditor from 'views/components/common/QuillEditor';
import DocumentCover from '../DocumentCover';

type Props = {
  userAnnouncementId: string;
  onClose: () => void;
};

const ViewAnnouncementModal = ({ userAnnouncementId, onClose }: Props) => {
  const theme = useTheme();
  const { userAnnouncement, isLoading } = useUserAnnouncement({ userAnnouncementId });
  const intl = useIntl();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <DialogClose onClose={onClose} />
      <DialogContent sx={{ minWidth: '50vw', minHeight: '30vh' }}>
        <Typography sx={{ mb: 1, fontSize: 14 }}>
          {!!userAnnouncement?.announcement?.updatedAt &&
            format(parseISO(userAnnouncement.announcement.updatedAt), intl.locale === 'fr' ? 'do MMMM yyyy' : 'MMMM do yyyy', {
              locale: intl.locale === 'fr' ? frLocale : enLocale
            })}
        </Typography>

        <Typography sx={{ mb: 1, fontSize: 26, fontWeight: '900', color: '#000' }}>{userAnnouncement?.announcement?.title}</Typography>
        <Typography sx={{ mb: 2, fontSize: 16, fontWeight: '500', color: theme.palette.primary.main }}>
          {getTypeCell(userAnnouncement?.announcement?.type as string, intl)}
        </Typography>

        <Box
          sx={{
            '& .main-editor-custom': {
              borderWidth: 0
            }
          }}
        >
          <QuillEditor disabled name="note" value={userAnnouncement?.announcement?.description ?? ''} />
        </Box>
      </DialogContent>

      <Divider
        sx={{
          mt: 2
        }}
      />
      <Box px={3}>
        {!!userAnnouncement?.announcement?.attachments?.length && (
          <Typography mt={2} sx={{ fontWeight: '500', fontSize: 13 }}>
            <FormattedMessage defaultMessage="Attachments" />:
          </Typography>
        )}
        <Stack direction="row" flexWrap="wrap" m={1} spacing={2} rowGap={1}>
          {userAnnouncement?.announcement?.attachments?.map(({ _id, name, link, contentType }) => (
            <DocumentCover key={_id} name={name} link={link} contentType={contentType} />
          ))}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ViewAnnouncementModal;
