import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import AdminDashboard from 'views/admin-dashboard/AdminDashboard';
import Loader from 'views/components/Loader';
import ProviderDashboard from './providerdashboard';

const PrivateDashboard = () => {
  const { userId } = useAuth();
  const { user, isLoading } = useUser(userId);

  if (user && user?.role === 'PROVIDER') {
    return <ProviderDashboard />;
  } else if (
    user?.role === 'PROVIDER_ADMINISTRATOR' ||
    user?.role === 'PROVIDER_ADMINISTRATOR (S)' ||
    user?.role === 'PROVIDER_ADMINISTRATOR (D)' ||
    user?.role === 'PROVIDER_ADMINISTRATOR [S]' ||
    user?.role === 'PROVIDER_ADMINISTRATOR [D]' ||
    user?.role === 'ADMINISTRATOR'
  ) {
    return <AdminDashboard />;
  } else {
    return <div>{isLoading && <Loader />}</div>;
  }
};

export { PrivateDashboard };
