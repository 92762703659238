import { User } from './user';

export interface Participant {
  _id: string;
  isPaid: boolean;
  chargeAmount: string;
  connectionId: string;
  user: User;
  status: string;
  isPending: boolean;
  paymentStatus: string;
}
export interface ServiceType {
  _id: string;
  name: string;
  nameFrench?: string;
  rate: string;
  durationInMinutes: number;
  taxType: string;
  taxPercentage: any;
  allowInAppointments: boolean;
  hourlyRate: string;
}
export interface Appointment {
  multipleClinicians: boolean;
  duration?: number;
  calendar_id?: string;
  event_uid?: string;
  summary: string;
  description: string;
  start: Date | string;
  end: Date | string;
  deleted?: boolean;
  created?: Date;
  updated?: Date;
  event_private?: boolean;
  participation_status?: string;
  attendees?: any[];
  organizer?: Organizer;
  transparency?: string;
  status?: string;
  categories?: any[];
  recurring?: boolean;
  options?: Options;
  event_id?: string;
  location?: Location;
  managedEvent?: boolean;
  sessionId?: string;
  appointmentStatus?: string;
  contextStatus?: string;
  owner?: string;
  members?: Member[];
  emails?: string[];
  emailsNewShow?: EmailsNewShow[];
  locationType?: string;
  serviceType?: ServiceType;
  faceToFace?: boolean;
  office?: Office;
  isRecordedEnabled?: boolean;
  resourceId?: string;
  isAssociateTherapist?: boolean;
  repeatId?: string | null;
  repeatInfo?: string | null;
}

export interface EmailsNewShow {
  isContactMe: string;
  _id: string;
  email: string;
  challenge: string;
}

export interface Options {
  delete: boolean;
  update: boolean;
  change_participation_status: boolean;
}

export interface Organizer {
  email: string;
  display_name: null;
}

export interface CalendarAppointment {
  attendees: any[];
  calendar_id: string;
  categories: any[];
  created: string;
  deleted: boolean;
  description: string;
  start: string;
  end: string;
  event_uid: string;
  options: any;
  organizer: {
    email: string;
    display_name: string | null;
  };
  participation_status: string;
  recurring: boolean;
  status: string;
  summary: string;
}

export interface NTCalendarEvent {
  event_id: string;
  description: string;
  start: string;
  end: string;
  summary: string;
  bubbleColor: string;
  label: string;
  fullDay: boolean;
  syncWithExternalCalendar: boolean;
  repeatId?: string | null;
}

export interface Reminder {
  hours: number;
  sent: boolean;
  sentEmail: Date | null;
  sentSMS: Date | null;
  participants: string[];
  floatingParticipants: string[];
}

export interface CreateAppointment {
  date: Date;
  officeId: string;
  members: string[];
  emails?: string[];
  serviceTypeId: string;
  message?: string;
  sessionType: string;
  repeatFrequency?: string;
  repeatNumberOfTimes?: number;
  reminders?: Reminder[];
  enableArchiving?: boolean;
  duration: number;
}
export interface Location {
  description: string;
}

export interface Member {
  fullName: string;
  isAssociateTherapist: boolean;
  isProviderOfRecord: boolean;
  isOwner: boolean;
  id: string;
  inactive: boolean;
  status: Status;
  isPending: boolean;
  role: string;
}

export enum Status {
  Accepted = 'ACCEPTED',
  NoShow = 'NO-SHOW',
  Pending = 'PENDING',
  Canceled = 'CANCELED',
  LateCanceled = 'LATE-CANCELED'
}

export interface Office {
  officeId: string;
  address: string;
  fullAddress: FullAddress;
}

export interface FullAddress {
  address1: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
}
