import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OfficeAddress } from 'types/globalTypes';
import { Service, User } from 'types/user';

interface Pagination<T extends {}> {
  data: T[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRows: number;
  sites: any[];
}

export default function useSessions(
  page?: number,
  pageSize?: number,
  userId?: string,
  clientId?: string,
  onRefetch?: () => void,
  dateRange?: DateRange<Dayjs>,
  site?: string
) {
  const { request } = useAuth();
  const queryClient = useQueryClient();

  const url = clientId ? `/user/${clientId}/sessions` : `/sessions`;

  const response = useQuery(
    [url, page, pageSize, dateRange, site],
    () =>
      request
        .get(url, { params: { page, pageSize, dateRange, site } })
        .then(
          (response) =>
            response.data as
              | Pagination<Session>
              | { balanceOwing: number; currencySymbol: string; currency: string; sessions: Pagination<Session> }
        ),
    { enabled: !!page, keepPreviousData: true, staleTime: 60000 }
  );

  const { data, refetch, isLoading: fetchLoading, isRefetching, isPreviousData } = response;

  const balanceOwing = _.get(data, 'balanceOwing');
  const totalPaid = _.get(data, 'totalPaid');
  const currency = _.get(data, 'currency');

  const sessions = (_.get(data, 'sessions') ? _.get(data, 'sessions') : data) as any;

  const { totalRows, sites } = sessions || {};

  const sessionsFinal = useMemo(
    () =>
      sessions?.data?.map((s, index) => {
        if (s && (!s.id || s.id === '')) {
          s.id = s.appointmentId;
        }

        s.id = s.id || new Date().getTime() + index;
        return s;
      }),
    [sessions]
  );

  useEffect(() => {
    if (totalRows && page && pageSize && totalRows > page * pageSize) {
      queryClient.prefetchQuery([url, page + 1, pageSize], () =>
        request.get(url, { params: { page: page + 1, pageSize } }).then((response) => response.data as any)
      );
    }
  }, [totalRows, url, page, pageSize, queryClient, request]);

  const handleRefetch = useCallback(async () => {
    await queryClient.invalidateQueries([url]);
    if (page) {
      refetch();
    }

    if (onRefetch) {
      onRefetch();
    }
  }, [onRefetch, page, url, queryClient, refetch]);

  const { mutateAsync: attendSession, isLoading: attendLoading } = useMutation(
    '/users/appointment/attend',
    ({ clientId, appointmentId }: any) => request.post(`/user/${clientId}/appointments/${appointmentId}/attend`, {}),
    { onSuccess: () => handleRefetch() }
  );

  const { mutateAsync: noShowSession, isLoading: noShowLoading } = useMutation(
    '/users/appoint/no-show',
    ({ clientId, appointmentId }: any) => request.post(`/user/${clientId}/appointments/${appointmentId}/noshow`),
    { onSuccess: () => handleRefetch() }
  );

  const { mutateAsync: resendInvoice, isLoading: invoiceLoading } = useMutation(
    '/invoices/resend',
    (invoiceId: string) => request.get(`/invoices/${invoiceId}/resend`),
    { onSuccess: () => handleRefetch() }
  );

  const { mutateAsync: resendReceipt, isLoading: receiptLoading } = useMutation(
    '/appointments/receipt/resend',
    ({ appointmentId, clientId }: any) => request.post(`/appointments/${appointmentId}/receipt/resend`, { clientId }),
    { onSuccess: () => handleRefetch() }
  );

  const { mutateAsync: previewInvoice, isLoading: invoicePreviewLoading } = useMutation(
    '/appointments/invoice/preview',
    ({ appointmentId, payload }: any) =>
      request.post(`/appointments/${appointmentId}/preview`, payload, { responseType: 'blob' }).then((response) => {
        const file = new Blob([response.data], {
          type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }),
    { onSuccess: () => handleRefetch() }
  );

  const { mutateAsync: previewReceipt, isLoading: receiptPreviewLoading } = useMutation(
    '/receipts/previewreceipt',
    ({ appointmentId, payload }: any) =>
      request.post(`/receipts/${appointmentId}/previewreceipt`, payload, { responseType: 'blob' }).then((response) => {
        const file = new Blob([response.data], {
          type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }),
    { onSuccess: () => handleRefetch() }
  );

  return {
    ...sessions,
    isPreviousData,
    fetchLoading: fetchLoading || isRefetching,
    isLoading:
      fetchLoading || attendLoading || noShowLoading || invoiceLoading || receiptLoading || invoicePreviewLoading || receiptPreviewLoading,
    attendSession,
    noShowSession,
    resendInvoice,
    resendReceipt,
    previewInvoice,
    previewReceipt,
    refetchSessions: handleRefetch,
    currency,
    balanceOwing,
    totalPaid,
    sessions: sessionsFinal,
    sites
  };
}

export interface Client {
  duration: number;
  emailVerified: boolean;
  isContactMe: boolean;
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  hasCustomerStripe: boolean;
  hasVendorStripe: boolean;
  emailAddress: string;
  eapInvoiceEmail?: any;
  discount?: Discount;
  preferredLanguage?: string;
}

export interface Discount {
  rate: Number;
  discountType: 'flat-rate' | 'percentage';
  enabled: boolean;
}

export interface Payment {
  chargedAmount: number;
  id: string;
  paymentStripeReceiptUrl: string;
  date: string;
  currency: string;
}

export interface Session {
  discount: any;
  id: string;
  attendanceMarked: boolean;
  invoiceId: string;
  dateInvoiced?: string;
  isInvoiceViewed: boolean;
  status: string;
  date: Date;
  duration: number;
  paymentStatus: 'Paid' | 'Unpaid' | 'Invoiced' | 'No Charge' | 'PROCESSING PAYMENT' | 'Charge Failed';
  paymentError?: string;
  isPaid: boolean;
  isComplimentary: boolean;
  total: number;
  isFaceToFace: boolean;
  sessionType: string;
  chargeAmountPlaceholder: number;
  subtotal: number;
  appointmentId: string;
  isRecordingEnabled: boolean;
  service: Service;
  payment?: Payment;
  client: Client;
  therapist: User;
  officeAddress: OfficeAddress;
  officeOptions: any[];
  officeId?: string;
  archives: any[];
  reminders: any[];
  invoiceNotes: string;
  participants: any;
  currency?: string;
  sites?: any[];
  deleted: boolean;
}
