import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogClose from '../buttons/DialogClose';

type Props = DialogProps & {
  title: string;
  description?: string;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ConfirmationDialog: React.FC<Props> = ({ title, description, onCancel, onConfirm, ...props }) => (
  <Dialog sx={{ '& .MuiDialog-paper': { p: 0 } }} {...props}>
    <DialogTitle>
      {title} <DialogClose onClose={onCancel} />
    </DialogTitle>
    {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
    )}
    <Divider />
    <DialogActions>
      <Button onClick={onCancel} variant="outlined" color="secondary">
        <FormattedMessage defaultMessage="Cancel" />
      </Button>
      <Button onClick={onConfirm} variant="contained" color="primary">
        <FormattedMessage defaultMessage="Confirm" />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
