import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { User } from 'types/user';

export default function useTherapists() {
  const { request } = useAuth();

  const { data: therapists, isLoading: therapistsLoading } = useQuery(
    '/therapists',
    () => request.get('/therapists').then((response) => response.data.users as User[]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  return { therapists, therapistsLoading };
}
