import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export type FreeBusyStatus = 'free' | 'busy';

export interface Availability {
  start: string;
  end: string;
  freeBusyStatus: FreeBusyStatus;
}

interface Props {
  from: Date;
  to: Date;
  provider?: string;
  locationType?: string;
}

export default function useAvailabilities({ from, to, provider, locationType = 'Online' }: Props) {
  const { request } = useAuth();

  const {
    data: availabilities,
    isLoading,
    refetch: refetchAvailabilities,
    error
  } = useQuery(
    [`/user/${provider}/availability`, { from, to, locationType }],
    () =>
      request
        .get(`/user/${provider}/availability`, { params: { from, to, locationType } })
        .then((response) => response.data.availabilityData as Availability[]),
    { enabled: !!provider && !!locationType }
  );

  return { availabilities, refetchAvailabilities, isLoading, error };
}
