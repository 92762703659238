// organize-imports-ignore

// third-party
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
// material-ui
import { Box, Button, Stack } from '@mui/material';
import { addWeeks, endOfMonth, format, startOfMonth, subHours, subWeeks } from 'date-fns';
// project imports
import MainCard from 'views/components/cards/MainCard';
import PopularCard from 'views/components/skeleton/PopularCard';
import { useCallback, useMemo, useState } from 'react';
import useUsers from 'hooks/useUsers';
import { Appointment } from 'types/appointment';
import { useNavigate } from 'react-router';

import { useSnackbar } from 'notistack';
import useAuth from 'hooks/useAuth';
import { FormattedMessage, useIntl } from 'react-intl';
import CalendarStyled from './CalendarStyled';
import ClinicToolbar from './ClinicToolbar';
// assets
import useCalendar from './useCalendar';
import useAllAppointments from './useAllAppointments';
import AppointmentDialogs from './AppointmentDialogs';
import useGroups from './useGroups';
import usePatients from './usePatients';
import useUser from 'hooks/useUser';

// ==============================|| APPLICATION CALENDAR ||============================== //

const TimelineCalendar = () => {
  const intl = useIntl();
  const [createInitialDate, setCreateInitialDate] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();

  const { userId } = useAuth();

  const { user } = useUser(userId);

  const timezone = user?.settings.timezone ?? 'America/Toronto';
  const { therapists } = useUsers({});
  const navigate = useNavigate();
  const { getToolbarProps, getCalendarProps, setDuration, setSlotLabelFormat, duration, slotLabelFormat, view, date } =
    useCalendar('resourceTimeline');

  const from = subWeeks(startOfMonth(date), 1);
  const to = addWeeks(endOfMonth(date), 1);

  const { queries, events, refetchQueries } = useAllAppointments({ from, to });
  const appointments = useMemo(() => queries.reduce((prev, query) => [...prev, ...(query.data || [])], [] as Appointment[]), [queries]);

  useGroups();
  usePatients();

  const handleAppointmentSuccess = useCallback(() => {
    refetchQueries();
    navigate('/clinic-appointments');
  }, [navigate, refetchQueries]);

  const resources = useMemo(() => {
    if (therapists) {
      const tTherapists: any = [];
      therapists.forEach((therapist) => {
        if (therapist.inactive || therapist.disabled) {
          return;
        }

        tTherapists.push({
          id: therapist.id,
          title: `${therapist.firstName} ${therapist.lastName}`
        });
      });
      return tTherapists;
    }

    return [];
  }, [therapists]);

  if (!events) {
    return <PopularCard />;
  }

  return (
    <MainCard
      title={intl.formatMessage({ defaultMessage: 'All Appointments' })}
      sx={{ height: 'calc(100vh - 128px)', maxHeight: 'calc(100vh - 128px)', display: 'flex', flexDirection: 'column' }}
      contentSX={{ flex: 1 }}
      secondary={
        <Stack sx={{ display: 'none' }} direction="row" spacing={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setCreateInitialDate(new Date());
              navigate('/clinic-appointments/create');
            }}
          >
            <FormattedMessage defaultMessage="New Session" />
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setCreateInitialDate(new Date());
              navigate('/clinic-appointments/create-group');
            }}
          >
            <FormattedMessage defaultMessage="New Group Session" />
          </Button>
        </Stack>
      }
    >
      <CalendarStyled style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ClinicToolbar {...getToolbarProps()} setDuration={setDuration} setSlotLabelFormat={setSlotLabelFormat} />
        <Box sx={{ flex: 1 }}>
          <FullCalendar
            {...getCalendarProps()}
            locale={intl.locale === 'fr' ? frLocale : undefined}
            headerToolbar={false}
            nowIndicator
            scrollTime={`${format(subHours(new Date(), 1), 'HH')}:00:00`}
            events={events}
            resources={resources}
            slotDuration={duration}
            slotLabelFormat={slotLabelFormat}
            resourceLabelDidMount={(info) => {
              info.el.onclick = () => navigate(`/user/${info?.resource?.id}/appointments`);
              info.el.style.cursor = 'pointer';
            }}
            height="100%"
            slotMinWidth={80}
            slotMinTime="00:00"
            slotMaxTime="24:00"
            eventClick={(arg) => {
              if (arg.event?.extendedProps?.appointment?.event_id) {
                navigate(
                  `/clinic-appointments/${arg.event.extendedProps.appointment.event_id}${
                    arg.event.extendedProps.appointment.resourceId ? '?therapist=' + arg.event.extendedProps.appointment.resourceId : ''
                  }`
                );
              }
            }}
            select={(props) => {
              const { start } = props;

              setCreateInitialDate(start);
              navigate(`/clinic-appointments/create?therapist=${props.resource?.id}`);
            }}
            eventAllow={() => false}
            plugins={[
              momentTimezonePlugin,
              listPlugin,
              dayGridPlugin,
              timelinePlugin,
              timeGridPlugin,
              interactionPlugin,
              resourceTimelinePlugin
            ]}
            timeZone={timezone}
          />
        </Box>
      </CalendarStyled>

      <AppointmentDialogs
        createInitialDate={createInitialDate}
        initializeTime={view !== 'dayGridMonth'}
        appointments={appointments}
        onSuccess={handleAppointmentSuccess}
        baseUrl="/clinic-appointments"
        disableBlockOffTab
      />
    </MainCard>
  );
};

export default TimelineCalendar;
