import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Stack } from '@mui/material';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getGoogleDocsURL } from 'utils/documents';
import DialogClose from '../buttons/DialogClose';

interface Props extends DialogProps {
  name: string;
  source?: string;
  url?: string;
  blob?: Blob;
  onClose: () => void;
  isImage?: boolean | string;
}

export default function PreviewDialog({ name, source, url, blob, onClose, isImage, ...props }: Props) {
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    try {
      if (blob) {
        const newUrl = URL.createObjectURL(blob);
        setBlobUrl(newUrl);

        return () => {
          URL.revokeObjectURL(newUrl);
        };
      }

      return noop;
    } catch (e: any) {
      return () => {};
    }
  }, [blob]);

  const resultUrl = url ?? blobUrl;

  return (
    <Dialog maxWidth="lg" fullWidth sx={{ '& .MuiDialog-paper': { p: 0 } }} onClose={onClose} {...props}>
      <DialogTitle>
        {name} <DialogClose onClose={onClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        {resultUrl ? (
          <>
            {isImage ? (
              <img
                src={source === 'googleDocs' ? getGoogleDocsURL(resultUrl) : resultUrl}
                alt="image1"
                style={{ width: '100%', height: '68vh', objectFit: 'scale-down' }}
              />
            ) : (
              <iframe
                src={source === 'googleDocs' ? getGoogleDocsURL(resultUrl) : resultUrl}
                title={name}
                style={{ width: '100%', height: '68vh' }}
              />
            )}
          </>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction="row" spacing={2}>
          <Button color="secondary" variant="contained" href={resultUrl} download={name} target="_blank">
            <FormattedMessage defaultMessage="Download" />
          </Button>
          <Button type="button" variant="contained" onClick={onClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
