import { Box, Button, ButtonProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconChevronUp } from '@tabler/icons';
import { forwardRef, RefObject, useState } from 'react';

interface Props extends ButtonProps {
  isSelected?: boolean;
  isLayout?: boolean;
  subMenu?: any;
  disabled?: boolean;
  containerRef?: RefObject<HTMLDivElement> | null;
  isEndSession?: boolean;
  closeSubMenu?: boolean;
  setCloseSubMenu?: Function;
  fullScreenRef?: RefObject<HTMLDivElement> | null;
}

const ControlButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      isSelected,
      isLayout,
      subMenu,
      disabled,
      containerRef,
      isEndSession,
      closeSubMenu,
      setCloseSubMenu,
      fullScreenRef,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setCloseSubMenu && setCloseSubMenu(true);
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement> | string) => {
      typeof event !== 'string' && event.stopPropagation();
      setAnchorEl(null);
      setCloseSubMenu && setCloseSubMenu(false);
    };
    // useEffect(() => {
    //   closeSubMenu && handleClose('');
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [closeSubMenu]);
    return (
      <Button
        ref={ref}
        className={classes.root}
        {...props}
        sx={{
          background: isSelected ? '#337ab7' : isEndSession ? '#D92D20' : isLayout ? 'transparent' : '#1D1D1DCC',
          // border: 'none !important',
          flexDirection: 'column',
          minWidth: {
            xs: 'none',
            lg: '100px !important'
          },
          alignItems: 'center',
          gap: 2,
          '& > *': { marginRight: '0 !important', marginBottom: 0.5 },
          ':hover': {
            background: isEndSession ? '#8B0000' : '#2e6da4'
          },
          ...props.sx
        }}
        disabled={disabled}
      >
        {children}
        {subMenu && (
          <Box onClick={handleClick} style={{ position: 'absolute', top: 8, right: 20, cursor: 'pointer' }}>
            {!open ? <IconChevronUp size={20} color="#fff" /> : <IconChevronUp size={20} color="#fff" />}
          </Box>
        )}

        <Menu
          container={containerRef?.current}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          className={classes.menu}
          sx={{
            minWidth: 0
          }}
        >
          {subMenu}
        </Menu>
      </Button>
    );
  }
);

export default ControlButton;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.Mui-disabled': {
      backgroundColor: 'rgba(51,51,51,.8) !important',
      color: '#999 !important'
    }
  },
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: 'rgba(60,60,60)',
      minHeight: 100
    }
  }
}));
