// project imports
import { lazy } from 'react';
import Loadable from 'views/components/Loadable';
import MinimalLayout from 'views/layout/MinimalLayout';

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

// landing & contact-us routing
const PagesContactUS = Loadable(lazy(() => import('views/pages/contact-us')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/pages/error',
      element: <MaintenanceError />
    },
    {
      path: '/pages/under-construction',
      element: <MaintenanceUnderConstruction />
    },
    {
      path: '/pages/contact-us',
      element: <PagesContactUS />
    }
  ]
};

export default AuthenticationRoutes;
