import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import CCRequired from 'views/components/CCRequired';
import ResendVerification from 'views/components/ResendVerification';
import PopularCard from 'views/components/skeleton/PopularCard';
import TimelineCalendar from './TimelineCalendar';

const ClinicAppointments = () => {
  const { userId } = useAuth();
  const { user } = useUser(userId);

  if (!user || !userId) {
    return <PopularCard />;
  }

  return (
    <>
      <CCRequired />
      <ResendVerification />
      <TimelineCalendar />
    </>
  );
};

export default ClinicAppointments;
