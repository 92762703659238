import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export default function useMessageNotifications(senderId?: string) {
  const { request, userId } = useAuth();

  const {
    data: messageNotifications,
    isLoading,
    isFetching,
    refetch: refetchMessageNotifications
  } = useQuery(`/messages/count`, () => request.get(`/messages/count`).then((response) => response.data), {});

  const {
    data: senderNotifications,
    isLoading: isSenderNotificationsLoading,
    isFetching: isSenderNotificationsFetching,
    refetch: refetchSenderNotificationsCount
  } = useQuery(
    `/messages/count/${senderId}`,
    () => {
      if (senderId) {
        return request.get(`/messages/count/${senderId}`).then((response) => response.data);
      }
      return null;
    },
    {
      enabled: !!userId
    }
  );

  return {
    messageCount: messageNotifications?.total || 0,
    totalInternalMessageCount: messageNotifications?.totalInternal || 0,
    totalExternalMessageCount: messageNotifications?.totalExternal || 0,
    totalAnnouncements: messageNotifications?.totalAnnouncements || 0,
    senderMessageCount: senderNotifications?.total || 0,
    isLoading: isLoading || isFetching || isSenderNotificationsLoading || isSenderNotificationsFetching,
    refetchMessageNotifications,
    refetchSenderNotificationsCount
  };
}
