import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useProvider from 'hooks/useProvider';
import useTherapists from 'hooks/useTherapists';
import useUser from 'hooks/useUser';
import { range, round } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useLocation, useParams } from 'react-router';
import { usePrevious, useSearchParam } from 'react-use';
import { CreateAppointment as CreateAppointmentModel } from 'types/appointment';
import { User } from 'types/user';
import useLabels from 'utils/apiTranslations/useLabels';
import { DURATION_OPTIONS, GET_DAYS_OF_WEEK, GET_REPEAT_FREQUENCY_OPTIONS, REMINDER_OPTIONS } from 'utils/constants';
import { getMonthDayNumber, getNumberWithOrdinal } from 'utils/dates';
import { getErrorMessage } from 'utils/stringUtils';
import DialogClose from 'views/components/buttons/DialogClose';
import Autocomplete from 'views/components/inputs/Autocomplete';
import DatePicker from 'views/components/inputs/DatePicker';
import FormContainer from 'views/components/inputs/FormContainer';
import SwitchField from 'views/components/inputs/Switch';
import TagsInput from 'views/components/inputs/TagsInput';
import TextField from 'views/components/inputs/TextField';
import TimePicker from 'views/components/inputs/TimePicker';
import * as Yup from 'yup';
import Loader from '../../components/Loader';
import useTherapistPatients from '../client-record/useTherapistPatients';
// import usePatients from './usePatients';

type FormValues = any;
interface Props {
  initialDate: Date;
  initializeTime?: boolean;
  onCreateSuccess: () => void;
  onClose: () => void;
  disableBlockOffTab?: boolean;
  isNonRegisteredAppointment?: boolean;
}

export default function CreateAppointment({
  initialDate,
  initializeTime,
  onCreateSuccess,
  onClose,
  disableBlockOffTab,
  isNonRegisteredAppointment = false
}: Props) {
  const intl = useIntl();
  const { request, userId: id } = useAuth();
  const { userId } = useParams();
  const clientId = useSearchParam('clientId') || undefined;
  const location: any = useLocation();
  const queryParams = new URLSearchParams(useLocation().search);

  const therapistId = userId ?? queryParams.get('therapist') ?? id;
  const { user, isLoading: userLoading } = useUser(therapistId);

  // const { patients, patientsLoading } = usePatients(therapistId);
  const { patients, isLoading: isPatientsLoading } = useTherapistPatients(therapistId);
  const { therapists, therapistsLoading } = useTherapists();

  const createAppointmentMutation = useMutation((values: CreateAppointmentModel) =>
    request.post(`/therapists/${therapistId}/appointments`, { isNonRegisteredAppointment, ...values })
  );
  const { enqueueSnackbar } = useSnackbar();
  const { provider } = useProvider(id);
  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Date is not valid' })),
    time: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Time is not valid' })),
    sessionType: Yup.string()
      .nullable()
      .required(intl.formatMessage({ defaultMessage: 'Required' })),
    serviceTypeId: Yup.string()
      .nullable()
      .required(intl.formatMessage({ defaultMessage: 'Required' })),
    duration: Yup.number()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .typeError(intl.formatMessage({ defaultMessage: 'Required' })),
    emails: Yup.array()
      .of(
        Yup.string()
          .email(intl.formatMessage({ defaultMessage: 'Invalid email' }))
          .defined()
      )
      .test('isUnique', intl.formatMessage({ defaultMessage: 'Duplicate emails are not allowed' }), function (value) {
        const uniqueValues = new Set(value);
        if (uniqueValues.size !== value?.length) {
          return false;
        }
        return true;
      })
      .optional(),
    members: Yup.array().of(Yup.string().defined()),
    reminders: Yup.array().of(Yup.number().defined()),
    message: Yup.string(),
    repeatFrequency: Yup.string(),
    repeatNumberOfTimes: Yup.number(),
    enableArchiving: Yup.bool(),
    isRecurring: Yup.bool(),
    officeId: Yup.string().when('sessionType', {
      is: 'Office',
      then: Yup.string()
        .nullable()
        .required(intl.formatMessage({ defaultMessage: 'Required' })),
      otherwise: Yup.string().nullable()
    })
  });
  const appointmentData = location?.state?.row;

  const initialTime = useMemo(() => {
    if (!initializeTime || !initialDate) return null;

    return initialDate;
  }, [initialDate, initializeTime]);

  const initialValues = {
    date: queryParams.get('date') ? new Date(queryParams.get('date') ?? '') : initialDate,
    time: queryParams.get('time') ? moment(queryParams.get('time'), 'hh:mm A').toDate() : initialTime,
    sessionType: queryParams.get('sessionType'),
    serviceTypeId: queryParams.get('serviceTypeId'),
    duration: null,
    emails: [] as string[],
    reminders: provider?.provider?.remindersAnticipationTime?.filter((time) =>
      REMINDER_OPTIONS.find((option) => Number(option.value) === Number(time))
    ) ?? [REMINDER_OPTIONS[2].value],
    message: '',
    members: [] as any[],
    repeatFrequency: GET_REPEAT_FREQUENCY_OPTIONS()[0].value,
    repeatNumberOfTimes: 0,
    enableArchiving: false,
    isRecurring: false,
    officeId: null,
    otherTherapists: []
  };

  const onFormSubmit = useCallback(
    async ({ isRecurring, officeId, date, time, reminders, familyMembers, emails = [], ...values }: FormValues) => {
      try {
        const members = [...values.members, ...values.otherTherapists];
        // console.log('emails ', emails);

        const filteredEmails = [
          ...emails.map((email) => email),
          ...(familyMembers
            ?.filter(({ registrationStatus }) => registrationStatus === 'unregistered')
            ?.map(({ email, user, fullName }) => ({ email, user, screenName: fullName })) || [])
        ];

        const data = {
          ...values,
          members,
          repeatFrequency: isRecurring ? values.repeatFrequency : 'None',
          repeatDays: values.repeatDays?.filter((obj) => {
            if (typeof obj === 'number') return true;
            return false;
          }),
          date: moment(`${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`).toISOString(),
          officeId: officeId ?? '',
          repeatNumberOfTimes: isRecurring ? values.repeatNumberOfTimes || 0 : 0,
          complimentaryParticipant: location?.state?.row ? location?.state?.row?._id : '',
          reminders: !user?.userOptions?.enableAppointmentReminders
            ? []
            : reminders.map((reminder) => ({
                hours: reminder,
                participants: values.members,
                floatingParticipants: filteredEmails?.map((filteredEmail: any) => filteredEmail) || [],
                sent: false,
                sentEmail: null,
                sentSMS: null
              })),
          emails: filteredEmails
        };
        delete data.otherTherapists;

        if (!members.length && !filteredEmails?.length) {
          enqueueSnackbar(intl.formatMessage({ defaultMessage: 'An appointment must have at least one participant' }), {
            variant: 'error'
          });
          return;
        }

        if (moment(data.date).isBefore()) {
          enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment date must be later than today' }), {
            variant: 'error'
          });
          return;
        }

        await createAppointmentMutation.mutateAsync(data);
        onCreateSuccess();
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment Created!' }), { variant: 'success' });
      } catch (e: any) {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      }
    },
    // eslint-disable-next-line
    [user?.userOptions?.enableAppointmentReminders, createAppointmentMutation, onCreateSuccess, enqueueSnackbar, intl]
  );

  return (
    <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onFormSubmit}>
      {isPatientsLoading || therapistsLoading || userLoading ? (
        <Loader />
      ) : (
        <>
          {disableBlockOffTab && (
            <>
              <DialogTitle>
                <FormattedMessage defaultMessage="Create Your Appointment" />
                <DialogClose onClose={onClose} />
              </DialogTitle>
              <Divider />
            </>
          )}
          <CreateAppointmentForm
            onClose={onClose}
            isSubmitting={createAppointmentMutation.isLoading}
            therapistId={therapistId}
            clientId={clientId}
            patients={patients || []}
            therapists={therapists?.filter((t) => t._id !== therapistId) || []}
            user={user}
          />
        </>
      )}
    </FormContainer>
  );
}

function CreateAppointmentForm({
  onClose,
  isSubmitting,
  therapistId,
  clientId,
  patients,
  therapists,
  user
}: {
  onClose: Function;
  isSubmitting: boolean;
  therapistId?: string;
  clientId?: string;
  patients: any[];
  therapists: User[];
  user?: User;
}) {
  const location: any = useLocation();

  const { options } = useOptions();
  const { reset } = useFormContext();
  const intl = useIntl();

  const { setValue, watch } = useFormContext();

  const reminders = watch('reminders');
  const emails = watch('emails');
  const members = watch('members');
  const otherTherapists = watch('otherTherapists');
  const previousEmails = usePrevious(emails);
  const enableMultipleParticipants = user?.userOptions?.enableMultipleParticipants;

  const isDirectoryMode = useMemo(() => {
    if (options?.DEFAULT_PROVIDER?.value === '0') {
      return true;
    } else {
      return false;
    }
  }, [options]);

  // Automatically add 2hrs option when non-registered participant added
  useEffect(() => {
    if (!reminders.length && !reminders.includes(REMINDER_OPTIONS[0].value) && emails?.length === 1 && !previousEmails?.length) {
      setValue('reminders', [...(reminders || []), REMINDER_OPTIONS[0].value]);
    }
  }, [emails, previousEmails, reminders, setValue]);

  const queryParams = new URLSearchParams(useLocation().search);
  // console.log('queryParams ', queryParams);

  useEffect(() => {
    if (queryParams.get('client')) {
      const getValue = patients.filter((patient: any) => patient.id === queryParams.get('client'));
      setValue('emails', [getValue[0].email]);
    }
    if (location?.state?.row) {
      setValue('emails', [location?.state?.row?.email]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('emails => ', emails);

  useEffect(() => {
    if (clientId && patients) {
      setValue(
        'members',
        patients.filter((patient) => patient.id === clientId && !(patient.inactive || patient.disabled))?.map((p) => p.id)
      );
    }
  }, [setValue, clientId, patients]);

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }

    reset();
  }, [reset, onClose]);

  return (
    <>
      {isSubmitting && <Loader />}
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3}>
          <AppointmentInfoFields />
          {user?.userOptions?.enableRegisteredSessionBooking && !location?.state?.row && (
            <Autocomplete
              multiple
              options={patients?.filter((obj) => !(obj.inactive || obj.disabled)) ?? []}
              renderOption={(props: any, option: any) => (
                <span
                  {...props}
                  key={option.id}
                  style={{
                    color: option?.flag === 'red' ? 'red' : ''
                  }}
                >
                  {`${option?.firstName} ${option?.lastName} <${option?.email}>`}
                </span>
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} <${option?.email}>`}
              label={intl.formatMessage({ defaultMessage: 'Registered Clients' })}
              name="members"
              hidden={!user?.userOptions?.enableRegisteredSessionBooking || (emails?.length && !enableMultipleParticipants)}
              getOptionDisabled={() => (members?.length >= 1 || emails?.length >= 1) && !enableMultipleParticipants}
            />
          )}
          {!location?.state?.row ? (
            user?.userOptions?.enableQuickBookings &&
            (enableMultipleParticipants ? (
              <TagsInput name="emails" options={[]} label={intl.formatMessage({ defaultMessage: 'Non-registered participant email(s)' })} />
            ) : (
              <TagsInput
                name="emails"
                options={[]}
                isMulti={false}
                hidden={members?.length > 0 ? true : false}
                emailData={emails?.length}
                getOptionDisabled={(option) => emails?.length > 0}
                label={intl.formatMessage({ defaultMessage: 'Non-registered participant email(s)' })}
              />
            ))
          ) : (
            <Autocomplete
              name="emails"
              options={[location?.state?.row]}
              getOptionLabel={(option: any) => option?.email}
              getOptionValue={(option: any) => option?.email}
              label={intl.formatMessage({ defaultMessage: 'Complimentary Participant' })}
              disabled={location?.state?.row}
              multiple
            />
          )}
          {therapists && !location?.state?.row && (
            <Autocomplete
              multiple
              options={therapists || []}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              name="otherTherapists"
              label={intl.formatMessage({ defaultMessage: 'Other Providers' })}
              disabled={therapists === undefined}
              hidden={!isDirectoryMode}
              getOptionDisabled={() => !enableMultipleParticipants && otherTherapists?.length === 1}
            />
          )}
          <Autocomplete
            multiple
            options={REMINDER_OPTIONS}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            label={intl.formatMessage({ defaultMessage: 'Reminders' })}
            name="reminders"
            hidden={!user?.userOptions?.enableAppointmentReminders}
          />
          {!location?.state?.row && (
            <SwitchField name="isRecurring" label={intl.formatMessage({ defaultMessage: 'This is a recurring appointment' })} />
          )}
          <RecurringFormFields />
          <OtherFields location={location?.state?.row} />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button type="button" variant="outlined" onClick={handleCancel}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            <FormattedMessage defaultMessage="Create Appointment" />
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

export function AppointmentInfoFields() {
  const { locationTypeLabels } = useLabels();
  const { userId: id } = useAuth();
  const { userId } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const therapistId = userId ?? queryParams.get('therapist') ?? id;
  const { provider } = useProvider(therapistId);
  const { user } = useUser(therapistId);
  const { watch, setValue } = useFormContext();
  const sessionType = watch('sessionType');
  const isOfficeVisible = sessionType === 'Office';
  const intl = useIntl();
  const serviceTypeId = watch('serviceTypeId');
  const [disabled, setDisabled] = useState(false);
  const [helperText, setHelperText] = useState('');
  const clinicalHours = provider?.provider.allowInBetweenAppointmentsTime;
  const selectedServiceType = watch('serviceTypeId');
  const services = useMemo(() => user?.provider.services ?? [], [user]);
  const serviceType = useMemo(
    () => services.find((service) => service._id === selectedServiceType) ?? null,
    [selectedServiceType, services]
  );
  const timeBetween = provider?.provider?.inBetweenAppointmentsTime || 0;

  // console.log('user ', user);

  useEffect(() => {
    if (!serviceType) {
      return;
    }

    if (serviceType?.durationInMinutes === 50 && clinicalHours) {
      setHelperText('This session is a standard clinical hour - (50 mins session + 10 mins for note taking related to client care)');
      setValue('duration', serviceType.durationInMinutes + 10);
      setDisabled(true);
    } else {
      setValue('duration', serviceType.durationInMinutes);
      setDisabled(false);
    }
    setValue('duration', serviceType.durationInMinutes);
  }, [serviceType, setValue, clinicalHours, timeBetween]);

  const durations = useMemo(() => {
    const preDuration = serviceType?.durationInMinutes;
    if (preDuration && !DURATION_OPTIONS.map((it) => it.value).includes(preDuration)) {
      return [...DURATION_OPTIONS, { value: preDuration, label: `${preDuration} minutes` }].sort((a, b) => a.value - b.value);
    }
    return DURATION_OPTIONS;
  }, [serviceType]);

  const location: any = useLocation();

  useEffect(() => {
    if (location?.state?.row) {
      setValue('serviceTypeId', user?.provider?.services?.find((e: any) => e?.isComplimentary)?._id);
      setValue('sessionType', location?.state?.row?.locationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  console.log('Current location row ', location?.state?.row?.locationType === 'Online');
  return (
    <>
      <DatePicker name="date" label={intl.formatMessage({ defaultMessage: 'Date' })} required />

      <TimePicker name="time" label={intl.formatMessage({ defaultMessage: 'Time' })} required />
      <Autocomplete
        required
        options={user?.provider.typeOfAppointments ?? []}
        getOptionLabel={(option) => {
          return locationTypeLabels[option] ?? option;
        }}
        isOptionEqualToValue={(option, value) => !!value && option === value}
        label={intl.formatMessage({ defaultMessage: 'Session Type' })}
        name="sessionType"
      />

      {isOfficeVisible && (
        <Autocomplete
          required
          options={user?.provider.offices ?? []}
          getOptionLabel={(option) => option.officeName}
          getOptionValue={(option) => option._id}
          label={intl.formatMessage({ defaultMessage: 'Location' })}
          name="officeId"
        />
      )}

      <Autocomplete
        required
        options={
          !location?.state?.row
            ? (user?.provider?.services?.filter((e) => !e.isComplimentary) as [])
            : (user?.provider?.services?.filter((e: any) => e.isComplimentary) as []) ?? []
        }
        getOptionLabel={(option: any) => `${option.name} [${option.durationInMinutes} min] ($${round(Number(option.rate), 3)})`}
        getOptionValue={(option) => (option ? option._id : '')}
        label={intl.formatMessage({ defaultMessage: 'Service Type' })}
        // disabled={location?.state}/
        name="serviceTypeId"
      />

      <Autocomplete
        required
        options={durations}
        disabled={disabled}
        getOptionValue={(option) => (option ? option.value : '')}
        getOptionLabel={(option) => (option ? option.label : '')}
        hasCreateOption
        getCreateOptionFromInput={(input: string) => {
          const num = Number(input);
          if (isNaN(num)) {
            return null;
          }
          return { value: num, label: `${num} minutes` };
        }}
        label={intl.formatMessage({ defaultMessage: 'Duration' })}
        name="duration"
      />
      {clinicalHours && serviceType?.durationInMinutes === 50 && !!helperText && (
        <Box>
          <FormattedMessage defaultMessage={'Note:'} /> {helperText}
        </Box>
      )}
    </>
  );
}

const repeatForOptions = range(1, 15).map((i) => ({
  id: i,
  label: `${i}`
}));

export function RecurringFormFields() {
  const intl = useIntl();
  const { control, setValue } = useFormContext();

  const repeatFrequency = useWatch({
    control,
    name: 'repeatFrequency',
    defaultValue: GET_REPEAT_FREQUENCY_OPTIONS()[0].value
  });

  const isRecurring = useWatch({
    control,
    name: 'isRecurring',
    defaultValue: false
  });

  const selectedDate = moment(
    useWatch({
      control,
      name: 'date'
    })
  ).toDate();

  useEffect(() => {
    setValue('repeatNumberOfTimes', repeatForOptions[0].id);
  }, [setValue]);

  useEffect(() => {
    if (repeatFrequency === 'Weeks') {
      setValue('repeatDays', [GET_DAYS_OF_WEEK()[selectedDate.getDay()].id]);
    } else {
      setValue('repeatDays', []);
    }
  }, [repeatFrequency, selectedDate, setValue]);

  if (!isRecurring) return null;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          required
          style={{ flex: 1 }}
          name="repeatNumberOfTimes"
          label={intl.formatMessage({ defaultMessage: 'Repeat for' })}
          options={repeatForOptions}
          getOptionLabel={(option) => (option ? option.label : '')}
          getOptionValue={(option) => (option ? option.id : '')}
          defaultValue={repeatForOptions[0]}
          filterOptions={(x) => x}
        />

        <Autocomplete
          required
          style={{ flex: 1 }}
          name="repeatFrequency"
          options={GET_REPEAT_FREQUENCY_OPTIONS()}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Stack>

      {repeatFrequency === 'Weeks' && (
        <Autocomplete
          required
          multiple
          name="repeatDays"
          options={GET_DAYS_OF_WEEK()}
          label={intl.formatMessage({ defaultMessage: 'Repeat on' })}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      )}

      {repeatFrequency === 'Months' && selectedDate && (
        <Autocomplete
          required
          name="repeatMonthDays"
          options={[
            {
              name: `${getMonthDayNumber(selectedDate)} ${format(selectedDate, 'EEEE')} of every month`,
              value: 'SameWeekDay'
            },
            { name: `${getNumberWithOrdinal(selectedDate.getDate())} of every month`, value: 'SameDate' }
          ]}
          label={intl.formatMessage({ defaultMessage: 'Repeat on' })}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      )}
    </>
  );
}

export function OtherFields({ location }) {
  const intl = useIntl();
  const { enableArchiving } = useOptions();
  const { watch } = useFormContext();
  const isOnline = watch('sessionType') === 'Online';

  return (
    <>
      {enableArchiving && isOnline && !location && (
        <SwitchField
          name="enableArchiving"
          label={intl.formatMessage({ defaultMessage: 'Record the session for later auditing or review' })}
        />
      )}
      <TextField
        name="message"
        label={intl.formatMessage({ defaultMessage: 'Include a message for the other participants' })}
        fullWidth
        multiline
        rows={5}
      />
    </>
  );
}
