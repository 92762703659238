import useAuth from 'hooks/useAuth';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PreviewDialog from 'views/components/modals/PreviewDialog';

export interface UseBlobViewParams {
  getLinkUrl: string;
  requestData?: unknown;
  name: string;
  mimeType: string;
  onLoading?: () => void;
  onLoaded?: () => void;
  watch?: Array<any>;
}

export default function useBlobView({ getLinkUrl, name, requestData, mimeType, onLoading, onLoaded, watch = [] }: UseBlobViewParams) {
  const { request } = useAuth();
  const getRequest = () => request;
  const [blob, setBlob] = useState<Blob | undefined>(undefined);

  const { isLoading, refetch } = useQuery(
    getLinkUrl,
    () => {
      const promiseFn = requestData
        ? () => getRequest().post(getLinkUrl, requestData, { responseType: 'blob' })
        : () => getRequest().get(getLinkUrl, { responseType: 'blob' });

      return promiseFn().then((r) => new Blob([r.data], { type: mimeType }));
    },
    { enabled: false, cacheTime: 0, refetchOnMount: true, refetchOnWindowFocus: true }
  );
  const { showModal } = useModal();

  const handleClick = useCallback(async () => {
    if (onLoading) {
      onLoading();
    }

    let blobToShow;
    if (!blob) {
      const fetch = await refetch();

      if (!fetch) {
        return;
      }

      blobToShow = fetch.data;

      setBlob(blobToShow);
    } else {
      blobToShow = blob;
    }

    const modal = showModal(PreviewDialog, {
      name,
      blob: blobToShow,
      onClose: () => modal.hide()
    });

    if (onLoaded) {
      onLoaded();
    }
  }, [showModal, refetch, blob, setBlob, name, onLoading, onLoaded]);

  useEffect(() => {
    setBlob(undefined); // Clear out the blob when the watch props change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...watch, setBlob]);

  return { isLoading, handleClick };
}
