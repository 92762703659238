import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
import { InvoiceSettings } from 'types/invoice';

export default function useInvoices({ userId }) {
  const { request } = useAuth();

  const {
    data: invoiceSettings,
    refetch,
    isLoading
  } = useQuery(
    `/invoiceSettings/${userId}`,
    () => request.get(`/therapists/${userId}/settings/invoicing`).then((response) => response.data as InvoiceSettings),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!userId
    }
  );

  const {
    data: invoices,
    refetch: refetchInvoices,
    isLoading: fetchLoading
  } = useQuery(`/user/${userId}/invoices`, () => request.get(`/user/${userId}/invoices`).then((response) => response.data.invoices));

  const { mutateAsync: updateInvoice, isLoading: updatedInvoiceLoading } = useMutation(
    `/user/${userId}/invoices`,
    (payload: any) => request.put(`/user/${userId}/invoices/${payload.invoiceId}`, payload),
    { onSuccess: () => refetchInvoices() }
  );

  return { invoiceSettings, refetchInvoiceSettings: refetch, isLoading, refetchInvoices, invoices, updateInvoice, updatedInvoiceLoading };
}
