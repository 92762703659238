import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParam } from 'react-use';
import Searchcategorylist from './Searchcategorylist';

function SearchHistory() {
  const searchString = useSearchParam('q');
  const [searchQuery, setSearchQuery] = useState(searchString ?? '');
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedArtcles, setSelectedArticles] = useState<any>(null);
  const [search, setSearch] = useState('');

  const { request } = useAuth();
  const {
    data: resourcesData,
    refetch: refetchResourcesData,
    isLoading
  } = useQuery(
    `/resources/search`,
    () =>
      request
        .get(`/resources/search`, {
          params: { q: searchQuery }
        })
        .then((response) => response.data),
    {
      onSuccess(data) {
        setSelectedCategory(data?.categories[0]?._id);
      }
    }
  );

  useEffect(() => {
    if (selectedCategory) {
      setSelectedArticles(
        resourcesData?.articles?.filter(
          (article: any) => article.resource === selectedCategory || article.resource[0]?._id === selectedCategory
        )
      );
    }
  }, [selectedCategory, resourcesData]);

  useEffect(() => {
    setSearch(searchQuery);
  }, [searchQuery]);

  return (
    <>
      <Searchcategorylist
        search={search}
        selectedArtcles={selectedArtcles}
        selectedCategory={selectedCategory}
        refetchResourcesData={refetchResourcesData}
        searchQuery={searchQuery}
        isLoading={isLoading}
        setSearchQuery={setSearchQuery}
        categories={resourcesData?.categories ?? []}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  );
}

export default SearchHistory;
