import { io } from 'socket.io-client';
import { store } from 'store';
import getSite from 'utils/site';
import { SET_SOCKET } from './../store/actions';

export const connectSocket = () => {
  const token = localStorage.getItem('serviceToken');

  let socket = io(`${process.env.REACT_APP_API_URL}`, {
    query: { token, site: getSite() },
    transports: ['websocket', 'polling', 'flashsocket']
  });

  socket?.on('connect', () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      socket?.emit('new-user-joined', { userId, site: getSite() });
    }
  });

  store.dispatch({ type: SET_SOCKET, payload: socket });
};

export const disconnectSocket = () => {
  const socket = (store.getState() as any)?.chat?.socket;

  socket?.emit('user-left');
  socket?.disconnect(true);
};
