import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { User } from 'types/user';

export default function useUsers({ includeDisabled = false, includeInactive = false, includeHidden = false, site = '' }) {
  const { request, userId } = useAuth();

  const { data, refetch, isLoading } = useQuery(
    ['/user', includeDisabled, includeInactive, includeHidden, site],
    () =>
      request
        .get('/user', {
          params: {
            includeDisabled,
            includeInactive,
            includeHidden,
            site
          }
        })
        .then((response) => response.data),
    {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      enabled: !!userId
    }
  );

  const users = useMemo(() => data?.profiles as User[], [data]);
  const sites = useMemo(() => data?.sites || [], [data]);

  const { mutateAsync: sendRegistrationLink, isLoading: isSendingRegLink } = useMutation(
    `/user/sendRegistrarLink`,
    (patientId) => request.get(`/user/${patientId}/sendRegistrarLink`).then((response) => response.data),
    {
      onSuccess: () => refetch()
    }
  );

  const { mutateAsync: updateUser, isLoading: updateLoading } = useMutation(
    '/user/update',
    ({ id, payload }: { id: string; payload: User }) => request.put(`/user/${id}`, payload).then((response) => response.data),
    { onSuccess: () => refetch() }
  );

  const { mutateAsync: deleteUser, isLoading: deleteLoading } = useMutation('/user/delete', (id: string) => request.delete(`/user/${id}`), {
    onSuccess: () => refetch()
  });

  const { mutateAsync: impersonate, isLoading: impersonateLoading } = useMutation(
    '/impersonate',
    (id: string) => request.post('/authentication/impersonate', { userId: id }).then((response) => response.data),
    { onSuccess: () => refetch() }
  );

  const { mutateAsync: updateTherapist, isLoading: changeTherapistLoading } = useMutation(
    '/administration/therapist-change',
    (payload) => request.post('/administration/therapist-change', payload),
    { onSuccess: () => refetch() }
  );

  const therapists = useMemo(
    () =>
      users
        ?.filter((u) => u.isProvider && u?.role !== 'PERMISSIONS_MANAGER')
        ?.map((user) => ({ ...user, fullName: `${user.firstName} ${user.lastName}` })) || [],
    [users]
  );

  const isAnyActiveUser = !!users?.find(
    (user: any) =>
      user?.role === 'PROVIDER_ADMINISTRATOR (S)' ||
      user?.role === 'PROVIDER_ADMINISTRATOR (D)' ||
      user?.role === 'PROVIDER' ||
      user?.role === 'ADMINISTRATOR'
  );

  const isProviderAdmin = !!users?.find(
    (user: any) => user?.role === 'PROVIDER_ADMINISTRATOR (S)' || user?.role === 'PROVIDER_ADMINISTRATOR (D)'
  );

  const moreThanOneProviderAdmin: any = users?.filter(
    (user: any) => user?.role === 'PROVIDER_ADMINISTRATOR (S)' || user?.role === 'PROVIDER_ADMINISTRATOR (D)'
  );

  const anyOtherUser = !!users?.find((user: any) => user?.role === 'PROVIDER' || user?.role === 'ADMINISTRATOR');

  const administrators = useMemo(() => users?.filter((user) => user.role === 'ADMINISTRATOR') || [], [users]);
  const usersWithName = useMemo(
    () =>
      users?.map((user) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
        // eslint-disable-next-line no-nested-ternary
        status: user.disabled ? 'Disabled' : user.inactive ? 'Inactive' : 'Active',
        createdDate: new Date(parseInt(user.id.toString().slice(0, 8), 16) * 1000).getTime(),
        therapistName: therapists.find((item) => item.id === user.therapist)?.fullName || ''
      })),
    [users, therapists]
  );

  const clinicStaff = useMemo(
    () => usersWithName?.filter((user) => user._id !== userId && !(user.isClient || user.isNousSupport)) ?? [],
    [usersWithName, userId]
  );

  return {
    users: usersWithName,
    sites,
    clinicStaff,
    refetchUsers: refetch,
    updateUser,
    sendRegistrationLink,
    deleteUser,
    administrators,
    // therapistsAndAdministrators: [...therapists, ...administrators],
    impersonate,
    updateTherapist,
    therapists,
    impersonateLoading,
    isLoading: isLoading || updateLoading || deleteLoading || changeTherapistLoading,
    isSendingRegLink,
    singleProviderInTheClinic: isProviderAdmin && !anyOtherUser && moreThanOneProviderAdmin?.length === 1,
    multipleProviderInTheClinic: moreThanOneProviderAdmin?.length > 1 || (isProviderAdmin && anyOtherUser),
    noProviderInTheClinic: !isProviderAdmin && !anyOtherUser,
    isAnyActiveUser
  };
}
