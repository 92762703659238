// material-ui
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';

// style constant
const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%',
    '& > * + *': {
      marginTop: 8
    }
  }
}));

// ==============================|| Loader ||============================== //

export type LoaderProps = LinearProgressProps;

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="primary" />
    </div>
  );
};

export default Loader;
