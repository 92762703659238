import FullCalendar, { FormatterInput } from '@fullcalendar/react';
import { useCallback, useRef, useState } from 'react';

export default function useCalendar(initialView?: string) {
  const calendarRef = useRef<FullCalendar>(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(initialView || 'dayGridMonth');
  const [duration, setDuration] = useState({ hours: 1 });
  const [slotLabelFormat, setSlotLabelFormat] = useState<FormatterInput>({
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: 'short'
  });

  // calendar toolbar events
  const handleDateToday = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
      calendarApi.changeView('timeGridDay');
      setView('timeGridDay');
    }
  }, [setDate]);

  const handleViewChange = useCallback(
    (newView: string, passedDate: any) => {
      const calendarEl = calendarRef.current;

      if (calendarEl) {
        const calendarApi = calendarEl.getApi();
        calendarApi.gotoDate(passedDate);
        calendarApi.changeView(newView);
        setView(newView);
      }
    },
    [setView]
  );

  const handleDatePrev = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, [setDate]);

  const handleDateNext = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, [setDate]);

  const getToolbarProps = useCallback(
    () => ({
      onClickToday: handleDateToday,
      onChangeView: handleViewChange,
      onClickPrev: handleDatePrev,
      onClickNext: handleDateNext,
      date,
      view
    }),
    [handleDateToday, handleViewChange, handleDatePrev, handleDateNext, date, view]
  );

  const getCalendarProps = useCallback(
    () => ({
      weekends: true,
      selectable: true,
      editable: true,
      ref: calendarRef,
      rerenderDelay: 10,
      initialDate: date,
      initialView: view,
      dayMaxEventRows: 3,
      eventDisplay: 'block',
      headerToolbar: undefined,
      allDayMaintainDuration: true,
      eventResizableFromStart: true
    }),
    [calendarRef, date, view]
  );

  return { getToolbarProps, getCalendarProps, setDuration, setSlotLabelFormat, setDate, duration, slotLabelFormat, date, view };
}
