/* eslint-disable no-nested-ternary */
import { FormControl, FormControlLabel, FormGroup, FormHelperText, Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';

export type SwitchFieldProps = Omit<SwitchProps, 'name'> & {
  name: string;
  label?: string;
  required?: boolean;
  parseError?: (error: FieldError) => string;
  helperText?: string;
  small?: boolean;
};

const SwitchField = React.memo(
  ({ required, parseError, label, name, disabled, small, ...rest }: SwitchFieldProps): JSX.Element => (
    <Controller
      name={name}
      render={({ field: { value, ref, onChange }, fieldState: { invalid, error } }) => (
        <FormControl error={invalid}>
          <FormGroup row>
            {label ? (
              <FormControlLabel
                checked={value}
                label={label ? `${label} ${required ? ' *' : ''}` : ''}
                name={name}
                value={value || false}
                onChange={onChange}
                inputRef={ref}
                control={<Switch size={small ? 'small' : 'medium'} />}
                disabled={disabled}
              />
            ) : (
              <Switch
                checked={value}
                name={name}
                value={value || false}
                onChange={onChange}
                inputRef={ref}
                disabled={disabled}
                size={small ? 'small' : 'medium'}
              />
            )}
            {invalid && (
              <FormHelperText error>
                {error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : rest.helperText}
              </FormHelperText>
            )}
          </FormGroup>
        </FormControl>
      )}
    />
  )
);

export default SwitchField;
