import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const ContactProviderDialog = () => {
  const [open, setOpen] = useState(true);
  return (
    <Dialog maxWidth="sm" fullWidth open={open} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
      <DialogTitle>
        <Typography variant="h4">
          <FormattedMessage defaultMessage="Cancellation policy in effect. Please contact your Provider to cancel or reschedule your appointment." />
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    </Dialog>
  );
};

export default ContactProviderDialog;
