import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { usePrevious } from 'react-use';
import { Option } from 'types/option';
import { default as useAuth, default as useRequest } from './useAuth';
import useTherapists from './useTherapists';
import useUser from './useUser';

interface Options {
  [key: string]: Option;
}

export default function useOptions(showSolo = false) {
  const intl = useIntl();
  const { request } = useRequest();
  const { therapists, therapistsLoading } = useTherapists();
  const { userId } = useAuth();
  const { user, isNousSupport } = useUser(userId);

  const {
    data,
    refetch,
    isLoading: fetchLoading,
    isRefetching
  } = useQuery(
    ['/options', isNousSupport],
    () =>
      request.get('', { params: { isNousSupport } }).then((response) => ({
        options: response.data.options as Options,
        roles: response.data.roles as string[],
        permissions: response?.data?.userPermissions as any
      })),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  const { options, roles, permissions } = data || {};

  const previousLocale = usePrevious(intl.locale);

  useEffect(() => {
    if (previousLocale && intl.locale !== previousLocale) {
      refetch();
    }
  }, [intl.locale, previousLocale, refetch]);

  const { mutateAsync: updateOptions, isLoading: updateLoading } = useMutation(
    '/options/update',
    (options) => request.put('/', { options }).then((response) => response.data.options as Options),
    { onSuccess: () => refetch() }
  );

  const switchOptions = options
    ? Object.keys(options)
        ?.map((key: string) => ({ ...options[key], key }))
        ?.filter((option: Option) => option.type === 'boolean')
        ?.sort((a: any, b: any) => a.index - b.index)
    : [];

  const selectOptions = options
    ? Object.keys(options)
        ?.map((key: string) => ({ ...options[key], key }))
        ?.filter((option: Option) => option.type === 'select')
    : [];

  const providerOption = options
    ? Object.keys(options)
        ?.map((key: string) => ({ ...options[key], key }))
        ?.find((option) => option.type === 'string' && option.key === 'DEFAULT_PROVIDER')
    : null;

  const logoOption = options
    ? Object.keys(options)
        ?.map((key: string) => ({ ...options[key], key }))
        ?.find((option) => option.type === 'picture')
    : null;

  // const providerRegistration = options?.ALLOW_PROVIDER_REGISTRATION?.value;

  let optionsByKey: any;
  if (options) {
    optionsByKey = {};
    Object.keys(options).forEach((key) => {
      optionsByKey[key] = options[key]?.value;
    });
  }

  const patientRegistrationEnabled = useMemo(
    () =>
      options?.ROLES_AND_PERMISSIONS?.value
        ? therapists?.find((obj) => !!obj.userOptions?.enableClientRegistration)
        : options?.ALLOW_PATIENT_REGISTRATION?.value,
    [options?.ALLOW_PATIENT_REGISTRATION?.value, options?.ROLES_AND_PERMISSIONS?.value, therapists]
  );

  if (showSolo && optionsByKey && optionsByKey?.DEFAULT_PROVIDER !== '0' && optionsByKey?.DEFAULT_PROVIDER !== '2') {
    optionsByKey.DEFAULT_PROVIDER = '1';
  }

  const isDirectoryMode = useMemo(() => {
    if (options?.DEFAULT_PROVIDER?.value === '0') {
      return true;
    } else {
      return false;
    }
  }, [options]);

  return {
    isLoading: !options || fetchLoading || updateLoading,
    isRefetching,
    options,
    optionsByKey,
    switchOptions,
    selectOptions,
    providerOption,
    isDefaultProvider: providerOption && providerOption.value !== '0' && providerOption.value !== '2',
    defaultProvider: optionsByKey?.DEFAULT_PROVIDER,
    enableArchiving: user?.userOptions?.canAccessRecordings || false,
    logoOption,
    // providerRegistration,
    updateOptions,
    refetch,
    roles,
    patientRegistrationEnabled,
    isDirectoryMode,
    permissions
  };
}
