import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';
import useUser from './useUser';

export default function usePendingICs() {
  const { request, userId } = useAuth();
  const { isClient } = useUser(userId);

  const {
    data: pendingICs,
    refetch,
    isLoading,
    isFetching,
    isRefetching
  } = useQuery(
    `/user/${userId}/pending-ics`,
    () => request.get(`/user/${userId}/pending-ics`).then((response) => response.data.therapists as any),
    {
      enabled: !!userId && isClient,
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  return {
    pendingICs,
    refetchPendingICs: refetch,
    isLoading: isLoading || isFetching || isRefetching
  };
}
