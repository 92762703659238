import { Box, Divider, IconButton, LinearProgress, Stack, TextField } from '@mui/material';
import { IconPaperclip, IconSend } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { ChangeEventHandler, Dispatch, KeyboardEventHandler, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import SessionMessage from './SessionMessage';

interface Props {
  unread: { group?: number };
  setUnread: Dispatch<SetStateAction<object>>;
  appointmentId: string | null | undefined;
  sessionId: string | null | undefined;
  sessionRef: any;
  floatingEmail: string | null;
  activeChat: any | null;
  messages: any[];
  screenName?: string;
  disableFileSharing: boolean;
}

export default function SessionChatDrawer({
  floatingEmail,
  unread,
  setUnread,
  appointmentId,
  sessionId,
  sessionRef,
  activeChat,
  messages,
  screenName,
  disableFileSharing
}: Props) {
  const [message, setMessage] = useState('');
  const { userId, request, guestRequest } = useAuth();
  const { user } = useUser(userId);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const onUploadProgress = (e) => {
    setUploadProgressPercent(Math.round((e.loaded * 100) / e.total));
  };

  const uploadMutation = useMutation(
    (formData: any) => {
      setIsUploading(true);
      setUploadProgressPercent(0);

      if (floatingEmail) {
        return guestRequest.post(`/session/${appointmentId}/floating/files`, formData, {
          params: { email: floatingEmail },
          onUploadProgress
        });
      }

      return request.post(`/user/${userId}/files`, formData, { onUploadProgress });
    },
    {
      onSuccess: () => {
        setIsUploading(false);
      }
    }
  );

  useEffect(() => {
    const tempUnread = { ...unread };
    if (activeChat === 'group') {
      delete tempUnread.group;
    } else {
      delete tempUnread[activeChat?._id || activeChat?.email];
    }
    setUnread(tempUnread);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUnread, messages]);

  const shouldMessageRender = useCallback(
    (message) => {
      if (activeChat !== 'group' && !message.receiver) {
        return false;
      }

      if (
        (!message.receiver && activeChat === 'group') ||
        ((message.sender === userId || message.sender === floatingEmail) &&
          (message.receiver === activeChat?._id || message.receiver === activeChat?.email)) ||
        ((message.receiver === userId || message.receiver === floatingEmail) &&
          (message.sender === activeChat?._id || message.sender === activeChat?.email))
      ) {
        return true;
      }
      return false;
    },
    [activeChat, floatingEmail, userId]
  );

  useEffect(() => {
    if (!messages.length) {
      return;
    }

    const message = messages[messages.length - 1];
    if (shouldMessageRender(message)) {
      scrollToBottom();
    }
  }, [messages, shouldMessageRender]);

  const scrollToBottom = () => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  const handleEnter: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== 'Enter' || message.trim() === '') {
      return;
    }
    handleOnSend();
  };

  const handleOnSend = () => {
    if (message.trim() === '') {
      return;
    }
    sessionRef.current?.signal({
      type: 'msg',
      data: JSON.stringify({
        id: Date.now(),
        date: new Date().toISOString(),
        from: screenName ?? floatingEmail ?? `${user?.firstName} ${user?.lastName}`,
        text: message,
        sender: userId ?? floatingEmail,
        receiver: activeChat?._id ?? activeChat?.email
      })
    });
    setMessage('');
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target?.files?.[0];

    if (!file) {
      return;
    }

    if (!appointmentId && !sessionId) {
      return;
    }

    if (file.size > 25000000) {
      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'File Size Limit Exceeded' }), { variant: 'error' });
      return;
    }

    const formData = new FormData();
    formData.set('sessionId', appointmentId ?? sessionId ?? '');
    formData.set('file', file);
    uploadMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        sessionRef.current?.signal({
          type: 'file',
          data: JSON.stringify({
            id: Date.now(),
            date: new Date().toISOString(),
            from: screenName ?? floatingEmail ?? `${user?.firstName} ${user?.lastName}`,
            sender: userId ?? floatingEmail,
            receiver: activeChat?._id ?? activeChat?.email,
            ...data
          })
        });
      }
    });
  };

  return (
    <Stack direction="column" sx={{ width: 430, height: '70vh' }}>
      <Box ref={scrollRef} sx={{ flex: 1, p: 3, overflow: 'auto' }}>
        <Stack spacing={3}>
          {messages.map((message) => {
            // Should message be shown to the user
            if (!shouldMessageRender(message)) {
              return null;
            }

            return <SessionMessage key={message.id} message={message} />;
          })}
        </Stack>
      </Box>
      {isUploading && <LinearProgress variant="determinate" value={uploadProgressPercent} />}
      <Divider />
      <Stack direction="row" alignItems="center" sx={{ p: 2 }}>
        <TextField
          fullWidth
          label={intl.formatMessage({ defaultMessage: 'Type a Message' })}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleEnter}
          sx={{ flex: 1, mr: 1 }}
        />
        {!disableFileSharing && (
          <IconButton component="label">
            <IconPaperclip />
            <input type="file" hidden onChange={handleFileChange} />
          </IconButton>
        )}
        <IconButton color="primary" onClick={handleOnSend}>
          <IconSend />
        </IconButton>
      </Stack>
    </Stack>
  );
}
