import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { IconPencil, IconTrash } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useUser from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { getErrorMessage } from 'utils/stringUtils';
import DialogClose from 'views/components/buttons/DialogClose';
import MainCard from 'views/components/cards/MainCard';
import CenteredLoading from 'views/components/CenteredLoading';
import FormContainer from 'views/components/inputs/FormContainer';
import AddCategoryDialog from './AddCategoryDialog';
import { default as useResource, default as useResources } from './useResources';

function Resources() {
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    color: 'black',
    margin: `0 0 ${grid}px 0`,
    background: 'lightgrey',
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '' : '',
    padding: grid,
    width: 600
  });
  const { resources, deleteCategory, reorderCategories, refetchResources } = useResource();
  const [items, setItems] = useState<any>(null);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return;
    }

    const reorderedItems = items ? Array.from(items) : [];
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    setItems(reorderedItems);
  };
  const { options, roles } = useOptions();
  const { isLoading } = useResources();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { isNousSupport } = useUser(userId);
  const intl = useIntl();
  const [createCategoriesModalOpen, setCreateCategoriesModalOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<any>(null);
  const [search, setSearch] = useState('');

  const [deleteId, setDeleteId] = useState(null);

  const hasEditAccess = useMemo(() => {
    if (isNousSupport && options?.MODIFY_RESOURCES?.value) {
      return true;
    }

    return false;
  }, [isNousSupport, options]);

  useEffect(() => {
    refetchResources();
  }, [intl.locale, refetchResources]);

  const handleSave = async () => {
    try {
      const reorderedItems = items ? items.map((item) => item._id) : [];
      const data = { resourceIds: reorderedItems };

      await reorderCategories(data);

      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'The category has been successfully deleted' }), { variant: 'success' });
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
    setOpen(false);
  };

  const handleReposition = () => {
    setOpen(true);
    setItems(resources);
  };

  const onFormSubmit = () => {
    if (search.trim() !== '') {
      navigate(`/resources/search?q=${encodeURIComponent(search.replace(/[^a-zA-Z0-9 ]/g, ''))}`);
    }
  };

  const DeleteDialog = ({ open, onClose, onDelete }) => {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle sx={{ pt: 0 }}>
          <FormattedMessage defaultMessage="Delete category" />
          <DialogClose onClose={onClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            <FormattedMessage defaultMessage="Are you sure to delete this category?" />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button onClick={onDelete} variant="contained">
            <FormattedMessage defaultMessage="Delete" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Dialog sx={{ cursor: 'pointer' }} open={open} onClose={() => setOpen(true)} fullWidth>
        <DragIndicatorIcon sx={{ color: '#337ab7', position: 'absolute', ml: 5, mt: 2 }} />
        <Typography sx={{ fontWeight: 700, fontSize: 20, textAlign: 'center', color: 'black', padding: 2 }}>
          <FormattedMessage defaultMessage={'Drag and Drop & Change the position of Categories'} />
        </Typography>
        <Divider sx={{ mt: 2 }} />

        <>
          <Divider />
        </>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {items.map((item, index) => (
                  <Draggable key={item._id} draggableId={item._id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <Typography sx={{ display: 'flex' }}>
                          <DragIndicatorIcon sx={{ color: '#337ab7' }} />
                          <Typography sx={{ mt: '2px', display: 'flex', flexWrap: 'wrap' }}>{item.title}</Typography>
                        </Typography>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <DialogActions>
          <Button variant="contained" onClick={handleSave}>
            <FormattedMessage defaultMessage={'Save'} />
          </Button>

          <Button variant="outlined" onClick={() => setOpen(false)}>
            <FormattedMessage defaultMessage={'Close'} />
          </Button>
        </DialogActions>
      </Dialog>
      <>
        <DeleteDialog
          open={!!deleteId}
          onClose={() => setDeleteId(null)}
          onDelete={async () => {
            await deleteCategory({ _id: deleteId });
            enqueueSnackbar(intl.formatMessage({ defaultMessage: `Category are success delete` }), { variant: 'error' });
            setDeleteId(null);
          }}
        />
        <MainCard sx={{ minHeight: '100%' }}>
          <FormContainer onSuccess={onFormSubmit}>
            <Stack spacing={2} sx={{ flexWrap: 'wrap', display: 'flex' }} m={2}>
              <Grid item sx={{ display: 'flex', justifyContent: 'end' }}>
                <TextField
                  sx={{ width: 280 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  placeholder={intl.formatMessage({ defaultMessage: 'Search Articles / Resources' })}
                  size="small"
                  name={''}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {/* Display your filtered results here */}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  sx={{ color: '#1e2915', fontSize: '35px', fontWeight: 'bold', lineHeight: '1.16em', wordSpacing: 2, textAlign: 'center' }}
                >
                  <FormattedMessage defaultMessage="How can we help you?" />
                </Typography>
              </Box>

              {hasEditAccess && (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button color="primary" variant="contained" sx={{ fontSize: 13 }} onClick={() => setCreateCategoriesModalOpen(true)}>
                    <FormattedMessage defaultMessage="Add category" />
                  </Button>
                  <Button variant="outlined" onClick={handleReposition} sx={{ fontSize: 13, ml: 1 }}>
                    <FormattedMessage defaultMessage={'Reposition Categories'} />
                  </Button>
                </Typography>
              )}
            </Stack>
          </FormContainer>

          {isLoading ? (
            <div style={{ width: '100%' }}>
              <CenteredLoading />
            </div>
          ) : (
            <>
              {(createCategoriesModalOpen || editingCategory) && (
                <AddCategoryDialog
                  editingCategory={editingCategory}
                  onClose={() => {
                    setCreateCategoriesModalOpen(false);
                    setEditingCategory(null);
                  }}
                />
              )}
            </>
          )}
          <Divider sx={{ mt: 3 }} />
          <Grid container sx={{ justifyContent: 'center' }} rowGap={3}>
            {resources?.map((item: any) => {
              const title = intl.locale === 'fr' ? item.titleFrench : item.title;
              const description = intl.locale === 'fr' ? item.descriptionFrench : item.description;
              const targetAudience =
                intl.locale === 'fr'
                  ? item.targetAudience
                    ? item.targetAudience
                        .map((item: any) => {
                          if (roles) {
                            const role: any = roles.find((e: any) => e.roleName === item);
                            return role?.roleNameFrench ?? '';
                          }
                          return '';
                        })
                        .filter((item) => item.length > 0)
                        .join(', ')
                    : ''
                  : item.targetAudience.join(', ') ?? '';

              return (
                <Fragment key={item._id}>
                  <Grid item md={4} key={item._id}>
                    <Paper
                      key={item._id}
                      sx={{
                        flexWrap: 'wrap',
                        height: '100%',
                        p: 1,
                        pt: 0,
                        pb: 1,
                        m: 2,
                        borderColor: theme.palette.primary[200],
                        borderStyle: 'solid',
                        borderWidth: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                      elevation={5}
                    >
                      <Box sx={{ pb: 0 }}>
                        <Stack>
                          {hasEditAccess && (
                            <Stack direction="row" alignSelf="flex-end" alignItems="center">
                              {item.isDraft && (
                                <Typography
                                  sx={{
                                    color: '#f00',
                                    fontWeight: '500',

                                    borderWidth: 1.5,
                                    borderColor: '#f00',
                                    borderStyle: 'solid',
                                    borderRadius: 4,
                                    mt: 0.4,
                                    mx: 0.5,
                                    py: 0.4,
                                    px: 2
                                  }}
                                >
                                  <FormattedMessage defaultMessage="Draft" />
                                </Typography>
                              )}
                              <Tooltip title={intl.formatMessage({ defaultMessage: 'Edit' })}>
                                <IconButton onClick={() => setEditingCategory({ ...item })}>
                                  <IconPencil />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={intl.formatMessage({ defaultMessage: 'Delete' })}>
                                <IconButton onClick={() => setDeleteId(item._id)}>
                                  <IconTrash />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}

                          <Typography
                            variant="h2"
                            fontSize={20}
                            textAlign="center"
                            sx={{ flex: 1, pt: 2, '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}
                            onClick={() => {
                              navigate(`/resources/${item.url}`);
                            }}
                          >
                            {title}
                          </Typography>
                        </Stack>

                        <Box key={item._id} p={3} pb={0} m={1} sx={{ width: '100%' }}>
                          <Typography textAlign={'center'}>{description}</Typography>

                          {hasEditAccess && (
                            <Typography sx={{ mt: 2, md: 4, fontSize: 12, color: '#333', fontWeight: '500' }}>{targetAudience}</Typography>
                          )}

                          <Stack
                            alignItems="flex-start"
                            mt={2}
                            sx={{
                              '& button:hover': {
                                textDecorationLine: 'underline'
                              }
                            }}
                          >
                            {item.articles?.map((article: any) => (
                              <Fragment key={article._id}>
                                <Button
                                  key={article._id}
                                  sx={{
                                    fontSize: 14,
                                    textAlign: 'left'
                                  }}
                                  onClick={() => {
                                    navigate(`/resources/${item.url}/${article.url}`);
                                  }}
                                >
                                  {article.title}
                                </Button>
                              </Fragment>
                            ))}
                          </Stack>
                        </Box>
                      </Box>

                      <Button
                        sx={{ pb: 3 }}
                        onClick={() => {
                          navigate(`/resources/${item.url}`);
                        }}
                        size="large"
                      >
                        <Typography sx={{ fontWeight: 'bold', display: 'flex' }}>
                          {isNousSupport && (item.articles.length === 0 ? <FormattedMessage defaultMessage={'Add Articles'} /> : null)}

                          {item.articles.length === 0 ? null : (
                            <>
                              {item.articlesCount}
                              <Typography sx={{ pl: '3px', fontWeight: 'bold' }}>
                                <FormattedMessage defaultMessage={'Articles'} />
                              </Typography>
                            </>
                          )}
                        </Typography>
                      </Button>
                    </Paper>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </MainCard>
      </>
    </>
  );
}

export default Resources;
