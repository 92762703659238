import { Box, Button, Grid, IconButton, List, ListItemButton, ListItemText, Stack, Tooltip } from '@mui/material';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import useInvoices from 'hooks/useInvoices';
import useSignature from 'hooks/useSignature';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Rnd } from 'react-rnd';
import { usePrevious } from 'react-use';
import MainCard from 'views/components/cards/MainCard';
import CenteredLoading from 'views/components/CenteredLoading';
import NoResults from 'views/components/common/NoResults';
import SessionNoteForm from './SessionNoteForm';
import { SessionData } from './useSessionData';
import useSessionNotes from './useSessionNotes';

interface Props {
  sessionData: SessionData;
  onClose: () => void;
}

export default function SessionNotes({ sessionData, onClose }: Props) {
  const intl = useIntl();
  const notesRef = useRef<HTMLDivElement>(null);

  const { userId, request } = useAuth();
  const { notes, refetchNotes } = useSessionNotes(sessionData.appointmentId, userId);
  const previousNotes = usePrevious(notes);
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [notesHidden, setNotesHidden] = useState(false);
  const [notesWidth, setNotesWidth] = useState(645);
  const [notesHeight, setNotesHeight] = useState(590);
  const deleteNoteMutation = useMutation((noteId: string) => request.delete(`user/${userId}/notes/${noteId}`), {
    onSuccess: () => refetchNotes()
  });

  const { invoiceSettings } = useInvoices({ userId }) || {};
  const { binarySignature } = useSignature({ userId, enabled: invoiceSettings?.hasSignature });

  useEffect(() => {
    if (!notes?.length) {
      setSelectedNoteId(null);
      return;
    }

    if (previousNotes && notes.length > previousNotes.length) {
      setSelectedNoteId(notes.slice(-1)[0]._id);
    } else {
      setSelectedNoteId((prevId) => notes?.find((note) => note?._id === prevId)?._id ?? null);
    }
  }, [notes, previousNotes]);

  const renderNoteList = useCallback(() => {
    if (!notes) {
      return <CenteredLoading />;
    }

    if (!notes.length) {
      return (
        <NoResults>
          <FormattedMessage defaultMessage="No notes found" />
        </NoResults>
      );
    }

    return (
      <Box
        sx={{
          overflowY: 'scroll',
          height: notesHeight * 0.8,
          paddingBottom: 5
        }}
        m={0}
        p={0}
      >
        <List disablePadding sx={{ pb: 4 }}>
          {notes?.map((note) => (
            <ListItemButton key={note._id} selected={note._id === selectedNoteId} onClick={() => setSelectedNoteId(note._id)}>
              <ListItemText primary={note.title} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    );
  }, [notes, selectedNoteId, setSelectedNoteId, notesHeight]);

  return (
    <Rnd
      default={{
        x: 250,
        y: 50,
        width: notesWidth,
        height: notesHeight
      }}
      minWidth={645}
      minHeight={590}
      style={{ zIndex: 1100 }}
      onResize={(e, direction, ref, delta, position) => {
        setNotesWidth(ref.offsetWidth);
        setNotesHeight(ref.offsetHeight);
      }}
      cancel=".main-editor-custom"
    >
      <Box
        sx={{
          width: notesWidth,
          height: notesHeight
        }}
      >
        <Box
          style={{
            backgroundColor: '#eeeeeef5',
            height: '100%',
            paddingLeft: notesHidden ? 32 : 0,
            border: '1px solid gray'
          }}
          sx={(theme) => ({ borderRadius: theme.shape.borderRadius })}
        >
          <Grid container spacing={3} sx={{ p: 3, height: '100%' }}>
            {notesHidden && (
              <Tooltip title={intl.formatMessage({ defaultMessage: 'Show Notes' })}>
                <IconButton
                  color="primary"
                  onClick={() => setNotesHidden(!notesHidden)}
                  style={{ position: 'absolute', left: 10, top: 75, backgroundColor: '#fff' }}
                >
                  {notesHidden ? <IconChevronsRight size={24} /> : <IconChevronsLeft size={24} />}
                </IconButton>
              </Tooltip>
            )}
            {!notesHidden && (
              <Grid ref={notesRef} item md={notesHidden ? 0 : 4} style={{ height: '100%' }}>
                <MainCard
                  sx={{ height: '100%' }}
                  title={
                    selectedNoteId && (
                      <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => deleteNoteMutation.mutate(selectedNoteId)}
                          disabled={deleteNoteMutation.isLoading}
                        >
                          <FormattedMessage defaultMessage="Delete" />
                        </Button>
                      </Stack>
                    )
                  }
                  headerSX={{ padding: 2 }}
                  contentSX={{ padding: notes?.length ? 0 : 3, height: '100%' }}
                >
                  <Box sx={{ display: 'flex', p: 0, justifyContent: 'flex-end' }}>
                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Hide Notes' })}>
                      <IconButton color="primary" onClick={() => setNotesHidden(!notesHidden)}>
                        <IconChevronsLeft size={24} />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {renderNoteList()}
                </MainCard>
              </Grid>
            )}
            <Grid item md={notesHidden ? 12 : 8} sx={{ height: '100%' }}>
              <MainCard style={{ width: '100%', height: '100%' }}>
                <SessionNoteForm
                  key={selectedNoteId ?? 0}
                  noteId={selectedNoteId}
                  sessionData={sessionData}
                  onSubmitSuccess={refetchNotes}
                  onClose={onClose}
                  notesHeight={notesHeight}
                  binarySignature={binarySignature}
                />
              </MainCard>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Rnd>
  );
}
