// material-ui
import { Box, Grid, Typography } from '@mui/material';
import useOptions from 'hooks/useOptions';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

export interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
  maxWidth?: Number;
}

const Logo = ({ size = 'md', maxWidth }: LogoProps) => {
  const { logoOption } = useOptions();

  if (!logoOption) return null;

  let sx = {
    height: 40,
    maxWidth: maxWidth ?? 80
  };

  if (size === 'md') {
    sx = {
      height: 60,
      maxWidth: maxWidth ?? 100
    };
  }

  if (size === 'lg') {
    sx = {
      height: 80,
      maxWidth: maxWidth ?? 280
    };
  }

  return (
    <Grid container alignItems="center">
      {logoOption?.value && (
        <Box
          sx={{
            ...(sx as any),
            alignItems: 'center',
            display: 'flex',

            '& > img': {
              maxWidth: '100%',
              maxHeight: '100%'
            }
          }}
        >
          <img src={logoOption?.value + `?${_.random(1, 10000000)} `} alt="" />
        </Box>
      )}
      {!logoOption?.value && (
        <Typography variant="h3" color="primary">
          <FormattedMessage defaultMessage="NousTalk" id="wG49qc" />
        </Typography>
      )}
    </Grid>
  );
};

export default Logo;
