import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';

export default function useUserPayment(userId?: string) {
  const { request } = useAuth();

  const {
    data: userPayment,
    refetch,
    isLoading: fetchLoading
  } = useQuery(`/user/${userId}/payment`, () => request.get(`/user/${userId}/payment`).then((response) => response.data.payment), {
    enabled: !!userId
  });

  const { mutateAsync: connectPaymentCard, isLoading: conectPaymentLoading } = useMutation(
    `/user/${userId}/payment/add`,
    (payload: any) => request.put(`/user/${userId}/payment`, payload),
    { onSuccess: () => refetch() }
  );

  const { mutateAsync: deletePaymentCard, isLoading: deleteLoading } = useMutation(
    `/user/${userId}/payment/delete`,
    () => request.delete(`/user/${userId}/payment`),
    { onSuccess: () => refetch() }
  );

  return {
    userPayment,
    connectPaymentCard,
    deletePaymentCard,
    isLoading: fetchLoading || conectPaymentLoading || deleteLoading
  };
}
