import draftToHtml from 'draftjs-to-html';
import { isEmpty, upperCase } from 'lodash';

export const stripeHtml = (html: string) => html.replace(/<[^>]+>/g, '');

export const getErrorMessage = (e: any) => {
  if (!isEmpty(e.response.data?.errors)) {
    return (Object.values(e.response.data?.errors) as any)[0][0];
  }
  return e.response.data?.message ?? e.response.data?.title ?? e.response.data?.error ?? e.message;
};

export const caseInsensitiveIncludes = (str: string, searchStr: string) => str.toLowerCase().includes(searchStr.toLowerCase());

export const getHtmlFromDraftRawState = (json: string) => {
  try {
    return draftToHtml(JSON.parse(json));
  } catch {
    return '';
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

export const formatCurrency = (value: number, currency: string | undefined = 'CAD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  });

  return formatter.format(value);
};

export const getTypeFromName = (name: string | null | undefined) => upperCase((name ?? '').split('.').slice(-1)[0]);
