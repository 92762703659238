/**
 * Participants list, Settings tabs
 */
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { Avatar, Badge, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCamera, IconCameraOff, IconMessage2, IconMicrophone, IconMicrophoneOff, IconX } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface SessionData {
  sessionRef: any;
  participants?: any[];
  floatingParticipants?: any[];
  setActiveChat: Function;
  floatingEmail: string | null;
  connectedUsers?: {};
  unread: {};
  oneToOneChatEnabled?: boolean;
  isOwner: boolean;
  isProvider: boolean;
  ownerId: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 18,
    '& .userAvatarBox': {
      width: 50,
      height: 50,
      marginRight: 12,
      position: 'relative',
      '& .userAvatar': {
        backgroundColor: '#ccc',
        width: 50,
        height: 50
      },
      '& .onlineIcon': {
        position: 'absolute',
        right: -2,
        bottom: -2,
        fontSize: 20,
        color: '#090'
      }
    },
    '& .participantText': {
      flex: 1,
      maxWidth: 160,
      wordWrap: 'break-word'
    },
    '& .participantsControls': {
      minWidth: 150,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& .icon': {
        color: 'grey'
      }
    }
  }
});

function ParticipantsList({
  sessionRef,
  participants,
  floatingParticipants,
  setActiveChat,
  floatingEmail,
  connectedUsers = {},
  unread,
  oneToOneChatEnabled,
  isOwner: isLoggedUserOwner,
  isProvider: isLoggedUserTherapist,
  ownerId
}: SessionData) {
  const classes = useStyles();
  const intl = useIntl();

  const { userId } = useAuth();
  const { user: loggedUser } = useUser(userId);

  const getName = (screenName, user, email, signalScreenName) => {
    if (signalScreenName) {
      return signalScreenName;
    }
    if (screenName) {
      return screenName;
    }
    if (user?.firstName) {
      return `${user?.firstName} ${user?.lastName}`;
    }
    return email.match(/^([^@]*)@/)[1];
  };

  const renderParticipantsListItem = useCallback(
    (item: any) => {
      const {
        user,
        email,
        screenName,
        _id
        // challenge
      } = item;

      const isUserOwner = user?._id === ownerId;
      const isUserTherapist = user?.isProvider;
      const isMe = (loggedUser?.id && user?._id === loggedUser?.id) || (!loggedUser?.id && email === floatingEmail);

      const name = getName(screenName, user, email, connectedUsers && connectedUsers[user?._id ?? email]?.screenName);
      const userActive = connectedUsers && connectedUsers[user?._id ?? email];

      /**
       * Mute audio of participant
       */
      const mutePariticipant = (id, email, value) => {
        if (!isLoggedUserTherapist) {
          return;
        }

        sessionRef.current?.signal({
          type: 'mute',
          data: JSON.stringify({ id, email, value })
        });
      };

      /**
       * Mute video of participant
       */
      const mutePariticipantCam = (id, email, value) => {
        if (!isLoggedUserTherapist) {
          return;
        }

        sessionRef.current?.signal({
          type: 'muteCam',
          data: JSON.stringify({ id, email, value })
        });
      };

      /**
       * Remove participant from session
       */
      const removePariticipant = (id, email, value) => {
        if (!isLoggedUserTherapist) {
          return;
        }

        sessionRef.current?.signal({
          type: 'removeParticipant',
          data: JSON.stringify({ id, email, value })
        });
      };

      const getVideoIconToolTip = () => {
        if (!connectedUsers) {
          return intl.formatMessage({ defaultMessage: 'Video' });
        }
        if (isLoggedUserOwner) {
          if (connectedUsers[user?._id ?? email]?.publishVideo) {
            return intl.formatMessage({ defaultMessage: 'Video Off' });
          }
          return intl.formatMessage({ defaultMessage: 'Video On' });
        }

        if (connectedUsers[user?._id ?? email]?.publishVideo) {
          return intl.formatMessage({ defaultMessage: 'Video On' });
        }
        return intl.formatMessage({ defaultMessage: 'Video Off' });
      };

      const getMicIconToolTip = () => {
        if (!connectedUsers) {
          return intl.formatMessage({ defaultMessage: 'Mic' });
        }
        if (isLoggedUserOwner) {
          if (connectedUsers[user?._id ?? email]?.publishAudio) {
            return intl.formatMessage({ defaultMessage: 'Mute' });
          }
          return intl.formatMessage({ defaultMessage: 'Unmute' });
        }

        if (connectedUsers[user?._id ?? email]?.publishAudio) {
          return intl.formatMessage({ defaultMessage: 'Unmute' });
        }
        return intl.formatMessage({ defaultMessage: 'Muted' });
      };

      const shouldChatButtonRender = () => {
        if (oneToOneChatEnabled || isLoggedUserTherapist || isUserTherapist) {
          return true;
        }
        return false;
      };

      return (
        <Box key={_id} className={classes.root}>
          <Box className="userAvatarBox">
            <Avatar alt={String(name)} src={user?.imageUrl} className="userAvatar">
              {String(name)?.charAt(0)?.toUpperCase()}
            </Avatar>
            {!!userActive && <FiberManualRecord className="onlineIcon" />}
          </Box>

          <Box className="participantText">
            <div>
              {name}
              {isMe && ' (Me)'}
            </div>
            {isUserTherapist && (
              <Typography variant="caption">
                <FormattedMessage defaultMessage={'Provider'} />
              </Typography>
            )}
          </Box>

          {!!userActive && !isMe && (
            <Box className="participantsControls">
              {shouldChatButtonRender() && (
                <Tooltip title={intl.formatMessage({ defaultMessage: 'Chat' })}>
                  <IconButton
                    onClick={() => {
                      setActiveChat({ name, _id: user?._id, email });
                    }}
                  >
                    <Badge color="error" badgeContent={unread[user?._id ?? email]}>
                      <IconMessage2 style={{ width: 30, height: 30 }} />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={getVideoIconToolTip()}>
                <IconButton
                  onClick={() => {
                    mutePariticipantCam(user?._id, email, !connectedUsers[user?._id ?? email]?.publishVideo);
                  }}
                  style={isLoggedUserTherapist ? {} : { backgroundColor: 'white', cursor: 'default' }}
                >
                  {!connectedUsers[user?._id ?? email]?.publishVideo ? (
                    <IconCameraOff style={{ width: 26, height: 26 }} />
                  ) : (
                    <IconCamera style={{ width: 26, height: 26 }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={getMicIconToolTip()}>
                <IconButton
                  onClick={() => {
                    mutePariticipant(user?._id, email, !connectedUsers[user?._id ?? email]?.publishAudio);
                  }}
                  style={isLoggedUserTherapist ? {} : { backgroundColor: 'white', cursor: 'default' }}
                >
                  {!connectedUsers[user?._id ?? email]?.publishAudio ? (
                    <IconMicrophoneOff style={{ width: 26, height: 26 }} />
                  ) : (
                    <IconMicrophone style={{ width: 26, height: 26 }} />
                  )}
                </IconButton>
              </Tooltip>
              {isLoggedUserOwner && (
                <Tooltip title={intl.formatMessage({ defaultMessage: 'Remove Participant' })}>
                  <IconButton
                    onClick={() => {
                      removePariticipant(user?._id, email, true);
                    }}
                  >
                    <IconX style={{ width: 26, height: 26 }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
      );
    },
    [
      classes.root,
      setActiveChat,
      floatingEmail,
      loggedUser?.id,
      connectedUsers,
      unread,
      oneToOneChatEnabled,
      isLoggedUserOwner,
      isLoggedUserTherapist,
      ownerId,
      intl,
      sessionRef
    ]
  );

  return (
    <div>
      <div>{participants?.map(renderParticipantsListItem)}</div>
      <div>{floatingParticipants?.map(renderParticipantsListItem)}</div>
    </div>
  );
}

export default ParticipantsList;
