import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, RadioProps } from '@mui/material';
import { red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent } from 'react';
import { FieldError, useController } from 'react-hook-form';

const useStyles = makeStyles({
  root: {
    color: red[400]
  }
});

export type RadioButtonGroupProps = {
  options: any[];
  helperText?: string;
  name: string;
  required?: boolean;
  parseError?: (error: FieldError) => string;
  label?: string;
  labelKey?: string;
  labelKeyFrench?: string;
  valueKey?: string;
  type?: 'number' | 'string';
  emptyOptionLabel?: 'string';
  onChange?: (value: any) => void;
  returnObject?: boolean;
  row?: boolean;
  preferredLanguage?: any;
  getOptionLabel?: (option: any) => string;
};

const RadioButtonGroup = React.memo(
  ({
    helperText,
    options,
    label,
    name,
    parseError,
    labelKey = 'label',
    labelKeyFrench = 'labelFrench',
    valueKey = 'id',
    required,
    emptyOptionLabel,
    getOptionLabel,
    returnObject,
    row,
    preferredLanguage,
    ...rest
  }: RadioButtonGroupProps): JSX.Element => {
    const classes = useStyles();
    const {
      field: { value, onChange },
      fieldState: { invalid, error }
    } = useController({
      name,
      rules: required ? { required: 'This field is required' } : undefined
    });

    // eslint-disable-next-line no-nested-ternary
    helperText = error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : helperText;

    const radioProps: RadioProps = {};
    if (invalid) {
      radioProps.className = classes.root;
    }

    const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
      const radioValue = (event.target as HTMLInputElement).value;
      const returnValue = returnObject ? options.find((items) => items[valueKey] === radioValue) : radioValue;
      // setValue(name, returnValue, { shouldValidate: true })
      onChange(returnValue);
      if (typeof rest.onChange === 'function') {
        rest.onChange(returnValue);
      }
    };

    return (
      <FormControl error={invalid}>
        {label && (
          <FormLabel required={required} error={invalid}>
            {label}
          </FormLabel>
        )}
        <RadioGroup onChange={onRadioChange} name={name} row={row} value={value || ''}>
          {emptyOptionLabel && <FormControlLabel control={<Radio {...radioProps} checked={!value} />} label={emptyOptionLabel} value="" />}
          {options.map((option: any, i) => {
            const optionKey = option[valueKey];
            let optionLabel;
            if (!optionKey) {
              console.error(`CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`, option);
            }
            if (getOptionLabel) {
              optionLabel = getOptionLabel(option);
            } else {
              optionLabel = option[preferredLanguage === 'french' ? labelKeyFrench : labelKey] || option[labelKey];
            }
            const isChecked = !!(value && (returnObject ? value[valueKey] === optionKey : value === optionKey));
            return (
              <FormControlLabel
                control={<Radio {...radioProps} checked={isChecked} />}
                value={optionKey}
                label={optionLabel}
                key={optionKey}
              />
            );
          })}
        </RadioGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default RadioButtonGroup;
