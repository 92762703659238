import { Alert, Grid, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';

const PoorConnection = () => {
  const theme = useTheme();
  const { userId } = useAuth();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Alert severity="warning" variant="filled" sx={{ color: theme.palette.grey[800], bgcolor: theme.palette.warning.dark }}>
          <FormattedMessage defaultMessage="You Internet connection is poor, it might cause audio video delay" />{' '}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default PoorConnection;
