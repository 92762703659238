import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export default function useAdminDashboardStatus(filter, startDate?, endDate?) {
  const { request, userId } = useAuth();
  const {
    data: { sessionStatus, percentageDifference } = {},
    refetch,
    isLoading,
    isFetching,
    isRefetching
  } = useQuery(
    '/dashboard-admin/status',
    () =>
      request
        .get('/dashboard-admin/status', {
          params: { filter, userId, startDate, endDate }
        })
        .then((response) => response.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      select: (data) => ({
        sessionStatus: data.sessionStatus,
        percentageDifference: data.percentageDifference
      })
    }
  );

  return {
    sessionStatus,
    percentageDifference,
    refetchStatus: refetch,
    isLoadingStatus: isLoading || isFetching || isRefetching
  };
}
