import { Alert, Grid, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import useCanBook from 'views/pages/availability/useCanBook';

const CCRequired = () => {
  const theme = useTheme();
  const { userId } = useAuth();
  const { ccRequired, isLoading } = useCanBook(userId);

  if (ccRequired && !isLoading) {
    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ mb: 4 }}>
          <Alert severity="warning" variant="filled" sx={{ color: theme.palette.grey[800], bgcolor: theme.palette.warning.dark }}>
            <FormattedMessage defaultMessage="You have not yet added a payment method. You will not be able to schedule appointments with your provider until you do." />{' '}
            <Link to="/account?tab=payment">
              <FormattedMessage defaultMessage="Click Here" />
            </Link>{' '}
            <FormattedMessage defaultMessage="to add it." />{' '}
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default CCRequired;
