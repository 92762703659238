import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EraseIcon from 'assets/images/call/erase_icon.png';
import pencilImg from 'assets/images/call/mono-point.png';
import classNames from 'classnames';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { DefaultRootStateProps } from 'types';
import BasicDialog from 'views/components/modals/BasicDialog';
import './index.scss';

import { IconDotsVertical } from '@tabler/icons';
import cursorIcon from 'assets/images/call/minus-vertical.svg';
import Controls from './Controls';

let drawing;
const current = {
  x: 0,
  y: 0
};

let currentPos = { x: 0, y: 0 };
let prevPos = { x: 0, y: 0 };

let draggingTextBox = false;
let draggingImageBox = false;
let resizingImageBox = false;

let steps: [{ signal; data; x: Number; y: Number }] | any = [];
const redoSteps: any = [];
let scale = 0.55;
let inverseScale = 0.55;
let containerScale = 0.55;
let newScreenshot = false;
const pointPrecision = 3;

let textBoxBoundaries = {
  x: 0,
  y: 0,
  width: 200,
  height: 50
};

let imageBoxBoundaries = {
  x: 50,
  y: 50,
  width: 200,
  height: 200
};

let textCursorOffset = {
  left: 0,
  top: 0
};

let dragAnchor = {
  x: 0,
  y: 0
};

let typing = false;
let typedText = '';

const WhiteBoard = ({
  containerRef,
  subscribersRef,
  whiteboardOpen,
  whiteboardOpenRef,
  setWhiteBoardOpen,
  sessionRef,
  annotate,
  isProvider,
  isOwner,
  appointmentId,
  setDialogMessage,
  showPromptSave,
  setShowPromptSave,
  closingAnnotation,
  setClosingAnnotation,
  chatOpen,
  changeLayout,
  floatingEmail,
  sessionData
}) => {
  const theme = useTheme();
  // Handle left drawer
  const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);

  const [activeCursor, setActiveCursor] = useState(`url(${pencilImg}), auto`);

  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [strokeWidth, setStrokeWidth, strokeWidthRef] = useStateRef<number>(1);
  const [eraseActive, setEraseActive, eraseActiveRef] = useStateRef<boolean>(false);
  const [color, setColor, colorRef] = useStateRef({
    rgb: { r: 220, b: 10, g: 34, a: 1 }
  });
  const [pencilActive, setPencilActive] = useState<boolean>(false);
  const [textActive, setTextActive, textActiveRef] = useStateRef<boolean>(false);
  const [activeImage, setActiveImage, activeImageRef] = useStateRef<null | undefined | File>(null);
  const [anchorShapeEl, setAnchorShapeEl] = useState<HTMLElement | null>(null);
  const [activeShape, setActiveShape, activeShapeRef] = useStateRef<string | null>(null);
  const [textBoxActive, setTextBoxActive, textBoxActiveRef] = useStateRef(false);
  const [anchorSelectBoxMenuEl, setSelectTextBoxMenuEl] = useState<null | HTMLElement>(null);
  const [selectBoxMenuPosition, setSelectBoxMenuPosition] = useState({ x: 0, y: 0 });
  const [selectBoxControlsVisible, setSelectBoxControlsVisible] = useState(false);

  const { userId, request, guestRequest } = useAuth();
  const { user: loggedUser } = useUser(userId);

  const annotationBoardRef = useRef<HTMLCanvasElement>();
  const annotationBoard2Ref = useRef<HTMLCanvasElement>();
  const screenshotCanvasRef = useRef<HTMLCanvasElement>();
  const [screenshot, setScreenshot] = useState<string>();
  const [originalScreenshot, setOriginalScreenshot] = useState<string>();

  const saveAnnotationMutation = useMutation((variables: any) => request.post(`/annotations/session/${appointmentId}`, variables));
  const updateAnnotationMutation = useMutation((variables: any) => request.put(`/annotations/session/${appointmentId}`, variables));

  const clearAfterSavingRef = useRef(false);
  const intl = useIntl();

  const prefetchedImagesRef = useRef<any>({});

  const { participants } = sessionData;
  // const {}=sessionData();

  const recordIds = participants
    .filter((participant: any) => participant.user.role !== 'CLIENT')
    .map((participant: any) => participant.user.recordId);

  const uploadAnnotationMutation = useMutation(
    (variables: any) => request.post(`/user/${userId}/files`, variables, { headers: { 'content-type': 'multipart/form-data' } }),
    {
      onSuccess: ({ data }) => {
        const { file } = data;
        if (newScreenshot) {
          saveAnnotationMutation.mutateAsync({ file: file._id });
        } else {
          updateAnnotationMutation.mutateAsync({ file: file._id });
        }

        newScreenshot = false;
      }
    }
  );

  const uploadImage = ({ file, x0, y0, x1, y1 }: { file: File; x0: number; y0: number; x1: number; y1: number }) => {
    const formData = new FormData();
    formData.set('sessionId', appointmentId ?? '');
    formData.set('file', file);
    uploadMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        setSelectBoxControlsVisible(false);

        const canvas = annotationBoardRef.current!;
        const rect = canvas.getBoundingClientRect()!;

        const w = rect.width;
        const h = rect.height;
        sendSignal({
          type: 'drawingImage',
          data: JSON.stringify({
            x0: (x0 / w).toPrecision(pointPrecision),
            y0: (y0 / h).toPrecision(pointPrecision),
            x1: (x1 / w).toPrecision(pointPrecision),
            y1: (y1 / h).toPrecision(pointPrecision),
            url: data.url,
            timestamp: new Date().getTime()
          })
        });
      }
    });
  };

  useEffect(() => {
    const saveScreenshot = (base64) => {
      if (!base64 || !appointmentId) {
        return;
      }

      fetch(base64)
        .then((res) => res.blob())
        .then(async (blob) => {
          const formData = new FormData();
          const fileName = `Annotation-${new Date().getTime()}.jpeg`;
          const file = new File([blob], fileName);

          formData.append('file', file);
          formData.append('sessionId', appointmentId);
          formData.append('fileType', 'ANNOTATION');

          await uploadAnnotationMutation.mutateAsync(formData);
        });
    };

    saveScreenshot(screenshot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalScreenshot]);

  const takeScreenshot = async () => {
    if (!steps.length || !isOwner) {
      return;
    }

    annotationBoardRef.current = document.getElementById('annotationBoard') as HTMLCanvasElement;
    annotationBoard2Ref.current = document.getElementById('annotationBoard2') as HTMLCanvasElement;
    screenshotCanvasRef.current = document.getElementById('screenshotCanvas') as HTMLCanvasElement;

    if (!annotationBoardRef.current || !whiteboardOpenRef.current) {
      return;
    }
    const destCtx = screenshotCanvasRef?.current?.getContext('2d')!;

    const screenElement = subscribersRef.current.findLast(
      (subscriber) => subscriber?.stream?.videoType === 'screen' || subscriber?.stream?.name === 'screen'
    );
    let videoElement;
    if (screenElement) {
      videoElement = screenElement.videoElement();
    }

    if (!screenshotCanvasRef?.current?.width || !screenshotCanvasRef?.current?.height) {
      return;
    }

    destCtx?.beginPath();
    destCtx?.clearRect(0, 0, screenshotCanvasRef.current.width, screenshotCanvasRef.current.height);
    destCtx?.closePath();

    if (videoElement) {
      screenshotCanvasRef.current.width = videoElement.videoWidth;
      screenshotCanvasRef.current.height = videoElement.videoHeight;
      destCtx.drawImage(videoElement, 0, 0);

      destCtx.drawImage(annotationBoardRef.current, 0, 0, videoElement.videoWidth, videoElement.videoHeight);
    }

    if (!videoElement) {
      destCtx.drawImage(annotationBoardRef.current, 0, 0, annotationBoardRef.current.width, annotationBoardRef.current.height);
    }

    let data = screenshotCanvasRef?.current?.toDataURL()!;
    setOriginalScreenshot(data);

    // Timestamp on screenshot
    const providerIds = recordIds.join(', ');
    const text = moment().format('DD MMM YYYY, h:mm:ss a') + ' | Provider IDs: ' + providerIds;
    destCtx.font = '16pt Arial';
    destCtx.fillStyle = `rgba(220, 10, 34)`;
    // const measuredDimensions = destCtx.measureText(text);
    destCtx.fillText(text, 10, screenshotCanvasRef.current.height - 10);
    data = screenshotCanvasRef?.current?.toDataURL()!;
    setScreenshot(data);
  };

  useEffect(() => {
    const whiteboardOpen = whiteboardOpenRef.current;

    annotationBoardRef.current = document.getElementById('annotationBoard') as HTMLCanvasElement;
    annotationBoard2Ref.current = document.getElementById('annotationBoard2') as HTMLCanvasElement;
    screenshotCanvasRef.current = document.getElementById('screenshotCanvas') as HTMLCanvasElement;

    registerEvents();
    registerSignals();

    if (!loggedUser?.isProvider) {
      return () => {};
    }
    const copyIntervalId = setInterval(() => {
      if (whiteboardOpen) {
        takeScreenshot();
      }
    }, 30000);

    return () => clearInterval(copyIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.role]);

  useEffect(() => {
    if (loggedUser?.isProvider && steps.length && closingAnnotation) {
      setShowPromptSave(true);
      changeLayout('left-sidebar');
      return;
    } else if (loggedUser?.isProvider && !steps.length && closingAnnotation) {
      setWhiteBoardOpen(false);
      changeLayout('left-sidebar');
      return;
    }

    if (whiteboardOpen) {
      newScreenshot = true;

      setTimeout(() => {
        const screenElement = subscribersRef.current.findLast(
          (subscriber) => subscriber?.stream?.videoType === 'screen' || subscriber?.stream?.name === 'screen'
        );
        const videoElement = screenElement?.videoElement();

        if (videoElement) {
          videoElement.onresize = () => onResize();
        }
        onResize();
      }, 2500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whiteboardOpen, annotate, closingAnnotation]);

  const drawingEvent = (event, reemit = false) => {
    // If whiteboard closed, open
    if (!whiteboardOpenRef.current) {
      setWhiteBoardOpen(true);
      changeLayout('left-sidebar');
    }

    const data = JSON.parse(event.data);
    onDrawingEvent(data, reemit);
    steps.push({ signal: 'drawing', data, timestamp: data.timestamp });
  };

  const drawingTextEvent = (event, reemit = false) => {
    // If whiteboard closed, open
    if (!whiteboardOpenRef.current) {
      setWhiteBoardOpen(true);
      changeLayout('left-sidebar');
    }
    const data = JSON.parse(event.data);
    const { x0, y0 } = data;
    const normalized = normalizePoints({ x0, y0 })!;

    drawText({ ...data, reemit, x0: normalized.x0, y0: normalized.y0, scaledX0: x0, scaledY0: y0, timestamp: data.timestamp });
    steps.push({ signal: 'drawingText', data, timestamp: data.timestamp });
  };

  const drawingShapeEvent = (event, reemit = false) => {
    // If whiteboard closed, open
    if (!whiteboardOpenRef.current) {
      setWhiteBoardOpen(true);
      changeLayout('left-sidebar');
    }
    const data = JSON.parse(event.data);
    const { x0, y0, x1, y1, color, strokeWidth, shape } = data;
    const normalized = normalizePoints({ x0, y0, x1, y1 })!;

    drawShape(normalized.x0, normalized.y0, normalized.x1, normalized.y1, color, false, true, strokeWidth, shape, reemit);
    steps.push({ signal: 'drawingShape', data, timestamp: data.timestamp });
  };

  const drawingImageEvent = (event) => {
    // If whiteboard closed, open
    if (!whiteboardOpenRef.current) {
      setWhiteBoardOpen(true);
      changeLayout('left-sidebar');
    }
    const data = JSON.parse(event.data);
    const { x0, y0, x1, y1, url } = data;
    const normalized = normalizePoints({ x0, y0, x1, y1 })!;

    drawImageFromUrl({ x0: normalized.x0, y0: normalized.y0, x1: normalized.x1, y1: normalized.y1, url });
    steps.push({ signal: 'drawingImage', data, timestamp: data.timestamp });
  };

  const registerSignals = () => {
    const session = sessionRef.current!;
    const whiteboardOpen = whiteboardOpenRef.current;

    session.on('signal:undoEvents', (event) => {
      const data = JSON.parse(event.data);
      steps = steps.filter((obj) => !data.includes(obj.timestamp));
      replayEvents(steps);
    });

    session.on('signal:redoEvents', (event) => {
      const data = JSON.parse(event.data);
      steps.push(...data);
      replayEvents(steps);
    });

    session.on('signal:drawing', drawingEvent);
    session.on('signal:drawingText', drawingTextEvent);
    session.on('signal:drawingShape', drawingShapeEvent);
    session.on('signal:drawingImage', drawingImageEvent);

    session.on('signal:clearDrawing', () => {
      clearDrawing();
      steps = [];
    });

    session.on('signal:openWhiteboard', () => {
      if (!whiteboardOpen && isProvider) {
        setDialogMessage(intl.formatMessage({ defaultMessage: 'Annotation will be auto saved every 30 seconds in Client Records' }));
      }
      setWhiteBoardOpen(true);
      changeLayout('left-sidebar');
    });

    session.on('signal:annotate', () => {
      if (!whiteboardOpen && isProvider) {
        setDialogMessage(intl.formatMessage({ defaultMessage: 'Annotation will be auto saved every 30 seconds in Client Records' }));
      }
      setWhiteBoardOpen(true);
      changeLayout('left-sidebar');
    });
  };

  const undoEvents = () => {
    setEraseActive(false);
    setPencilActive(true);

    if (!steps.length) {
      return;
    }
    const tempSteps = [...steps];

    const times = tempSteps[tempSteps.length - 1].signal === 'drawing' ? 12 : 1;
    let lastTimestamp = tempSteps[tempSteps.length - 1].data.timestamp;
    const undoSteps: any = [];

    for (let i = 0; i < times; i++) {
      if (!tempSteps.length) {
        break;
      }
      const lastStep = tempSteps.pop();
      if (!lastStep) {
        break;
      }

      undoSteps.push(lastStep.timestamp);
      redoSteps.push(lastStep);
      const newTimestamp = lastStep.data.timestamp;

      if (lastStep.signal !== 'drawing' || Math.abs(lastTimestamp - newTimestamp) > 20) {
        break;
      }
      lastTimestamp = newTimestamp;
    }

    sendSignal({
      type: 'undoEvents',
      data: JSON.stringify(undoSteps)
    });
  };

  const redoEvents = () => {
    setEraseActive(false);
    setPencilActive(true);

    if (!redoSteps.length) {
      return;
    }

    const replaySteps: any = [];
    const times = redoSteps[redoSteps.length - 1].signal === 'drawing' ? 12 : 1;
    let lastTimestamp = redoSteps[redoSteps.length - 1].data.timestamp;

    for (let i = 0; i < times; i++) {
      if (!redoSteps.length) {
        break;
      }
      const lastStep = redoSteps.pop();
      if (!lastStep) {
        return;
      }
      replaySteps.push(lastStep);

      if (Math.abs(lastTimestamp - lastStep.data.timestamp) > 20) {
        break;
      }
      lastTimestamp = lastStep.data.timestamp;
    }

    sendSignal({
      type: 'redoEvents',
      data: JSON.stringify(replaySteps)
    });
  };

  const sendSignal = useCallback(
    ({ type = 'drawing', data = JSON.stringify({ type: 'drawing' }) }) => {
      const session = sessionRef.current!;

      session.signal({ data, type });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [sessionRef]
  );

  /**
   * Get mouse click position on canvas
   */
  const getMousePosition = (event) => {
    const x = event.offsetX;
    const y = event.offsetY;
    currentPos = { x, y };
    return currentPos;
  };

  const normalizePoints = (data: any): any => {
    const canvas = annotationBoardRef.current;
    if (!canvas) {
      return undefined;
    }

    const rect = canvas.getBoundingClientRect();
    const w = rect.width;
    const h = rect.height;

    return { x0: data.x0 * w, y0: data.y0 * h, x1: (data?.x1 || 0) * w, y1: (data?.y1 || 0) * h };
  };

  const replayEvents = (newSteps) => {
    if (!newSteps) {
      return;
    }
    clearDrawing();

    const canvas = annotationBoardRef.current!;
    const rect = canvas.getBoundingClientRect();
    const w = rect.width;
    const h = rect.height;
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    newSteps.forEach(({ data, signal }) => {
      const { x0, y0, x1, y1, color, strokeWidth, shape, text, url } = data;

      if (signal === 'drawing') {
        context.beginPath();
        context.moveTo(x0 * w, y0 * h);
        context.lineTo(x1 * w, y1 * h);
        context.strokeStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        context.lineWidth = strokeWidth;
        context.lineCap = 'round';
        context.stroke();
        context.closePath();
        return;
      } else if (signal === 'drawingText') {
        context.font = `28pt Arial`;

        if (color) {
          context.fillStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        } else {
          context.fillStyle = `rgba(${colorRef.current.rgb.r}, ${colorRef.current.rgb.g}, ${colorRef.current.rgb.b}, ${colorRef.current.rgb.a})`;
        }
        context.fillText(text, x0 * w, y0 * h);
        return;
      } else if (signal === 'drawingShape') {
        context.beginPath();
        const newX0 = x0 * w;
        const newY0 = y0 * h;
        const newX1 = x1 * w;
        const newY1 = y1 * h;
        const width = newX1 - newX0;
        const height = newY1 - newY0;

        context.lineWidth = 1;
        if (shape === 'rectangle') {
          context.rect(newX0, newY0, width, height);
        } else if (shape === 'circle') {
          const a = Math.abs(newX0 - newX1);
          const b = Math.abs(newY0 - newY1);
          context.ellipse(newX0 + a / 2, newY0 + b / 2, a, b, 0, 0, 2 * Math.PI);
        } else {
          context.moveTo(newX0, newY0);
          context.lineTo(newX1, newY1);
        }
        context.strokeStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        context.stroke();
        context.closePath();
      } else if (signal === 'drawingImage') {
        const newX0 = x0 * w;
        const newY0 = y0 * h;
        const newX1 = x1 * w;
        const newY1 = y1 * h;

        drawImageFromUrl({ x0: newX0, y0: newY0, x1: newX1, y1: newY1, url });
      }
    });
  };

  const drawText = ({ text, emit = false, reemit = false, x0 = currentPos.x, y0 = currentPos.y, color, scaledX0, scaledY0 }: any) => {
    const canvas = annotationBoardRef.current!;

    const context = canvas.getContext('2d')!;
    const rect = canvas.getBoundingClientRect();
    let newX0 = x0;
    let newY0 = y0;
    if (emit) {
      newX0 = x0 - rect.left;
      newY0 = y0 - rect.top;
    }

    context.font = `28pt Arial`;
    if (color) {
      context.fillStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    } else {
      context.fillStyle = `rgba(${colorRef.current.rgb.r}, ${colorRef.current.rgb.g}, ${colorRef.current.rgb.b}, ${colorRef.current.rgb.a})`;
    }

    if (!emit) {
      const textArray = text.split('\n');

      for (let i = 0; i < textArray.length; i++) {
        context.fillText(textArray[i], newX0, newY0 + i * 35);
      }
    }
    setTextActive(false);

    if (!(emit || reemit)) {
      return;
    }

    const w = rect.width;
    const h = rect.height;

    sendSignal({
      type: 'drawingText',
      data: JSON.stringify({
        x0: (reemit ? scaledX0 : newX0 / w).toPrecision(pointPrecision),
        y0: (reemit ? scaledY0 : newY0 / h).toPrecision(pointPrecision),
        color: colorRef.current,
        text,
        timestamp: new Date().getTime()
      })
    });
  };

  const drawLine = useCallback(
    (
      x0: number,
      y0: number,
      x1: number,
      y1: number,
      color: {
        rgb: any;
        hex?: string;
        hsl?: { a: number; h: number; l: number; s: number } | { a: number; h: number; l: number; s: number };
      },
      emit: boolean,
      lineWidth = strokeWidthRef.current,
      reemit = false
    ) => {
      if (eraseActiveRef.current && emit) {
        lineWidth = 18;
      }
      const canvas = annotationBoardRef.current;

      if (!canvas || !color) {
        return;
      }

      const rect = canvas.getBoundingClientRect();
      let newX0 = x0;
      let newX1 = x1;
      let newY0 = y0;
      let newY1 = y1;

      if (emit) {
        if (eraseActiveRef.current) {
          newX0 = x0 + 14;
          newX1 = x1 + 14;
          newY0 = y0 + 16;
          newY1 = y1 + 16;
        } else {
          newX0 = x0 + 20;
          newX1 = x1 + 20;
          newY0 = y0 + 20;
          newY1 = y1 + 20;
        }
      }

      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }
      if (emit && textActiveRef.current) {
        return;
      }
      if (!emit) {
        context.beginPath();
        context.moveTo(newX0, newY0);
        context.lineTo(newX1, newY1);
        context.strokeStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        context.lineWidth = lineWidth;
        context.lineCap = 'round';
        context.stroke();
        context.closePath();
      }

      if (!(emit || reemit)) {
        return;
      }
      const w = rect.width;
      const h = rect.height;

      sendSignal({
        type: 'drawing',
        data: JSON.stringify({
          x0: (newX0 / w).toPrecision(pointPrecision),
          y0: (newY0 / h).toPrecision(pointPrecision),
          x1: (newX1 / w).toPrecision(pointPrecision),
          y1: (newY1 / h).toPrecision(pointPrecision),
          color,
          strokeWidth: lineWidth,
          timestamp: new Date().getTime()
        })
      });
    },
    [sendSignal, eraseActiveRef, strokeWidthRef, textActiveRef]
  );

  const removeImageBox = () => {
    const canvas2 = annotationBoard2Ref.current!;
    const context2 = canvas2.getContext('2d')!;

    // Clear canvas
    context2?.beginPath();
    context2?.clearRect(0, 0, canvas2.width, canvas2.height);
    context2?.closePath();

    imageBoxBoundaries = {
      x: 50,
      y: 50,
      width: 200,
      height: 200
    };
    setActiveImage(null);
    setActiveShape(null);
    setSelectBoxControlsVisible(false);
    draggingImageBox = false;
    removeTextBox();
  };

  const removeTextBox = () => {
    const canvas2 = annotationBoard2Ref.current!;
    const context2 = canvas2.getContext('2d')!;

    // Clear canvas
    context2?.beginPath();
    context2?.clearRect(0, 0, canvas2.width, canvas2.height);
    context2?.closePath();

    setTextBoxActive(false);
    setSelectBoxControlsVisible(false);
    typing = false;
    typedText = '';
    draggingTextBox = false;
    setActiveCursor(`url(${pencilImg}), auto`);
  };

  const drawTextBox = useCallback(
    ({ x = current.x, y = current.y, text = typedText }) => {
      const canvas2 = annotationBoard2Ref.current;
      if (!canvas2) {
        return;
      }

      const context2 = canvas2.getContext('2d')!;

      const textArray = text.split('\n');
      let longestLine = textArray[0];
      // Find max width
      for (let text of textArray) {
        if (text.length > longestLine.length) {
          longestLine = text;
        }
      }

      const measuredDimensions = context2.measureText(longestLine);

      let width = Math.max(measuredDimensions.width, 200);
      let height = 35 * textArray.length + 10;

      textBoxBoundaries = {
        x,
        y,
        width,
        height
      };

      // Clear canvas
      context2?.beginPath();
      context2?.clearRect(0, 0, canvas2.width, canvas2.height);
      context2?.closePath();

      // Text to write
      context2.font = `28pt Arial`;
      context2.fillStyle = `rgba(${colorRef.current.rgb.r}, ${colorRef.current.rgb.g}, ${colorRef.current.rgb.b}, ${colorRef.current.rgb.a})`;

      let lastPosition = { x: 0, y: 0, text: '' };
      for (let i = 0; i < textArray.length; i++) {
        context2.fillText(textArray[i], x + 10, y + i * 35 + 35);
      }

      lastPosition = { x: x + 10, y: y + textCursorOffset.top * 35 + 35, text: textArray[textCursorOffset.top] };

      const lastLineWidth = context2.measureText(lastPosition.text.slice(0, textCursorOffset.left)).width;

      // Textbox boundaries
      context2.beginPath();
      context2.lineWidth = 1;
      context2.rect(x, y, width + 25, height);
      context2.strokeStyle = `#337ab7`;
      context2.stroke();
      context2.closePath();

      const cursorImage = new Image();
      cursorImage.src = cursorIcon;
      cursorImage.onload = () => {
        let leftPosition;

        if (textCursorOffset.left > -1) {
          const cursorText = lastPosition.text.slice(0, textCursorOffset.left);
          const leftOffsetWidth = context2.measureText(cursorText).width;
          leftPosition = lastPosition.x + leftOffsetWidth - 4;
        }
        context2.drawImage(cursorImage, lastPosition.x + lastLineWidth - 4, lastPosition.y - 38, 10, 50);
      };

      typing = true;
    },
    [colorRef]
  );

  const uploadMutation = useMutation((formData: any) => {
    if (floatingEmail) {
      return guestRequest.post(`/session/${appointmentId}/floating/files`, formData, {
        params: { email: floatingEmail }
      });
    }

    return request.post(`/user/${userId}/files`, formData);
  });

  const drawImageFromUrl = ({ x0, y0, x1, y1, url }: { x0: number; y0: number; x1: number; y1: number; url: string }) => {
    if (!url) return;

    const canvas = annotationBoardRef.current!;
    const context = canvas.getContext('2d')!;

    try {
      if (prefetchedImagesRef.current[url]) {
        context.drawImage(prefetchedImagesRef.current[url], x0, y0, x1 - x0, y1 - y0);
      } else {
        const img = new Image();
        img.onload = () => {
          prefetchedImagesRef.current[url] = img;

          context.drawImage(img, x0, y0, x1 - x0, y1 - y0);
        };
        img.src = url;
      }
    } catch (error) {
      console.error('Error in drawImageFromUrl', error);
    }
  };

  const drawImage = ({ file }: { file: File }) => {
    if (!file) return;

    uploadImage({
      file,
      x0: imageBoxBoundaries.x,
      y0: imageBoxBoundaries.y,
      x1: imageBoxBoundaries.x + imageBoxBoundaries.width,
      y1: imageBoxBoundaries.y + imageBoxBoundaries.height
    });

    const canvas = annotationBoardRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext('2d')!;

    const img = new Image();
    img.onload = () => {
      context.drawImage(img, imageBoxBoundaries.x, imageBoxBoundaries.y, imageBoxBoundaries.width, imageBoxBoundaries.height);

      handleImageClick({});
    };

    img.src = URL.createObjectURL(file);
  };

  const drawImageBox = useCallback(
    ({ x = 50, y = 50, file = activeImageRef.current }: { x?: number; y?: number; file?: File | null }) => {
      if (!file) return;

      const canvas2 = annotationBoard2Ref.current;
      if (!canvas2) {
        return;
      }

      const context2 = canvas2.getContext('2d')!;

      const img = new Image();
      img.onload = () => {
        // Clear canvas
        context2?.beginPath();
        context2?.clearRect(0, 0, canvas2.width, canvas2.height);
        context2?.closePath();

        const width = imageBoxBoundaries.width;
        const height = imageBoxBoundaries.width * (img.height / img.width);
        context2.drawImage(img, x, y, width, height);

        context2.strokeStyle = `#337ab7`;
        context2.fillStyle = `#337ab7`;
        context2.lineWidth = 2;

        // ImageBox boundaries
        context2.beginPath();
        context2.rect(x - 2, y - 2, width + 4, height + 4);
        context2.stroke();
        context2.closePath();

        // Resize Button
        context2.beginPath();
        context2.fillRect(x + width - 5, y + height - 5, 10, 10);
        context2.stroke();
        context2.closePath();

        imageBoxBoundaries = {
          x,
          y,
          width,
          height
        };
      };
      img.src = URL.createObjectURL(file);

      setActiveImage(file);
    },
    [setActiveImage, activeImageRef]
  );

  const drawShape = useCallback(
    (
      x0: number,
      y0: number,
      x1: number,
      y1: number,
      color: {
        rgb: any;
        hex?: string;
        hsl?: { a: number; h: number; l: number; s: number } | { a: number; h: number; l: number; s: number };
      },
      emit = false,
      actual = true,
      lineWidth = 1,
      shape = activeShapeRef.current,
      reemit
    ) => {
      const canvas = annotationBoardRef.current;
      const canvas2 = annotationBoard2Ref.current;
      if (!canvas || !canvas2 || !color) {
        return;
      }
      const rect = canvas.getBoundingClientRect();
      const newX0 = emit || !actual ? x0 : x0;
      const newX1 = emit || !actual ? x1 : x1;
      const width = x1 - x0;
      const newY0 = emit || !actual ? y0 : y0;
      const newY1 = emit || !actual ? y1 : y1;
      const height = y1 - y0;
      const context = actual ? canvas.getContext('2d') : canvas2.getContext('2d');

      if (!context) {
        return;
      }
      if (emit && textActiveRef.current) {
        return;
      }
      context.beginPath();
      context.lineWidth = lineWidth;
      if (shape === 'rectangle') {
        context.rect(newX0, newY0, width, height);
      } else if (shape === 'circle') {
        const a = Math.abs(newX0 - newX1);
        const b = Math.abs(newY0 - newY1);
        context.ellipse(newX0 + a / 2, newY0 + b / 2, a, b, 0, 0, 2 * Math.PI);
      } else {
        context.moveTo(newX0, newY0);
        context.lineTo(newX1, newY1);
      }
      context.strokeStyle = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
      context.stroke();
      context.closePath();

      if (!actual || !(emit || reemit)) {
        return;
      }

      const w = rect.width;
      const h = rect.height;
      sendSignal({
        type: 'drawingShape',
        data: JSON.stringify({
          x0: (newX0 / w).toPrecision(pointPrecision),
          y0: (newY0 / h).toPrecision(pointPrecision),
          x1: (newX1 / w).toPrecision(pointPrecision),
          y1: (newY1 / h).toPrecision(pointPrecision),
          color,
          strokeWidth: lineWidth,
          shape,
          timestamp: new Date().getTime()
        })
      });
    },
    [sendSignal, textActiveRef, activeShapeRef]
  );

  const onDrawingEvent = (
    data: { x0: number; y0: number; x1: number; y1: number; color: any; strokeWidth: number | undefined },
    reemit = false
  ) => {
    const canvas = annotationBoardRef.current;
    if (!canvas) {
      return;
    }
    const rect = canvas.getBoundingClientRect();
    const w = rect.width;
    const h = rect.height;

    drawLine(data.x0 * w, data.y0 * h, data.x1 * w, data.y1 * h, data.color, false, data.strokeWidth, reemit);
  };

  const clearDrawing = (emit = false) => {
    const canvas = annotationBoardRef.current;
    if (!canvas) {
      return;
    }
    const context = canvas.getContext('2d');

    context?.beginPath();
    context?.clearRect(0, 0, canvas.width, canvas.height);
    context?.closePath();

    if (!emit) {
      return;
    }

    sendSignal({
      type: 'clearDrawing',
      data: JSON.stringify({
        type: 'clearDrawing'
      })
    });
    setShowColorPicker(false);
    if (emit) {
      setStrokeWidth(2);
    }
  };

  const onMouseDown = (e) => {
    const destX = e.offsetX || (e.touches?.length ? e.touches[0]?.offsetX : 0);
    const destY = e.offsetY || (e.touches?.length ? e.touches[0]?.offsetY : 0);

    if (textBoxActiveRef.current) {
      if (!typing) {
        drawTextBox({ x: destX - 10, y: destY - 20, text: '' });
        current.x = destX - 10;
        current.y = destY - 20;

        setSelectBoxMenuPosition({ x: destX + textBoxBoundaries.width + 18, y: destY });
        setSelectBoxControlsVisible(false);

        return;
      }
      setSelectBoxMenuPosition({ x: textBoxBoundaries.x + textBoxBoundaries.width + 18, y: textBoxBoundaries.y - 10 });

      draggingTextBox = true;

      dragAnchor = {
        x: destX - textBoxBoundaries.x,
        y: destY - textBoxBoundaries.y
      };

      if (
        !(
          destX > textBoxBoundaries.x + 20 &&
          destX < textBoxBoundaries.x + textBoxBoundaries.width + 20 &&
          destY > textBoxBoundaries.y - 20 &&
          destY < textBoxBoundaries.y + textBoxBoundaries.height + 20
        )
      ) {
        const canvas = annotationBoardRef.current!;
        const rect = canvas.getBoundingClientRect();
        currentPos.x = textBoxBoundaries.x + rect.left + 9;
        currentPos.y = textBoxBoundaries.y + rect.top + 35;
        // Click outside TextBox
        drawText({ text: typedText, emit: true });
        removeTextBox();
      }

      return;
    } else if (activeImageRef.current) {
      if (
        destX > imageBoxBoundaries.x + imageBoxBoundaries.width - 10 &&
        destX < imageBoxBoundaries.x + imageBoxBoundaries.width + 10 &&
        destY > imageBoxBoundaries.y + imageBoxBoundaries.height - 10 &&
        destY < imageBoxBoundaries.y + imageBoxBoundaries.height + 10
      ) {
        // Resizing image box
        resizingImageBox = true;
        draggingImageBox = false;
      } else if (
        !(
          destX > imageBoxBoundaries.x + 20 &&
          destX < imageBoxBoundaries.x + imageBoxBoundaries.width + 20 &&
          destY > imageBoxBoundaries.y - 20 &&
          destY < imageBoxBoundaries.y + imageBoxBoundaries.height + 20
        )
      ) {
        // Mouse outside image box
        drawImage({ file: activeImageRef.current });
      } else {
        draggingImageBox = true;
        setSelectBoxControlsVisible(false);
        dragAnchor = {
          x: destX - imageBoxBoundaries.x,
          y: destY - imageBoxBoundaries.y
        };
      }
      return;
    } else if (activeShapeRef.current) {
      prevPos = { x: destX, y: destY };
    }

    current.x = destX;
    current.y = destY;
    drawing = true;
  };

  const onMouseUp = useCallback(
    (e) => {
      draggingTextBox = false;
      draggingImageBox = false;
      resizingImageBox = false;

      if (!drawing || textBoxActiveRef.current) {
        return;
      }
      drawing = false;

      if (activeShapeRef.current) {
        const canvas2 = annotationBoard2Ref.current!;
        const context2 = canvas2.getContext('2d');

        context2?.beginPath();
        context2?.clearRect(0, 0, canvas2.width, canvas2.height);
        context2?.closePath();

        drawShape(
          prevPos.x,
          prevPos.y,
          current.x,
          current.y,
          colorRef.current,
          true,
          true,
          strokeWidthRef.current,
          activeShapeRef.current,
          false
        );
        // setActiveShape(null);
      } else {
        drawLine(
          current.x,
          current.y,
          e.offsetX || (e.touches?.length ? e.touches[0]?.offsetX : 0),
          e.offsetY || (e.touches?.length ? e.touches[0]?.offsetY : 0),
          eraseActiveRef.current
            ? {
                rgb: { r: 255, b: 255, g: 255, a: 1 },
                hex: '#21228f',
                hsl: { a: 1, h: 239, l: 0, s: 5 }
              }
            : colorRef.current,
          true
        );
      }
    },
    [
      drawLine,
      drawShape,
      eraseActiveRef,
      colorRef,
      activeShapeRef,
      // setActiveShape,
      textBoxActiveRef,
      strokeWidthRef
    ]
  );

  const onMouseMove = useCallback(
    (e) => {
      const destX = e.offsetX || (e.touches?.length ? e.touches[0]?.offsetX : 0);
      const destY = e.offsetY || (e.touches?.length ? e.touches[0]?.offsetY : 0);

      // TextBox mouse over/out events
      if (textBoxActiveRef.current && !draggingTextBox) {
        if (!typing) {
          setActiveCursor('text');
        } else if (
          destX > textBoxBoundaries.x &&
          destX < textBoxBoundaries.x + textBoxBoundaries.width &&
          destY > textBoxBoundaries.y &&
          destY < textBoxBoundaries.y + textBoxBoundaries.height
        ) {
          setActiveCursor('move');
        } else {
          setActiveCursor('move');
        }

        // Text Box controls visibility
        if (
          destX > textBoxBoundaries.x - 50 &&
          destX < textBoxBoundaries.x + textBoxBoundaries.width + 50 &&
          destY > textBoxBoundaries.y - 50 &&
          destY < textBoxBoundaries.y + textBoxBoundaries.height + 50
        ) {
          setSelectBoxControlsVisible(true);

          setSelectBoxMenuPosition({ x: textBoxBoundaries.x + textBoxBoundaries.width + 18, y: textBoxBoundaries.y - 10 });
        } else {
          setSelectBoxControlsVisible(false);
        }
      } else if (activeImageRef.current && !draggingImageBox) {
        if (resizingImageBox) {
          const newWidth = destX - imageBoxBoundaries.x;
          const newHeight = destY - imageBoxBoundaries.y;

          if (
            (newWidth > 50 && newHeight > 50 && Math.abs(newWidth - imageBoxBoundaries.width) > 2) ||
            Math.abs(newHeight - imageBoxBoundaries.height) > 2
          ) {
            imageBoxBoundaries = {
              ...imageBoxBoundaries,
              width: newWidth,
              height: newHeight
            };

            drawImageBox({ x: imageBoxBoundaries.x, y: imageBoxBoundaries.y });
          }
          return;
        } else if (
          destX > imageBoxBoundaries.x - 50 &&
          destX < imageBoxBoundaries.x + imageBoxBoundaries.width + 50 &&
          destY > imageBoxBoundaries.y - 50 &&
          destY < imageBoxBoundaries.y + imageBoxBoundaries.height + 50
        ) {
          // Mouse inside image box
          setSelectBoxControlsVisible(true);

          setSelectBoxMenuPosition({ x: imageBoxBoundaries.x + imageBoxBoundaries.width - 5, y: imageBoxBoundaries.y - 10 });
        } else {
          setSelectBoxControlsVisible(false);
        }
      }

      // ImageBox mouse over/out events
      if (activeImageRef.current) {
        if (
          destX > imageBoxBoundaries.x + imageBoxBoundaries.width - 10 &&
          destX < imageBoxBoundaries.x + imageBoxBoundaries.width + 10 &&
          destY > imageBoxBoundaries.y + imageBoxBoundaries.height - 10 &&
          destY < imageBoxBoundaries.y + imageBoxBoundaries.height + 10
        ) {
          setActiveCursor('nwse-resize');
          setSelectBoxControlsVisible(false);
        } else if (
          destX > imageBoxBoundaries.x &&
          destX < imageBoxBoundaries.x + imageBoxBoundaries.width &&
          destY > imageBoxBoundaries.y &&
          destY < imageBoxBoundaries.y + imageBoxBoundaries.height
        ) {
          setActiveCursor('move');
        } else {
          setActiveCursor('default');
        }
      }

      if (!drawing && !draggingTextBox && !draggingImageBox) {
        return;
      }

      // TextBox drag
      if (textBoxActiveRef.current && draggingTextBox) {
        current.x = destX - dragAnchor.x;
        current.y = destY - dragAnchor.y;
        setSelectBoxControlsVisible(false);
        drawTextBox({});

        return;
      }

      // ImageBox drag
      if (activeImageRef.current && draggingImageBox) {
        current.x = destX - dragAnchor.x;
        current.y = destY - dragAnchor.y;

        if (Math.abs(current.x - imageBoxBoundaries.x) > 2 || Math.abs(current.y - imageBoxBoundaries.y) > 2) {
          drawImageBox({ x: current.x, y: current.y });
        }

        return;
      }

      if (!drawing) return;

      if (!activeShapeRef.current) {
        drawLine(
          current.x,
          current.y,
          e.offsetX || (e.touches?.length ? e.touches[0]?.offsetX : 0),
          e.offsetY || (e.touches?.length ? e.touches[0]?.offsetY : 0),
          eraseActiveRef.current
            ? {
                rgb: { r: 255, b: 255, g: 255, a: 1 },
                hex: '#21228f',
                hsl: { a: 1, h: 239, l: 0, s: 5 }
              }
            : colorRef.current,
          true
        );
      }

      current.x = destX;
      current.y = destY;

      if (activeShapeRef.current) {
        // Temporary shape
        const canvas2 = annotationBoard2Ref.current!;
        const context2 = canvas2.getContext('2d');

        context2?.beginPath();
        context2?.clearRect(0, 0, canvas2.width, canvas2.height);
        context2?.closePath();
        drawShape(
          prevPos.x,
          prevPos.y,
          e.offsetX || (e.touches?.length ? e.touches[0]?.offsetX : 0),
          e.offsetY || (e.touches?.length ? e.touches[0]?.offsetY : 0),
          colorRef.current,
          false,
          false,
          undefined,
          undefined,
          false
        );
      }
    },
    [drawLine, drawShape, eraseActiveRef, colorRef, activeShapeRef, textBoxActiveRef, drawTextBox, activeImageRef, drawImageBox]
  );

  let previousCall = new Date().getTime();

  // limit the number of events per second
  const throttle = (callback, e, delay) => {
    const time = new Date().getTime();

    if (time - previousCall >= delay) {
      previousCall = time;
      callback(e);
    }
  };

  // make the canvas fill its parent
  const onResize = () => {
    const canvas = annotationBoardRef.current;
    const canvas2 = annotationBoard2Ref.current;
    const screenshotCanvas = screenshotCanvasRef.current;

    if (!canvas || !canvas2 || !screenshotCanvas) {
      return null;
    }

    const innerWidth = window.innerWidth * 0.7;
    const innerHeight = window.innerHeight * 0.8;

    canvas.width = innerWidth;
    canvas.height = innerHeight;

    canvas2.width = innerWidth;
    canvas2.height = innerHeight;

    screenshotCanvas.width = innerWidth;
    screenshotCanvas.height = innerHeight;

    // If publisher is screen sharing
    let containerWidth = 1920;
    let containerHeight = 978;
    let videoWidth = 1920;
    let videoHeight = 978;

    const whiteboardEditor = document.querySelector('.whiteboard-editor') as HTMLElement;

    const screenElement = subscribersRef.current.findLast(
      (subscriber) => subscriber?.stream?.videoType === 'screen' || subscriber?.stream?.name === 'screen'
    );
    let videoElement;
    if (screenElement) {
      videoElement = screenElement.videoElement();
    }

    if (whiteboardEditor && videoElement) {
      containerWidth = whiteboardEditor?.clientWidth!;
      containerHeight = whiteboardEditor?.clientHeight!;
      containerScale = containerHeight / containerWidth;

      videoWidth = videoElement.videoWidth;
      videoHeight = videoElement.videoHeight;

      scale = videoHeight / videoWidth;
      inverseScale = videoWidth / videoHeight;

      if (scale > containerScale) {
        canvas.height = containerHeight;
        canvas.width = containerHeight * inverseScale;

        canvas2.height = containerHeight;
        canvas2.width = containerHeight * inverseScale;

        screenshotCanvas.height = containerHeight;
        screenshotCanvas.width = containerHeight * inverseScale;
      } else {
        canvas.width = containerWidth;
        canvas.height = containerWidth * scale;

        canvas2.width = containerWidth;
        canvas2.height = containerWidth * scale;

        screenshotCanvas.width = containerWidth;
        screenshotCanvas.height = containerWidth * scale;
      }
    }

    replayEvents(steps);

    return null;
  };

  useEffect(() => {
    setTimeout(() => onResize(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftDrawerOpened, chatOpen]);

  const write = async (e) => {
    if (!typing) return;

    setSelectBoxControlsVisible(false);

    let textArray = typedText.split('\n');

    const keyCode = e.which || e.keyCode;

    // Detecting Ctrl/Cmd
    const ctrl = e.ctrlKey && keyCode === 86;
    const cmd = e.metaKey && keyCode === 86;

    if (ctrl || cmd) {
      // Ctrl/Cmd + v
      const text = await navigator.clipboard.readText();
      typedText += text;
      drawTextBox({ text: typedText });
      return;
    }

    if (e.key?.length === 1) {
      textArray[textCursorOffset.top] =
        textArray[textCursorOffset.top].slice(0, textCursorOffset.left) +
        e.key +
        textArray[textCursorOffset.top].slice(textCursorOffset.left);
      typedText = textArray.join('\n');

      textCursorOffset = {
        left: textCursorOffset.left + 1,
        top: textCursorOffset.top
      };

      drawTextBox({ text: typedText });
      return;
    }

    switch (e.key) {
      case 'Backspace':
        if (textCursorOffset.left > 0) {
          // Delete character at cursor
          textCursorOffset = {
            left: textCursorOffset.left - 1,
            top: textCursorOffset.top
          };

          textArray[textCursorOffset.top] =
            textArray[textCursorOffset.top].slice(0, textCursorOffset.left) +
            textArray[textCursorOffset.top].slice(textCursorOffset.left + 1);

          typedText = textArray.join('\n');
          drawTextBox({ text: typedText });
        } else if (textCursorOffset.top > 0 && !textArray[textCursorOffset.top].length) {
          // Delete current line
          textArray.splice(textCursorOffset.top, 1);
          typedText = textArray.join('\n');

          textCursorOffset = {
            left: textArray[textCursorOffset.top - 1].length,
            top: textCursorOffset.top - 1
          };

          drawTextBox({ text: typedText });
        } else if (textCursorOffset.top > 0) {
          typedText = '';
          // Merge two lines
          for (let i = 0; i < textArray.length; i++) {
            const join = i === textCursorOffset.top - 1 ? '' : '\n';
            typedText += textArray[i] + join;
          }

          textCursorOffset = {
            left: textArray[textCursorOffset.top - 1].length,
            top: textCursorOffset.top - 1
          };

          drawTextBox({ text: typedText });
        }
        return;
      case 'Enter':
        typedText += '\n';

        textCursorOffset = {
          left: 0,
          top: textCursorOffset.top + 1
        };

        drawTextBox({ text: typedText });
        return;
      case 'ArrowRight':
        if (textCursorOffset.left < textArray[textCursorOffset.top].length) {
          textCursorOffset = {
            left: textCursorOffset.left + 1,
            top: textCursorOffset.top
          };
        } else if (textCursorOffset.top < textArray.length - 1) {
          textCursorOffset = {
            left: 0,
            top: textCursorOffset.top + 1
          };
        }

        drawTextBox({});
        return;
      case 'ArrowLeft':
        if (textCursorOffset.left > 0) {
          textCursorOffset = {
            left: textCursorOffset.left - 1,
            top: textCursorOffset.top
          };
        } else if (textCursorOffset.top > 0) {
          textCursorOffset = {
            left: textArray[textCursorOffset.top - 1].length,
            top: textCursorOffset.top - 1
          };
        }
        drawTextBox({});
        return;
      case 'ArrowUp':
        if (textCursorOffset.top > 0) {
          textCursorOffset = {
            left: textArray[textCursorOffset.top - 1].length,
            top: textCursorOffset.top - 1
          };
          drawTextBox({});
        }
        return;
      case 'ArrowDown':
        if (textCursorOffset.top < textArray.length - 1) {
          textCursorOffset = {
            left: textCursorOffset.left,
            top: textCursorOffset.top + 1
          };
          drawTextBox({});
        }
        return;
    }
  };

  const registerEvents = () => {
    const canvas = annotationBoardRef.current;
    const canvas2 = annotationBoard2Ref.current;
    if (!canvas || !canvas2) {
      return;
    }

    canvas.addEventListener('mousedown', onMouseDown, false);
    canvas.addEventListener('mouseup', onMouseUp, false);
    canvas.addEventListener('mouseout', onMouseUp, false);
    canvas.addEventListener('mousemove', (e) => throttle(onMouseMove, e, 10), false);

    canvas2.addEventListener('mousedown', onMouseDown, false);
    canvas2.addEventListener('mouseup', onMouseUp, false);
    canvas2.addEventListener('mouseout', onMouseUp, false);
    canvas2.addEventListener('mousemove', (e) => throttle(onMouseMove, e, 10), false);

    // Touch support for mobile devices
    canvas.addEventListener('touchstart', onMouseDown, false);
    canvas.addEventListener('touchend', onMouseUp, false);
    canvas.addEventListener('touchcancel', onMouseUp, false);
    canvas.addEventListener(
      'touchmove',
      (e) => throttle(onMouseMove, e, 10),

      false
    );

    canvas2.addEventListener('touchstart', onMouseDown, false);
    canvas2.addEventListener('touchend', onMouseUp, false);
    canvas2.addEventListener('touchcancel', onMouseUp, false);
    canvas2.addEventListener(
      'touchmove',
      (e) => throttle(onMouseMove, e, 10),

      false
    );

    window.addEventListener('resize', () => onResize(), false);
    onResize();

    window.addEventListener('keydown', (e) => throttle(write, e, 5));
  };

  const handlePencilClick = () => {
    removeTextBox();
    removeImageBox();

    setShowColorPicker(false);
    setEraseActive(false);
    setPencilActive(true);
    setTextActive(false);
    setActiveShape(null);

    setActiveCursor(`url(${pencilImg}), auto`);
  };

  const handleLetterClick = () => {
    removeImageBox();

    setShowColorPicker(false);
    setEraseActive(false);
    setPencilActive(false);
    // setTextActive(true);
    setActiveShape(null);
    setTextBoxActive((pre) => {
      if (pre) {
        removeTextBox();
      } else {
        setActiveCursor('text');
      }
      return !pre;
    });
    drawing = false;

    textCursorOffset = {
      left: 0,
      top: 0
    };

    setSelectBoxControlsVisible(false);
  };

  const handleImageClick = ({ file }: { file?: File }) => {
    imageBoxBoundaries = {
      x: 50,
      y: 50,
      width: 200,
      height: 200
    };

    removeTextBox();
    removeImageBox();
    drawImageBox({ file });
  };

  const handleShapeClick = (shape: string) => {
    removeTextBox();
    removeImageBox();

    setShowColorPicker(false);
    setEraseActive(false);
    setPencilActive(false);
    setTextActive(false);
    setActiveShape(shape);
    setAnchorShapeEl(null);

    setActiveCursor('crosshair');
  };

  const handleColorPickerClick = () => {
    removeTextBox();
    removeImageBox();

    setEraseActive(false);
    setPencilActive(false);
    setTextActive(false);
    setShowColorPicker((pre) => !pre);
    setActiveShape(null);
  };

  const handleEraserClick = () => {
    removeTextBox();
    removeImageBox();

    setTextActive(false);
    setPencilActive(false);
    setEraseActive(true);
    setStrokeWidth(18);
    setShowColorPicker(false);
    setActiveShape(null);

    setActiveCursor(`url(${EraseIcon}), auto`);
  };

  return (
    <>
      <BasicDialog
        container={containerRef?.current}
        open={showPromptSave}
        onClose={() => {
          setShowPromptSave(false);
          clearAfterSavingRef.current = false;
        }}
        maxWidth="sm"
        actions={
          <>
            <Button
              variant="contained"
              onClick={() => {
                newScreenshot = true;
                clearDrawing(true);
                setShowPromptSave(false);
                if (closingAnnotation) {
                  setTimeout(() => {
                    setWhiteBoardOpen(false);
                    setClosingAnnotation(false);
                    changeLayout('left-sidebar');
                  }, 500);
                }
              }}
            >
              <FormattedMessage defaultMessage="Clear" />
            </Button>
            {isOwner && (
              <Button
                variant="contained"
                onClick={async () => {
                  await takeScreenshot();
                  setShowPromptSave(false);
                  if (closingAnnotation) {
                    setClosingAnnotation(false);
                    setTimeout(() => {
                      setWhiteBoardOpen(false);
                      changeLayout('left-sidebar');
                    }, 500);
                  }
                  if (clearAfterSavingRef.current) {
                    setTimeout(() => {
                      clearDrawing(true);
                    }, 500);
                  }
                }}
              >
                <FormattedMessage defaultMessage="Save" />
              </Button>
            )}
          </>
        }
      >
        <FormattedMessage defaultMessage="Do you want to save the annotation before clearing/closing annotation?" />
      </BasicDialog>

      <canvas id="screenshotCanvas" style={{ display: 'none' }} />
      <div
        className={classNames('whiteboard-container', whiteboardOpen ? 'is-open' : null)}
        hidden={!whiteboardOpen}
        style={annotate ? { backgroundColor: 'transparent', height: '100%' } : { backgroundColor: '#fff' }}
      >
        <div className="whiteboard-editor">
          <canvas
            id="annotationBoard2"
            className="whiteboard"
            style={{
              display: activeShape || textBoxActive || activeImage ? 'block' : 'none',
              backgroundColor: '#00000008',
              cursor: activeCursor,
              position: 'absolute',
              zIndex: 1,
              marginTop: (window as any)?.safari ? -2 : -5
            }}
          />

          <div onClick={() => setShowColorPicker(false)} onKeyDown={() => setShowColorPicker(false)} role="button" tabIndex={0}>
            <canvas
              id="annotationBoard"
              className="whiteboard"
              style={{
                backgroundColor: annotate ? 'transparent' : '#fff',
                cursor: activeCursor,
                position: 'relative',
                borderColor: theme.palette.primary.main,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 3
              }}
              onClick={(e) => {
                getMousePosition(e);
              }}
            />
          </div>

          <Controls
            containerRef={containerRef}
            isProvider={isProvider}
            handleShapeClick={handleShapeClick}
            pencilActive={pencilActive}
            handlePencilClick={handlePencilClick}
            textActive={textActive}
            anchorShapeEl={anchorShapeEl}
            handleColorPickerClick={handleColorPickerClick}
            textBoxActive={textBoxActive}
            setAnchorShapeEl={setAnchorShapeEl}
            color={color}
            eraseActive={eraseActive}
            setStrokeWidth={setStrokeWidth}
            showColorPicker={showColorPicker}
            setShowPromptSave={setShowPromptSave}
            handleEraserClick={handleEraserClick}
            handleLetterClick={handleLetterClick}
            clearAfterSavingRef={clearAfterSavingRef}
            undoEvents={undoEvents}
            redoEvents={redoEvents}
            setColor={setColor}
            activeShape={activeShape}
            handleImageClick={handleImageClick}
            imageActive={!!activeImage}
          />

          <div style={{ display: (textBoxActive || activeImage) && selectBoxControlsVisible ? 'block' : 'none' }}>
            <IconButton
              id="basic-button"
              aria-controls={!!anchorSelectBoxMenuEl ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={!!anchorSelectBoxMenuEl ? 'true' : undefined}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setSelectTextBoxMenuEl(event.currentTarget);
              }}
              sx={{
                display: selectBoxControlsVisible ? 'block' : 'none',
                position: 'absolute',
                zIndex: 1,
                left: selectBoxMenuPosition.x,
                top: selectBoxMenuPosition.y,
                backgroundColor: theme.palette.primary.main,
                p: 0,
                width: 28,
                height: 28,

                '&:hover': {
                  backgroundColor: theme.palette.primary.main
                }
              }}
            >
              <IconDotsVertical size={14} style={{ color: '#fff' }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorSelectBoxMenuEl}
              open={!!anchorSelectBoxMenuEl}
              onClose={() => setSelectTextBoxMenuEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem
                onClick={() => {
                  if (textBoxActiveRef.current) {
                    removeTextBox();
                  } else {
                    removeImageBox();
                  }
                  setSelectTextBoxMenuEl(null);
                }}
              >
                <FormattedMessage defaultMessage="Delete" />
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhiteBoard;
