import { Box, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { IconLayout } from '@tabler/icons';
import { bindMenu, bindTrigger, PopupState } from 'material-ui-popup-state/hooks';
import { ComponentProps, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import ControlButton from './ControlButton';

export type Layout = 'grid' | 'just-me' | 'just-them' | 'left-sidebar' | 'picture-in-picture' | 'pinned';

interface Props extends ComponentProps<typeof ControlButton> {
  onLayoutChange: (value: Layout) => void;
  draggableRef: any;
  whiteboardOpen: boolean;
  containerRef: RefObject<HTMLDivElement> | null;
  currentLayout: Layout;
  popupState: PopupState;
}

export default function LayoutButton({
  onLayoutChange,
  draggableRef,
  whiteboardOpen,
  containerRef,
  currentLayout,
  popupState,
  ...props
}: Props) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * Reset picture-in-picture draggable to original position
   */
  const resetDraggable = () => {
    if (draggableRef?.current) {
      draggableRef.current.state.x = 0;
      draggableRef.current.state.y = 0;
    }
  };

  return (
    <>
      {matchDownSM ? (
        <Box {...bindTrigger(popupState)}>
          <FormattedMessage defaultMessage="Layout" />
        </Box>
      ) : (
        <ControlButton
          {...bindTrigger(popupState)}
          startIcon={<IconLayout size={20} />}
          {...props}
          isLayout
          sx={{ border: 'none !important' }}
        ></ControlButton>
      )}
      <Menu
        container={containerRef?.current}
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          zIndex: 1302
        }}
      >
        {!whiteboardOpen && (
          <MenuItem
            sx={{
              background: currentLayout === 'grid' ? '#337AB7' : '',
              color: currentLayout === 'grid' ? 'white' : '#515151',
              '&:hover': {
                color: '#515151'
              }
            }}
            onClick={() => {
              onLayoutChange('grid');
              resetDraggable();
              popupState.close();
            }}
          >
            <FormattedMessage defaultMessage="Grid" />
          </MenuItem>
        )}
        {!whiteboardOpen && (
          <MenuItem
            sx={{
              background: currentLayout === 'just-me' ? '#337AB7' : '',
              color: currentLayout === 'just-me' ? 'white' : '#515151',
              '&:hover': {
                color: '#515151'
              }
            }}
            onClick={() => {
              onLayoutChange('just-me');
              resetDraggable();
              popupState.close();
            }}
          >
            <FormattedMessage defaultMessage="Just Me" />
          </MenuItem>
        )}
        {!whiteboardOpen && (
          <MenuItem
            sx={{
              background: currentLayout === 'just-them' ? '#337AB7' : '',
              color: currentLayout === 'just-them' ? 'white' : '#515151',
              '&:hover': {
                color: '#515151'
              }
            }}
            onClick={() => {
              onLayoutChange('just-them');
              popupState.close();
            }}
          >
            <FormattedMessage defaultMessage="Just Them" />
          </MenuItem>
        )}
        <MenuItem
          sx={{
            background: currentLayout === 'left-sidebar' ? '#337AB7' : '',
            color: currentLayout === 'left-sidebar' ? 'white' : '#515151',
            '&:hover': {
              color: '#515151'
            }
          }}
          onClick={() => {
            onLayoutChange('left-sidebar');
            resetDraggable();
            popupState.close();
          }}
        >
          <FormattedMessage defaultMessage="Left Sidebar" />
        </MenuItem>
        {!whiteboardOpen && (
          <MenuItem
            sx={{
              background: currentLayout === 'picture-in-picture' ? '#337AB7' : '',
              color: currentLayout === 'picture-in-picture' ? 'white' : '#515151',
              '&:hover': {
                color: '#515151'
              }
            }}
            onClick={() => {
              onLayoutChange('picture-in-picture');
              popupState.close();
            }}
          >
            <FormattedMessage defaultMessage="Picture in Picture" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
