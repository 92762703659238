import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import usePermissions from 'hooks/usePermissions';
import useProfileOptions from 'hooks/useProfileOptions';
import useUser from 'hooks/useUser';
import useUserRoles from 'hooks/useUserRoles';
import {
  administrationGroup,
  clientGroup,
  permissionsManagerGroup,
  providerItem,
  therapistAdminGroup,
  therapistGroup,
  therapistItem
} from 'menu-items';
import { useEffect, useState } from 'react';
import PopularCard from 'views/components/skeleton/PopularCard';
import NavGroup from './NavGroup';

const MenuList = () => {
  const { userId } = useAuth();
  const { providerOption, options } = useOptions();
  const { user, isClient, isNousSupport, refetchUser } = useUser(userId);
  const { profileOptionsMap } = useProfileOptions();

  const [isLoading, setIsLoading] = useState(false);
  const { permissions } = usePermissions();

  useEffect(() => {
    setIsLoading(true);
    refetchUser();

    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  }, [profileOptionsMap, refetchUser]);

  const { userRoles } = useUserRoles();
  let list: any = null;
  let firstItem: any = null;

  if (!user || !providerOption || isLoading) return <PopularCard />;

  if (user.isNousSupport) {
    list = administrationGroup;
  } else if (user.isProvider && user.isAdministrator) {
    list = therapistAdminGroup;
  } else if (user.isProvider) {
    list = therapistGroup;
  } else if (user.isClient) {
    list = clientGroup;
  } else {
    list = therapistGroup;
  }

  if (user?.isPermissionsManager) {
    list = permissionsManagerGroup;
  }
  firstItem = providerItem;

  if (providerOption.value === '0') {
    if (user.role !== 'PROVIDER') {
      firstItem = therapistItem;
    }
  }

  const hideItems: string[] = [];

  if (!isNousSupport && !user.userOptions?.canEditClinicAccounts) {
    hideItems.push('clinic-accounts');
  }

  if (!user.userOptions?.canEditClinicAppointments) {
    hideItems.push('clinic-appointments');
  }

  if (!user.isProvider && !user.isClient) {
    hideItems.push('appointments');
  }

  // If recordings are not allowed for therapist and therapist administrator role
  if (!user.userOptions?.canAccessRecordings) {
    hideItems.push('recordings');
  }

  if (isNousSupport) {
    hideItems.push('clinic-messages');
  }

  // If option to enable patient note taking is turned off then this code will be executed
  if (user.isClient && !user.clientOptions?.enableClientNoteTaking) {
    hideItems.push('notes');
  }

  if (!isClient && !user.isProvider) {
    hideItems.push('availability');
  }

  if (!user.userOptions?.canAccessClientRecord && !user.userOptions?.canAccessAllClientRecords) {
    hideItems.push('client-record');
  }

  if (!isClient && !user.userOptions?.enableProviderNoteTaking) {
    hideItems.push('notes');
  }

  if (!user?.isProvider || !user.userOptions?.enableMultipleParticipants) {
    hideItems.push('groups');
  }

  if (
    isNousSupport ||
    (isNousSupport && !!!userRoles?.find((userRole: any) => userRole?.permissions?.COMPLIMENTARY_SESSION?.defaultValue === true)) ||
    !user.userOptions?.manageComplimentarySession
  ) {
    hideItems.push('Contactme', 'waitinglist');
  }
  const currentUser = permissions && permissions.find((user) => user?._id === userId)?.accessSettings;
  if (
    currentUser?.DASHBOARD === false ||
    currentUser?.DASHBOARD === undefined ||
    profileOptionsMap?.providerPractice?.keys?.DASHBOARD?.value === false
  ) {
    hideItems.push('dashboard');
  }

  if (currentUser?.COMPLIMENTARY_SESSION) {
    if (
      user?.role === 'PROVIDER_ADMINISTRATOR (D)' ||
      user?.role === 'PROVIDER_ADMINISTRATOR [D]' ||
      user?.role === 'ADMINISTRATOR'
      // user?.role === 'PROVIDER_ADMINISTRATOR (S)' ||
      // user?.role === 'PROVIDER_ADMINISTRATOR [S]'
    ) {
      ['Contactme', 'waitinglist'].forEach((item) => {
        const index = hideItems.indexOf(item);
        if (index > -1) {
          hideItems.splice(index, 1);
        }
      });
    }
  }

  if (!options?.ROLES_AND_PERMISSIONS?.value) {
    hideItems.push('ProfileOptions');
  }
  if (!isClient && !user?.userOptions?.enableQuickBookings && !user?.userOptions?.enableRegisteredSessionBooking) {
    hideItems.push('appointments');
  }
  list = {
    ...list,
    children: list?.children?.filter((c) => !hideItems.includes(c.id))
  };

  // If option to hide messaged is turned off then this code will be executed
  if (
    isClient
      ? !user.clientOptions?.enableClientMessaging
      : !user.userOptions?.canAccessClientMessaging &&
        !user.userOptions?.canAccessInternalMessaging &&
        !user?.userOptions?.canAccessAllClientMessaging
  ) {
    const children = isClient
      ? list?.children?.filter((c) => c.id !== 'messages')
      : list?.children?.filter((c) => c.id !== 'internal-messages');
    list = {
      ...list,
      children
    };
  }

  const { children } = list;

  return <NavGroup item={{ ...list, children: user?.isPermissionsManager ? [...children] : [firstItem, ...children] }} />;
};

export default MenuList;
