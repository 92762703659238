import RTE from 'react-rte';

export const markdownToHTML = (value = '') => {
  try {
    const HTMLRegex = /(<([^>]+)>)/i;
    const isMarkdown = (str) => str.search('\\n') >= 0 || str.search(/__\w+__/) >= 0 || str.search(/\*\*\w+\*\*/) >= 0;

    if (!HTMLRegex.test(value) && isMarkdown(value)) {
      return RTE.createValueFromString(value, 'markdown').toString('html');
    }
    return value;
  } catch (error) {
    console.error('Error in markdownToHTML', error);
    return value;
  }
};
