import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useModal } from 'mui-modal-provider';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from 'store/actions';
import { DefaultRootStateProps } from 'types';
import LanguageChangeDialog from 'views/pages/session/dialogs/LanguageChangeDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '170px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 16px 6px 6px',
    marginRight: '2px',
    cursor: 'pointer'
  },
  bellBox: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'left',
    marginLeft: '1px',
    marginTop: '1px'
  },
  language: {
    width: '180px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 16px',
    marginLeft: '-11px',
    borderRadius: '12px',
    gap: '12px',
    transition: 'borderColor 0.3s ease-in-out',
    border: `1px solid transparent`,
    '&:hover': {
      '& $userName': {
        color: 'rgb(1, 118, 161)'
      },
      '& $arrow': {
        color: 'rgb(1, 118, 161)'
      }
    },
    whiteSpace: 'nowrap'
  },
  languageContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '120px',
    justifyContent: 'space between',
    flex: 1,
    marginRight: '2px'
  },
  avatar: {
    width: '26px',
    height: '26px',
    // marginRight: '4px',
    marginLeft: '4px'
  },
  selectedLanguageName: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.25%',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: '0 2px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  divider: {
    height: '30px',
    marginRight: '3px',
    borderLeft: `1px solid ${theme.palette.divider}`,
    marginLeft: '6px'
  },
  bellIcon: {
    marginRight: '8px'
  },
  arrow: {
    marginTop: '5px'
  },
  badge: {
    top: '0',
    right: '0'
  }
}));

const LocalizationSection = () => {
  const classes = useStyles();
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en-CA');
  const isFromSessionPath = window.location.pathname.split('/')[1] === 'session';
  const isFromLoginPath = window.location.pathname.split('/')[1] === 'login';
  const { request } = useAuth();
  const { refetchUser } = useUser(userId);
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    }
    const storedLanguage = localStorage.getItem('language') || 'en-CA';
    setSelectedLanguage(storedLanguage);
    dispatch({ type: actionTypes.THEME_LOCALE, locale: storedLanguage });
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };
  const updateProfile = useMutation((preferredLanguage: string) => request.put(`/user/${userId}`, { preferredLanguage }), {
    onSuccess: () => refetchUser()
  });
  const { showModal } = useModal();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>,
    lng: string
  ) => {
    const languageMap: { [key: string]: string } = {
      'en-CA': 'english-CA',
      en: 'english-US',
      fr: 'french'
    };
    const selectedlang = languageMap[lng] || '';
    if (!isFromLoginPath) {
      setSelectedLanguage(lng);
      localStorage.setItem('language', lng);
      dispatch({ type: actionTypes.THEME_LOCALE, locale: lng });
      updateProfile.mutate(selectedlang);
      handleClose();
      return;
    }
    if ((lng === 'en-CA' || lng === 'en') && selectedLanguage !== 'fr') {
      setSelectedLanguage(lng);
      handleClose();
      return;
    }
    setSelectedLanguage(lng);
    localStorage.setItem('language', lng);
    dispatch({ type: actionTypes.THEME_LOCALE, locale: lng });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    setSelectedLanguage(customization.locale);
  }, [customization]);

  return (
    <Box className={classes.root}>
      <Box className={classes.language} onClick={handleClick}>
        <div className={classes.languageContainer}>
          <Typography component="span" variant="h4" className={classes.selectedLanguageName} sx={{ m: '0 2px' }}>
            {selectedLanguage === 'en-CA' ? 'English - CA' : selectedLanguage === 'en' ? 'English - US' : 'Francais - CA'}
          </Typography>
          <div className={classes.arrow}>{isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorReference="anchorEl"
        slotProps={{ paper: { style: { width: '120px' } } }}
      >
        <MenuItem
          selected={selectedLanguage === 'en-CA'}
          onClick={(event) => {
            if (isFromSessionPath) {
              showModal(LanguageChangeDialog, {
                event,
                handleClose,
                selectedLanguage,
                setSelectedLanguage,
                lng: 'en-CA'
              });
            } else {
              handleListItemClick(event, 'en-CA');
            }
          }}
        >
          English - CA
        </MenuItem>
        <MenuItem
          selected={selectedLanguage === 'en'}
          onClick={(event) => {
            if (isFromSessionPath) {
              showModal(LanguageChangeDialog, {
                event,
                handleClose,
                selectedLanguage,
                setSelectedLanguage,
                lng: 'en'
              });
            } else {
              handleListItemClick(event, 'en');
            }
          }}
        >
          English - US
        </MenuItem>
        <MenuItem
          selected={selectedLanguage === 'fr'}
          onClick={(event) => {
            if (isFromSessionPath) {
              showModal(LanguageChangeDialog, {
                event,
                handleClose,
                selectedLanguage,
                setSelectedLanguage,
                lng: 'fr'
              });
            } else {
              handleListItemClick(event, 'fr');
            }
          }}
        >
          Francais - CA
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LocalizationSection;
