import { Box, FormHelperText } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import RMultiDatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

import './styles.scss';

interface Props {
  name: string;
  label: string;
  required?: boolean;
}

const MultiDatePicker = React.memo(
  ({ name, label, required, ...rest }: Props): JSX.Element => (
    <Controller
      name={name}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <Box
          className="multi-date-picker-container"
          sx={{ '& .rmdp-input': error ? { borderColor: '#f44336' } : { borderColor: '#0000003b' } }}
        >
          <span className="multi-date-picker-label" style={{ color: error ? '#f44336' : '#444' }}>
            {label} {required ? '*' : ''}
          </span>
          <RMultiDatePicker
            multiple
            sort
            format="MM/DD/YYYY"
            plugins={[<DatePanel />]}
            value={value}
            onChange={(date) => {
              onChange(date);
            }}
            weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          />
          {!!error && (
            <FormHelperText error={!!error} sx={{ marginLeft: 2 }}>
              Required
            </FormHelperText>
          )}
        </Box>
      )}
    />
  )
);

export default MultiDatePicker;
