import { lazy } from 'react';
import Loadable from 'views/components/Loadable';
import MinimalLayout from 'views/layout/MinimalLayout';

const Verify = Loadable(lazy(() => import('views/pages/authentication/verify')));

const GlobalRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/verify',
      element: <Verify />
    }
  ]
};

export default GlobalRoutes;
