import { Stack } from '@mui/material';
import PopularCard from 'views/components/skeleton/PopularCard';

export default function CenteredLoading() {
  return (
    <Stack direction="column" alignItems="stretch">
      <PopularCard />
    </Stack>
  );
}
