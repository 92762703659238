import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';

export type CheckboxProps = Omit<MUICheckboxProps, 'name'> & {
  name: string;
  parseError?: (error: FieldError) => string;
  label?: React.ReactNode;
  helperText?: string;
  required?: boolean;
  getValue?: Function;
};

const Checkbox = React.memo(
  ({ name, required, parseError, label = '', disabled, getValue, ...rest }: CheckboxProps): JSX.Element => (
    <Controller
      name={name}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
        // eslint-disable-next-line no-nested-ternary
        const helperText = error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : rest.helperText;

        const getData = (data: boolean) => {
          if (typeof getValue === 'function') {
            getValue(data);
          }

          return data;
        };

        return (
          <FormControl required={required} error={invalid}>
            <FormGroup row>
              {label ? (
                <FormControlLabel
                  label={`${label} ${required ? ' *' : ''}`}
                  control={
                    <MUICheckbox
                      color="primary"
                      style={{
                        color: invalid ? red[400] : undefined
                      }}
                      value={value}
                      checked={!!value}
                      onChange={() => {
                        onChange(getData(!value));
                      }}
                      disabled={disabled}
                    />
                  }
                />
              ) : (
                <MUICheckbox
                  color="primary"
                  style={{
                    color: invalid ? red[400] : undefined
                  }}
                  value={value}
                  checked={!!value}
                  onChange={() => {
                    onChange(getData(!value));
                  }}
                  disabled={disabled}
                />
              )}
            </FormGroup>
            {helperText && <FormHelperText error={invalid}>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  )
);

export default Checkbox;
