import value from 'assets/scss/_themes-vars.module.scss';
import { useIntl } from 'react-intl';
import { NumbColorFunc, StringBoolFunc, StringNumFunc } from 'types';

/**
 * @description Handle naming conventions
 */
function useLabels() {
  const intl = useIntl();

  const locationTypeLabels = {
    Office: intl.formatMessage({ defaultMessage: 'In-Person' }),
    Online: intl.formatMessage({ defaultMessage: 'Virtual' }),
    Phone: intl.formatMessage({ defaultMessage: 'Phone' }),
    Text: intl.formatMessage({ defaultMessage: 'Text' })
  };

  const statusLabels = {
    Attended: intl.formatMessage({ defaultMessage: 'Attended' }),
    'Pending Provider Confirmation': intl.formatMessage({ defaultMessage: 'Pending' }),
    Pending: intl.formatMessage({ defaultMessage: 'Pending' }),
    Canceled: intl.formatMessage({ defaultMessage: 'Canceled' }),
    'Late Canceled': intl.formatMessage({ defaultMessage: 'Late Canceled' }),
    'Late Canceled by Provider': intl.formatMessage({ defaultMessage: 'Late Canceled' }),
    'Canceled by Provider': intl.formatMessage({ defaultMessage: 'Canceled' }),
    'No Show': intl.formatMessage({ defaultMessage: 'No Show' }),
    Accepted: intl.formatMessage({ defaultMessage: 'Accepted' })
  };

  const paymentStatusLabels = {
    Unpaid: intl.formatMessage({ defaultMessage: 'Unpaid' }),
    Invoiced: intl.formatMessage({ defaultMessage: 'Invoiced' }),
    Paid: intl.formatMessage({ defaultMessage: 'Paid' }),
    'Partially Paid': intl.formatMessage({ defaultMessage: 'Partially Paid' }),
    'No Charge': intl.formatMessage({ defaultMessage: 'No Charge' }),
    'Charge Failed': intl.formatMessage({ defaultMessage: 'Charge Failed' }),
    'PROCESSING PAYMENT': intl.formatMessage({ defaultMessage: 'PROCESSING PAYMENT' })
  };

  const strengthIndicator: StringNumFunc = (number) => {
    const hasNumber: StringBoolFunc = (number) => new RegExp(/[0-9]/).test(number);

    // has mix of small and capitals
    const hasMixed: StringBoolFunc = (number) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // has special chars
    const hasSpecial: StringBoolFunc = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);
    let strengths = 0;
    if (number.length > 5) strengths += 1;
    if (number.length > 7) strengths += 1;
    if (hasNumber(number)) strengths += 1;
    if (hasSpecial(number)) strengths += 1;
    if (hasMixed(number)) strengths += 1;
    return strengths;
  };

  const strengthColor: NumbColorFunc = (count) => {
    if (count < 2) return { label: intl.formatMessage({ defaultMessage: 'Poor' }), color: value.errorMain };
    if (count < 3) return { label: intl.formatMessage({ defaultMessage: 'Weak' }), color: value.warningDark };
    if (count < 4) return { label: intl.formatMessage({ defaultMessage: 'Normal' }), color: value.orangeMain };
    if (count < 5) return { label: intl.formatMessage({ defaultMessage: 'Good' }), color: value.successMain };
    if (count < 6) return { label: intl.formatMessage({ defaultMessage: 'Strong' }), color: value.successDark };
    return { label: 'Poor', color: value.errorMain };
  };

  return { locationTypeLabels, strengthIndicator, strengthColor, statusLabels, paymentStatusLabels };
}

export default useLabels;
