import { Box, Chip, FormControl, InputLabel, MenuItem, Select, Stack, TablePagination, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid';
import { addDays, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import useUpcomingSession from 'hooks/useUpcomingSession';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Appointment } from 'types/appointment';
import MainCard from 'ui-component/cards/MainCard';
import BasicTableCollaps from 'views/components/BasicTableCollaps';
import ListSkeleton from 'views/components/skeleton/ListSkeleton';
import TableMoreAction from './TableMoreAction';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    fontFamily: 'Roboto',
    backgroundColor: '#F8F8F8'
  },
  tableContainer: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  rowContainer: {
    marginBottom: 0,
    maxHeight: '440px'
  },
  dataGridRoot: {
    border: 'none',
    borderRadius: 'none'
  },
  avatar: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(0.1)
  },
  userName: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.25%',
    color: theme.palette.text.primary
  },
  profileCard: {
    width: '170px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 16px',
    marginLeft: '2px',
    borderRadius: '12px',
    gap: '12px'
  },
  profile: {
    display: 'flex',
    flexDirection: 'column'
  },
  email: {
    fontWeight: 'normal',
    fontSize: 'small'
  },
  '& .MuiCardHeader-root': {
    backgroundColor: '#F8F8F8'
  },
  sessionTitle: {
    fontWeight: '500',
    fontSize: '18px'
  }
}));

const UpcomingSessions = () => {
  const classes = useStyles();

  const intl = useIntl();

  const [sessionType, setSessionType] = React.useState<string | null>('All');
  const { userId } = useAuth();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { timezone } = useTimezone();
  const [dateRange, setDateRange] = useState<{ from: Date | null; to: Date | null }>({ from: null, to: null });

  useEffect(() => {
    const currentDate = moment().tz(timezone);
    const from = new Date(currentDate.toISOString());
    const to = addDays(from, 30);
    setDateRange({ from, to });
  }, [timezone]);

  const { appointments, isLoadingAppointments } = useUpcomingSession({
    from: dateRange?.from as Date,
    to: dateRange?.to as Date,
    id: userId,
    limit: true
  });

  const [appointmentData, setappointmentData] = useState<any>([]);

  useEffect(() => {
    const clientAppointments = appointments?.filter((appointment) =>
      (appointment as Appointment)?.members?.some((user) => user.role === 'CLIENT')
    );
    clientAppointments?.sort((a, b) => (new Date(a.start) as any) - (new Date(b.start) as any));
    setappointmentData(clientAppointments);
  }, [appointments]);

  const paginatedData = appointmentData?.length > 0 ? appointmentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];

  const [selected, setSelected] = useState('All');

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleSessionTypeFilter = (sessionType: string) => {
    const filteredData = appointments?.filter((appointment) => {
      if (!(appointment as Appointment)?.members?.some((user) => user.role === 'CLIENT')) {
        return false;
      }
      if (sessionType === 'All') {
        return true;
      }
      if (sessionType === 'Virtual') {
        setPage(0);
        return (appointment as Appointment).locationType === 'Online';
      }
      if (sessionType === 'Text') {
        setPage(0);
        return (appointment as Appointment).locationType === 'Text';
      }
      if (sessionType === 'Office') {
        setPage(0);
        return (appointment as Appointment).locationType === 'Office';
      }
      if (sessionType === 'Phone') {
        setPage(0);
        return (appointment as Appointment).locationType === 'Phone';
      }
      return false;
    });

    setappointmentData(filteredData);
    setSessionType(sessionType);
  };

  appointmentData?.forEach((appointment) => {
    const clients = appointment?.members?.filter((user) => user.role === 'CLIENT');
    if (clients && clients.length > 0) {
      appointment.fullName = clients[0].fullName;
    }
  });

  const statusColors = {
    paid: '#ECFDF3',
    received: '#ECFDF3',

    invoiced: '#FFFAEB',
    completed: '#FFFAEB',

    due: '#FEE4E2',
    unpaid: '#FEF3F2',

    cancelled: '#F8FCFF',
    'in process': '#FFFAEB'
  };

  const statusTextColors = {
    paid: '#039855',
    received: '#039855',

    invoiced: '#DC6803',
    completed: '#DC6803',

    due: '#DC6803',
    unpaid: '#D92D20',

    'in process': '#DC6803',
    cancelled: '#0076A1'
  };

  const columns: GridColDef[] = [
    {
      field: 'client_name',
      width: 260,
      headerName: intl.formatMessage({ defaultMessage: 'Clients' }),
      valueGetter: ({ row }) => row.fullName,
      sortable: false,
      renderCell: ({ value }) => value
    },
    {
      field: 'date_time',
      width: 260,
      headerName: intl.formatMessage({ defaultMessage: 'Date & Time' }),
      valueGetter: ({ row }) => row.start,
      sortable: false,
      renderCell: ({ value, row }) => {
        return (
          <Typography>
            {value
              ? format(utcToZonedTime(value, timezone), intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a', {
                  locale: intl.locale === 'fr' ? frLocale : enLocale
                })
              : '-'}
          </Typography>
        );
      }
    },
    {
      field: 'service_type',
      width: 260,
      headerName: intl.formatMessage({ defaultMessage: 'Service Type' }),
      sortable: false,
      valueGetter: ({ row }) => row?.serviceType?.name,
      renderCell: ({ value }) => {
        return value;
      }
    },
    {
      field: 'locationType',
      width: 260,
      headerName: intl.formatMessage({ defaultMessage: 'Session Type' }),
      valueGetter: ({ row }) => row.locationType,
      sortable: false,
      renderCell: ({ row }) => {
        let apptType = row?.locationType;
        if (apptType === 'Online') {
          apptType = 'Virtual';
        }
        const icons = {
          Office: (
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.5 8.33398C16.5 14.1673 9 19.1673 9 19.1673C9 19.1673 1.5 14.1673 1.5 8.33398C1.5 6.34486 2.29018 4.43721 3.6967 3.03068C5.10322 1.62416 7.01088 0.833984 9 0.833984C10.9891 0.833984 12.8968 1.62416 14.3033 3.03068C15.7098 4.43721 16.5 6.34486 16.5 8.33398Z"
                stroke="#0076A1"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          Virtual: (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_91_6088)">
                <path
                  d="M19.1654 5.83398L13.332 10.0007L19.1654 14.1673V5.83398Z"
                  stroke="#0076A1"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.6654 4.16602H2.4987C1.57822 4.16602 0.832031 4.91221 0.832031 5.83268V14.166C0.832031 15.0865 1.57822 15.8327 2.4987 15.8327H11.6654C12.5858 15.8327 13.332 15.0865 13.332 14.166V5.83268C13.332 4.91221 12.5858 4.16602 11.6654 4.16602Z"
                  stroke="#0076A1"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_91_6088">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ),
          Text: (
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.1016 12.5C18.1016 12.942 17.926 13.366 17.6134 13.6785C17.3008 13.9911 16.8769 14.1667 16.4349 14.1667H6.4349L3.10156 17.5V4.16667C3.10156 3.72464 3.27716 3.30072 3.58972 2.98816C3.90228 2.67559 4.3262 2.5 4.76823 2.5H16.4349C16.8769 2.5 17.3008 2.67559 17.6134 2.98816C17.926 3.30072 18.1016 3.72464 18.1016 4.16667V12.5Z"
                stroke="#337AB7"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          Phone: (
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.9328 14.0994V16.5994C18.9337 16.8315 18.8862 17.0612 18.7932 17.2739C18.7003 17.4865 18.5639 17.6774 18.3929 17.8343C18.2219 17.9912 18.02 18.1107 17.8001 18.185C17.5802 18.2594 17.3473 18.287 17.1161 18.2661C14.5518 17.9875 12.0886 17.1112 9.92447 15.7078C7.911 14.4283 6.20392 12.7212 4.92447 10.7078C3.51612 8.53377 2.63967 6.05859 2.36614 3.48276C2.34531 3.25232 2.3727 3.02006 2.44656 2.80078C2.52041 2.5815 2.63911 2.38 2.79511 2.20911C2.95111 2.03822 3.14098 1.90169 3.35263 1.8082C3.56429 1.71471 3.79309 1.66631 4.02447 1.6661H6.52447C6.92889 1.66212 7.32097 1.80533 7.62761 2.06904C7.93425 2.33275 8.13454 2.69897 8.19114 3.09943C8.29666 3.89948 8.49235 4.68504 8.77447 5.4411C8.88659 5.73937 8.91086 6.06352 8.84439 6.37516C8.77793 6.6868 8.62352 6.97286 8.39947 7.19943L7.34114 8.25776C8.52744 10.3441 10.2549 12.0715 12.3411 13.2578L13.3995 12.1994C13.626 11.9754 13.9121 11.821 14.2237 11.7545C14.5354 11.688 14.8595 11.7123 15.1578 11.8244C15.9139 12.1066 16.6994 12.3022 17.4995 12.4078C17.9043 12.4649 18.274 12.6688 18.5382 12.9807C18.8025 13.2926 18.9429 13.6907 18.9328 14.0994Z"
                stroke="#337AB7"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )
        };
        const selectedIcon = icons[apptType];

        return (
          <Box display="flex" alignItems="center">
            {selectedIcon}
            <Typography marginLeft="4px" pl="6px">
              {apptType === 'Text'
                ? intl.formatMessage({ defaultMessage: 'Text' })
                : apptType === 'Virtual' || apptType === 'Online'
                ? intl.formatMessage({ defaultMessage: 'Virtual' })
                : apptType === 'Office'
                ? intl.formatMessage({ defaultMessage: 'In Person' })
                : intl.formatMessage({ defaultMessage: 'Phone' })}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'status',
      width: 260,
      headerName: intl.formatMessage({ defaultMessage: 'Payment Status' }),
      valueGetter: ({ row }) => row?.paymentStatus,
      sortable: false,
      renderCell: ({ row }) => {
        if (!row) return null;

        const getLabel = (status) => {
          switch (status.toLowerCase()) {
            case 'paid':
              return intl.formatMessage({ defaultMessage: 'Paid' });
            case 'received':
              return intl.formatMessage({ defaultMessage: 'Received' });
            case 'invoiced':
              return intl.formatMessage({ defaultMessage: 'Invoiced' });
            case 'completed':
              return intl.formatMessage({ defaultMessage: 'Completed' });
            case 'due':
              return intl.formatMessage({ defaultMessage: 'Due' });
            case 'unpaid':
              return intl.formatMessage({ defaultMessage: 'Unpaid' });
            case 'in process':
              return intl.formatMessage({ defaultMessage: 'In process' });
            case 'cancelled':
              return intl.formatMessage({ defaultMessage: 'Cancelled' });
            default:
              return '';
          }
        };

        const label = getLabel(row.paymentStatus);
        const status = row.paymentStatus.toLowerCase();

        return (
          <Chip
            label={label}
            size="small"
            sx={{
              backgroundColor: statusColors[status],
              color: statusTextColors[status],
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: statusColors[status],
                color: statusTextColors[status]
              }
            }}
          />
        );
      }
    }
  ];

  return (
    <Box className={classes.root}>
      <MainCard
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography component="div" className={classes.sessionTitle}>
              {intl.formatMessage({ defaultMessage: 'Upcoming sessions' })}
            </Typography>
          </Stack>
        }
        content={false}
        sx={{
          '& .MuiCardHeader-root': {
            backgroundColor: '#F8F8F8',
            height: '80px'
          },
          '& .MuiDataGrid-footerContainer': {
            display: 'none'
          }
        }}
        secondary={
          <>
            <Stack direction="row" spacing={2} alignItems="center" background-Color="#F8F8F8">
              <FormControl sx={{ width: '8rem', borderRadius: '12px', height: '2rem' }}>
                <InputLabel id="session-type-select-label">
                  <FormattedMessage defaultMessage="Session Type" />
                </InputLabel>
                <Select
                  labelId="session-type-select-label"
                  id="session-type-select"
                  value={selected}
                  label={intl.formatMessage({ defaultMessage: 'Session Type' })}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  <MenuItem value="All" key={0} onClick={() => handleSessionTypeFilter('All')} selected={sessionType === 'All'}>
                    {intl.formatMessage({ defaultMessage: 'All' })}
                  </MenuItem>
                  <MenuItem value="Virtual" key={1} onClick={() => handleSessionTypeFilter('Virtual')} selected={sessionType === 'Virtual'}>
                    {intl.formatMessage({ defaultMessage: 'Virtual' })}
                  </MenuItem>
                  <MenuItem value="Text" key={2} onClick={() => handleSessionTypeFilter('Text')} selected={sessionType === 'Text'}>
                    {intl.formatMessage({ defaultMessage: 'Text' })}
                  </MenuItem>
                  <MenuItem value="Office" key={1} onClick={() => handleSessionTypeFilter('Office')} selected={sessionType === 'Office'}>
                    {intl.formatMessage({ defaultMessage: 'In Person' })}
                  </MenuItem>
                  <MenuItem value="Phone" key={2} onClick={() => handleSessionTypeFilter('Phone')} selected={sessionType === 'Phone'}>
                    {intl.formatMessage({ defaultMessage: 'Phone' })}
                  </MenuItem>
                </Select>
              </FormControl>
              <TableMoreAction title="More" to="/appointments" />
            </Stack>
          </>
        }
      >
        <Box sx={{ mt: 0, mb: 0, pl: 2.1 }} className={classes.rowContainer}>
          {isLoadingAppointments ? (
            <ListSkeleton />
          ) : (
            <BasicTableCollaps
              emptyMessage={intl.formatMessage({ defaultMessage: 'No Appointment Found.' })}
              columns={columns}
              rows={paginatedData}
              uniqueBy="event_id"
              pagination={false}
              defaultSortModel={[{ field: 'date', sort: 'asc' }]}
              getRowId={(row) => row.event_id}
              hideSearch
              dashboard
              getRowHeight={() => 75}
              classes={{ root: classes.dataGridRoot }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  minHeight: '56.89px !important',
                  maxHeight: '56.89px !important'
                }
              }}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <TablePagination
            // rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={appointmentData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            SelectProps={{
              sx: { display: 'none' }
            }}
          />
        </Box>
      </MainCard>
    </Box>
  );
};

export default UpcomingSessions;
