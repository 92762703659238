import { useIntl } from 'react-intl';
import BasicDialog from 'views/components/modals/BasicDialog';

import { Button, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { RefObject, useState } from 'react';
import SessionSettings from '../SessionSettings';

type Props = {
  containerRef: RefObject<HTMLDivElement | null>;
};

function SessionSettingsDialog({ containerRef }: Props) {
  const intl = useIntl();
  const [open, setOpen] = useState(true);
  // eslint-disable-next-line no-restricted-globals
  return window.innerHeight === screen.height ? (
    <BasicDialog open={open} maxWidth="xs" container={containerRef.current}>
      <Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h4">{intl.formatMessage({ defaultMessage: 'Settings' })}</Typography>
          <Button onClick={() => setOpen(false)} sx={{ width: '100%', justifyContent: 'flex-end' }}>
            <IconX />
          </Button>
        </Stack>

        <SessionSettings />
      </Stack>
    </BasicDialog>
  ) : (
    <BasicDialog open={open} maxWidth="xs">
      <Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h4">{intl.formatMessage({ defaultMessage: 'Settings' })}</Typography>
          <Button onClick={() => setOpen(false)} sx={{ width: '100%', justifyContent: 'flex-end' }}>
            <IconX />
          </Button>
        </Stack>

        <SessionSettings />
      </Stack>
    </BasicDialog>
  );
}

export default SessionSettingsDialog;
