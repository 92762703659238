import { Chip } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import PreviewDialog from 'views/components/modals/PreviewDialog';

type Props = {
  _id?: string;
  name: string;
  link?: string;
  contentType?: string;
  onDelete?: Function;
};

const DocumentCover = ({ _id, name, link, contentType, onDelete }: Props) => {
  const { showModal } = useModal();

  const isImage = contentType?.includes('image') && link;

  return (
    <Chip
      label={name}
      variant="outlined"
      sx={{ backgroundColor: '#f8f8f8' }}
      onClick={() => {
        showModal(PreviewDialog, {
          name,
          url: link,
          isImage,
          onClose: () => {}
        });
      }}
      onDelete={
        onDelete
          ? () => {
              onDelete(_id);
            }
          : undefined
      }
    />
  );
};

export default DocumentCover;
