import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
export default function useDashboard({ filter, startDate, endDate }: any) {
  const { request, userId } = useAuth();
  const {
    data: dashboardData,
    isLoading,
    refetch: refetchTotalEarning
  } = useQuery([`/dashboard`, filter], () =>
    request
      .get(`/dashboard`, {
        params: { filter, startDate, endDate, userId }
      })
      .then((response) => response.data as any)
  );

  const { mutateAsync: updateDashboard, isLoading: isUpdatingDashboard } = useMutation(`/user/${userId}/dashboard`, (payload: any) =>
    request.put(`/user/${userId}/dashboard`, payload).then((response) => response.data)
  );

  return {
    dashboardData,
    refetchTotalEarning,
    isLoading,
    isUpdatingDashboard,
    updateDashboard
  };
}
