export const supported = {
  object: ['pdf', 'image'],
  googleDocs: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain'
  ]
};

export const validateSupport = (fileType) => {
  const returning: any = { supported: false, type: null };
  const supportedEntries = Object.entries(supported);
  for (let i = 0; i < supportedEntries.length; i++) {
    const key = supportedEntries[i][0];
    const value = supportedEntries[i][1];
    if (value.some((type) => fileType.includes(type))) {
      returning.type = key;
      returning.supported = true;
      break;
    }
  }
  return returning;
};

export const getGoogleDocsURL = (documentURL) => `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(documentURL)}`;
