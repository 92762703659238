import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import {
  IconApps,
  IconBasket,
  IconCalendar,
  IconCalendarEvent,
  IconCalendarTime,
  IconClipboardList,
  IconDashboard,
  IconFilePhone,
  IconFiles,
  IconFileTime,
  IconListSearch,
  IconMessage,
  IconMessages,
  IconNfc,
  IconNote,
  IconPaperclip,
  IconSettings,
  IconSpeakerphone,
  IconTable,
  IconTableOptions,
  IconUserCheck,
  IconUsers,
  IconVideo
} from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

const icons = {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconDashboard,
  IconNote,
  IconCalendar,
  IconNfc,
  IconUsers,
  IconSettings,
  IconPaperclip,
  IconListSearch,
  IconVideo,
  IconCalendarEvent,
  IconCalendarTime,
  IconClipboardList,
  IconTable,
  CampaignOutlinedIcon,
  IconSpeakerphone,
  IconFileTime,
  IconFilePhone,
  IconMessage,
  IconApps,
  IconFiles,
  IconTableOptions
};

export const administrationGroup = {
  id: 'administration',
  type: 'group',
  children: [
    {
      id: 'availability',
      title: <FormattedMessage defaultMessage="Availability" />,
      type: 'item',
      url: '/availability',
      icon: icons.IconCalendarTime
    },
    {
      id: 'clinic-appointments',
      title: <FormattedMessage defaultMessage="Clinic Appointments" />,
      type: 'item',
      url: '/clinic-appointments',
      icon: icons.IconCalendar
    },
    {
      id: 'client-record',
      title: <FormattedMessage defaultMessage="Client Record" />,
      type: 'item',
      url: '/client-record?tab=Info',
      icon: icons.IconClipboardList
    },
    {
      id: 'clinic-accounts',
      title: <FormattedMessage defaultMessage="Provider Accounts" />,
      type: 'item',
      url: '/clinic-accounts',
      icon: icons.IconTable
    },
    {
      id: 'notes',
      title: <FormattedMessage defaultMessage="Notes" />,
      type: 'item',
      url: '/notes',
      icon: icons.IconNote
    },
    {
      id: 'Contactme',
      title: <FormattedMessage defaultMessage="Contact Me Request" />,
      type: 'item',
      url: '/complimentarysession',
      icon: icons.IconFilePhone,
      divider: true
    },
    {
      id: 'internal-messages',
      title: <FormattedMessage defaultMessage="Messages" />,
      type: 'collapse',
      icon: icons.IconMessages,
      children: [
        {
          id: 'clinic-messages',
          title: <FormattedMessage defaultMessage="Clinic  Messages" />,
          type: 'item',
          url: '/internal-messages',
          icon: icons.IconMessage
        },
        {
          id: 'messages',
          title: <FormattedMessage defaultMessage="Client Messages" />,
          type: 'item',
          url: '/messages',
          icon: icons.IconUsers
        }
      ]
    },
    {
      id: 'administration',
      title: <FormattedMessage defaultMessage="Administration" />,
      type: 'item',
      url: '/administration',
      icon: icons.IconSettings,
      divider: true
    },
    {
      id: 'notifications',
      title: <FormattedMessage defaultMessage="Notifications" />,
      type: 'item',
      url: '/notifications',
      icon: icons.IconSpeakerphone
    },
    {
      id: 'resources',
      title: <FormattedMessage defaultMessage="Resources" />,
      type: 'item',
      url: '/resources',
      icon: icons.IconApps
    }
  ]
};

export const permissionsManagerGroup = {
  id: 'permissionsManager',
  type: 'group',
  children: [
    {
      id: 'administration',
      title: <FormattedMessage defaultMessage="Administration" />,
      type: 'item',
      url: '/administration',
      icon: icons.IconSettings,
      divider: true
    }
  ]
};

export const therapistAdminGroup = {
  id: 'therapist-admin',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage defaultMessage="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard
    },
    {
      id: 'groups',
      title: <FormattedMessage defaultMessage="Groups" />,
      type: 'item',
      url: '/groups',
      icon: icons.IconUsers
    },
    {
      id: 'availability',
      title: <FormattedMessage defaultMessage="Availability" />,
      type: 'item',
      url: '/availability',
      icon: icons.IconCalendarTime
    },
    {
      id: 'clinic-appointments',
      title: <FormattedMessage defaultMessage="Clinic Appointments" />,
      type: 'item',
      url: '/clinic-appointments',
      icon: icons.IconCalendarEvent
    },
    {
      id: 'appointments',
      title: <FormattedMessage defaultMessage="My Appointments" />,
      type: 'item',
      url: '/appointments',
      icon: icons.IconCalendarEvent
    },
    {
      id: 'client-record',
      title: <FormattedMessage defaultMessage="Client Record" />,
      type: 'item',
      url: '/client-record?tab=Info',
      icon: icons.IconClipboardList
    },
    {
      id: 'clinic-accounts',
      title: <FormattedMessage defaultMessage="Provider Accounts" />,
      type: 'item',
      url: '/clinic-accounts',
      icon: icons.IconTable
    },
    {
      id: 'recordings',
      title: <FormattedMessage defaultMessage="Recordings" />,
      type: 'item',
      url: '/recordings',
      icon: icons.IconVideo
    },
    {
      id: 'notes',
      title: <FormattedMessage defaultMessage="Notes" />,
      type: 'item',
      url: '/notes',
      icon: icons.IconNote
    },
    {
      id: 'internal-messages',
      title: <FormattedMessage defaultMessage="Messages" />,
      type: 'collapse',
      icon: icons.IconMessages,
      children: [
        {
          id: 'clinic-messages',
          title: <FormattedMessage defaultMessage="Clinic  Messages" />,
          type: 'item',
          url: '/internal-messages',
          icon: icons.IconMessage
        },
        {
          id: 'messages',
          title: <FormattedMessage defaultMessage="Client Messages" />,
          type: 'item',
          url: '/messages',
          icon: icons.IconUsers
        }
      ]
    },
    {
      id: 'Contactme',
      title: <FormattedMessage defaultMessage="Contact Me Request" />,
      type: 'item',
      url: '/complimentarysession',
      icon: icons.IconFilePhone,
      divider: true
    },
    {
      id: 'administration',
      title: <FormattedMessage defaultMessage="Administration" />,
      type: 'item',
      url: '/administration',
      icon: icons.IconSettings,
      divider: true
    },
    {
      id: 'notifications',
      title: <FormattedMessage defaultMessage="Notifications" />,
      type: 'item',
      url: '/notifications',
      icon: icons.IconSpeakerphone
    },
    {
      id: 'resources',
      title: <FormattedMessage defaultMessage="Resources" />,
      type: 'item',
      url: '/resources',
      icon: icons.IconApps
    }
  ]
};

export const clientGroup = {
  id: 'client',
  type: 'group',
  children: [
    {
      id: 'availability',
      title: <FormattedMessage defaultMessage="Availability" />,
      type: 'item',
      url: '/availability',
      icon: icons.IconCalendarTime
    },
    {
      id: 'appointments',
      title: <FormattedMessage defaultMessage="My Appointments" />,
      type: 'item',
      url: '/appointments',
      icon: icons.IconCalendarEvent
    },
    {
      id: 'documents',
      title: <FormattedMessage defaultMessage="Documents" />,
      type: 'item',
      url: '/documents',
      icon: icons.IconPaperclip
    },
    {
      id: 'records',
      title: <FormattedMessage defaultMessage="Records" />,
      type: 'item',
      url: '/record',
      icon: icons.IconClipboardList
    },
    {
      id: 'notes',
      title: <FormattedMessage defaultMessage="Notes" />,
      type: 'item',
      url: '/notes',
      icon: icons.IconNote
    },
    {
      id: 'messages',
      title: <FormattedMessage defaultMessage="Messages" />,
      type: 'item',
      url: '/messages',
      icon: icons.IconMessages
    },
    {
      id: 'notifications',
      title: <FormattedMessage defaultMessage="Notifications" />,
      type: 'item',
      url: '/notifications',
      icon: icons.IconSpeakerphone,
      divider: true
    },
    {
      id: 'resources',
      title: <FormattedMessage defaultMessage="Resources" />,
      type: 'item',
      url: '/resources',
      icon: icons.IconApps
    }
  ]
};

export const therapistGroup = {
  id: 'therapist',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage defaultMessage="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard
    },
    {
      id: 'groups',
      title: <FormattedMessage defaultMessage="Groups" />,
      type: 'item',
      url: '/groups',
      icon: icons.IconUsers
    },
    {
      id: 'availability',
      title: <FormattedMessage defaultMessage="Availability" />,
      type: 'item',
      url: '/availability',
      icon: icons.IconCalendarTime
    },
    {
      id: 'clinic-appointments',
      title: <FormattedMessage defaultMessage="Clinic Appointments" />,
      type: 'item',
      url: '/clinic-appointments',
      icon: icons.IconCalendar
    },
    {
      id: 'appointments',
      title: <FormattedMessage defaultMessage="My Appointments" />,
      type: 'item',
      url: '/appointments',
      icon: icons.IconCalendarEvent
    },
    {
      id: 'client-record',
      title: <FormattedMessage defaultMessage="Client Record" />,
      type: 'item',
      url: '/client-record?tab=Info',
      icon: icons.IconClipboardList
    },
    {
      id: 'clinic-accounts',
      title: <FormattedMessage defaultMessage="Provider Accounts" />,
      type: 'item',
      url: '/clinic-accounts',
      icon: icons.IconTable
    },
    {
      id: 'recordings',
      title: <FormattedMessage defaultMessage="Recordings" />,
      type: 'item',
      url: '/recordings',
      icon: icons.IconVideo
    },
    {
      id: 'notes',
      title: <FormattedMessage defaultMessage="Notes" />,
      type: 'item',
      url: '/notes',
      icon: icons.IconNote
    },
    {
      id: 'internal-messages',
      title: <FormattedMessage defaultMessage="Messages" />,
      type: 'collapse',
      icon: icons.IconMessages,
      children: [
        {
          id: 'clinic-messages',
          title: <FormattedMessage defaultMessage="Clinic  Messages" />,
          type: 'item',
          url: '/internal-messages',
          icon: icons.IconMessage
        },
        {
          id: 'messages',
          title: <FormattedMessage defaultMessage="Client Messages" />,
          type: 'item',
          url: '/messages',
          icon: icons.IconUsers
        }
      ]
    },
    {
      id: 'notifications',
      title: <FormattedMessage defaultMessage="Notifications" />,
      type: 'item',
      url: '/notifications',
      icon: icons.IconSpeakerphone,
      divider: true
    },
    {
      id: 'ProfileOptions',
      title: <FormattedMessage defaultMessage="Profile Options" />,
      type: 'item',
      url: '/profile-options',
      icon: icons.IconTableOptions,
      divider: true
    },
    {
      id: 'Contactme',
      title: <FormattedMessage defaultMessage="Contact Me Request" />,
      type: 'item',
      url: '/complimentarysession',
      icon: icons.IconFilePhone
    },
    {
      id: 'resources',
      title: <FormattedMessage defaultMessage="Resources" />,
      type: 'item',
      url: '/resources',
      icon: icons.IconApps
    }
  ]
};

export const providerItem = {
  id: 'provider',
  title: <FormattedMessage defaultMessage="Provider" />,
  type: 'item',
  url: '/provider',
  icon: icons.IconUserCheck
};

export const therapistItem = {
  id: 'provider-directory',
  title: <FormattedMessage defaultMessage="Provider Directory" />,
  type: 'item',
  url: '/search',
  icon: icons.IconListSearch
};
