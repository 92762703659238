import { Box, Link, Stack } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { IconPaperclip } from '@tabler/icons';
import { format } from 'date-fns';
import { ReactNode } from 'react';

interface Props {
  message: Message;
}

export interface File {
  fileType: string;
  _id: string;
  user: string;
  session: string;
  key: string;
  bucket: string;
  mimeType: string;
  size: number;
  location: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface Message {
  id: string;
  type: string;
  text: ReactNode;
  from: string;
  date: string;
  isMe: boolean;
  url?: string;
  file?: File;
}

export default function SessionMessage({ message }: Props) {
  const renderFile = () => (
    <>
      {message.file?.mimeType?.includes?.('image') && (
        <Box>
          <img src={message?.url} alt={message?.file?.name ?? ''} style={{ maxWidth: '100%' }} />
        </Box>
      )}
      <Link href={message.url} download rel="noopener noreferrer" target="_blank">
        <Stack direction="row" spacing="4px" alignItems="center">
          <IconPaperclip />
          <Box>{message?.file?.name}</Box>
        </Stack>
      </Link>
    </>
  );

  return (
    <Stack
      spacing="4px"
      sx={{
        alignSelf: message.isMe ? 'flex-end' : 'flex-start',
        minWidth: 250
      }}
    >
      <Stack
        spacing={1}
        sx={{
          bgcolor: message.isMe ? grey[100] : blue[100],
          px: 2,
          py: '12px',
          borderRadius: 4,
          width: '100%'
        }}
      >
        <Box sx={{ fontWeight: 'bold', fontSize: 12 }}>{message.from}</Box>
        <Box>{message.type === 'file' ? renderFile() : message.text}</Box>
      </Stack>
      <Box sx={{ fontSize: 14, alignSelf: 'flex-end' }}>{format(new Date(message.date), 'pp')}</Box>
    </Stack>
  );
}
