import { getTimeZones } from '@vvo/tzdb';

export const populate = <T extends { id: string }, U extends { id: string }>(a: T[] | null | undefined, b: U[] | null | undefined) =>
  a
    ?.map((aItem) => {
      const bItem = b?.find((bi) => aItem.id === bi.id);
      if (!bItem) {
        return null;
      }

      return {
        ...bItem,
        ...aItem
      };
    })
    .filter((item) => !!item);

export const populateFromIdArr = <T extends { id: string }>(idArr: string[] | null | undefined, b: T[] | null | undefined) =>
  idArr?.map((id) => b?.find((bItem) => id === bItem.id)).filter((item) => !!item);

export const populateOne = <T extends { id: string }>(id: string | undefined, a: T[] | null | undefined) =>
  a?.find((item) => item.id === id) ?? null;

export const cleanJoin = (arr: (string | null | undefined)[], separator: string) => arr.filter((item) => !!item).join(separator);

export const getTimeZoneOptions = () =>
  getTimeZones().map((timezone) => ({
    id: timezone.name,
    label: `(${timezone.abbreviation} UTC${timezone.currentTimeFormat.slice(0, 6)}) ${timezone.name}`
  }));
