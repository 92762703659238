import { Box, Divider, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import CenteredLoading from 'views/components/CenteredLoading';
import useResource from './useResources';

type Props = {
  articles: any;
  isLoadingArticles: any;
};

function ArticlesList({ articles, isLoadingArticles }: Props) {
  const navigate = useNavigate();
  const { resources, isLoading } = useResource();
  const { category, article } = useParams();
  const selectedCategory = useMemo(() => {
    if (!resources || !category) return null;

    return resources.find((item) => item.url === category);
  }, [resources, category]);

  return (
    <>
      {isLoading || isLoadingArticles ? (
        <CenteredLoading />
      ) : (
        <Box>
          <Grid item sx={{ width: 300, minHeight: '100%' }}>
            <Typography
              sx={{
                fontSize: '25px',
                color: '#1e2028',
                mt: 3,
                fontWeight: 'bold',
                lineHeight: '1.2',
                textalign: 'left'
              }}
            >
              {selectedCategory?.title}
            </Typography>

            {articles ? (
              articles?.map((item: any) => {
                const selected = item.url === article;

                return (
                  <Typography
                    key={item.url}
                    sx={{
                      textAlign: 'left',
                      mt: 1,
                      cursor: 'pointer',
                      fontSize: 17,
                      color: selected ? '#4b7bb7' : '#858585',
                      fontWeight: selected ? 'bold' : '',
                      backgroundColor: selected ? '#e2f2fd' : '',
                      padding: selected ? 1 : 1,
                      borderRadius: selected ? 3 : 3,
                      width: selected ? 300 : 300,
                      display: 'flex',
                      flexWrap: 'wrap',
                      '&:hover': {
                        color: '#4b7bb7',
                        backgroundColor: '#e2f2fd'
                      }
                    }}
                    onClick={() => {
                      navigate(`/resources/${category}/${item.url}`, { replace: true });
                    }}
                  >
                    {item.title}
                  </Typography>
                );
              })
            ) : (
              <Typography>no article found</Typography>
            )}
          </Grid>
          <Divider sx={{ mt: 2 }} />
        </Box>
      )}
    </>
  );
}

export default ArticlesList;
