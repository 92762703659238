import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

export default function useProfileOptions() {
  const intl = useIntl();
  const { request, userId } = useAuth();

  const {
    data: profileOptionsData,
    refetch,
    isLoading,
    isRefetching
  } = useQuery(
    `/user/${userId}/profile-options`,
    () => request.get(`/user/${userId}/profile-options`).then((response) => response.data as any),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: typeof userId === 'string' && userId !== '0'
    }
  );

  const { mutateAsync: updateProfileOptions, isLoading: isPermissionsUpdating } = useMutation(
    `/permissions`,
    (payload: any) => request.put(`/user/${userId}/profile-options`, payload).then((response) => response.data),
    {
      onSuccess: () => refetch()
    }
  );

  useEffect(() => {
    refetch();
  }, [intl.locale, refetch]);

  return {
    profileOptionsMap: profileOptionsData?.profileOptionsMap,
    updateProfileOptions,
    isLoading,
    isRefetching,
    isPermissionsUpdating
  };
}
