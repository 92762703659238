// project imports
import config from 'config';
import useAuth from 'hooks/useAuth';
import usePendingICs from 'hooks/usePendingICs';
import useUser from 'hooks/useUser';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuardProps } from 'types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, isInitialized, userId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isClient } = useUser(userId);
  const { pendingICs } = usePendingICs();

  useEffect(() => {
    if (!isLoggedIn && isInitialized) {
      navigate('login', { replace: true });
    }
  }, [isLoggedIn, isInitialized, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`login?next=${encodeURIComponent(location.pathname)}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isICVerificationRequired = useCallback(() => {
    if (isClient && !!user && pendingICs?.length) {
      return true;
    }

    return false;
  }, [user, isClient, pendingICs]);

  useEffect(() => {
    // Check if IC pending for primary or associate provider
    const icICVerificationPending = isICVerificationRequired();

    if (icICVerificationPending) {
      navigate('informed-consent', { replace: true });
    }
  }, [user, isClient, navigate, isICVerificationRequired]);

  useEffect(() => {
    const icICVerificationPending = isICVerificationRequired();

    if (location?.pathname === '/informed-consent' && isClient && !!user && !icICVerificationPending) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [location, user, isClient, navigate, isICVerificationRequired]);

  return children;
};

export default AuthGuard;
