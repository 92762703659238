import { User } from 'types/user';

export default function roleMatches(targetRole: string, userRole?: string) {
  if (!userRole) {
    return undefined;
  }

  if (targetRole === userRole) {
    return true;
  }

  if (userRole === 'PROVIDER_ADMINISTRATOR' || userRole === 'PROVIDER_ADMINISTRATOR (S)' || userRole === 'PROVIDER_ADMINISTRATOR (D)') {
    if (targetRole === 'PROVIDER') {
      return true;
    }
  }

  return false;
}

export const isProviderAdminD = (user: User) => {
  if (user?.role === 'PROVIDER_ADMINISTRATOR (D)' || user?.role === 'PROVIDER_ADMINISTRATOR [D]') {
    return true;
  }

  return false;
};
