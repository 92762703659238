import { useEffect, useState } from 'react';

function useNetworkStatus() {
  interface NetworkInformation {
    downlink: number;
    effectiveType: string;
    saveData: boolean;
    addEventListener(type: string, listener: () => void): void;
    removeEventListener(type: string, listener: () => void): void;
  }

  const [isConnectionPoor, setIsConnectionPoor] = useState(false);

  useEffect(() => {
    const connection = (navigator as any).connection as NetworkInformation | undefined;

    const updateNetworkStatus = () => {
      if (!navigator.onLine) {
        setIsConnectionPoor(true);
        return;
      }

      if (connection) {
        setIsConnectionPoor(connection.downlink < 1 || connection.effectiveType === '2g' || connection.saveData);
      } else {
        setIsConnectionPoor(false);
      }
    };

    updateNetworkStatus(); // Initial check

    if (connection) {
      connection.addEventListener('change', updateNetworkStatus);
    }

    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return () => {
      if (connection) {
        connection.removeEventListener('change', updateNetworkStatus);
      }

      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, []);

  return isConnectionPoor;
}

export default useNetworkStatus;
