import { Box, Grow, IconButton, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { IconChevronsRight } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import MainCard from 'views/components/cards/MainCard';
import MessageTable from './MessageTable';

interface Props {
  isInternalMessage?: boolean;
  clientId?: string;
}

export default function Messages({ isInternalMessage = false, clientId }: Props) {
  const { userId } = useAuth();
  const { isNousSupport, user }: any = useUser(userId);
  const [selectedTab, setSelectedTab] = useState(0);
  const intl = useIntl();

  let tabs: any = [];

  tabs = [
    {
      label: intl.formatMessage({ defaultMessage: 'INBOX' }),
      component: <MessageTable clientId={clientId} inbox isInternalMessage={isInternalMessage} />
    },
    {
      label: intl.formatMessage({ defaultMessage: 'SENT' }),
      component: <MessageTable clientId={clientId} sent isInternalMessage={isInternalMessage} />
    }
  ];

  if (user?.provider?.isMessage) {
    return null;
  }

  return (
    <Stack direction="row" sx={{ height: '100%', position: 'relative' }}>
      <Grow>
        <Tooltip title={intl.formatMessage({ defaultMessage: 'Show Client Roster' })}>
          <IconButton
            color="primary"
            sx={{
              position: 'absolute',
              left: -28,
              top: 0,
              borderRadius: 12,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              border: '1px solid #A7CFF175',
              background: 'white',
              '&:hover': {
                background: 'white'
              }
            }}
          >
            <IconChevronsRight />
          </IconButton>
        </Tooltip>
      </Grow>

      <MainCard
        title={
          isInternalMessage ? intl.formatMessage({ defaultMessage: 'CLINIC MESSAGES' }) : intl.formatMessage({ defaultMessage: 'MESSAGES' })
        }
        sx={{ height: '100%', flex: 1, ml: 3 }}
        contentSX={{ padding: 0 }}
      >
        <Tabs style={{ paddingLeft: '30px' }} value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
          {tabs.map((tab, index) => (
            <Tab label={tab.label} key={index} />
          ))}
        </Tabs>
        <Box sx={{ p: 3 }}>{tabs[selectedTab].component}</Box>
      </MainCard>
    </Stack>
  );
}
