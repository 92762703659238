const filterSessionCanceledBy = (status) => {
  if (status === 'Canceled by Clinician' || status === 'Canceled by Provider') {
    return 'Canceled';
  }

  if (status === 'Late Canceled by Clinician' || status === 'Late Canceled by Provider') {
    return 'Late Canceled';
  }

  return status;
};

export default filterSessionCanceledBy;
