// material-ui
import { Avatar, Box, ButtonBase, Grid, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// assets
import { IconMenu2 } from '@tabler/icons';
import LogoSection from '../LogoSection';
import LocalizationSection from './LocalizationSection';
import MobileSection from './MobileSection';
// import NotificationSection from './NotificationSection';
import { FormattedMessage } from 'react-intl';
import ProfileSection from './ProfileSection';

// import NotificationSection from './NotificationSection';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useIntl } from 'react-intl';
// assets

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
      background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
      color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
    }
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  flex: {
    display: 'flex'
  },
  name: {
    marginLeft: '2px',
    fontWeight: 400
  }
}));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
  drawerOpen: boolean;
}

const Header = ({ handleLeftDrawerToggle, drawerOpen }: HeaderProps) => {
  const classes = useStyles();
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const intl = useIntl();
  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Tooltip title={drawerOpen ? <FormattedMessage defaultMessage="Hide Menu" /> : <FormattedMessage defaultMessage="Show Menu" />}>
            <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        </ButtonBase>
      </div>

      {/* header search */}
      <div className={classes.grow} />

      {/* live customization & localization */}

      <div style={{ marginRight: '15px' }}>
        <Grid item className={classes.flex}>
          <Typography variant="h4">
            <FormattedMessage defaultMessage="Hello" />,
          </Typography>
          <Typography component="span" variant="h4" className={classes.name}>
            {user?.preferredName || user?.firstName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {intl.locale === 'fr' ? user?.roleNameFrench || user?.role : user?.role || user?.roleNameFrench}
          </Typography>
        </Grid>
      </div>

      <LocalizationSection />

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
