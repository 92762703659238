import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useNotes from 'hooks/useNotes';
import useTimezone from 'hooks/useTimezone';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import NoResults from 'views/components/common/NoResults';

interface Column {
  id: 'patientName' | 'sessionDate' | 'date_modified';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

function createData(patientName: string, sessionDate: string, date_modified: string) {
  return { patientName, sessionDate, date_modified };
}

function NotesTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { notes } = useNotes();
  const { timezone } = useTimezone();
  const intl = useIntl();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  notes?.notesData?.sort((a, b) => (new Date(b?.date_modified) as any) - (new Date(a?.date_modified) as any));

  const navigate = useNavigate();
  const rows = notes?.notesData?.map((note: any) => {
    const participant = note.participants && note.participants[0];
    const participantName = participant ? `${participant.firstName} ${participant.lastName}` : '-';
    const formattedSessionDate = note?.sessionDate
      ? format(utcToZonedTime(note?.sessionDate, timezone), intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a', {
          locale: intl.locale === 'fr' ? frLocale : enLocale
        })
      : '-';
    const formatedModifiedDate = note?.date_modified
      ? format(utcToZonedTime(note?.date_modified, timezone), intl.locale === 'fr' ? 'do MMMM yyyy, HH:mm' : 'MMMM do yyyy, h:mm a', {
          locale: intl.locale === 'fr' ? frLocale : enLocale
        })
      : '-';
    return createData(participantName, formattedSessionDate, formatedModifiedDate);
  });

  const columns: Column[] = [
    { id: 'patientName', label: intl.formatMessage({ defaultMessage: 'Client Name' }) },
    {
      id: 'sessionDate',
      label: intl.formatMessage({ defaultMessage: 'Session Date' }),
      minWidth: 100
    },
    { id: 'date_modified', label: intl.formatMessage({ defaultMessage: 'Modified Date' }) }
  ];

  const handleNavigate = () => {
    navigate('/notes');
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '117%', height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '16px', overflow: 'hidden' }}>
      <TableContainer
        sx={{ maxHeight: '100%', flexGrow: 1, borderRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={{ backgroundColor: '#F8F8F8' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{intl.formatMessage({ defaultMessage: 'Notes' })}</Typography>
                  <Button onClick={handleNavigate} endIcon={<ArrowOutwardIcon />}>
                    {intl.formatMessage({ defaultMessage: 'More' })}
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <NoResults>{intl.formatMessage({ defaultMessage: 'No Notes Found' })}</NoResults>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          {rows?.length !== 0 && (
            <TableBody>
              {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ height: 70 }}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          SelectProps={{
            sx: { display: 'none' }
          }}
        />
      </Box>
    </Paper>
  );
}
export default NotesTable;
