import { Button } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useModal } from 'mui-modal-provider';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { validateSupport } from 'utils/documents';
import Loader from '../Loader';
import PreviewDialog from '../modals/PreviewDialog';

interface Props {
  getLinkUrl?: string;
  postLinkUrl?: string;
  generatePostData?: Function;
  label?: string;
  keyId?: string;
  buttonRef?: any;
  variant?: any;
  sx?: any;
}

export default function LinkViewButton({
  buttonRef,
  getLinkUrl,
  postLinkUrl,
  generatePostData,
  label,
  keyId,
  variant = 'contained',
  sx = { whiteSpace: 'nowrap' }
}: Props) {
  const intl = useIntl();
  const { request } = useAuth();
  const [opening, setOpening] = useState(false);

  const { data: dataGet, refetch: refetchDataGet } = useQuery(getLinkUrl ?? '', () => request.get(getLinkUrl ?? '').then((r) => r.data), {
    enabled: false,
    cacheTime: 0
  });

  const { data: dataPost, refetch: refetchDataPost } = useQuery(
    [postLinkUrl ?? '', keyId],
    async () =>
      request
        .post(postLinkUrl ?? '', {
          ...(generatePostData ? generatePostData() : {})
        })
        .then((r) => r.data),
    { enabled: false, cacheTime: 0 }
  );

  const data = dataGet || dataPost;

  const { showModal } = useModal();

  const getData = useCallback(async () => {
    if (data) {
      return data;
    }

    let r;

    if (getLinkUrl) {
      r = await refetchDataGet();
    } else if (postLinkUrl && generatePostData) {
      r = await refetchDataPost();
    }

    return r.data;
  }, [data, refetchDataGet, refetchDataPost, generatePostData, getLinkUrl, postLinkUrl]);

  const handleClick = useCallback(async () => {
    setOpening(true);
    const data = await getData();
    const validation = validateSupport(data.mimeType);

    if (!data) {
      return;
    }

    if (validation.supported) {
      setTimeout(() => {
        const modal = showModal(PreviewDialog, {
          name: data.name,
          url: data.url,
          source: validation.type,
          onClose: () => modal.hide()
        });
        setOpening(false);
      }, 1000);
    } else {
      window.open(data.url, '_blank');
      setOpening(false);
    }
  }, [showModal, getData]);

  return (
    <Button ref={buttonRef} sx={sx} variant={variant} color="secondary" disabled={opening} onClick={handleClick}>
      {opening && <Loader />}
      {label || intl.formatMessage({ defaultMessage: 'Quick View' })}
    </Button>
  );
}
