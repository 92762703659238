import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';

export default function useResource() {
  const { request } = useAuth();
  const {
    data: resources,
    refetch: refetchResources,
    isLoading
  } = useQuery('/resources', () => request.get('/resources').then((response) => response.data.resources));

  const { mutateAsync: addCategory, isLoading: addingCategory } = useMutation(
    '/resources',
    (payload: any) => request.post('/resources', payload),
    {
      onSuccess: () => refetchResources()
    }
  );

  const { mutateAsync: editCategory } = useMutation(
    '/resources',
    ({ title, titleFrench, description, descriptionFrench, targetAudience, isDraft, _id }: any) =>
      request.put(`/resources/${_id}`, { title, titleFrench, description, descriptionFrench, targetAudience, isDraft }),
    {
      onSuccess: () => refetchResources()
    }
  );

  const { mutateAsync: deleteCategory } = useMutation('/resources', ({ _id }: any) => request.delete(`/resources/${_id}`), {
    onSuccess: () => refetchResources()
  });

  const { mutateAsync: reorderCategories } = useMutation(
    '/resources/reorder',
    (payload: any) => request.post(`/resources/reorder`, payload),
    {
      onSuccess: () => refetchResources()
    }
  );

  return {
    isLoading,
    addCategory,
    addingCategory,
    resources,
    reorderCategories,
    editCategory,
    deleteCategory,
    refetchResources
  };
}
