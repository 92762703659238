import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { JWTProvider } from 'contexts/JWTContext';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useUser from 'hooks/useUser';
import useUsers from 'hooks/useUsers';
import ModalProvider from 'mui-modal-provider';
import { SnackbarProvider } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';
// global style for resizer
import 'react-resizable/css/styles.css';
// routing
import Routes from 'routes';
// defaultTheme
import themes from 'themes';
// store
import { DefaultRootStateProps } from 'types';
// import RTLLayout from 'components/RTLLayout';
import Snackbar from 'views/components/extended/Snackbar';
// project imports
import Locales from 'views/components/Locales';
import DirectoryModePrompt from 'views/components/modals/DirectoryModePrompt';
import SoloModePrompt from 'views/components/modals/SoloModePrompt';
// auth provider
import NavigationScroll from 'views/layout/NavigationScroll';

// ==============================|| APP ||============================== //
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const Components = () => {
  const intl = useIntl();
  const { options } = useOptions();
  const { userId } = useAuth();
  const { isNousSupport } = useUser(userId);
  const { singleProviderInTheClinic, multipleProviderInTheClinic } = useUsers({});
  const { therapists } = useUsers({});
  const [open, setOpen] = useState(JSON.parse(localStorage.getItem('directoryModePrompt') || 'false'));
  const [openSoloModePrompt, setOpenSoloModePrompt] = useState(JSON.parse(localStorage.getItem('soloModePrompt') || 'false'));

  const isSingleProviderMode = useMemo(() => {
    if (options?.DEFAULT_PROVIDER?.value !== '0' && options?.DEFAULT_PROVIDER?.value !== '2') {
      return true;
    } else {
      return false;
    }
  }, [options]);

  const isDirectoryMode = useMemo(() => {
    if (options?.DEFAULT_PROVIDER?.value === '0') {
      return true;
    } else {
      return false;
    }
  }, [options]);

  useEffect(() => {
    if (!localStorage.getItem('directoryModePrompt') && isNousSupport) {
      localStorage.setItem('directoryModePrompt', 'true');
      setOpen(true);
    }
    if (!localStorage.getItem('soloModePrompt') && isNousSupport) {
      localStorage.setItem('soloModePrompt', 'true');
      setOpenSoloModePrompt(true);
    }
  }, [isNousSupport, options, therapists, userId]);

  return (
    <Elements stripe={stripePromise} options={{ locale: intl.locale as StripeElementLocale }}>
      <ModalProvider beta>
        <>
          <Routes />
          <Snackbar />
          {isNousSupport && isSingleProviderMode && multipleProviderInTheClinic && <DirectoryModePrompt open={open} setOpen={setOpen} />}
          {isNousSupport && isDirectoryMode && singleProviderInTheClinic && (
            <SoloModePrompt open={openSoloModePrompt} setOpen={setOpenSoloModePrompt} />
          )}
        </>
      </ModalProvider>
    </Elements>
  );
};

const App = () => {
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        {/* RTL layout */}
        {/* <RTLLayout> */}
        <Locales>
          <SnackbarProvider>
            <NavigationScroll>
              <QueryClientProvider client={queryClient}>
                <JWTProvider>
                  <Components />
                </JWTProvider>
                {/* <ReactQueryDevtools /> */}
              </QueryClientProvider>
            </NavigationScroll>
          </SnackbarProvider>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
