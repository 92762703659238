import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ProfileOptions from 'views/components/administration/ProfileOptions';
import Loadable from 'views/components/Loadable';
import { PrivateDashboard } from 'views/dashboard/PrivateDashboard';
import MainLayout from 'views/layout/MainLayout';
import ClinicAppointments from 'views/pages/appointments/ClinicAppointments';
import Messages from 'views/pages/messages';
import Records from 'views/pages/records';
import Resources from 'views/pages/resources';
import Articles from 'views/pages/resources/Articles';
import ArticlesDescription from 'views/pages/resources/ArticlesDescription';
import SearchHistory from 'views/pages/resources/SearchHistory';
import Session from 'views/pages/session';

const Announcement = Loadable(lazy(() => import('views/pages/announcement')));
const Appointments = Loadable(lazy(() => import('views/pages/appointments')));
const Availability = Loadable(lazy(() => import('views/pages/availability')));
const Provider = Loadable(lazy(() => import('views/pages/provider')));
const ProviderDirectory = Loadable(lazy(() => import('views/pages/provider-directory')));
const Notes = Loadable(lazy(() => import('views/pages/notes')));
const Documents = Loadable(lazy(() => import('views/pages/documents')));
const ClientIntakeForm = Loadable(lazy(() => import('views/pages/documents/IntakeForm')));
const Account = Loadable(lazy(() => import('views/pages/account')));
const ClientRecord = Loadable(lazy(() => import('views/pages/client-record')));
const Administration = Loadable(lazy(() => import('views/pages/administration')));
const Groups = Loadable(lazy(() => import('views/pages/groups')));
const UserAppointment = Loadable(lazy(() => import('views/pages/administration/user-appointment')));
const Stripe = Loadable(lazy(() => import('views/pages/stripe')));
const StripeClinician = Loadable(lazy(() => import('views/pages/stripe-clinician')));
const Recordings = Loadable(lazy(() => import('views/pages/recordings')));
const ConnectionTest = Loadable(lazy(() => import('views/pages/appointments/ConnectionTestDialog')));
const Contactme = Loadable(lazy(() => import('views/pages/contact-me')));
const ClinicRecord = Loadable(lazy(() => import('views/pages/clinic-accounts')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: <PrivateDashboard />
    },
    {
      path: '/provider',
      element: <Provider />
    },
    {
      path: '/search',
      element: <ProviderDirectory />
    },
    {
      path: '/clinic-appointments',
      element: <ClinicAppointments />
    },
    {
      path: '/clinic-appointments/:appointmentId',
      element: <ClinicAppointments />
    },
    {
      path: '/clinic-appointments/:appointmentId/:detailMode',
      element: <ClinicAppointments />
    },
    {
      path: '/appointments',
      element: <Appointments />
    },
    {
      path: '/appointments/:appointmentId',
      element: <Appointments />
    },
    {
      path: '/appointments/:appointmentId/:detailMode',
      element: <Appointments />
    },
    {
      path: '/availability',
      element: <Availability />
    },
    {
      path: '/availability/:providerId',
      element: <Availability />
    },
    {
      path: '/availability/:providerId/:date',
      element: <Availability />
    },
    {
      path: '/notes',
      element: <Notes />
    },
    {
      path: '/documents',
      element: <Documents />
    },
    {
      path: '/documents/client/intake-form',
      element: <ClientIntakeForm />
    },
    {
      path: '/account',
      element: <Account />
    },
    {
      path: '/clinic-accounts',
      element: <ClinicRecord />
    },
    {
      path: '/clinic-accounts/:userId',
      element: <ClinicRecord />
    },
    {
      path: '/record',
      element: <Records />
    },
    {
      path: '/client-record',
      element: <ClientRecord />
    },
    {
      path: '/client-record/:clientId',
      element: <ClientRecord />
    },
    {
      path: '/recordings',
      element: <Recordings />
    },
    {
      path: '/administration',
      element: <Administration />
    },
    {
      path: '/profile-options',
      element: <ProfileOptions />
    },
    {
      path: '/notifications',
      element: <Announcement />
    },
    {
      path: '/stripe',
      element: <Stripe />
    },
    {
      path: '/stripe-clinician',
      element: <StripeClinician />
    },
    {
      path: '/groups',
      element: <Groups />
    },
    {
      path: '/user/:userId/appointments',
      element: <UserAppointment />
    },
    {
      path: '/user/:userId/appointments/:appointmentId',
      element: <UserAppointment />
    },
    {
      path: '/user/:userId/appointments/:appointmentId/:detailMode',
      element: <UserAppointment />
    },
    {
      path: '/session/:sessionId',
      element: <Session />
    },
    {
      path: '/floatingsession/:sessionId',
      element: <Session />
    },
    {
      path: '/connection-test',
      element: <ConnectionTest />
    },
    {
      path: '/messages',
      element: <Messages />
    },
    {
      path: '/internal-messages',
      element: <Messages isInternalMessage />
    },
    {
      path: '/complimentarysession',
      element: <Contactme />
    },
    {
      path: '/resources',
      element: <Resources />
    },
    {
      path: '/resources/search',
      element: <SearchHistory />
    },
    {
      path: '/resources/:category',
      element: <Articles />
    },
    {
      path: '/resources/:category/:article',
      element: <ArticlesDescription />
    }
  ]
};

export default MainRoutes;
