import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Dialog, Grid, IconButton, MenuItem, Stack, SxProps, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker as MUITimePicker, StaticTimePickerProps as MUITimePickerProps } from '@mui/x-date-pickers/StaticTimePicker';
import { format, isValid } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useTimezone from 'hooks/useTimezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type TimePickerProps = Omit<MUITimePickerProps<any, any>, 'value' | 'onChange' | 'renderInput'> & {
  name: string;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => string;
  onChange?: (date: any, keyboardInputValue?: string | undefined) => void;
  parseDate?: (date: any) => string;
  helperText?: string;
  sx?: SxProps;
  timeAMPM?: string;
};

const TimePicker = React.memo(
  ({ isDate, parseError, name, label, required, parseDate, helperText, sx, timeAMPM, ...rest }: TimePickerProps): JSX.Element => (
    <Controller
      name={name}
      render={(props: any) => (
        <Picker {...props} label={label} helperText={helperText} required={required} parseError={parseError} sx={sx} timeAMPM={timeAMPM} />
      )}
    />
  )
);

const Picker = ({ field: { onBlur, onChange, value }, fieldState: { error, invalid }, label, helperText, required, parseError, sx }) => {
  const { timezone } = useTimezone();
  const intl = useIntl();
  const dateValue = useMemo(() => (value ? utcToZonedTime(value, timezone) : null), [value, timezone]);

  const [open, setOpen] = useState(false);
  const [ampm, setAMPM] = useState<string | null>(dateValue ? format(dateValue, 'a') : 'AM');

  const handleChange = useCallback(
    (date: any) => {
      const combined = date ? date.valueOf() : null;
      onChange(zonedTimeToUtc(combined, timezone));
    },
    [onChange, timezone]
  );

  const handleAmpmChange = useCallback(
    (e: any) => {
      if (!dateValue) {
        return;
      }
      if (e.target.value === 'AM') {
        onChange(zonedTimeToUtc(dateValue.valueOf() - 12 * 60 * 60 * 1000, timezone));
      } else if (e.target.value === 'PM') {
        onChange(zonedTimeToUtc(dateValue.valueOf() + 12 * 60 * 60 * 1000, timezone));
      }

      setAMPM(e.target.value);
    },
    [dateValue, onChange, timezone]
  );

  const MyActionBar = useCallback(() => {
    return (
      <Stack direction={'row'} justifyContent={'space-evenly'} m={2}>
        <Grid item sm={6} width={100}>
          {intl.locale !== 'fr' && ampm && (
            <TextField fullWidth size="small" select onChange={handleAmpmChange} value={ampm} label="AM/PM">
              <MenuItem value="AM">
                <FormattedMessage defaultMessage={'AM'} />
              </MenuItem>
              <MenuItem value="PM">
                <FormattedMessage defaultMessage={'PM'} />
              </MenuItem>
            </TextField>
          )}
        </Grid>
        <Button onClick={() => setOpen(false)}> OK </Button>
      </Stack>
    );
  }, [ampm, handleAmpmChange, intl]);

  const timeValue = useMemo(
    () =>
      dateValue && isValid(dateValue)
        ? intl.locale === 'fr'
          ? `${format(dateValue, 'HH:mm')}`
          : `${format(dateValue, 'hh:mm')} ${ampm}`
        : '',
    [ampm, dateValue, intl]
  );

  return (
    <>
      <TextField
        value={timeValue}
        onClick={() => setOpen(true)}
        onChange={(e: any) => onChange(e.target.value)}
        label={`${label} ${required ? ' *' : ''}`}
        error={invalid}
        // eslint-disable-next-line no-nested-ternary
        helperText={error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : helperText}
        inputProps={{ readOnly: true }}
        sx={sx}
      />

      <Dialog onClose={() => setOpen(false)} open={open}>
        <Box>
          <LocalizationProvider adapterLocale={intl.locale === 'fr' ? frLocale : enLocale} dateAdapter={AdapterDateFns}>
            <MUITimePicker
              displayStaticWrapperAs="mobile"
              value={dateValue}
              openTo="hours"
              onChange={handleChange}
              components={{ ActionBar: MyActionBar }}
              shouldDisableTime={(timeValue, clockType) => {
                if (clockType === 'minutes' && timeValue % 5) {
                  return true;
                }

                return false;
              }}
              showToolbar
              renderInput={(params) => <TextField {...params} />}
              ToolbarComponent={({ openView, setOpenView }) => (
                <Grid container spacing={3} sx={{ px: 4, py: 2 }}>
                  <Grid item sm={12}>
                    <Typography variant="h4" textAlign={'center'}>
                      <FormattedMessage defaultMessage={'Select Time'} />
                    </Typography>
                  </Grid>

                  <Grid item display="flex" justifyContent="center" sm={12}>
                    <IconButton disabled={openView === 'hours'} onClick={() => setOpenView('hours')}>
                      <ChevronLeftIcon />
                    </IconButton>

                    <IconButton disabled={openView === 'minutes'} onClick={() => setOpenView('minutes')}>
                      <ChevronRightIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Dialog>
    </>
  );
};

export default TimePicker;
