import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import useWindowFocus from 'use-window-focus';

const SessionTimeout = () => {
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const isSupport = user?.isNousSupport || user?.role === 'NOUS_SUPPORT';
  const windowFocused = useWindowFocus();
  const timeoutSeconds = isSupport ? parseInt('5400', 10) : parseInt(process.env.REACT_APP_TIMEOUT_SECONDS || '900', 10); // Default to 15 minutes

  const { logout } = useAuth();
  const logoutTimerRef = useRef<NodeJS.Timeout>();

  const getLastActivityTimeStamp = useCallback(() => Number(localStorage.getItem('lastActivityTimeStamp')) || moment().unix(), []);

  const setLastActivityTimeStamp = useCallback(() => {
    localStorage.setItem('lastActivityTimeStamp', String(moment().unix()));
  }, []);

  const removeLastActivityTimeStamp = useCallback(() => {
    localStorage.removeItem('lastActivityTimeStamp');
  }, []);

  const startTimer = useCallback(() => {
    logoutTimerRef.current = setInterval(() => {
      const now = moment().unix();

      if (now - getLastActivityTimeStamp() >= timeoutSeconds) {
        logout(true);
      }
    }, 60000);
  }, [timeoutSeconds, getLastActivityTimeStamp, logout]);

  const resetTimer = useCallback(() => {
    if (logoutTimerRef.current) {
      clearInterval(logoutTimerRef.current);
    }

    setLastActivityTimeStamp();

    startTimer();
  }, [startTimer, setLastActivityTimeStamp]);

  useEffect(() => {
    if (windowFocused) {
      const now = moment().unix();

      if (now - getLastActivityTimeStamp() >= timeoutSeconds) {
        logout(true);
      }
    }
  }, [windowFocused, timeoutSeconds, getLastActivityTimeStamp, logout]);

  useEffect(() => {
    const resetTimerEvents = ['mousemove', 'mousedown', 'keypress', 'keydown', 'touchmove'];

    for (let i = 0; i < resetTimerEvents.length; i++) {
      const event = resetTimerEvents[i];
      window.addEventListener(event, resetTimer);
    }

    setLastActivityTimeStamp();

    startTimer();

    return () => {
      if (logoutTimerRef.current) {
        clearInterval(logoutTimerRef.current);
      }

      removeLastActivityTimeStamp();

      for (let i = 0; i < resetTimerEvents.length; i++) {
        const event = resetTimerEvents[i];
        window.removeEventListener(event, resetTimer);
      }
    };
  }, [resetTimer, startTimer, setLastActivityTimeStamp, removeLastActivityTimeStamp]);

  return <></>;
};

export default React.memo(SessionTimeout);
