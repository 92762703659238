import useAuth from 'hooks/useAuth';
import useUsers from 'hooks/useUsers';
import { useQuery } from 'react-query';
import { User } from 'types/user';
import { populateOne } from 'utils/arrayUtils';

export interface TherapistPatient extends Omit<User, 'therapist'> {
  _id?: string;
  therapist: User | null;
  therapistId: string;
  imageUrl: string;
  client: any;
}

export default function useTherapistPatients(therapistId: string | null | undefined) {
  const { request } = useAuth();
  const { users } = useUsers({});

  const url = `/therapists/${therapistId}/patients`;
  const {
    data: patients,
    refetch: refetchPatients,
    isLoading
  } = useQuery(
    url,
    () =>
      request.get(url).then(
        (response) =>
          response.data.patients.map((patient) => ({
            ...patient,
            id: patient._id,
            therapistId: patient.therapist,
            therapist: populateOne(patient.therapist, users)
          })) as TherapistPatient[]
      ),
    {
      enabled: !!therapistId && !!users && !url.includes('undefined')
    }
  );

  return { patients, refetchPatients, isLoading };
}
