import useProvider from 'hooks/useProvider';
import useUser from 'hooks/useUser';
import useUserPayment from 'hooks/useUserPayment';

export default function useCanBook(id?: string) {
  const { user, isClient } = useUser(id);
  const { provider } = useProvider(id);
  const { userPayment, isLoading } = useUserPayment(id);

  if (!user || !provider) {
    return { canBook: false };
  }

  // // Cannot book appointment as self
  // if (user.id === provider.id) {
  //   return { canBook: false };
  // }

  if (isClient) {
    if (!user?.clientOptions?.canClientRequestAppointment) {
      return { canBook: false };
    }

    if (provider?.provider?.informedConsent && !user.icVerification) {
      return { canBook: false };
    }

    if (!user.emailVerified) {
      return { canBook: false };
    }

    if (user?.clientOptions?.requireCreditCard && !userPayment?.length) {
      return { canBook: false, ccRequired: true };
    }
  }

  return { canBook: true, isLoading, hasUserPaymentMethod: !!userPayment?.length };
}
