// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Avatar,
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
// material-ui
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';
import useContactMeRequest from 'hooks/useContactMeRequest';
import useMessageNotifications from 'hooks/useMessageNotifications';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
import { DefaultRootStateProps, LinkTarget, NavItemType } from 'types';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  listIcon: {
    minWidth: '18px',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  menuIcon: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  listCustomIcon: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  listCustomIconSub: {
    width: '6px',
    height: '6px'
  },
  listCustomIconSubActive: {
    width: '8px',
    height: '8px'
  },
  listItem: {
    marginBottom: '5px',
    alignItems: 'center'
  },
  listItemNoBack: {
    marginBottom: '5px',
    backgroundColor: 'transparent !important',
    paddingTop: '8px',
    paddingBottom: '8px',
    alignItems: 'flex-start'
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption
  },
  notificationChip: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.main,
    marginLeft: 3
  }
}));

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

export interface NavItemProps {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: NavItemProps) => {
  const { contactMeList, updateContactMeCount } = useContactMeRequest();
  const classes = useStyles();
  const { userId, request } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { totalInternalMessageCount, totalExternalMessageCount, totalAnnouncements } = useMessageNotifications();
  // const { getContactCount, refetchUserContact } = useComplementryTherapists();
  const [contactMeCount, setContactMeCount] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    // setWaitingListCount(waitingList?.data?.clients?.filter((list: any) => !list?.readBy.find((id: string) => id === userId)).length || 0);
    setContactMeCount(contactMeList?.data?.clients?.filter((list: any) => !list?.readBy.find((id: string) => id === userId)).length || 0);
  }, [contactMeList, userId]);

  const Icon = item?.icon!;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      className={customization.isOpen.findIndex((id) => id === item?.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemIconClass = item?.icon ? classes.listIcon : classes.menuIcon;
  /**
   *nav-dark doesnt exist on navType
   *navType can only be 'light' | 'dark'
   */

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }
  // If session screen, open link in another tab
  if (location.pathname.startsWith('/session/')) {
    itemTarget = '_blank';
  }

  itemIconClass = customization.navType === 'dark' ? [itemIconClass, classes.listCustomIcon].join(' ') : itemIconClass;

  let listItemProps: {
    component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: React.forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = async (id: string) => {
    if (id === 'Contactme' && contactMeCount > 0) {
      await updateContactMeCount();
    }
    dispatch({ type: MENU_OPEN, id });
    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  React.useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        className={level > 1 ? classes.listItemNoBack : classes.listItem}
        sx={{ borderRadius: `${customization.borderRadius}px` }}
        selected={location.pathname.includes(item.url ?? '')}
        onClick={() => itemHandler(item.id!)}
        style={{ paddingLeft: `${level * 23}px` }}
      >
        <ListItemIcon className={itemIconClass} style={{ minWidth: level > 1 ? 18 : 36 }}>
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={location.pathname.includes(item.url ?? '') ? 'h5' : 'body1'} color="inherit" component="div">
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}

        {item.id === 'messages' && !!totalExternalMessageCount && (
          <Chip size="small" label={totalExternalMessageCount} className={classes.notificationChip} />
        )}
        {item.id === 'clinic-messages' && !!totalInternalMessageCount && (
          <Chip size="small" label={totalInternalMessageCount} className={classes.notificationChip} />
        )}
        {item.id === 'notifications' && totalAnnouncements > 0 && (
          <Chip size="small" label={totalAnnouncements} className={classes.notificationChip} />
        )}
        {item.id === 'Contactme' && !!contactMeCount && <Chip size="small" label={contactMeCount} className={classes.notificationChip} />}
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
