/**
 * Participants list, Settings tabs
 */
import { Badge, Box, Button, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconChevronLeft, IconX } from '@tabler/icons';
import clsx from 'clsx';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ParticipantsList from './ParticipantsList';
import SessionChat from './SessionChat';
import { Message } from './SessionMessage';

interface Props {
  containerRef: RefObject<HTMLDivElement | null>;
  open: boolean;
  onClose: () => void;
  unread: { group?: number };
  unreadRef: RefObject<{ group?: number }>;
  setUnread: Dispatch<SetStateAction<object>>;
  appointmentId: string;
  sessionId: string | null | undefined;
  sessionRef: any;
  floatingEmail: string | null;
  sessionData: any;
  connectedUsers?: {};
  oneToOneChatEnabled?: boolean;
  setOneToOneChatEnabled?: Function;
  isWaitingScreen?: boolean;
  role?: string;
  screenName?: string;
  arrived?: boolean;
  isOwner: boolean;
  isProvider: boolean;
  ownerId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: Number;
  index: Number;
}

type ActiveChat = 'none' | 'group' | any;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      <Box>{children}</Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const SessionSideDrawer = ({
  containerRef,
  open,
  onClose,
  appointmentId,
  floatingEmail,
  sessionId,
  unread,
  unreadRef,
  setUnread,
  sessionRef,
  sessionData,
  connectedUsers,

  isWaitingScreen,
  role,
  screenName,
  arrived,
  oneToOneChatEnabled,
  setOneToOneChatEnabled = () => {},
  isOwner,
  isProvider,
  ownerId
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [activeChat, setActiveChat] = useState<ActiveChat>('none');
  const { userId } = useAuth();
  const { user: loggedUser } = useUser(userId);

  const [messages, setMessages] = useState<Message[]>([]);

  const participants = useMemo(() => {
    const mergedArray = sessionData?.participants || [];

    const updatedArray = [...mergedArray];
    mergedArray?.forEach((item, i) => {
      const isMe = (loggedUser?.id && item?.user?._id === loggedUser?.id) || (!loggedUser?.id && item?.email === floatingEmail);

      if (isMe) {
        const removedElement = updatedArray.splice(i, 1)[0]; //get item
        updatedArray.splice(0, 0, removedElement);
      }
    });

    return updatedArray;
  }, [floatingEmail, loggedUser?.id, sessionData]);

  /**
   * Signal Events
   */
  useEffect(() => {
    if (!sessionRef.current) return;

    const session = sessionRef.current;
    const unread = unreadRef.current || { group: 0 };

    session.on('signal:msg', (event: any) => {
      const { data, from } = event;
      const isMe = session.connection.connectionId === from.connectionId;

      const messageDetails = JSON.parse(data);

      const message: Message = {
        ...messageDetails,
        type: 'msg',
        isMe
      };

      if (!isMe && (!messageDetails.receiver || messageDetails.receiver === floatingEmail || messageDetails.receiver === userId)) {
        setUnread({
          ...unread,
          [messageDetails.receiver ? messageDetails.sender : 'group']:
            (unread[messageDetails.receiver ? messageDetails.sender : 'group'] ?? 0) + 1
        });
      }

      setMessages((prev) => [...prev, message]);
    });

    session.on('signal:file', (event: any) => {
      const { data, from } = event;
      const isMe = session.connection.connectionId === from.connectionId;

      const messageDetails = JSON.parse(data);

      const message: Message = {
        ...messageDetails,
        type: 'file',
        isMe
      };

      if (!isMe && (!messageDetails.receiver || messageDetails.receiver === floatingEmail || messageDetails.receiver === userId)) {
        setUnread({
          ...unread,
          [messageDetails.receiver ? messageDetails.sender : 'group']:
            (unread[messageDetails.receiver ? messageDetails.sender : 'group'] ?? 0) + 1
        });
      }

      setMessages((prev) => [...prev, message]);
    });

    return () => {
      session?.off('signal:msg');
      session?.off('signal:file');
    };
  }, [sessionRef, unreadRef, setUnread, floatingEmail, userId]);

  const activeConnections = () => {
    let activeUsers = 1;
    if (connectedUsers) {
      activeUsers = Object.keys(connectedUsers).length;
      if (activeUsers < 0) {
        activeUsers = 1;
      }
    }
    return activeUsers;
  };

  return (
    <Box className={clsx(classes.root, open && 'is-open')}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Button onClick={onClose} sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <IconX />
        </Button>
      </Box>

      {role === 'CLIENT' && isWaitingScreen ? (
        <Box sx={{ p: 4 }} style={{ textAlign: 'center' }}>
          <FormattedMessage defaultMessage="Your Provider will be joining the session shortly" />
        </Box>
      ) : (
        <div>
          {activeChat === 'none' ? (
            <Box className="content">
              <Box id="participantListHead">
                <Typography>
                  <FormattedMessage defaultMessage="Active" /> ({activeConnections()})
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  {arrived && (
                    <Button
                      style={{ textDecoration: 'underline' }}
                      onClick={() => {
                        setActiveChat('group');
                      }}
                    >
                      <Badge color="error" badgeContent={unread?.group}>
                        <FormattedMessage defaultMessage="Group Chat" />
                      </Badge>
                    </Button>
                  )}
                  {isProvider && (
                    <Box style={{ paddingRight: '16px', paddingTop: 10 }}>
                      <FormControlLabel
                        sx={{
                          backgroundColor: (theme) => theme.palette.primary.light,
                          padding: '3px 10px',
                          color: (theme) => theme.palette.primary.main,
                          borderRadius: (theme) => theme.shape.borderRadius
                        }}
                        control={
                          <AntSwitch
                            checked={oneToOneChatEnabled}
                            onChange={(e) => setOneToOneChatEnabled(e.target.checked)}
                            size="small"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: '500'
                            }}
                          >
                            <FormattedMessage defaultMessage={' Enable 1:1 chat'} />
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <ParticipantsList
                sessionRef={sessionRef}
                participants={participants}
                floatingParticipants={sessionData?.floatingParticipants}
                setActiveChat={setActiveChat}
                floatingEmail={floatingEmail}
                connectedUsers={connectedUsers}
                unread={unread}
                oneToOneChatEnabled={oneToOneChatEnabled}
                isOwner={isOwner}
                isProvider={isProvider}
                ownerId={ownerId}
              />
            </Box>
          ) : (
            <>
              <Box className="activeChatHeader">
                <IconButton onClick={() => setActiveChat('none')}>
                  <IconChevronLeft />
                </IconButton>
                <Typography component="div">
                  {activeChat === 'group'
                    ? intl.formatMessage({ defaultMessage: 'Group Chat' })
                    : intl.locale === 'fr'
                    ? `Parler avec ${activeChat?.name}`
                    : `Chat with ${activeChat?.name}`}
                </Typography>
              </Box>
              <Box className="content" style={{ paddingTop: 0 }}>
                <SessionChat
                  floatingEmail={floatingEmail}
                  appointmentId={appointmentId}
                  sessionId={sessionId}
                  unread={unread}
                  setUnread={setUnread}
                  sessionRef={sessionRef}
                  activeChat={activeChat}
                  messages={messages}
                  screenName={screenName}
                  disableFileSharing={sessionData?.disableFileSharing}
                />
              </Box>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default SessionSideDrawer;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.default,
    zIndex: 1000,

    '&.is-open': {
      width: 450
    },

    '& .content': {
      padding: 20
    },
    '& #participantListHead': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15
    },
    '& .activeChatHeader': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#eee',
      padding: 10,
      '& div': {
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        marginLeft: -35
      }
    }
  }
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 10,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));
