import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Stack } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends DialogProps {
  actions?: ReactNode;
  renderWrapper?: (children: ReactNode) => ReactNode;
  onClose?: () => void;
}

export default function BasicDialog({ title, children, actions, renderWrapper = (children) => children, onClose, ...props }: Props) {
  return (
    <Dialog fullWidth sx={{ '& .MuiDialog-paper': { p: 0 } }} onClose={onClose} {...props}>
      {renderWrapper(
        <>
          {title && (
            <>
              <DialogTitle>
                {/* <DialogClose onClose={onClose} /> */}
                {title}
              </DialogTitle>
              <Divider />
            </>
          )}
          <DialogContent>{children}</DialogContent>
          {actions && (
            <>
              <Divider />
              <DialogActions sx={{ py: 2, px: 3 }}>
                <Stack direction="row" spacing={2}>
                  {actions}
                </Stack>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
}
