/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, DialogActions, DialogContent, Divider, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useProvider from 'hooks/useProvider';
import useTherapists from 'hooks/useTherapists';
import useUser from 'hooks/useUser';
import { round } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';
import { Appointment } from 'types/appointment';
import { User } from 'types/user';
import useLabels from 'utils/apiTranslations/useLabels';
import { DURATION_OPTIONS } from 'utils/constants';
import { getErrorMessage } from 'utils/stringUtils';
import CenteredLoading from 'views/components/CenteredLoading';
import Autocomplete from 'views/components/inputs/Autocomplete';
import DatePicker from 'views/components/inputs/DatePicker';
import FormContainer from 'views/components/inputs/FormContainer';
import SwitchField from 'views/components/inputs/Switch';
import TagsInput from 'views/components/inputs/TagsInput';
import TextField from 'views/components/inputs/TextField';
import TimePicker from 'views/components/inputs/TimePicker';
import * as Yup from 'yup';
import usePatients from './usePatients';

interface Props {
  appointment: Appointment;
  onUpdateSuccess: () => void;
  renderCancel: () => ReactNode;
  baseUrl: string;
  appointmentId: string | undefined;
  mode: string;
}

type FormValues = any;

export default function EditAppointment({ appointment, onUpdateSuccess, renderCancel, baseUrl, appointmentId, mode }: Props) {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Date is not valid' })),
    time: Yup.date()
      .required(intl.formatMessage({ defaultMessage: 'Required' }))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(intl.formatMessage({ defaultMessage: 'Time is not valid' })),
    emails: Yup.array().of(
      Yup.string()
        .email(intl.formatMessage({ defaultMessage: 'Invalid email' }))
        .defined()
    ),
    duration: Yup.number().required(intl.formatMessage({ defaultMessage: 'Required' })),
    locationType: Yup.string()
      .nullable()
      .required(intl.formatMessage({ defaultMessage: 'Required' })),
    members: Yup.array().of(Yup.string().defined()),
    reason: Yup.string(),
    enableArchiving: Yup.bool()
  });

  const userId = appointment.owner;

  const { request } = useAuth();
  const { provider, isLoading: providerLoading } = useProvider(userId);
  const { patients } = usePatients(appointment.owner);
  const { therapists, therapistsLoading } = useTherapists();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [updateFuture, setUpdateFuture] = useState(false);

  const tz = provider?.settings.timezone || 'America/Vancouver';

  const updateAppointmentMuatation = useMutation((variables: any) => request.put(`/appointments/${appointment.event_id}`, variables), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment updated!' }), { variant: 'success' });
      onUpdateSuccess();
    },
    onError(e: any) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  });

  const onFormSubmit = useCallback(
    async ({ date, time, locationType, ...values }: FormValues) => {
      const staticMembers = appointment.members
        ?.map((member) => member.id)
        .filter(
          (id) =>
            !patients?.some((patient) => id === patient.id) &&
            !therapists?.some((therapist) => id === therapist._id && id !== appointment.owner)
        );

      const members = [...(staticMembers || []), ...values.members, ...values.otherTherapists];

      const data = {
        ...values,
        sessionType: locationType,
        members,
        updateForRecurring: updateFuture,
        date: moment(`${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`)
          .tz(tz)
          .toISOString()
      };
      delete data.otherTherapists;

      if (!members.length && !values.emails?.length) {
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'An appointment must have at least one participant' }), {
          variant: 'error'
        });
        return;
      }

      if (moment(data.date).isBefore()) {
        enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Appointment date must be later than today' }), {
          variant: 'error'
        });
        return;
      }

      await updateAppointmentMuatation.mutateAsync(data);
    },
    [appointment.members, updateAppointmentMuatation, tz, patients, updateFuture, appointment.owner, therapists]
  );

  const initialValues = {
    duration: appointment.duration,
    emails: appointment.emailsNewShow
      ?.map((member) => (member?.isContactMe === 'false' ? member?.email : ''))
      .filter((data) => appointment?.emails?.some((email) => email === data)),
    members: appointment.members?.map((member) => member.id).filter((id) => patients?.some((patient) => id === patient.id)),
    date: new Date(appointment.start),
    time: new Date(appointment.start),
    locationType: appointment?.locationType ?? null,
    serviceTypeId: appointment.serviceType?._id ?? null,
    officeId: appointment.office?.officeId ?? null,
    reason: '',
    updateForRecurring: updateFuture,
    enableArchiving: appointment.isRecordedEnabled,
    otherTherapists: appointment.members
      ?.map((member) => member.id)
      .filter((id) => therapists?.some((therapist) => id === therapist?._id && id !== appointment.owner))
  };

  if (!patients || therapistsLoading || providerLoading) {
    return (
      <Box p={8}>
        <CenteredLoading />
      </Box>
    );
  }

  return (
    <>
      <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onFormSubmit}>
        {mode === 'editFor' && (
          <>
            <FormLabel sx={{ m: 2, fontWeight: 'bold', color: 'black' }}>
              <FormattedMessage defaultMessage={'Edit for'} />
            </FormLabel>
            <Stack direction="row" sx={{ mx: 3 }} spacing={5} justifyContent="flex-start">
              <RadioGroup defaultValue="This session only">
                <FormControlLabel
                  value="This session only"
                  control={<Radio onClick={() => setUpdateFuture(false)} />}
                  label={intl.formatMessage({ defaultMessage: 'This session only' })}
                />
                <FormControlLabel
                  value="All future sessions"
                  control={<Radio onClick={() => setUpdateFuture(true)} />}
                  label={intl.formatMessage({ defaultMessage: 'All future sessions (if any)' })}
                />
              </RadioGroup>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Button sx={{ m: 2 }} type="submit" disabled={updateAppointmentMuatation.isLoading || !patients} variant="contained">
                <FormattedMessage defaultMessage="Confirm" />
              </Button>
            </Stack>
          </>
        )}
        {mode === 'edit' && (
          <>
            <DialogContent sx={{ p: 3 }}>
              <EditAppointmentFields appointment={appointment} therapists={therapists?.filter((t) => t?._id !== appointment.owner) || []} />
            </DialogContent>
            <Divider />
            <DialogActions sx={{ py: 2, px: 3 }}>
              <Stack direction="row" width="100%" justifyContent="space-between">
                {renderCancel()}
                <Button
                  type="button"
                  onClick={() => navigate(`${baseUrl}/${appointmentId}/editFor`)}
                  variant="contained"
                  disabled={updateAppointmentMuatation.isLoading || !patients}
                >
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}
      </FormContainer>
    </>
  );
}

interface EditAppointmentFieldsProps {
  appointment: Appointment;
  therapists: User[];
}

function EditAppointmentFields({ appointment, therapists }: EditAppointmentFieldsProps) {
  const { locationTypeLabels } = useLabels();
  const { options } = useOptions();
  const intl = useIntl();
  const userId = appointment.owner;
  const { provider } = useProvider(userId);
  const { patients } = usePatients(userId);
  const { user } = useUser(userId);

  const { watch, setValue } = useFormContext();
  const { enableArchiving } = useOptions();
  const emails = watch('emails');
  const members = watch('members');
  const isOfficeVisible = watch('locationType') === 'Office';
  const isOnline = watch('locationType') === 'Online';
  const serviceTypeId = watch('serviceTypeId');
  const [disabled, setDisabled] = useState(false);
  const [helperText, setHelperText] = useState('');
  const clinicalHours = provider?.provider.allowInBetweenAppointmentsTime;
  const services = useMemo(() => user?.provider.services ?? [], [user]);
  const serviceType = useMemo(() => services.find((service) => service?._id === serviceTypeId) ?? null, [serviceTypeId, services]);
  const timeBetween = provider?.provider?.inBetweenAppointmentsTime || 0;
  const getSelectePatient = appointment?.members?.filter((e) => e?.isOwner === false) || [];
  const getSelectePatientList =
    patients?.map((data) => data)?.filter((data) => getSelectePatient.some((value) => data.id === value.id)) ?? [];

  useEffect(() => {
    setValue(
      'otherTherapists',
      appointment.members
        ?.map((member) => member.id)
        .filter((id) => therapists?.some((therapist) => id === therapist?._id && id !== appointment.owner))
    );
  }, [therapists, appointment.members, appointment.owner, setValue]);

  const prevServiceType = usePrevious(serviceType);

  useEffect(() => {
    if (!serviceType) {
      return;
    }
    const serviceTypeChanged = !!prevServiceType && prevServiceType._id !== serviceType._id;

    if (appointment?.duration === 50 && serviceType?.durationInMinutes === 50 && clinicalHours) {
      setHelperText(
        intl.formatMessage({
          defaultMessage: 'This session is a standard clinical hour - (50 mins session + 10 mins for note taking related to client care)'
        })
      );
      setDisabled(true);
    } else {
      setHelperText('');
      setDisabled(false);
    }
    setValue('duration', serviceTypeChanged ? serviceType?.durationInMinutes : appointment?.duration);
  }, [serviceType, setValue, clinicalHours, timeBetween]);

  const durations = useMemo(() => {
    const preDuration = serviceType?.durationInMinutes;
    if (preDuration && !DURATION_OPTIONS.map((it) => it.value).includes(preDuration)) {
      return [...DURATION_OPTIONS, { value: preDuration, label: `${preDuration} minutes` }].sort((a, b) => a.value - b.value);
    }
    return DURATION_OPTIONS;
  }, [serviceType]);

  return (
    <Stack spacing={3}>
      <DatePicker name="date" label={intl.formatMessage({ defaultMessage: 'Date' })} required />

      <TimePicker name="time" label={intl.formatMessage({ defaultMessage: 'Time' })} required />

      <Autocomplete
        multiple
        options={
          appointment?.serviceType?.name === 'Complimentary Session' && appointment?.serviceType?.rate.toString() === '0'
            ? getSelectePatientList
            : patients ?? []
        }
        renderOption={(props: any, option: any) => (
          <span
            {...props}
            key={option.id}
            style={{
              color: option?.flag === 'red' ? 'red' : ''
            }}
          >
            {`${option?.firstName} ${option?.lastName} <${option?.email}>`}
          </span>
        )}
        getOptionValue={(option) => option?.id}
        getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} <${option?.email}>`}
        label={intl.formatMessage({ defaultMessage: 'Registered Clients' })}
        name="members"
        hidden={!user?.userOptions?.enableRegisteredSessionBooking && !members.length}
      />
      <Autocomplete
        required
        options={provider?.provider.typeOfAppointments ?? []}
        getOptionLabel={(option) => {
          return locationTypeLabels[option] ?? option;
        }}
        isOptionEqualToValue={(option, value) => !!value && option === value}
        label={intl.formatMessage({ defaultMessage: 'Session Type' })}
        name="locationType"
      />

      {isOfficeVisible && (
        <Autocomplete
          required
          options={provider?.provider.offices ?? []}
          getOptionLabel={(option) => option.officeName}
          getOptionValue={(option) => option._id}
          label={intl.formatMessage({ defaultMessage: 'Location' })}
          name="officeId"
        />
      )}

      {provider?.role === 'NOUS_SUPPORT' ? (
        <Autocomplete
          required
          options={services ?? []}
          getOptionLabel={(option) =>
            `${intl.locale === 'fr' ? option.nameFrench || option.name : option.name || option.nameFrench} [${
              option.durationInMinutes
            } min] ($${round(Number(option.rate), 3)})`
          }
          getOptionValue={(option) => option?._id}
          label={intl.formatMessage({ defaultMessage: 'Service Type' })}
          name="serviceTypeId"
          getOptionDisabled={(option) =>
            appointment?.serviceType?.name === 'Complimentary Session' && appointment?.serviceType?.rate.toString() === '0'
              ? !!provider?.provider.services?.find((element) => element === option)
              : false
          }
        />
      ) : (
        <Autocomplete
          required
          options={provider?.provider.services ?? []}
          getOptionLabel={(option) =>
            `${intl.locale === 'fr' ? option.nameFrench || option.name : option.name || option.nameFrench} [${
              option.durationInMinutes
            } min] ($${round(Number(option.rate), 3)})`
          }
          getOptionValue={(option) => option?._id}
          label={intl.formatMessage({ defaultMessage: 'Service Type' })}
          name="serviceTypeId"
          getOptionDisabled={(option) =>
            appointment?.serviceType?.name === 'Complimentary Session' && appointment?.serviceType?.rate.toString() === '0'
              ? !!provider?.provider.services?.find((element) => element === option)
              : false
          }
        />
      )}

      <Autocomplete
        required
        options={durations}
        disabled={disabled}
        getOptionValue={(option) => (option ? option.value : '')}
        getOptionLabel={(option) => (option ? option.label : '')}
        hasCreateOption
        getCreateOptionFromInput={(input: string) => {
          const num = Number(input);
          if (isNaN(num)) {
            return null;
          }
          return { value: num, label: `${num} minutes` };
        }}
        label={intl.formatMessage({ defaultMessage: 'Duration' })}
        name="duration"
        // getOptionDisabled={(option) => (serviceType?.rate.toString() === '0' ? false : !!durations?.find((element) => element === option))}
      />
      {clinicalHours && appointment?.duration === 50 && !!helperText && <Box>Note: {helperText}</Box>}

      <TagsInput name="emails" options={[]} label={intl.formatMessage({ defaultMessage: 'Non-registered participant email(s)' })} />

      {therapists && (
        <Autocomplete
          multiple
          options={therapists || []}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionValue={(option) => option._id}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          name="otherTherapists"
          label={intl.formatMessage({ defaultMessage: 'Other Providers' })}
          disabled={therapists === undefined}
          hidden={!options?.MULTIPLE_CLINICIANS_ENCOUNTERS?.value}
        />
      )}

      <TextField name="reason" label={intl.formatMessage({ defaultMessage: 'Reason' })} fullWidth multiline rows={5} />

      {enableArchiving && isOnline && (
        <SwitchField
          name="enableArchiving"
          label={intl.formatMessage({ defaultMessage: 'Record the session for later auditing or review' })}
          disabled
        />
      )}
    </Stack>
  );
}
