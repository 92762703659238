import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export default function useNoteTemplates(userId: string | undefined) {
  const { request } = useAuth();
  const url = `/user/${userId}/notes/templates`;

  const {
    data: templates,
    refetch: refetchTemplates,
    isLoading,
    isRefetching
  } = useQuery(url, () => request.get(url).then((r) => r.data.templates as Template[]), {
    enabled: !!userId
  });

  return { templates, refetchTemplates, isTemplatesLoading: isLoading || isRefetching };
}

export interface Template {
  content: string;
  _id: string;
  owner: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}
