import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MUIDatePicker, DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import moment from 'moment';

import React, { useState } from 'react';
import { Controller, FieldError, FieldValues, FormState } from 'react-hook-form';
import { useIntl } from 'react-intl';

export type DatePickerProps = Omit<MUIDatePickerProps<any, any>, 'value' | 'onChange' | 'renderInput'> & {
  name: string;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => string;
  onChange?: (date: any, keyboardInputValue?: string | undefined) => void;
  parseDate?: (date: any) => string;
  helperText?: string;
  intl?: any;
  minDate?: Date | number | string | undefined;
  maxDate?: Date | number | string | undefined;
  formState?: FormState<FieldValues>;
  source?: string;
  placeholder?: string;
};

const DatePicker: React.FC<DatePickerProps> = ({
  isDate,
  parseError,
  name,
  label,
  required,
  parseDate,
  helperText,
  source,
  formState,
  ...rest
}: DatePickerProps): JSX.Element => {
  const intl = useIntl();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { minDate, maxDate } = rest;
  const minDateMoment = minDate ? moment(minDate, 'MM/DD/YYYY') : null;
  const isSubmitted = formState?.isSubmitted;
  return (
    <Controller
      name={name}
      render={({ field: { onBlur, onChange, value }, fieldState: { error, invalid } }) => (
        <LocalizationProvider
          adapterLocale={intl.locale === 'fr' ? frLocale : enLocale}
          dateAdapter={AdapterDateFns}
          // dateLibInstance={moment}
        >
          <MUIDatePicker
            // value={isValid(value) ? value : null}
            value={value}
            onChange={(date) => {
              // const updated = date ? new Date(date.valueOf()) : null;
              if (source === 'register' && minDateMoment && date && moment(date).year() < minDateMoment.year()) {
                setErrorMessage(`Choose a date on or after ${minDate.toDateString()}`);
              } else if (source === 'register' && maxDate && date && maxDate.getFullYear() < new Date(date).getFullYear()) {
                setErrorMessage(`Choose a date on or before ${maxDate.toDateString()}`);
              } else {
                setErrorMessage('');
                onChange(date);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label ? `${label} ${required ? ' *' : ''}` : ''}
                fullWidth
                placeholder={rest?.placeholder}
                error={(source === 'register' && isSubmitted && Boolean(errorMessage)) || invalid}
                // eslint-disable-next-line no-nested-ternary
                helperText={
                  (error ? (typeof parseError === 'function' ? parseError(error as any) : error.message) : helperText) ||
                  (isSubmitted && Boolean(errorMessage) && errorMessage)
                }
              />
            )}
            inputFormat={intl.locale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            {...rest}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePicker;
