import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import useUsers from 'hooks/useUsers';
import { useQuery } from 'react-query';
import { User } from 'types/user';
import { populate } from 'utils/arrayUtils';

export default function usePatients(id?: string) {
  const { request, userId } = useAuth();
  const { isProvider } = useUser(id ?? userId);
  const { users } = useUsers({});
  const url = `/user/${id ?? userId}/patients`;

  const { data: patients, isLoading: patientsLoading } = useQuery(
    url,
    () => request.get(url).then((response) => populate(response.data?._embedded?.items ?? [], users) as User[]),
    { enabled: !!users && isProvider && !!userId && !url.includes('undefined') }
  );

  return { patients, patientsLoading };
}
