import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';

export default function useSessionNotes(sessionId: string | undefined, userId: string | undefined) {
  const { request } = useAuth();

  const url = `/session/${sessionId}/participant/${userId}/notes`;

  const { data: notes, refetch: refetchNotes } = useQuery(url, () => request.get(url).then((r) => r.data.notes as Note[]), {
    enabled: !!sessionId && !!userId
  });

  return { notes, refetchNotes };
}

export interface Note {
  participants: string[];
  _id: string;
  ownerId: string;
  title: string;
  body: string;
  sessionId: string;
  date_created: Date;
  date_modified: Date;
  attachments?: { name: string; url: string }[];
  __v: number;
}
