import { DefaultRootStateProps } from 'types';
import { RESET_SELECTION, TOGGLE_SELECT_ALL, TOGGLE_USER_SELECTION, UPDATE_SELECTION_STATE } from '../actions';

export const initialState: DefaultRootStateProps['clientCheckbox'] = {
  selectedUserIds: [],
  selectAllCheckboxes: false
};

function checkboxReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SELECT_ALL:
      return {
        ...state,
        selectAllCheckboxes: action.payload,
        selectedUserIds: action.payload ? action.totalRows.map((row) => row.id) : []
      };
    case TOGGLE_USER_SELECTION:
      const { userId, value } = action.payload;
      return {
        ...state,
        selectAllCheckboxes: value === false ? false : state.selectAllCheckboxes,
        selectedUserIds: state.selectedUserIds.includes(userId)
          ? state.selectedUserIds.filter((id) => id !== userId)
          : [...state.selectedUserIds, userId]
      };
    case UPDATE_SELECTION_STATE:
      return {
        ...state,
        selectAllCheckboxes: state.selectedUserIds.length === action.payload.totalRows
      };
    case RESET_SELECTION:
      return {
        ...state,
        selectedUserIds: [],
        selectAllCheckboxes: false
      };
    default:
      return state;
  }
}

export default checkboxReducer;
