import { IntlShape } from 'react-intl';

export const getTypeCell = (value: string, intl: IntlShape) => {
  switch (value) {
    case 'New Feature':
      return intl.formatMessage({ defaultMessage: 'New Feature' });
    case 'Update':
      return intl.formatMessage({ defaultMessage: 'Update' });
    case 'Alert':
      return intl.formatMessage({ defaultMessage: 'Alert' });
    case 'Info':
      return intl.formatMessage({ defaultMessage: 'Info' });
    case 'Enhancement':
      return intl.formatMessage({ defaultMessage: 'Enhancement' });
    default:
      return intl.formatMessage({ defaultMessage: 'Fix' });
  }
};
