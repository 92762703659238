import { Backdrop, Button, DialogActions, DialogTitle, Paper, Stack, Theme } from '@mui/material';
// material-ui
import { makeStyles } from '@mui/styles';
import { IconArrowBackUp } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import { useEffect, useRef, useState } from 'react';
// project imports
import { FormattedMessage } from 'react-intl';
import { Rnd } from 'react-rnd';
import DialogClose from 'views/components/buttons/DialogClose';
import Message from './Message';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .messageContainer': {
      minHeight: 300,
      marginTop: 10,
      fontSize: 15,
      display: 'flex',
      flexDirection: 'column',

      '& .public-DraftEditor-content': {
        minHeight: '0 !important'
      },

      '& .replies': {
        marginTop: 20
      },

      '& .attachmentDoc': {
        marginTop: 5,
        '& > p': {
          fontWeight: '500',
          marginRight: 5
        }
      }
    }
  }
}));

interface Props {
  message: any;
  sent: boolean;
  onClose: () => void;
  markAsRead: Function;
  setReplyingMessage: Function;
}

const ViewMessage = ({ message, sent, onClose, markAsRead, setReplyingMessage }: Props) => {
  const classes = useStyles();
  const isSmallScreen = window.innerWidth <= 850;

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { userId } = useAuth();
  const { _id, title, unreadBy, replies } = message;
  const unread = unreadBy === userId;

  const [width, setWidth] = useState(!isSmallScreen ? 850 : window.innerWidth * 0.9);
  const [height, setHeight] = useState(window.innerHeight * 0.9);

  const positionRef = useRef<any>({
    x: isSmallScreen ? 10 : window.innerWidth * 0.5 - 350,
    y: 10,
    width,
    height
  });

  const scrollToBottom = () => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
    // Mark message as read on message opening
    if (unread) {
      markAsRead({ _id, read: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dismiss = (x: number, y: number) => {
    const offset = 50;
    if (
      x <= positionRef.current.x - offset ||
      x >= positionRef.current.x + positionRef.current.width + offset ||
      y <= positionRef.current.y - offset ||
      y >= positionRef.current.y + positionRef.current.height + offset
    ) {
      onClose();
    }
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 1200 }} open onClick={(e) => dismiss(e.clientX, e.clientY)}>
      <Rnd
        default={positionRef.current}
        disableDragging={isSmallScreen}
        minWidth={isSmallScreen ? window.innerWidth * 0.9 : window.innerWidth * 0.4}
        minHeight={isSmallScreen ? window.innerHeight * 0.9 : window.innerHeight * 0.7}
        style={{ zIndex: 1200, margin: 20 }}
        onResize={(e, direction, ref, delta, position) => {
          setWidth(ref.offsetWidth);
          setHeight(ref.offsetHeight);
          positionRef.current = { x: position.x, y: position.y, width: ref.offsetWidth, height: ref.offsetHeight };
        }}
        onDragStop={(e, d) => {
          positionRef.current = { ...positionRef.current, x: d.x, y: d.y };
        }}
        cancel=".main-editor-custom,button,.message-box"
      >
        <Paper
          sx={(theme) => ({
            width,
            height,
            backgroundColor: '#fff',
            borderRadius: theme.shape.borderRadius,
            p: 4,
            pt: 3
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <Stack sx={{ height: '100%' }}>
            <DialogTitle>
              {title} <DialogClose onClose={onClose} />
            </DialogTitle>
            <Stack
              className="message-box"
              ref={scrollRef}
              sx={{
                overflowY: 'scroll',
                flex: 1,
                wordWrap: 'break-word',
                cursor: 'default',
                '&::-webkit-scrollbar': {
                  width: 3
                },

                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#ddd',
                  borderRadius: 3
                },

                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555'
                }
              }}
            >
              <Message message={message} />
              {replies?.map((message, index) => (
                <Message key={index} message={message} />
              ))}
            </Stack>
            <DialogActions>
              <Button variant="contained" onClick={() => setReplyingMessage(message)}>
                <IconArrowBackUp width={20} height={20} />
                <FormattedMessage defaultMessage="Reply" />
              </Button>
              <Button onClick={onClose}>
                <FormattedMessage defaultMessage="Close" />
              </Button>
            </DialogActions>
          </Stack>
        </Paper>
      </Rnd>
    </Backdrop>
  );
};

export default ViewMessage;
