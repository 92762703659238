import { Box, Popover, Stack, Tooltip, Typography } from '@mui/material';
import {
  IconArrowBackUp,
  IconArrowForwardUp,
  IconBrush,
  IconCircle,
  IconCircleSquare,
  IconEraser,
  IconLine,
  IconPhoto,
  IconRectangle,
  IconSquareX,
  IconTextResize
} from '@tabler/icons';
import imageCompression from 'browser-image-compression';
import { useSnackbar } from 'notistack';
import { ChangeEventHandler, Dispatch, MutableRefObject, RefObject, SetStateAction, useRef } from 'react';
import { SketchPicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  containerRef: RefObject<HTMLDivElement> | null;
  isProvider: boolean;
  handleShapeClick: (string) => void;
  pencilActive: boolean;
  handlePencilClick: () => void;
  textActive: boolean;
  anchorShapeEl: HTMLElement | null;
  handleColorPickerClick: () => void;
  textBoxActive: boolean;
  setAnchorShapeEl: Function;
  color: any;
  eraseActive: boolean;
  setStrokeWidth: Dispatch<SetStateAction<number>>;
  showColorPicker: boolean;
  setShowPromptSave: (boolean) => {};
  handleEraserClick: () => void;
  handleLetterClick: () => void;
  clearAfterSavingRef: MutableRefObject<boolean>;
  undoEvents: () => void;
  redoEvents: () => void;
  setColor: (any) => void;
  activeShape: string | null;
  handleImageClick: ({ file }: { file: File }) => void;
  imageActive: boolean;
}

function Controls({
  containerRef,
  isProvider,
  handleShapeClick,
  pencilActive,
  handlePencilClick,
  textActive,
  anchorShapeEl,
  handleColorPickerClick,
  textBoxActive,
  setAnchorShapeEl,
  color,
  eraseActive,
  setStrokeWidth,
  showColorPicker,
  setShowPromptSave,
  handleEraserClick,
  handleLetterClick,
  clearAfterSavingRef,
  undoEvents,
  redoEvents,
  setColor,
  activeShape,
  handleImageClick,
  imageActive
}: Props) {
  const intl = useIntl();

  const fileInputRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target?.files?.[0];

    if (!file) {
      return;
    }
    if (file.size > 15728640) {
      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'File size must be less than 15MB' }), { variant: 'error' });
      return;
    }

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    const compressedFile = await imageCompression(file, options);

    handleImageClick({ file: compressedFile });
    fileInputRef.current.value = '';
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          borderWidth: 1,
          position: 'absolute',
          right: 15,
          top: 50,
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: 3,
          justifyContent: 'center',
          paddingLeft: 3,
          paddingRight: 3,
          display: 'flex',
          backgroundColor: 'rgba(51,51,51)',
          zIndex: 3,
          py: 1,
          px: 0,
          width: 60,

          '& .control-button': {
            cursor: 'pointer',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            mx: 0,
            my: 0.7,
            width: 40,
            height: 40,

            '&.active': {
              backgroundColor: '#fff',
              color: '#000'
            }
          }
        }}
      >
        <Tooltip
          PopperProps={{ ...PaperProps, container: containerRef?.current }}
          title={<FormattedMessage defaultMessage="Stroke width" />}
        >
          <Box className="control-button">
            <select
              style={{ height: '75%', borderRadius: 5 }}
              onChange={(e) => {
                setStrokeWidth(Number(e.target.value));
              }}
            >
              <option value="2">2</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="8">8</option>
              <option value="10">10</option>
            </select>
          </Box>
        </Tooltip>

        <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Color" />}>
          <Box className="control-button" onClick={handleColorPickerClick}>
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                background: `rgba(${color.rgb?.r ?? 0}, ${color.rgb?.g ?? 0}, ${color.rgb?.b ?? 0}, ${color.rgb?.a ?? 0})`,
                border: '1px solid #777'
              }}
            />
          </Box>
        </Tooltip>

        <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Brush" />}>
          <Box className={`control-button pencil ${pencilActive ? 'active' : ''}`} onClick={handlePencilClick}>
            <IconBrush size={24} />
          </Box>
        </Tooltip>

        <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Eraser" />}>
          <Box className={`control-button ${eraseActive === true ? 'active' : ''}`} onClick={handleEraserClick}>
            <IconEraser size={24} />
          </Box>
        </Tooltip>

        <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Text" />}>
          <Box className={`control-button text ${textActive || textBoxActive ? 'active' : ''}`} onClick={handleLetterClick}>
            <IconTextResize size={24} />
          </Box>
        </Tooltip>

        <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Shapes" />}>
          <Box className={`control-button shape ${activeShape ? 'active' : ''}`} onClick={(e) => setAnchorShapeEl(e.currentTarget)}>
            <IconCircleSquare size={24} />
          </Box>
        </Tooltip>

        <Popover
          container={containerRef?.current}
          open={Boolean(anchorShapeEl)}
          anchorEl={anchorShapeEl}
          onClose={() => setAnchorShapeEl(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
        >
          <Stack
            spacing={1}
            p={1}
            sx={{
              backgroundColor: 'rgba(51,51,51,.8)',
              '& .shape': {
                cursor: 'pointer',
                color: '#fff'
              }
            }}
          >
            <Box className="shape" onClick={() => handleShapeClick('rectangle')}>
              <IconRectangle size={24} style={{ color: '#fff' }} />
            </Box>
            <Box className="shape" onClick={() => handleShapeClick('circle')}>
              <IconCircle size={24} style={{ color: '#fff' }} />
            </Box>
            <Box className="shape" onClick={() => handleShapeClick('line')}>
              <IconLine size={24} style={{ color: '#fff' }} />
            </Box>
          </Stack>
        </Popover>

        <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Image" />}>
          <Box
            className={`control-button ${imageActive ? 'active' : ''}`}
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            <IconPhoto size={24} />
          </Box>
        </Tooltip>

        <input ref={fileInputRef} type="file" hidden onChange={handleFileChange} accept="image/png, image/gif, image/jpeg" />

        {isProvider && (
          <>
            <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Undo" />}>
              <Stack className="control-button" onClick={undoEvents} mx={1}>
                <IconArrowBackUp size={22} />
                <Typography sx={{ fontSize: 13 }}>
                  <FormattedMessage defaultMessage="Undo" />
                </Typography>
              </Stack>
            </Tooltip>
            <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Redo" />}>
              <Stack className="control-button" onClick={redoEvents} mx={1}>
                <IconArrowForwardUp size={22} />
                <Typography sx={{ fontSize: 13 }}>
                  <FormattedMessage defaultMessage="Redo" />
                </Typography>
              </Stack>
            </Tooltip>
            <Tooltip PopperProps={{ ...PaperProps, container: containerRef?.current }} title={<FormattedMessage defaultMessage="Clear" />}>
              <Stack
                className="control-button"
                onClick={() => {
                  setShowPromptSave(true);
                  clearAfterSavingRef.current = true;
                }}
                mx={1}
              >
                <IconSquareX size={24} />
                <Typography sx={{ fontSize: 13 }}>
                  <FormattedMessage defaultMessage="Clear" />
                </Typography>
              </Stack>
            </Tooltip>
          </>
        )}
      </Stack>

      {/* show color picker  */}
      {showColorPicker && (
        <Box sx={{ position: 'absolute', top: 80, right: 100 }}>
          <SketchPicker
            color={color.rgb}
            onChange={(color) =>
              setColor({
                rgb: color.rgb
              })
            }
          />
        </Box>
      )}
    </>
  );
}

export default Controls;

const PaperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-40, -45]
      }
    }
  ]
};
