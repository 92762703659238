import { Buffer } from 'buffer';
export async function parseHTMLString(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc;
}

export async function identifyBlobs(doc) {
  const blobs: any = [];
  const images = doc.querySelectorAll('img');
  images.forEach((img) => {
    if (img.src.startsWith('data:image')) {
      blobs.push({ element: img, blob: img.src });
    }
  });
  return blobs;
}

export async function replaceBlobsWithUrls(doc, blobs, uploadMutation, blobUrlMap) {
  for (const { element, blob } of blobs) {
    if (blobUrlMap.has(blob)) {
      element.src = blobUrlMap.get(blob);
    } else {
      const presignedUrl = await getPresignedUrl(uploadMutation, blob);
      blobUrlMap.set(blob, presignedUrl);
      element.src = presignedUrl;
    }
  }
  return doc;
}

export async function serializeDOMToString(doc) {
  const serializedString = new XMLSerializer().serializeToString(doc);
  const ampRemove = serializedString.toString().replace(/&amp;/g, '&');
  return ampRemove;
}
export async function getPresignedUrl(uploadMutation, blobImage) {
  let url: any;

  const base64DataMatch = blobImage;
  const base64Data = base64DataMatch.match(/base64,([A-Za-z0-9+/=]+)/)[1];
  const buffer = Buffer.from(base64Data, 'base64');
  const contentTypeMatch = base64DataMatch.match(/image\/(jpeg|png|gif|bmp|jpg)/);
  const contentType = `image/${contentTypeMatch[1]}`;
  const blob = new Blob([buffer], { type: contentType });
  const file = new File([blob], `image.${contentTypeMatch[1]}`, { type: contentType });
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'NOTES_DOCUMENT');

  await uploadMutation.mutateAsync(formData, {
    onSuccess: ({ data }) => {
      url = data.url;
    }
  });

  return url;
}

export async function convertImageBlobToImageFile(htmlString, uploadMutation, blobUrlMap) {
  const doc = await parseHTMLString(htmlString);
  const blobs = await identifyBlobs(doc);
  const updatedDoc = await replaceBlobsWithUrls(doc, blobs, uploadMutation, blobUrlMap);
  const res = await serializeDOMToString(updatedDoc);
  return res;
}
