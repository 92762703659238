import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button, Chip, DialogActions, Grid, IconButton, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { IconPaperclip } from '@tabler/icons';

// material-ui
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useTherapists from 'hooks/useTherapists';
import useUser from 'hooks/useUser';
import useUsers from 'hooks/useUsers';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { DefaultRootStateProps } from 'types';
import { convertImageBlobToImageFile } from 'utils/convertImageBlobToImageFile';
import DialogClose from 'views/components/buttons/DialogClose';
import QuillEditorController from 'views/components/common/QuillEditorController';
import AnimateButton from 'views/components/extended/AnimateButton';
import AutocompleteField from 'views/components/inputs/Autocomplete';
import Checkbox from 'views/components/inputs/Checkbox';
import FormContainer from 'views/components/inputs/FormContainer';
import TextField from 'views/components/inputs/TextField';
import Loader from 'views/components/Loader';
import BasicDialog from 'views/components/modals/BasicDialog';
import PopularCard from 'views/components/skeleton/PopularCard';
import * as Yup from 'yup';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import useMessages from './useMessages';

const MAX_COUNT = 10;
export type ComposeMessageProps = {
  isInternalMessage: boolean;
  onClose: () => void;
  patients: any[];
  therapists: any[];
  isLoading: boolean;
  replyingMessage: any;
  setReplyingMessage: Function;
  replyToMessage: Function;
  defaultRecipientIds?: string[];
  clientId?: string;
};

const ComposeMessage: React.FC<ComposeMessageProps> = ({
  isInternalMessage,
  onClose,
  patients,
  therapists,
  isLoading,
  replyingMessage,
  replyToMessage,
  defaultRecipientIds = [],
  clientId
}) => {
  const socket = useSelector((state: DefaultRootStateProps) => state.chat)?.socket;

  const { therapists: allTherapists, therapistsLoading } = useTherapists();

  const isSmallScreen = window.innerWidth <= 850;

  const [width, setWidth] = useState(!isSmallScreen ? 850 : window.innerWidth * 0.9);
  const [height, setHeight] = useState(replyingMessage ? window.innerHeight * 0.85 : window.innerHeight * 0.91);

  const textBoxRef = useRef<any>();
  const [editorHeight, setEditorHeight] = useState(300);

  const positionRef = useRef<any>({
    x: isSmallScreen ? 10 : window.innerWidth * 0.5 - 350,
    y: 10,
    width,
    height
  });

  const { userId, request } = useAuth();
  const { isClient, isProvider, user, isAdministrator } = useUser(userId);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [fileLimit, setFileLimit] = useState(false);
  const [error, setError] = useState('');

  const { administrators } = useUsers({});
  const { sendMessage } = useMessages({ isInternalMessage });

  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
  const canAccessAllClientRecords = useMemo(() => user?.provider?.mAccessSettings?.ACCESS_ALL_CLIENT_RECORDS, [user]);
  const { options } = useOptions();

  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const validationSchema = !replyingMessage
    ? Yup.object().shape({
        title: Yup.string().required(intl.formatMessage({ defaultMessage: 'Subject is required' })),
        description: Yup.string().optional(),
        receivers: Yup.array()
          .of(Yup.string().required())
          .required()
          .min(1, intl.formatMessage({ defaultMessage: 'At least one recipient is required' }))
      })
    : Yup.object().shape({
        title: Yup.string().required(intl.formatMessage({ defaultMessage: 'Subject is required' })),
        description: Yup.string().optional(),
        receivers: Yup.array().of(Yup.string().required()).optional()
      });

  type FormValues = Yup.InferType<typeof validationSchema>;

  const notAccessAllClient = useMemo(
    () =>
      options?.PER_USER_ACCESS_ROLES?.value &&
      !user?.provider?.accessSettings?.canAccessAllClientRecords &&
      user?.role === 'PROVIDER_ADMINISTRATOR',
    [user, options]
  );

  const receivers = useMemo(() => {
    if (isInternalMessage) return allTherapists?.filter((therapist) => therapist._id !== userId) || [];
    if ((isProvider && canAccessAllClientRecords) || (!isInternalMessage && isAdministrator)) {
      //based on provider permission
      return patients;
    } else if (isProvider || (!isInternalMessage && isAdministrator)) {
      let r: any[] = []; //get clients if user includes in client's associateTherapists array
      // patients?.forEach(
      //   (client) =>
      //     client?.therapistId !== userId &&
      //     client?.associateTherapists?.forEach((t: any) => {
      //       if (t?.therapist === userId) {
      //         r.push(client);
      //       }
      //     })
      // );

      return patients.filter((client) => client?.therapistId === userId).concat(r);
    }

    const associateTherapistIds = (user?.associateTherapists?.filter((t) => !t.removed && t.canAccessClientMessages) || []).map(
      (t: any) => t.therapist
    );
    return therapists?.filter((t) => t.id === user?.therapist || associateTherapistIds.includes(t.id));
  }, [
    isProvider,
    patients,
    therapists,
    user?.associateTherapists,
    user?.therapist,
    isInternalMessage,
    allTherapists,
    userId,
    isAdministrator,
    canAccessAllClientRecords
  ]);

  const initialValues = {
    title: replyingMessage?.title ? `Re: ${replyingMessage?.title}` : '',
    description: '',
    receivers: defaultRecipientIds,
    selectAll: false
  };

  const formContext = useForm({ defaultValues: initialValues, resolver: yupResolver(validationSchema) });
  const { watch } = formContext;
  const selectedReceivers = watch('receivers');
  const selectedTitle = watch('title');

  const selectAll = useWatch({ control: formContext.control, name: 'selectAll' });

  useEffect(() => {
    if (selectAll && receivers?.length) {
      formContext.setValue(
        'receivers',
        receivers?.filter((o) => !(o?.inactive || o?.disabled)).map(({ _id, id }) => _id || id)
      );
    }
  }, [selectAll, receivers, formContext]);
  const uploadMutation = useMutation((formData: any) => request.post(`/user/${userId}/files`, formData));
  const blobUrlMap = new Map();
  const submitMessage = useCallback(
    async (payload: any) => {
      const { description, receivers, title } = payload;
      let updatedDescription = description;
      if (updatedDescription) {
        updatedDescription = await convertImageBlobToImageFile(description, uploadMutation, blobUrlMap);
      }
      const formData = new FormData();
      formData.set('title', title);
      formData.set('description', updatedDescription ?? '');
      formData.set('receivers', JSON.stringify(receivers));
      formData.set('isInternalMessage', isInternalMessage.toString());

      if (uploadedFiles) {
        uploadedFiles.forEach((file) => {
          formData.append('file', file);
        });
      }

      onClose();
      if (replyingMessage) {
        formData.set('_id', replyingMessage._id);
        await replyToMessage(formData);

        const allUsers = [replyingMessage.sender?._id, replyingMessage.receiver?._id].filter((_id) => _id !== userId && _id !== null);

        socket?.emit('sent-message', allUsers);
      } else {
        await sendMessage(formData);
        socket?.emit('sent-message', payload.receivers);
      }
      setIsSubmitting(false);

      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Message sent successfully' }), { variant: 'success' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInternalMessage, uploadedFiles, onClose, replyingMessage, enqueueSnackbar, intl, replyToMessage, socket, userId, sendMessage]
  );

  const onFormSubmit = useCallback(
    async (values: FormValues) => {
      if (!replyingMessage && values?.receivers?.length > 1) {
        const modal = showModal(ConfirmationDialog, {
          title: 'Confirmation',
          description: intl.formatMessage({
            defaultMessage: `This message will be sent by blind copy to more than one recipient. Do you wish to proceed?`
          }),
          onCancel: () => modal.hide(),
          onConfirm: () => {
            modal.hide();
            submitMessage(values);
          }
        });
      } else {
        submitMessage(values);
      }
    },
    [intl, showModal, replyingMessage, submitMessage]
  );

  const deleteAttachment = (index) => {
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newUploadedFiles);
  };

  const dismiss = (x: number, y: number) => {
    const offset = 50;
    if (
      x <= positionRef.current.x - offset ||
      x >= positionRef.current.x + positionRef.current.width + offset ||
      y <= positionRef.current.y - offset ||
      y >= positionRef.current.y + positionRef.current.height + offset
    ) {
      onClose();
    }
  };

  useEffect(() => {
    let minHeight = height - (textBoxRef.current?.clientHeight + 12) * 6;
    if (replyingMessage) {
      minHeight += 40;
    }

    setTimeout(() => {
      setEditorHeight(!isSmallScreen ? minHeight - 60 : minHeight - 100);
    }, 700);
  }, [height, isSmallScreen, replyingMessage, isLoading, therapistsLoading]);

  const activeReceivers = useMemo(() => {
    if (isInternalMessage) {
      const filteredAdministrators = administrators?.filter((a) => a.id !== userId);
      return receivers?.filter((receiver) => !(receiver.inactive || receiver.disabled)).concat(filteredAdministrators);
    }

    //   if (!isClient) {
    //     if (isAdministrator) return patients;

    //     let r: any[] = [];

    //     receivers.forEach((receiver) => {
    //       const isFound = receiver?.associateTherapists.find(
    //         (associateTherapist) => !associateTherapist.removed && associateTherapist.therapist === userId
    //       );
    //       if (isFound?.canAccessClientMessages || receiver.therapistId === userId) {
    //         r.push(receiver);
    //       }
    //     });
    //     return r;
    //   } else {
    //     return receivers?.filter((receiver) => !(receiver.inactive || receiver.disabled));
    //   }
    // }, [isInternalMessage, receivers, administrators, userId, isClient, isAdministrator, patients]);
    if (!isClient) {
      if ((isProvider && canAccessAllClientRecords) || (!isInternalMessage && isAdministrator)) {
        //based on provider permission
        const filteredUsers = receivers?.filter((receiver) => !(receiver.inactive || receiver.disabled));

        return filteredUsers;
      } else {
        let r: any[] = [];

        receivers.forEach((receiver) => {
          const isFound = receiver?.associateTherapists.find(
            (associateTherapist) => !associateTherapist.removed && associateTherapist.therapist === userId
          );
          if (isFound?.canAccessClientMessages || receiver.therapistId === userId) {
            r.push(receiver);
          }
        });
        return r;
      }
    } else {
      return receivers?.filter((receiver) => !(receiver.inactive || receiver.disabled));
    }
  }, [isInternalMessage, isProvider, canAccessAllClientRecords, isAdministrator, isClient, administrators, receivers, userId]);
  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          setError('You can add only maximum 10 files');
          setFileLimit(false);
          limitExceeded = true;
        }
      }
    });

    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const MAX_FILE_SIZE = 25 * 1024 * 1024;
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    // Check if any chosen file exceeds the size limit
    const oversizedFiles = chosenFiles.filter((file) => {
      return file.size > MAX_FILE_SIZE;
    });

    if (oversizedFiles.length) {
      enqueueSnackbar(intl.formatMessage({ defaultMessage: ` Size must be (Max. 25 MB)` }), {
        variant: 'error'
      });

      return;
    }
    handleUploadFiles(chosenFiles);
  };

  const handleloading = () => {
    return <Loader />;
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1200 }} open onClick={(e) => dismiss(e.clientX, e.clientY)}>
        <Rnd
          default={positionRef.current as any}
          minWidth={isSmallScreen ? window.innerWidth * 0.8 : window.innerWidth * 0.4}
          minHeight={isSmallScreen ? window.innerHeight * 0.8 : window.innerHeight * 0.7}
          style={{ zIndex: 1200, margin: 20 }}
          onResize={(e, direction, ref, delta, position) => {
            setWidth(ref.offsetWidth);
            setHeight(ref.offsetHeight);
            positionRef.current = { x: position.x, y: position.y, width: ref.offsetWidth, height: ref.offsetHeight };
          }}
          onDragStop={(e, d) => {
            positionRef.current = { ...positionRef.current, x: d.x, y: d.y };
          }}
          cancel=".cancel-drag,button"
        >
          <Paper
            sx={(theme) => ({
              width,
              height,
              backgroundColor: '#fff',
              borderRadius: theme.shape.borderRadius,
              p: 4,
              pt: 3,

              '& > form': {
                height: '100%'
              }
            })}
            onClick={(e) => e.stopPropagation()}
          >
            {isLoading || therapistsLoading ? (
              <PopularCard />
            ) : (
              <FormContainer formContext={formContext} onSuccess={onFormSubmit}>
                <Box className="control" sx={(theme) => ({ fontSize: 20, fontWeight: '900', color: theme.palette.dark[800], mb: 2 })}>
                  {replyingMessage ? (
                    <FormattedMessage defaultMessage="Reply to Message" />
                  ) : (
                    <FormattedMessage defaultMessage="Compose Message" />
                  )}
                  <DialogClose onClose={onClose} />
                </Box>
                <Stack
                  className="cancel-drag"
                  spacing="10px"
                  paddingTop="10px"
                  sx={{
                    cursor: 'default',
                    pr: 1,
                    maxHeight: '90%',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: 3
                    },

                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#ddd',
                      borderRadius: 3
                    },

                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555'
                    }
                  }}
                >
                  <Box ref={textBoxRef}>
                    <TextField
                      id="outlined-basic-review-product"
                      fullWidth
                      name="title"
                      label={intl.formatMessage({ defaultMessage: 'Subject' })}
                      autoFocus
                    />
                  </Box>

                  <Box
                    sx={{
                      flex: 1,
                      '& .ql-editor': {
                        minHeight: 280,
                        height: `${editorHeight}px !important`
                      }
                    }}
                  >
                    <QuillEditorController name="description" />
                  </Box>

                  <Grid container justifyContent="flex-start" alignItems="right" className="attachments">
                    {uploadedFiles.map((file, index) => (
                      <Chip
                        key={index}
                        className="attachedDoc"
                        label={file.name}
                        variant="outlined"
                        onDelete={() => deleteAttachment(index)}
                        sx={{ margin: 1, width: '20%' }}
                      />
                    ))}
                  </Grid>

                  <Grid container justifyContent="flex-end" alignItems="right" className="attachments">
                    <IconButton component="label" sx={{ color: uploadedFiles.length >= MAX_COUNT ? 'grey' : '#337cc1' }}>
                      <IconPaperclip />
                      <input
                        hidden
                        id="fileUpload"
                        type="file"
                        accept=".gif, .jpg , .jpeg , .jfif , .pjpeg , .pjp, .png, .svg, .webp,
                        .doc,.docx, .xls, .pdf,.csv"
                        multiple
                        onChange={handleFileEvent}
                        disabled={uploadedFiles.length >= MAX_COUNT}
                        onInputCapture={handleloading}
                      />
                      <Typography sx={{ color: uploadedFiles.length >= MAX_COUNT ? 'grey' : '#337cc1' }}>
                        <FormattedMessage defaultMessage="Attach" />
                      </Typography>
                    </IconButton>
                  </Grid>
                  <Typography sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                    <FormattedMessage defaultMessage={'Select only maximum 10 files & Size must be (Max. 25 MB)'} />
                  </Typography>

                  {isUploading && <LinearProgress variant="determinate" value={uploadProgressPercent} />}

                  {!replyingMessage && (
                    <Grid container>
                      <Grid item flex={1} mr={2}>
                        <AutocompleteField
                          multiple
                          options={activeReceivers?.filter((receiver: any) => !(receiver?.inactive || receiver?.disabled))}
                          getOptionValue={(option) => option?.id || option?._id}
                          renderOption={(props, option) => (
                            <li {...props} key={option.id || option._id}>
                              {option?.firstName || ''} {option?.lastName || ''} {!isClient && <>( {option?.role} )</>}
                            </li>
                          )}
                          getOptionLabel={(option) => `${option?.firstName || ''} ${option?.lastName || ''}`}
                          name="receivers"
                          label={intl.formatMessage({ defaultMessage: 'Recipients' })}
                          disabled={selectAll}
                          required
                        />
                      </Grid>

                      {!clientId && (isProvider || isAdministrator) && (
                        <Grid item pt={0.7}>
                          <Checkbox name="selectAll" label={intl.formatMessage({ defaultMessage: 'Select All' })} sx={{ my: 3 }} />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Stack>

                <DialogActions sx={{ mt: 1, pb: 0 }}>
                  <Button variant="text" onClick={onClose}>
                    <FormattedMessage defaultMessage="Close" />
                  </Button>
                  <AnimateButton>
                    <Button
                      disabled={
                        isSubmitting ||
                        !selectedTitle.length ||
                        (!replyingMessage && !selectedReceivers?.length) ||
                        uploadMutation.isLoading
                      }
                      type="submit"
                      variant="contained"
                    >
                      <FormattedMessage defaultMessage="Send" />
                    </Button>
                  </AnimateButton>
                </DialogActions>
              </FormContainer>
            )}
          </Paper>
        </Rnd>
      </Backdrop>
      <BasicDialog
        open={!!error}
        maxWidth="xs"
        actions={
          <>
            <Button
              variant="contained"
              sx={{ height: 30 }}
              onClick={() => {
                setError('');
              }}
            >
              <FormattedMessage defaultMessage="OK" />
            </Button>
          </>
        }
      >
        <Typography color="#616161" sx={{ textAlign: 'center' }}>
          {error}
        </Typography>
      </BasicDialog>
    </>
  );
};

export default ComposeMessage;
