import { Box, Grid, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconPaperclip } from '@tabler/icons';
import { format } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useAuth from 'hooks/useAuth';
import { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import LinkViewButton from 'views/components/buttons/LinkViewButton';
import DraftEditor from 'views/components/common/DraftEditor';
import './style.scss';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '45%',
    maxWidth: '85%',
    marginBottom: 10,

    '& .correspondent': {
      fontWeight: 'bold',
      fontSize: 12,
      paddingLeft: 7
    },

    '& .title': {
      fontWeight: 'bold',
      fontSize: 14
    },

    '& .sentOn': {
      fontSize: 14,
      alignSelf: 'flex-end'
    }
  }
}));

interface Props {
  message: MessageType;
}

export interface MessageType {
  _id: string;
  sender: { _id: string; firstName: string; lastName: string };
  title: string;
  description: string;
  createdAt?: string;
  sentOn?: string;
  unreadBy: string;
  file: any;
  files: any;
}

export default function Message({ message }: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const { userId } = useAuth();
  const isMe = message.sender._id === userId;
  const ref = useRef<any>(null);

  const isDraftJs = useMemo(() => {
    try {
      JSON.parse(message.description);
      return true;
    } catch (error) {
      return false;
    }
  }, [message.description]);

  const files = useMemo(() => {
    const f: any = [];

    if (message.file) {
      f.push(message.file);
    }

    if (message.files?.length) {
      f.push(...message.files);
    }

    return f;
  }, [message]);

  return (
    <Stack
      spacing="4px"
      className={classes.root}
      sx={{
        alignSelf: isMe ? 'flex-end' : 'flex-start',
        padding: 4
      }}
    >
      <Box className="correspondent">
        {message.sender.firstName} {message.sender.lastName}
      </Box>
      <Stack
        spacing={1}
        sx={{
          bgcolor: (theme) => (isMe ? theme.palette.grey[100] : theme.palette.primary.light),
          padding: 2,
          borderRadius: 4,
          width: '100%'
        }}
        className={isMe ? 'talk-bubble tri-right right-top' : 'talk-bubble tri-right left-top'}
      >
        <Box
          sx={{
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
              height: 10
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ddd',
              borderRadius: 3
            },

            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            }
          }}
        >
          <Box className="title">{message.title}</Box>

          {/* eslint-disable react/no-danger */}
          {!isDraftJs ? (
            <div dangerouslySetInnerHTML={{ __html: message.description }} />
          ) : (
            <DraftEditor disabled name="message" value={message.description} style={{ padding: 0, border: 0 }} />
          )}
        </Box>
        {files.length > 0 && (
          <Grid container direction="row" alignSelf="flex-end" alignItems="flex-end" className="attachmentDoc" style={{ marginTop: 10 }}>
            <Stack direction={'row'}>
              {files.length > 1 ? (
                <Typography color={(theme) => theme.palette.primary.dark} mt={1}>
                  Attachments:
                </Typography>
              ) : (
                <Typography color={(theme) => theme.palette.primary.dark} mt={1}>
                  Attachment:
                </Typography>
              )}

              <Stack sx={{ width: 300, flexWrap: 'wrap' }} direction={'row'} gap={1}>
                {files.map((file) => (
                  <Stack key={file._id} direction={'row'} alignItems={'center'}>
                    <LinkViewButton
                      key={file._id}
                      variant="text"
                      buttonRef={ref}
                      getLinkUrl={`/user/${message.sender._id}/documents/${file._id}/get-link`}
                      label={file.name.length > 20 ? `${file.name.substring(0, 30)}...` : file.name}
                      sx={{ ':hover': { cursor: 'pointer' }, textDecoration: 'underline' }}
                    />
                    <Typography color={(theme) => theme.palette.primary.dark}>
                      <IconPaperclip key={message._id} width={16} height={16} />
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Grid>
        )}
      </Stack>
      {(message.createdAt || message.sentOn) && (
        <Box className="sentOn">
          {format(
            new Date(message.createdAt ?? message.sentOn ?? new Date()),
            intl.locale === 'fr' ? 'dd MMM yyyy, HH:mm:ss' : 'dd MMM yyyy, h:mm:ss a',
            {
              locale: intl.locale === 'fr' ? frLocale : enLocale
            }
          )}
        </Box>
      )}
    </Stack>
  );
}
