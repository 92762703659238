import { yupResolver } from '@hookform/resolvers/yup';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HomeIcon from '@mui/icons-material/Home';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Tab,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import useOptions from 'hooks/useOptions';
import useUser from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { convertImageBlobToImageFile } from 'utils/convertImageBlobToImageFile';
import { getErrorMessage } from 'utils/stringUtils';
import MainCard from 'views/components/cards/MainCard';
import CenteredLoading from 'views/components/CenteredLoading';
import QuillEditorController from 'views/components/common/QuillEditorController';
import CheckboxGroup from 'views/components/inputs/CheckboxGroup';
import FormContainer from 'views/components/inputs/FormContainer';
import SwitchField from 'views/components/inputs/Switch';
import TextField from 'views/components/inputs/TextField';
import * as Yup from 'yup';
import Categories from './CategoriesList';
import useArticles from './useArticles';
import useResource from './useResources';

function Articles() {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const [items, setItems] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { options, roles } = useOptions();
  const { resources } = useResource();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(!matchDownSM);
  const { userId, request } = useAuth();
  const { isNousSupport } = useUser(userId);
  const { category } = useParams();
  const [addingArticle, setAddingArticle] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState<any>('1');
  const uploadMutation = useMutation((formData: any) => request.post(`/user/${userId}/files`, formData));

  const hasEditAccess = useMemo(() => {
    if (isNousSupport && options?.MODIFY_RESOURCES?.value) {
      return true;
    }

    return false;
  }, [isNousSupport, options]);

  const selectedCategory = useMemo(() => {
    if (!resources || !category) return null;

    return resources.find((item) => item.url === category);
  }, [resources, category]);

  const { articles, addArticle, refetchArticles, isLoading, reorderArticles } = useArticles({ categoryId: selectedCategory?._id });
  const blobUrlMap = new Map();
  const onSubmit = async (values) => {
    try {
      const { title, titleFrench, description, descriptionFrench, isDraft, targetAudience } = values;
      let updatedDescription = description;
      if (description) {
        updatedDescription = await convertImageBlobToImageFile(description, uploadMutation, blobUrlMap);
      }
      let updatedDescriptionFrench = descriptionFrench;
      if (descriptionFrench) {
        updatedDescriptionFrench = await convertImageBlobToImageFile(descriptionFrench, uploadMutation, blobUrlMap);
      }
      const data = {
        articleId: selectedCategory.articleId,
        title,
        titleFrench,
        description: updatedDescription,
        descriptionFrench: updatedDescriptionFrench,
        isDraft,
        targetAudience
      };
      await addArticle(data);

      formContext.reset();
      setAddingArticle(false);
      refetchArticles();

      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Article added successfully' }), { variant: 'success' });
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  };
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    color: 'black',
    margin: `0 0 ${grid}px 0`,
    background: 'lightgrey',
    ...draggableStyle
  });
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '' : '',
    padding: grid,
    width: 600
  });
  const initialValues = {
    title: '',
    titleFrench: '',
    description: '',
    descriptionFrench: '',
    isDraft: false,
    targetAudience: []
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({ defaultMessage: 'Title is required' })),
    titleFrench: Yup.string(),
    description: Yup.string().required(intl.formatMessage({ defaultMessage: 'Description is required' })),
    descriptionFrench: Yup.string()
  });

  const formContext = useForm({ defaultValues: initialValues, resolver: yupResolver(validationSchema) });
  const handleReposition = () => {
    setOpen(true);
    setItems(articles);
  };

  const handleSave = async () => {
    try {
      const reorderedItems = items ? items.map((item) => item._id) : [];
      const data = { articleIds: reorderedItems };
      await reorderArticles(data);

      enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Articles updated successfully' }), { variant: 'success' });
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
    setOpen(false);
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return;
    }

    const reorderedItems = items ? Array.from(items) : [];
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);
    setItems(reorderedItems);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setOpenDrawer((pre) => !pre);
        }}
        size="large"
        aria-label="chat menu collapse"
      >
        <MenuRoundedIcon />
      </IconButton>
      <MainCard>
        <Breadcrumbs
          sx={{
            mb: 3,
            '& a:hover': {
              textDecorationLine: 'underline'
            }
          }}
        >
          <Link style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }} to="/resources">
            <HomeIcon sx={{ mr: 0.5, fontSize: 18 }} />
            <FormattedMessage defaultMessage="Resources" />
          </Link>

          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
            {selectedCategory?.title}
          </Typography>
        </Breadcrumbs>

        <Grid container style={{ width: '100%', minHeight: '100%' }}>
          {openDrawer && (
            <Grid item sx={{ width: 300, minHeight: '100%' }}>
              <Box sx={{ minHeight: '100%', p: 1 }}>
                <Categories />
              </Box>
            </Grid>
          )}

          <Grid item sx={{ flex: 1, minHeight: '100%', ml: 2 }}>
            <MainCard sx={{ minHeight: '100%', pl: 2 }}>
              {hasEditAccess && !addingArticle && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" sx={{ fontSize: 13 }} onClick={handleReposition}>
                    <FormattedMessage defaultMessage="Reposition Articles" />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ maxwidth: 15, ml: 1, fontSize: 12 }}
                    onClick={() => {
                      setAddingArticle(true);
                    }}
                    disabled={uploadMutation.isLoading}
                  >
                    <FormattedMessage defaultMessage="Add Article" />
                  </Button>
                </Box>
              )}

              {isLoading ? (
                <div style={{ width: '100%' }}>
                  <CenteredLoading />
                </div>
              ) : (
                <Typography sx={{ fontSize: 30, fontWeight: 'bold', color: ' rgb(30, 32, 40)', fontweight: 20, textAlign: 'left' }}>
                  {selectedCategory?.title}
                </Typography>
              )}
              <Typography sx={{ fontSize: 16, marginBottom: '20px', color: '#757575', mt: 2 }}> {selectedCategory?.description}</Typography>

              <Stack alignItems="flex-start">
                {articles?.map((item: any) => (
                  <Stack direction="row" alignItems="center">
                    <Button
                      sx={{
                        fontSize: 15,
                        textAlign: 'left',
                        '&:hover': { textDecoration: 'underline' }
                      }}
                      onClick={() => {
                        navigate(`/resources/${selectedCategory.url}/${item.url}`);
                      }}
                      size="large"
                    >
                      {item.title}
                    </Button>
                    {item.isDraft && (
                      <Typography
                        sx={{
                          color: '#f00',
                          fontWeight: '500'
                        }}
                        fontSize="small"
                      >
                        <FormattedMessage defaultMessage="Draft" />
                      </Typography>
                    )}
                  </Stack>
                ))}
              </Stack>
              {addingArticle && (
                <>
                  <FormContainer formContext={formContext} onSuccess={onSubmit}>
                    <Grid sx={{ mt: 3, display: 'flex' }} item xs={12} justifyContent="flex-end">
                      <SwitchField name="isDraft" label={intl.formatMessage({ defaultMessage: 'Draft Mode' })} />
                    </Grid>

                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                          <Tab label="EN" value="1" />
                          <Tab label="FR" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <Grid sx={{ mt: 3 }} item xs={12}>
                          <TextField name="title" label={intl.formatMessage({ defaultMessage: 'Title' })} />
                        </Grid>
                        <Grid item display="flex" flexDirection="column" xs={12} mt={4}>
                          <Box sx={{ '& .ql-editor': { minHeight: 300 } }}>
                            <QuillEditorController name="description" enableLink />
                          </Box>

                          {formContext.formState.errors.description && (
                            <Box sx={{ mt: 3 }}>
                              <FormHelperText error>{String(formContext.formState.errors.description.message)}</FormHelperText>
                            </Box>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value="2">
                        <Grid sx={{ mt: 3 }} item xs={12}>
                          <TextField name="titleFrench" label={intl.formatMessage({ defaultMessage: 'Title' })} />
                        </Grid>
                        <Grid item display="flex" flexDirection="column" xs={12} mt={4}>
                          <Box sx={{ '& .ql-editor': { minHeight: 300 } }}>
                            <QuillEditorController name="descriptionFrench" enableLink />
                          </Box>

                          {formContext.formState.errors.description && (
                            <Box sx={{ mt: 3 }}>
                              <FormHelperText error>{String(formContext.formState.errors.description.message)}</FormHelperText>
                            </Box>
                          )}
                        </Grid>
                      </TabPanel>
                    </TabContext>

                    <DialogContent>
                      <CheckboxGroup
                        name="targetAudience"
                        label={intl.formatMessage({ defaultMessage: 'Target Audience' })}
                        options={
                          roles?.map((role: any) => ({
                            label: intl.locale === 'fr' ? role?.roleNameFrench : role.roleName,
                            id: role.roleName
                          })) ?? []
                        }
                        row
                      />
                    </DialogContent>
                    <Stack direction="row" mt={4} mb={6} spacing={2} justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setAddingArticle(false);
                        }}
                      >
                        <FormattedMessage defaultMessage="Cancel" />
                      </Button>
                      <Button type="submit" variant="contained">
                        <FormattedMessage defaultMessage="Add Article" />
                      </Button>
                    </Stack>
                  </FormContainer>
                </>
              )}
            </MainCard>
          </Grid>
        </Grid>
      </MainCard>
      <Dialog sx={{ cursor: 'pointer' }} open={open} onClose={() => setOpen(true)} fullWidth>
        <DragIndicatorIcon sx={{ color: '#337ab7', position: 'absolute', ml: 5, mt: 2 }} />
        <Typography sx={{ fontWeight: 700, fontSize: 20, textAlign: 'center', color: 'black', padding: 2 }}>
          <FormattedMessage defaultMessage={'Drag and Drop & Change the position of Articles'} />
        </Typography>
        <Divider sx={{ mt: 2 }} />

        <>
          <Divider />
        </>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {items.map((item, index) => (
                  <Draggable key={item._id} draggableId={item._id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <Typography sx={{ display: 'flex' }}>
                          <DragIndicatorIcon sx={{ color: '#337ab7' }} />
                          <Typography sx={{ mt: '2px', ml: 1 }}>{item.title}</Typography>
                        </Typography>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <DialogActions>
          <Button variant="contained" onClick={handleSave}>
            <FormattedMessage defaultMessage={'Save'} />
          </Button>

          <Button variant="outlined" onClick={() => setOpen(false)}>
            <FormattedMessage defaultMessage={'Close'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Articles;
