/**
 * Session Setttings Tab
 */
import { Box, Grid, MenuItem, Select, Tab, Tabs } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import { SessionContext } from 'contexts/SessionContext';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface TabPanelProps {
  children?: React.ReactNode;
  value: Number;
  index: Number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      <Box>{children}</Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  menu: {
    maxWidth: 200,
    textOverflow: 'ellipsis'
  },
  listItem: {
    overflowX: 'auto',
    textOverflow: 'ellipsis'
  },
  selectedItem: {
    maxWidth: 350,
    textOverflow: 'ellipsis'
  }
}));

const SessionSettings = () => {
  const classes = useStyles();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<any>(undefined);
  const [selectedMic, setSelectedMic] = useState<any>(undefined);
  const [selectedSpeaker, setSelectedSpeaker] = useState<any>(undefined);

  useEffect(() => {
    /**
     * Get media devices
     */
    const getDevices = () => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        setDevices(devices);
      });
    };

    getDevices();

    // Detect camera/mic change
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
  }, [setSelectedSpeaker, setSelectedMic, setSelectedCamera]);

  const audioOutputDevices = devices?.filter((device) => device.kind === 'audiooutput');

  const audioInputDevices = devices?.filter((device) => device.kind === 'audioinput');

  const videoInputDevices = devices?.filter((device) => device.kind === 'videoinput');

  useEffect(() => {
    !selectedSpeaker && audioOutputDevices?.length && setSelectedSpeaker(audioOutputDevices[0].deviceId);
    !selectedMic && audioInputDevices?.length && setSelectedMic(audioInputDevices[0].deviceId);
    !selectedCamera && videoInputDevices?.length && setSelectedCamera(videoInputDevices[0].deviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioInputDevices, audioOutputDevices, videoInputDevices]);

  const intl = useIntl();

  const [value, setValue] = useState(0);

  return (
    <SessionContext.Provider value={{ selectedCamera, selectedMic, selectedSpeaker }}>
      <Tabs
        value={value}
        onChange={(e, index) => {
          setValue(index);
        }}
        variant="fullWidth"
        aria-label="Session Side Drawer"
      >
        <Tab label={intl.formatMessage({ defaultMessage: 'Audio' })} />
        <Tab label={intl.formatMessage({ defaultMessage: 'Video' })} />
      </Tabs>
      <TabPanel index={0} value={value}>
        <Grid container direction="column">
          <Grid item xs={6}>
            <FormLabel component="legend">
              <FormattedMessage defaultMessage="Speaker" />
            </FormLabel>
            <Select
              aria-label="speaker"
              name="speaker"
              value={selectedSpeaker || (audioOutputDevices?.length && audioOutputDevices[0].deviceId)}
              onChange={(e) => setSelectedSpeaker(e.target.value)}
              fullWidth
              MenuProps={{ className: classes.menu, container: document.fullscreenElement ?? document.body }}
              classes={{ select: classes.selectedItem }}
            >
              {audioOutputDevices?.map((device, key) => (
                <MenuItem key={device.deviceId} value={device.deviceId} className={classes.listItem}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <FormLabel component="legend">
              <FormattedMessage defaultMessage="Microphone" />
            </FormLabel>
            <Select
              fullWidth
              aria-label="microphone"
              name="microphone"
              value={selectedMic || (audioInputDevices?.length && audioInputDevices[0].deviceId)}
              onChange={(e) => setSelectedMic(e.target.value)}
              MenuProps={{ className: classes.menu, container: document.fullscreenElement ?? document.body }}
              classes={{ select: classes.selectedItem }}
            >
              {audioInputDevices?.map((device, key) => (
                <MenuItem key={device.deviceId} value={device.deviceId} className={classes.listItem}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <FormLabel component="legend">
            <FormattedMessage defaultMessage="Camera" />
          </FormLabel>
          <Select
            fullWidth
            aria-label="camera"
            name="camera"
            value={selectedCamera || (videoInputDevices?.length && videoInputDevices[0].deviceId)}
            onChange={(e) => setSelectedCamera(e.target.value)}
            MenuProps={{ className: classes.menu, container: document.fullscreenElement ?? document.body }}
            classes={{ select: classes.selectedItem }}
          >
            {videoInputDevices?.map((device, key) => (
              <MenuItem key={device.deviceId} value={device.deviceId} className={classes.listItem}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </TabPanel>
    </SessionContext.Provider>
  );
};

export default SessionSettings;
