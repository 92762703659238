import { useQuery } from 'react-query';
import { UserAnnouncement } from 'types/announcement';
import useAuth from './useAuth';

type Props = {
  userAnnouncementId: string;
};

export default function useUserAnnouncement({ userAnnouncementId }: Props) {
  const { request } = useAuth();

  const { data: userAnnouncement, isLoading } = useQuery(
    `/user-announcements/${userAnnouncementId}`,
    () => request.get(`/user-announcements/${userAnnouncementId}`).then(({ data }) => data as UserAnnouncement),
    {
      enabled: !!userAnnouncementId
    }
  );

  return { userAnnouncement, isLoading };
}
