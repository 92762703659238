import { createContext } from 'react';
type InitialValuesType = {
  selectedMic: any;
  selectedCamera: any;
  selectedSpeaker: any;
};
const initialValues: InitialValuesType = {
  selectedMic: undefined,
  selectedCamera: undefined,
  selectedSpeaker: undefined
};

export const SessionContext = createContext(initialValues);
