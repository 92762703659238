import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
type Props = {
  categoryId?: string | null;
  articleId?: string | null;
};

export default function useArticle({ categoryId, articleId }: Props) {
  const { request } = useAuth();

  const {
    data: article,
    refetch: refetchArticle,
    isRefetching,
    isLoading
  } = useQuery(
    `/resources/${categoryId}/articles/${articleId}`,
    () => request.get(`/resources/${categoryId}/articles/${articleId}`).then((response) => response.data.article),
    {
      enabled: !!categoryId && !!articleId
    }
  );

  const { mutateAsync: deleteArticle } = useMutation(() => request.delete(`/resources/${categoryId}/articles/${articleId}`));

  const { mutateAsync: updateArticle } = useMutation((payload: any) =>
    request.put(`/resources/${categoryId}/articles/${articleId}`, payload)
  );

  return { article, refetchArticle, deleteArticle, updateArticle, isLoading: isLoading || isRefetching };
}
