// third-party
import axios, { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { connectSocket, disconnectSocket } from 'socket';
import { store } from 'store';
import { ACCOUNT_INITIALIZE, LOGOUT } from 'store/actions';
import accountReducer from 'store/reducers/accountReducer';
// reducer - state management
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';
import { MutateOptions } from 'react-query';
import { InitialLoginContextProps, KeyedObject } from 'types';
import getSite from 'utils/site';
import Loader from 'views/components/Loader';
// constant
const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/${getSite()}`
};

const request = axios.create(config);
const guestRequest = axios.create(config);

function languageInterceptor(config) {
  // Add the lang parameter to the request's URL parameters

  const lang = store.getState()?.customization?.locale ?? 'fr';

  config.params = {
    ...config.params,
    lang
  };

  return config;
}

guestRequest.interceptors.request.use(languageInterceptor, (error) => {
  return Promise.reject(error);
});

request.interceptors.request.use(languageInterceptor, (error) => {
  return Promise.reject(error);
});

const initialState: InitialLoginContextProps = {
  login: (e: string, p: string) => Promise.resolve(),
  isLoggedIn: false,
  isInitialized: false,
  userId: undefined,
  token: undefined,
  request,
  guestRequest
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);
  /**
   * Property 'iat' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   * Enable token a day = 24 hours * 60 minutes * 60 seconds = 86400
   */
  return decoded.iat + 86400 > Date.now() / 1000;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext({
  ...initialState,
  logout: (idleLogout: boolean = false) => Promise.resolve(),
  signupClient: (data: any, therapistId: string) => Promise.resolve(),
  emailCheckVerify: (data: any) => Promise.resolve(),
  signupProvider: (data: any) => Promise.resolve(),
  contactus: (data: any) => Promise.resolve(),
  contactMeList: (therapistId: string) => Promise.resolve(),
  waitingList: (therapistId: string) => Promise.resolve(),
  getdata: (data: string) => Promise.resolve(),
  updatedata: (data: any, patientId: string) => Promise.resolve(),
  verifyEmail: (userId: string, challenge: string) => Promise.resolve(),
  forgotPassword: (email: string) => Promise.resolve(),
  resetPassword: (address: string, password: string, challenge: string) => Promise.resolve(),
  createPassword: (data: any, clientId: string) => Promise.resolve(),
  setSession: (token?: string, id?: string) => {},
  resendVerifyMail: (email: string, challenge: string) => Promise.resolve()
});

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // const { data: user } = useQuery(
  //   `/user/${userId}`,
  //   () => request.get(`/user/${userId}`).then((response) => response.data as User & { shouldAcceptIC?: boolean }),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //     enabled: typeof userId === 'string' && typeof token === 'string'
  //   }
  // );

  const loginAPI = useMutation(
    (params: { email: string; password: string; login2fa?: string; resetChallenge?: string; secondaryEmail?: string }) =>
      request.post(`/authentication`, params)
  );
  const logoutAPI = useMutation(() => request.post('/authentication/logout'));
  const signupClientAPI = useMutation(({ data, therapistId }: { data: any; therapistId: string }) =>
    request.post(`/therapists/${therapistId}/patients`, data)
  );
  const emailCheckVerifyAPI = useMutation(({ email }: { email: any }) => request.post(`/validations/email/verification`, email));
  const contactusAPI = useMutation(({ data }: { data: any }) => request.post(`/contactus`, data));
  const contactMeListAPI = useMutation((params: { therapistId: string }) =>
    request.get(`/contactus/contactMeList?therapistId=` + params.therapistId)
  );
  const waitingListAPI = useMutation((params: { therapistId: string }) => request.get(`/user/waiting?therapistId=` + params.therapistId));
  const getdataAPI = useMutation((params: { patientId: string }) => request.get(`/user/${params.patientId}/get-data`));
  const updatedataAPI = useMutation(({ data, patientId }: { data: any; patientId: string }) =>
    request.put(`/user/${patientId}/update-data`, data)
  );
  const signupProviderAPI = useMutation(({ data }: { data: any }) => request.post(`/user`, data));
  const verifyAPI = useMutation((params: { userId: string; challenge: string }) =>
    request.post(`/user/${params.userId}/verified`, { challenge: params.challenge })
  );
  const forgotPasswordAPI = useMutation((params: { email: string }) =>
    request.post(`/passwordreset?address=${encodeURIComponent(params.email)}`)
  );
  const resetPasswordAPI = useMutation((params: { address: string; password: string; challenge: string }) =>
    request.post(`/passwordchange`, params)
  );
  const createPasswordAPI = useMutation(({ data, clientId }: any) => request.post(`/user/${clientId}/password`, data));

  const resendVerifyMailAPI = useMutation((params: { email: string; challenge: string }) => request.post(`/resend-verify-mail`, params));

  const setSession = useCallback((serviceToken?: string, id?: string) => {
    if (serviceToken && id) {
      localStorage.setItem('serviceToken', serviceToken);
      localStorage.setItem('userId', id);
    } else {
      localStorage.removeItem('serviceToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('shownAnnouncementAlerts');
    }

    setToken(serviceToken);
    setUserId(id);
  }, []);

  useEffect(() => {
    request.defaults.headers.common.Authorization = `Bearer ${token}`;
  }, [token]);

  useEffect(() => {
    if (!token) {
      const serviceToken = window.localStorage.getItem('serviceToken');
      const id = window.localStorage.getItem('userId');
      if (serviceToken && id) {
        setSession(serviceToken, id);
      } else {
        dispatch({
          type: ACCOUNT_INITIALIZE,
          payload: {
            ...state,
            isLoggedIn: false,
            token: undefined,
            userId: undefined
          }
        });
      }
    } else if (token && verifyToken(token)) {
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          ...state,
          isLoggedIn: true,
          token,
          userId
        }
      });
    } else {
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          ...state,
          isLoggedIn: false,
          token: undefined,
          userId: undefined
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialized, token, userId, setSession]);

  useEffect(() => {
    if (userId) {
      connectSocket();
    } else {
      disconnectSocket();
    }
  }, [userId]);

  const login = async (email: string, password: string, login2fa?: string, resetChallenge?: string, secondaryEmail?: string) =>
    loginAPI.mutateAsync(
      { email, password, login2fa, resetChallenge, secondaryEmail },
      {
        onSuccess: (response) => {
          const { token, id } = response.data;
          setSession(token, id);
        }
      }
    );

  const signupClient = async (data: any, therapistId: string) => {
    const response = await signupClientAPI.mutateAsync({ data, therapistId });
    return response.data.isValid;
  };

  const emailCheckVerify = async (email: any) => {
    const response = await emailCheckVerifyAPI.mutateAsync({ email });
    return response.data;
  };

  const updatedata = async (data: any, patientId: string) => {
    const response = await updatedataAPI.mutateAsync({ data, patientId });
    return response.data;
  };
  const contactMeList = async (therapistId: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    const response = await contactMeListAPI.mutateAsync({ therapistId }, {
      headers
    } as MutateOptions<AxiosResponse<any>, unknown, unknown>);

    return response.data;
  };
  const waitingList = async (therapistId: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    const response = await waitingListAPI.mutateAsync({ therapistId }, {
      headers
    } as MutateOptions<AxiosResponse<any>, unknown, unknown>);

    return response.data;
  };
  const getdata = async (patientId: string) => {
    const response = await getdataAPI.mutateAsync({ patientId });
    return response.data;
  };
  const contactus = async (data: any) => {
    const response = await contactusAPI.mutateAsync({ data });
    return response.data;
  };
  const signupProvider = async (data: any) => {
    const response = await signupProviderAPI.mutateAsync({ data });
    return response.data;
  };

  const verifyEmail = async (id: string, challenge: string) => {
    const response = await verifyAPI.mutateAsync({ userId: id, challenge });
    return response.data;
  };

  const forgotPassword = async (email: string) => {
    await forgotPasswordAPI.mutateAsync({ email });
    enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Forgot password email sent' }), { variant: 'success' });
  };

  const resetPassword = async (address: string, password: string, challenge: string) => {
    await resetPasswordAPI.mutateAsync({ address, password, challenge });
    await login(address, password, undefined, challenge);
  };

  const createPassword = async ({ username, ...data }: any, clientId: string) => {
    const result = await createPasswordAPI.mutateAsync({ data, clientId });

    const email = result?.data?.email || username;

    if (email && email !== 'null') {
      await login(email, data?.password);
    }
  };

  const logout = async (idleLogout: boolean = false) => {
    dispatch({
      type: LOGOUT
    });
    // Logout api call
    await logoutAPI.mutateAsync();
    setTimeout(() => {
      // Invalidate cache for user call
      queryClient.removeQueries('/user');
      setSession(undefined, undefined);
    }, 500);

    if (idleLogout) {
      navigate('/login?idle=1');
    } else {
      navigate('/login');
    }
  };

  const resendVerifyMail = async (email: any, challenge: string) => {
    const response = await resendVerifyMailAPI.mutateAsync({ email, challenge });

    return response.data as any;
  };

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        signupClient,
        emailCheckVerify,
        contactus,
        contactMeList,
        waitingList,
        getdata,
        updatedata,
        signupProvider,
        verifyEmail,
        forgotPassword,
        resetPassword,
        createPassword,
        setSession,
        resendVerifyMail
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
