import HomeIcon from '@mui/icons-material/Home';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import MainCard from 'views/components/cards/MainCard';
import CenteredLoading from 'views/components/CenteredLoading';
import FormContainer from 'views/components/inputs/FormContainer';
import ListSkeleton from 'views/components/skeleton/ListSkeleton';

export const highlight = (text, query) => {
  if (!query || !text) return text;

  return text.replaceAll(
    new RegExp(`(?<!<img\\s.*)(\\b${query.replace(/[^a-zA-Z0-9 ]/g, '').trim()}\\b)(?![^<]*>)`, 'gi'),
    (match) => `<span style="background-color: yellow">${match}</span>`
  );
};

function Searchcategorylist({
  search,
  selectedArtcles,
  selectedCategory,
  refetchResourcesData,
  searchQuery,
  isLoading,
  setSearchQuery,
  categories,
  setSelectedCategory
}: any) {
  const navigate = useNavigate();

  const intl = useIntl();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(!matchDownSM);

  const onFormSubmit = async () => {
    if (search.trim() !== '') {
      navigate(`/resources/search?q=${encodeURIComponent(search.replace(/[^a-zA-Z0-9 ]/g, ''))}`);
      await refetchResourcesData();
    }
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setOpenDrawer((pre) => !pre);
        }}
        size="large"
        aria-label="chat menu collapse"
      >
        <MenuRoundedIcon />
      </IconButton>
      <MainCard>
        <Breadcrumbs
          sx={{
            mb: 3,
            '& a:hover': {
              textDecorationLine: 'underline'
            }
          }}
        >
          <Link style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }} to="/resources">
            <HomeIcon sx={{ mr: 0.5, fontSize: 18 }} />
            <FormattedMessage defaultMessage="Resources" />
          </Link>
        </Breadcrumbs>
        <Grid item sx={{ display: 'flex', justifyContent: 'end', mt: -6, mb: 2 }}>
          <FormContainer onSuccess={onFormSubmit}>
            <TextField
              sx={{ width: 280 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              placeholder={intl.formatMessage({ defaultMessage: 'Search Articles / Resources' })}
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            {/* Display your filtered results here */}
          </FormContainer>
        </Grid>
        {isLoading ? (
          <CenteredLoading />
        ) : (
          <>
            {categories && categories.length > 0 ? (
              <Grid container style={{ width: '100%', minHeight: '100%' }}>
                {openDrawer && (
                  <Grid item sx={{ width: 300, minHeight: '100%' }}>
                    <Box>
                      <Grid item sx={{ width: 300, minHeight: '100%' }}>
                        <Typography
                          sx={{
                            fontSize: 25,
                            color: '#1e2028',
                            fontWeight: 'bold',
                            lineHeight: '1.2',
                            mt: 2
                          }}
                        >
                          <FormattedMessage defaultMessage="Categories" />
                        </Typography>
                        <>
                          {categories?.map((item: any) => (
                            <Button
                              onClick={() => setSelectedCategory(item._id)}
                              key={item._id}
                              sx={{
                                textAlign: 'left',
                                color: item._id === selectedCategory ? '#4b7bb7' : '#858585',
                                fontWeight: item._id === selectedCategory ? 'bold' : '',
                                backgroundColor: item._id === selectedCategory ? '#e2f2fd' : '',
                                padding: item._id === selectedCategory ? 1 : 1,
                                borderRadius: item._id === selectedCategory ? 3 : 3,
                                width: item._id === selectedCategory ? 280 : 280,
                                cursor: 'pointer',
                                fontSize: 17,
                                display: 'flex',
                                flexWrap: 'wrap',
                                mt: 1,
                                '&:hover': {
                                  color: '#4b7bb7',
                                  backgroundColor: '#e2f2fd'
                                }
                              }}
                            >
                              {item.title}
                            </Button>
                          ))}
                        </>
                      </Grid>
                    </Box>
                  </Grid>
                )}

                <Grid item sx={{ flex: 1, minHeight: '100%', ml: 2 }}>
                  <MainCard sx={{ minHeight: '100%', pl: 2 }}>
                    {!selectedArtcles ? (
                      <ListSkeleton />
                    ) : (
                      <>
                        {selectedArtcles && selectedArtcles.length > 0 ? (
                          <Stack alignItems="flex-start" sx={{ height: 650, overflowY: 'auto' }}>
                            {selectedArtcles?.map((item: any) => (
                              <Fragment key={item._id}>
                                <Stack direction="row" alignItems="center">
                                  <Link to={`/resources/${item.resourceUrl}/${item.url}?q=${encodeURIComponent(search)}`}>
                                    <Typography
                                      sx={{
                                        fontSize: 16,
                                        color: ' #1F73B7',
                                        textAlign: 'left',
                                        '&:hover': {
                                          cursor: 'pointer',
                                          textDecoration: 'underline',
                                          color: ' #464646',
                                          fontFamily: ' sans-serif ',

                                          lineHeight: '1.5'
                                        }
                                      }}
                                    >
                                      <span dangerouslySetInnerHTML={{ __html: highlight(item.title, searchQuery) }} />
                                    </Typography>
                                  </Link>
                                </Stack>

                                <Typography sx={{ fontSize: 14, marginBottom: '15px', color: '#757575', mt: 1 }}>
                                  <span dangerouslySetInnerHTML={{ __html: highlight(item.description, searchQuery) }} />
                                </Typography>
                              </Fragment>
                            ))}
                          </Stack>
                        ) : (
                          <Box sx={{ height: 650, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography>
                              <FormattedMessage defaultMessage="No articles to show" />
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </MainCard>
                </Grid>
              </Grid>
            ) : (
              <MainCard>
                <Box sx={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FormattedMessage defaultMessage="No results found" />
                </Box>
              </MainCard>
            )}
          </>
        )}
      </MainCard>
    </>
  );
}

export default Searchcategorylist;
