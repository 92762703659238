import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  createInlineStyleButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton
} from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import StrikethroughS from '@mui/icons-material/StrikethroughSSharp';
import { Box } from '@mui/material';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

interface Props {
  name: string;
  value: string;
  focus?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  minHeight?: number;
  maxHeight?: number;
  hasHtml?: boolean;
}

const StrikeThroughButton = createInlineStyleButton({
  style: 'STRIKETHROUGH',
  children: <StrikethroughS />
});

const isHTML = RegExp.prototype.test.bind(/^(<([^>]+)>)$/i);

const DraftEditor: React.FC<Props> = ({
  name,
  value,
  focus,
  disabled = false,
  onChange,
  maxHeight = window.innerHeight - 300,
  style,
  hasHtml
}) => {
  const formContext = useFormContext();
  const { setValue } = formContext || {};
  const [content, setContent] = useState<EditorState>(() => EditorState.createEmpty());
  const strData: any = useRef();

  const [initialized, setInitialized] = useState(false); // To fix plugin library bug
  const editor: any = useRef(null);

  useEffect(() => {
    if (value !== null && value !== strData.current) {
      let contentState: EditorState | null = null;
      try {
        const data = convertFromRaw(JSON.parse(value));
        contentState = EditorState.createWithContent(data);
        const content = contentState.getCurrentContent();
        const text = content.getPlainText();

        // If the text content included a json property, then we had a nesting save issue
        if (text && text.includes('blockMap')) {
          contentState = EditorState.createEmpty();
        }
        if (isHTML(text)) {
          const blocksFromHtml = htmlToDraft(value);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const data = ContentState.createFromBlockArray(contentBlocks, entityMap);
          contentState = EditorState.createWithContent(data);
        }
      } catch (e) {
        if (hasHtml) {
          const blocksFromHtml = htmlToDraft(value);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const data = ContentState.createFromBlockArray(contentBlocks, entityMap);
          contentState = EditorState.createWithContent(data);
        } else {
          const md = stateFromMarkdown(value);
          contentState = EditorState.createWithContent(md);
        }
      }

      setContent(contentState);
      setTimeout(() => {
        if (contentState) {
          setContent(EditorState.moveFocusToEnd(contentState));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (editor && focus) {
      editor.current.focus();
    }
  }, [editor, focus]);

  const handleChange = useCallback(
    (data: EditorState) => {
      if (initialized) {
        strData.current = JSON.stringify(convertToRaw(data.getCurrentContent()));
        setContent(data);

        if (setValue) {
          setValue(name, strData.current);
        }
        if (onChange) {
          onChange(strData.current);
        }
      } else {
        setInitialized(true);
      }
    },
    [initialized, name, setValue, onChange]
  );

  const handleClick = () => {
    editor?.current?.focus();
  };

  return (
    <Box
      borderRadius={3}
      border="solid 1px"
      borderColor="#9e9e9ea8"
      onClick={handleClick}
      p={2}
      sx={{
        height: '100%',
        '& .DraftEditor-root': {
          flex: 1,
          overflow: 'auto',
          lineHeight: '1.5rem',
          height: '100%'
        },
        '& .public-DraftEditor-content': {
          maxHeight,
          overflow: 'auto'
        }
      }}
      style={style}
    >
      {!disabled && (
        <Box pb={2}>
          <Toolbar>
            {(externalProps) => (
              <>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <StrikeThroughButton {...externalProps} />
                <CodeButton {...externalProps} />
                <Separator />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <Separator />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
                <CodeBlockButton {...externalProps} />
              </>
            )}
          </Toolbar>
        </Box>
      )}

      <Editor ref={editor} readOnly={disabled} editorState={content} spellCheck plugins={[staticToolbarPlugin]} onChange={handleChange} />
    </Box>
  );
};

export default DraftEditor;
