import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import useAdminDashboardProviderList from 'hooks/useAdminDashboardProviderList';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import NoResults from 'views/components/common/NoResults';

interface Column {
  id: 'name' | 'totalPatients' | 'totalAppointment' | 'totalSessions' | 'totalActive' | 'inActiveClient' | 'status';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

function createData(
  name: string,
  totalPatients: number,
  totalAppointment: number,
  totalSessions: number,
  totalActive: number,
  inActiveClient: number,
  status: boolean
) {
  return { name, totalPatients, totalAppointment, totalSessions, totalActive, inActiveClient, status };
}

function ProviderListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { providerList } = useAdminDashboardProviderList();
  const intl = useIntl();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const navigate = useNavigate();
  const rows = providerList?.map((provider: any) =>
    createData(
      `${provider.firstName} ${provider.lastName}`,
      provider.totalClient,
      provider.totalAppointment,
      provider.totalSession,
      provider.activeClients,
      provider.inActiveClient,
      provider.status
    )
  );

  const columns: Column[] = [
    { id: 'name', label: intl.formatMessage({ defaultMessage: 'Providers' }) },
    {
      id: 'status',
      label: intl.formatMessage({ defaultMessage: 'Status' }),
      minWidth: 100,
      format: (value: boolean) =>
        value ? intl.formatMessage({ defaultMessage: 'Inactive' }) : intl.formatMessage({ defaultMessage: 'Active' })
    },
    { id: 'totalPatients', label: intl.formatMessage({ defaultMessage: 'Total Clients' }) },
    {
      id: 'totalActive',
      label: intl.formatMessage({ defaultMessage: 'Active Clients' }),
      minWidth: 100
    },
    {
      id: 'inActiveClient',
      label: intl.formatMessage({ defaultMessage: 'Inactive/Disable Clients' }),
      minWidth: 100
    },
    {
      id: 'totalAppointment',
      label: intl.formatMessage({ defaultMessage: 'Total Appointments' }),
      minWidth: 100
    }
  ];
  const statusColors = {
    [intl.formatMessage({ defaultMessage: 'Active' }).toLowerCase()]: '#ECFDF3',
    [intl.formatMessage({ defaultMessage: 'Inactive' }).toLowerCase()]: '#FEF3F2'
  };

  const statusTextColors = {
    [intl.formatMessage({ defaultMessage: 'Active' }).toLowerCase()]: '#039855',
    [intl.formatMessage({ defaultMessage: 'Inactive' }).toLowerCase()]: '#FF6666'
  };

  const handleNavigate = () => {
    navigate('/clinic-accounts?tab=profile');
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '117%', height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '16px', overflow: 'hidden' }}>
      <TableContainer
        sx={{ maxHeight: '100%', flexGrow: 1, borderRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={{ backgroundColor: '#F8F8F8' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{intl.formatMessage({ defaultMessage: 'Providers' })}</Typography>
                  <Button onClick={handleNavigate} endIcon={<ArrowOutwardIcon />}>
                    {intl.formatMessage({ defaultMessage: 'More' })}
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <NoResults>{intl.formatMessage({ defaultMessage: 'No Provider Found' })}</NoResults>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                    sx={column.id === 'status' ? { pl: 3 } : undefined}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          {rows?.length !== 0 && (
            <TableBody>
              {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ height: 70 }}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'status') {
                        const status = value
                          ? intl.formatMessage({ defaultMessage: 'Inactive' })
                          : intl.formatMessage({ defaultMessage: 'Active' });
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Chip
                              label={status}
                              size="small"
                              sx={{
                                backgroundColor: statusColors[status.toLowerCase()],
                                color: statusTextColors[status.toLowerCase()],
                                '&:hover': {
                                  backgroundColor: statusColors[status.toLowerCase()],
                                  color: statusTextColors[status.toLowerCase()]
                                }
                              }}
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          SelectProps={{
            sx: { display: 'none' }
          }}
        />
      </Box>
    </Paper>
  );
}
export { ProviderListTable };
