import DefaultPersonIcon from '@mui/icons-material/Person';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef } from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    objectFit: 'cover',
    zIndex: 3,
    transform: 'scaleX(1)',
    border: '1px solid black',
    borderRadius: '15px'
  }
});

export default function CameraPreview({ selectedCamera, setCameraGranted, defaultImage, publishVideo, source }) {
  const classes = useStyles();
  const mediaStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedCamera }
        });
        mediaStreamRef.current = mediaStream;

        const video = document.querySelector('#cameraPreview') as HTMLVideoElement;
        if (!video) {
          return;
        }
        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
          video.play();
        };
        setCameraGranted(true);
      } catch (error: any) {
        console.error(error?.name, ':', error?.message);
        if (error?.name === 'NotAllowedError' || error?.name === 'NotFoundError') {
          setCameraGranted(false);
        }
      }
    };
    if (publishVideo) {
      getMedia();
    } else {
      mediaStreamRef.current?.getTracks()?.forEach((track) => {
        track.stop();
      });
    }

    return () => {
      if (publishVideo) {
        mediaStreamRef.current?.getTracks()?.forEach((track) => {
          track.stop();
        });
      }
    };
  }, [selectedCamera, setCameraGranted, publishVideo]);

  return publishVideo ? (
    <video
      id="cameraPreview"
      className={classes.root}
      style={{ position: source === 'screenName' ? 'static' : 'absolute', transform: 'scaleX(-1)' }}
    />
  ) : defaultImage ? (
    <img
      id="cameraPreview"
      className={classes.root}
      src={defaultImage}
      style={{ position: source === 'screenName' ? 'static' : 'absolute', objectFit: 'contain' }}
      alt="camera-preview"
    />
  ) : (
    <DefaultPersonIcon
      id="cameraPreview"
      sx={{ color: 'white', borderRadius: '50%', background: 'black', borderColor: '1px solid black' }}
      className={classes.root}
      style={{ position: source === 'screenName' ? 'static' : 'absolute', objectFit: 'contain' }}
    />
  );
}
