import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr-CA';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import NoResults from 'views/components/common/NoResults';
import useTherapistPatients from 'views/pages/client-record/useTherapistPatients';

interface Column {
  id: 'clientName' | 'date' | 'providerName';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

function createData(clientName: string, date: string, providerName: string) {
  return { clientName, date, providerName };
}

function RecentClient() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isTabletScreen, setIsTabletScreen] = React.useState(false);
  const { userId } = useAuth();
  const { timezone } = useTimezone();

  const intl = useIntl();
  const navigate = useNavigate();
  let { patients } = useTherapistPatients(userId);

  const columns: Column[] = [
    { id: 'clientName', label: intl.formatMessage({ defaultMessage: 'Client Name' }) },
    {
      id: 'date',
      label: intl.formatMessage({ defaultMessage: 'Registration Date' }),
      minWidth: 100
    },
    {
      id: 'providerName',
      label: intl.formatMessage({ defaultMessage: 'Primary Providers' }),
      minWidth: 100
    }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  patients = patients?.sort((a: any, b: any) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any));
  const rows = patients?.map((patient: any) => {
    const formattedDate = patient.createdAt
      ? format(utcToZonedTime(patient.createdAt, timezone), intl.locale === 'fr' ? 'do MMMM yyyy' : 'MMMM do yyyy', {
          locale: intl.locale === 'fr' ? frLocale : enLocale
        })
      : '-';

    return createData(patient.firstName + ' ' + patient.lastName, formattedDate || '-', patient?.therapist?.fullName || '-');
  });

  const handleNavigate = () => {
    navigate('/client-record?tab=Info');
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    const handleResize = (event) => {
      if (event.target.innerWidth < 1466) {
        setIsTabletScreen(true);
      } else {
        setIsTabletScreen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  return (
    <Paper
      sx={{
        width: isTabletScreen ? '100%' : '117%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        overflow: 'hidden'
      }}
    >
      <>
        <TableContainer
          sx={{ maxHeight: '100%', flexGrow: 1, borderRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ backgroundColor: '#F8F8F8' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                      {intl.formatMessage({ defaultMessage: 'Recent Clients' })}
                    </Typography>
                    <Button onClick={handleNavigate} endIcon={<ArrowOutwardIcon />}>
                      {intl.formatMessage({ defaultMessage: 'More' })}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
              {rows?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <NoResults>{intl.formatMessage({ defaultMessage: 'No Recent Client Found' })}</NoResults>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            {rows?.length !== 0 && (
              <TableBody>
                {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ height: 70 }}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <TablePagination
            // rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            SelectProps={{
              sx: { display: 'none' }
            }}
          />
        </Box>
      </>
    </Paper>
  );
}
export { RecentClient };
