import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import { useQuery } from 'react-query';

export default function useGroups(id?: string) {
  const { userId, request } = useAuth();
  const { isProvider } = useUser(userId);
  const url = `/user/${id ?? userId}/groups`;

  const {
    data: groups,
    isLoading: groupsLoading,
    refetch: refetchGroups
  } = useQuery(['appointments', url], () => request.get(url).then((r) => r.data._embedded.items as Group[]), {
    enabled: isProvider && !!userId && !url.includes('undefined')
  });

  return { groups, refetchGroups, groupsLoading };
}

export interface Group {
  id: string;
  name: string;
  members: string[];
  emails: string[];
}
